<template>
  <div>
    <v-tooltip top v-if="item && item.name">
      <template v-slot:activator="{ on }">
        <v-chip
          small
          class="primaryText--text"
          outlined
          v-on="on"
          style="border: none; font-size: 11px"
        >
          <v-avatar
            size="22"
            :color="item && item.avatar ? 'white' : 'secondary'"
            left
          >
            <v-img v-if="item.avatar" :src="item.avatar" contain></v-img>
            <h3 v-else style="color: white">
              {{ item.name.charAt(0) }}
            </h3>
          </v-avatar>
          {{item.name}} {{item.surname}}
        </v-chip>
      </template>
      <span>{{ item.name }} {{ item.surname }}</span>
    </v-tooltip>
    <h4 v-else-if="group">
        {{ group }}
    </h4>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: null,
    group: null
  }),
  mounted() {
    this.item = {
      avatar: this.params.avatar,
        name: this.params.name,
        surname: this.params.surname
    };
    if(this.params.node && this.params.node.group && this.params.node.key){
        this.group = this.params.value
    }
  }
};
</script>