<template>
  <v-app
    :style="{
      background:
        $route.name == 'Login' ||
        $route.name == 'Register' ||
        $route.name == 'Share: Shipment' ||
        $route.name == 'Reset Password'
          ? $vuetify.theme.themes['dark'].background
          : $vuetify.theme.themes['dark'].background,
    }"
  >
    <!-- <v-app> -->
    <div
      v-if="
        $route.name == 'Login' ||
        $route.name == 'Register' ||
        $route.name == 'Reset Password'
      "
    >
      <!-- <div id='stars'></div> -->
      <div id="stars2"></div>
      <div id="stars3"></div>
      <div id="stars4"></div>
      <!-- <div id="foglayer_01" class="fog">
        <div class="image01"></div>
        <div class="image02"></div>
      </div>
      <div id="foglayer_02" class="fog">
        <div class="image01"></div>
        <div class="image02"></div>
      </div>
      <div id="foglayer_03" class="fog">
        <div class="image01"></div>
        <div class="image02"></div>
      </div> -->
    </div>

    <Navbar
      v-if="
        $route.name != 'Login' &&
        $route.name != 'LoginScreen' &&
        $route.name != 'Register' &&
        $route.name != 'Share: Shipment' &&
        $route.name != 'Reset Password'
      "
      app
    />
    <v-main class="ml-0">
      <transition
        v-if="
          $route.name != 'Login' &&
          $route.name != 'LoginScreen' &&
          $route.name != 'Register' &&
          $route.name != 'Share: Shipment' &&
          $route.name != 'Reset Password'
        "
        name="fade"
        mode="out-in"
      >
        <router-view :key="key" class="mx-0 px-0 pl-0"  />
      </transition>
      <transition v-else name="fade" mode="out-in">
        <router-view class="mx-0 px-0" />
      </transition>
    </v-main>
    <v-snackbar
      bottom
      center
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      <span class="primaryText--text">An new version is available</span>
      <v-btn
        text
        @click="refreshApp"
        style="text-transform: none"
        color="primaryText"
      >
        Update Now
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import Navbar from "./components/Navigation/Navbar.vue";
import update from "./mixins/update";
export default {
  name: "App",
  components: {
    Navbar,
  },
  watch: {
    "$store.state.orgToken": {
      immediate: true,
      handler() {
        this.key++;
      },
    },
    "$store.state.user": {
      immediate: true,
      handler() {
        this.key++;
      },
    },
    "$store.state.darkMode": {
      immediate: true,
      handler(val) {
        // if (val) {
        //   this.theme = "dark";
        // } else if (val == false) {
        //   this.theme = "light";
        // } else {
          this.theme = "dark";
        // }
      },
    },
  },
  mixins: [update],
  data: () => ({
    key: 0,
    theme: "dark",
  }),
  async created() {},
};
</script>

<style lang="scss">
.additionalMargin{
  margin-left: 100px;
  padding-left: 100px;
}
/* cinzel-regular - latin */
@font-face {
  font-family: "Cinzel";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/cinzel-v19-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/cinzel-v19-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/cinzel-v19-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/cinzel-v19-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/cinzel-v19-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/cinzel-v19-latin-regular.svg#Cinzel") format("svg"); /* Legacy iOS */
}
.div {
  .v-row {
    background-color: var(--v-component-base) !important;
  }
}

.v-application {
  //   .theme--light {
  //     color: #4a4a4a !important;
  // }
  .v-slide-group__prev {
    background-color: var(--v-component-base) !important;
  }
  .v-slide-group__next {
    background-color: var(--v-component-base) !important;
  }

  .v-data-table-header__icon {
    opacity: 1;
  }
  .v-tabs {
    // height: 40px;
  }
  .v-navigation-drawer {
z-index: 10 
}
.v-dialog--active{
  z-index: 100
}
  .v-tab {
    font-size: 13px;
    color: var(--v-primaryText-base) !important;
    background: var(--v-component-base) !important;
    text-transform: none;
    border-radius: 20px;
  }

  .v-tabs-bar__content {
    background: var(--v-component-base) !important;
  }

  .v-tab--active {
    background-color: transparent !important;
    color: var(--v-primaryText-base) !important;
    border-radius: 20px;
  }
  .v-dialog {
    .el-pagination {
      background-color: var(--v-modal-base) !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
      font-size: 11px !important;
      background-color: var(--v-modal-base) !important;
    }
    .v-data-footer {
      background-color: var(--v-modal-base) !important;
    }
    .v-data-table-header th {
      white-space: nowrap;
    }
    .v-col {
      background-color: var(--v-modal-base) !important;
    }
    .v-data-table__wrapper {
      font-size: 11px !important;
      background-color: var(--v-modal-base) !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
      font-size: 11px !important;
      background-color: var(--v-modal-base) !important;
    }
    .v-tab {
      font-size: 13px;
      background: var(--v-modal-base) !important;
      text-transform: none;
      color: var(--v-primaryText-base) !important;
      .v-icon {
        color: var(--v-primaryText-base) !important;
      }
    }

    .v-tabs-bar__content {
      background: var(--v-modal-base) !important;
    }

    .v-tab--active {
      background-color: var(--v-modal-base) !important;
      color: var(--v-primaryText-base) !important;
    }
    .v-card {
      background-color: var(--v-modal-base) !important;
      border-radius: 20px;
      .glass-card {
      background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
    }
      .v-list {
        background: transparent;
      }
      .theme--dark.v-table {
        background-color: var(--v-modal-base);
      }
      .theme--light.v-table {
        background-color: var(--v-modal-base);
      }
    }
  }
  .v-card {
    background-color: var(--v-component-base) !important;
    .glass-card {
      background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
    }
    .v-list {
      background: transparent;
    }
    .theme--dark.v-table {
      background-color: var(--v-component-base);
    }
    .theme--light.v-table {
      background-color: var(--v-component-base);
    }
    .v-data-table__wrapper {
      background-color: var(--v-component-base);
    }
  }
  .v-text-field {
    font-size: 14px;
  }
}

.theme--dark.v-table {
  background-color: var(--v-component-base);
}
.theme--light.v-table {
  background-color: var(--v-modal-base);
}

.v-toolbar {
  background-color: transparent !important;
}
.v-tooltip__content {
  opacity: 1 !important;
}
.v-data-footer {
  background: var(--v-component-base);
}
.v-input--select .v-label {
  font-size: 10px;
}
// .v-data-table > .v-data-table__wrapper {
//   background-color: var(--v-component-base) !important;
// }
// .v-data-table > .v-data-table__wrapper > table > tbody > tr >  :hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper)
// {
//     background-color: green !important;
// }
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 11px !important;
  background-color: var(--v-component-base) !important;
}
.v-data-table-header th {
  white-space: nowrap;
}

.active-tab {
  background-color: var(--v-component-base) !important;
  color: var(--v-primaryText-base) !important;
  font-size: 10px;
  border-top: 1px solid var(--v-primary-base);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 11px !important;
  background-color: var(--v-component-base) !important;
}

.el-input__inner {
  background: var(--v-component-base);
  color: var(--v-primaryText-base) !important;
  // border: 0.5px solid grey;
}

.el-input-group__prepend {
  border: none;
}
.el-pagination {
  background-color: transparent !important;
}
.el-button {
  // background-color: var(--v-greyBase-base) !important;
  // border: none
}

.v-col {
  background-color: var(--v-component-base) !important;
}

.v-window__container {
  background: var(--v-background-base);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--v-background-base);
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--v-secondary-lighten1);
  border-radius: 20px;

  // border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--v-secondary-lighten1);
}
.v-avatar{
  h2{
    color: white !important;
  }
  h3{
    color: white !important;
  }
}
</style>
<style lang="scss">
  @import "~ag-grid-community/styles/ag-grid.css";
  @import "~ag-grid-community/styles/ag-theme-quartz.css";

#stars4 {
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 440px 48px #cacacaa3, 1506px 1137px #cacacaa3, 731px 1535px #cacacaa3,
    1518px 1913px #6d6d6dd5, 444px 303px #cacacaa3, 1240px 1581px #cacacaa3, 297px 1321px #cacacaa3,
    997px 710px #cacacaa3, 1735px 272px #cacacaa3, 451px 1161px #cacacaa3, 1243px 1696px #cacacaa3,
    1338px 575px #cacacaa3, 1036px 143px #cacacaa3, 1384px 728px #cacacaa3, 422px 532px #cacacaa3,
    727px 1785px #cacacaa3, 603px 128px #cacacaa3, 551px 1397px #cacacaa3, 1073px 33px #cacacaa3,
    382px 1626px #cacacaa3, 342px 1270px #cacacaa3, 1890px 232px #cacacaa3, 92px 1689px #cacacaa3,
    1930px 665px #cacacaa3, 472px 1490px #cacacaa3, 270px 1665px #cacacaa3, 1094px 904px #cacacaa3,
    873px 1884px #cacacaa3, 264px 312px #cacacaa3, 1637px 1103px #cacacaa3, 1313px 1108px #cacacaa3,
    1322px 507px #cacacaa3, 547px 1194px #cacacaa3, 1502px 1918px #cacacaa3, 858px 416px #cacacaa3,
    1608px 571px #cacacaa3, 647px 1543px #cacacaa3, 1288px 111px #cacacaa3, 713px 1868px #cacacaa3,
    659px 741px #cacacaa3, 139px 99px #cacacaa3, 584px 1256px #cacacaa3, 932px 210px #cacacaa3,
    1482px 366px #cacacaa3, 912px 1296px #cacacaa3, 1412px 431px #cacacaa3, 1020px 664px #cacacaa3,
    1825px 1121px #cacacaa3, 621px 361px #cacacaa3, 1061px 672px #cacacaa3, 400px 1116px #cacacaa3,
    94px 721px #cacacaa3, 423px 674px #cacacaa3, 474px 1517px #cacacaa3, 1680px 1126px #cacacaa3,
    1301px 376px #cacacaa3, 1252px 843px #cacacaa3, 145px 180px #cacacaa3, 382px 31px #cacacaa3,
    11px 900px #cacacaa3, 516px 109px #cacacaa3, 1288px 1018px #cacacaa3, 1660px 209px #cacacaa3,
    636px 1326px #cacacaa3, 1046px 639px #cacacaa3, 879px 396px #cacacaa3, 1488px 1733px #cacacaa3,
    24px 1605px #cacacaa3, 1518px 293px #cacacaa3, 1780px 1810px #cacacaa3, 72px 597px #cacacaa3,
    1836px 1778px #cacacaa3, 439px 962px #cacacaa3, 1624px 765px #cacacaa3, 1472px 835px #cacacaa3,
    111px 152px #cacacaa3, 1493px 1771px #cacacaa3, 501px 237px #cacacaa3, 1396px 363px #cacacaa3,
    1634px 1447px #cacacaa3, 1460px 1658px #cacacaa3, 1985px 199px #cacacaa3, 1804px 858px #cacacaa3,
    1659px 336px #cacacaa3, 495px 1772px #cacacaa3, 131px 49px #cacacaa3, 1483px 288px #cacacaa3,
    491px 1000px #cacacaa3, 50px 274px #cacacaa3, 270px 1013px #cacacaa3, 1808px 88px #cacacaa3,
    1495px 545px #cacacaa3, 1825px 82px #cacacaa3, 718px 1635px #cacacaa3, 685px 874px #cacacaa3,
    669px 646px #cacacaa3, 1426px 828px #cacacaa3, 1375px 1744px #cacacaa3, 158px 1730px #cacacaa3,
    1904px 1028px #cacacaa3, 1669px 905px #cacacaa3, 1356px 1385px #cacacaa3, 1109px 686px #cacacaa3,
    1166px 1106px #cacacaa3, 1530px 1608px #cacacaa3, 781px 345px #cacacaa3, 1562px 293px #cacacaa3,
    861px 909px #cacacaa3, 26px 75px #cacacaa3, 154px 1774px #cacacaa3, 1859px 435px #cacacaa3,
    1153px 1072px #cacacaa3, 826px 1629px #cacacaa3, 1043px 469px #cacacaa3, 539px 1525px #cacacaa3,
    1851px 712px #cacacaa3, 640px 1009px #cacacaa3, 545px 1077px #cacacaa3, 73px 627px #cacacaa3,
    304px 425px #cacacaa3, 1876px 1347px #cacacaa3, 707px 831px #cacacaa3, 663px 408px #cacacaa3,
    1725px 730px #cacacaa3, 453px 1367px #cacacaa3, 428px 1800px #cacacaa3, 585px 1737px #cacacaa3,
    1351px 1599px #cacacaa3, 1093px 1202px #cacacaa3, 845px 1153px #cacacaa3, 614px 1394px #cacacaa3,
    702px 619px #cacacaa3, 743px 339px #cacacaa3, 439px 928px #cacacaa3, 1340px 1629px #cacacaa3,
    1031px 702px #cacacaa3, 977px 296px #cacacaa3, 1863px 75px #cacacaa3, 372px 128px #cacacaa3,
    62px 594px #cacacaa3, 832px 238px #cacacaa3, 961px 532px #cacacaa3, 1706px 446px #cacacaa3,
    1357px 1640px #cacacaa3, 1810px 1862px #cacacaa3, 738px 295px #cacacaa3, 1744px 1634px #cacacaa3,
    1777px 1461px #cacacaa3, 1496px 1844px #cacacaa3, 1639px 487px #cacacaa3,
    1276px 1924px #cacacaa3, 767px 959px #cacacaa3, 597px 1018px #cacacaa3, 1326px 1336px #cacacaa3,
    1106px 451px #cacacaa3, 1119px 91px #cacacaa3, 503px 257px #cacacaa3, 1442px 1px #cacacaa3,
    1487px 1998px #cacacaa3, 1093px 1485px #cacacaa3, 794px 702px #cacacaa3, 1498px 270px #cacacaa3,
    1037px 1886px #cacacaa3, 659px 616px #cacacaa3, 749px 1929px #cacacaa3, 1896px 1535px #cacacaa3,
    1541px 23px #cacacaa3, 1664px 194px #cacacaa3, 314px 59px #cacacaa3, 1767px 1243px #cacacaa3,
    383px 885px #cacacaa3, 222px 278px #cacacaa3, 889px 1184px #cacacaa3, 359px 1008px #cacacaa3,
    1081px 1604px #cacacaa3, 421px 483px #cacacaa3, 1140px 1667px #cacacaa3, 1666px 1744px #cacacaa3,
    838px 263px #cacacaa3, 302px 241px #cacacaa3, 1465px 1035px #cacacaa3, 1424px 1914px #cacacaa3,
    792px 352px #cacacaa3, 1539px 1442px #cacacaa3, 440px 1000px #cacacaa3, 395px 294px #cacacaa3,
    994px 125px #cacacaa3, 1049px 1095px #cacacaa3, 573px 575px #cacacaa3, 1516px 802px #cacacaa3,
    198px 1014px #cacacaa3, 1696px 956px #cacacaa3, 327px 1688px #cacacaa3, 1438px 686px #cacacaa3,
    369px 42px #cacacaa3, 474px 362px #cacacaa3, 762px 1712px #cacacaa3, 887px 1452px #cacacaa3,
    113px 815px #cacacaa3, 818px 1113px #cacacaa3, 1344px 1147px #cacacaa3, 1575px 1685px #cacacaa3,
    954px 1291px #cacacaa3, 825px 106px #cacacaa3, 1006px 727px #cacacaa3, 536px 1205px #cacacaa3,
    255px 1072px #cacacaa3, 1469px 805px #cacacaa3, 841px 1057px #cacacaa3, 1325px 910px #cacacaa3,
    1373px 352px #cacacaa3, 616px 1940px #cacacaa3, 188px 501px #cacacaa3, 1819px 1684px #cacacaa3,
    1958px 833px #cacacaa3, 481px 891px #cacacaa3, 967px 1983px #cacacaa3, 52px 1953px #cacacaa3,
    760px 1874px #cacacaa3, 495px 934px #cacacaa3, 1260px 1425px #cacacaa3, 696px 1688px #cacacaa3,
    1565px 499px #cacacaa3, 375px 139px #cacacaa3, 1138px 1314px #cacacaa3, 980px 1804px #cacacaa3,
    1674px 1332px #cacacaa3, 442px 254px #cacacaa3, 230px 557px #cacacaa3, 464px 1531px #cacacaa3,
    225px 694px #cacacaa3, 26px 178px #cacacaa3, 379px 1288px #cacacaa3, 965px 1030px #cacacaa3,
    427px 1391px #cacacaa3, 591px 269px #cacacaa3, 1673px 836px #cacacaa3, 131px 431px #cacacaa3,
    1900px 805px #cacacaa3, 1388px 1192px #cacacaa3, 378px 612px #cacacaa3, 433px 1097px #cacacaa3,
    698px 426px #cacacaa3, 863px 146px #cacacaa3, 206px 1982px #cacacaa3, 472px 1124px #cacacaa3,
    924px 1049px #cacacaa3, 367px 144px #cacacaa3, 1995px 97px #cacacaa3, 1349px 1648px #cacacaa3,
    182px 312px #cacacaa3, 1584px 375px #cacacaa3, 84px 90px #cacacaa3, 277px 1471px #cacacaa3,
    1732px 1273px #cacacaa3, 1354px 1982px #cacacaa3, 1464px 1735px #cacacaa3,
    748px 1917px #cacacaa3, 1158px 376px #cacacaa3, 342px 403px #cacacaa3, 1427px 1492px #cacacaa3,
    1369px 1126px #cacacaa3, 244px 1247px #cacacaa3, 1944px 1241px #cacacaa3,
    1502px 1770px #cacacaa3, 228px 397px #cacacaa3, 933px 441px #cacacaa3, 1962px 991px #cacacaa3,
    1544px 106px #cacacaa3, 1319px 1043px #cacacaa3, 45px 735px #cacacaa3, 1541px 769px #cacacaa3,
    56px 121px #cacacaa3, 892px 1677px #cacacaa3, 396px 466px #cacacaa3, 1588px 1904px #cacacaa3,
    415px 1325px #cacacaa3, 629px 741px #cacacaa3, 1128px 897px #cacacaa3, 1881px 425px #cacacaa3,
    656px 1758px #cacacaa3, 1280px 1813px #cacacaa3, 1995px 1632px #cacacaa3, 415px 1056px #cacacaa3,
    294px 1249px #cacacaa3, 753px 1190px #cacacaa3, 895px 1479px #cacacaa3, 910px 403px #cacacaa3,
    986px 1711px #cacacaa3, 99px 1480px #cacacaa3, 593px 1413px #cacacaa3, 1864px 1954px #cacacaa3,
    1517px 448px #cacacaa3, 259px 997px #cacacaa3, 967px 279px #cacacaa3, 683px 1423px #cacacaa3,
    1826px 255px #cacacaa3, 1936px 201px #cacacaa3, 1949px 1741px #cacacaa3, 437px 1006px #cacacaa3,
    432px 858px #cacacaa3, 1227px 1329px #cacacaa3, 1661px 593px #cacacaa3, 605px 1791px #cacacaa3,
    43px 70px #cacacaa3, 209px 117px #cacacaa3, 717px 1517px #cacacaa3, 1912px 919px #cacacaa3,
    593px 509px #cacacaa3, 299px 1592px #cacacaa3, 775px 1061px #cacacaa3, 135px 703px #cacacaa3,
    911px 1105px #cacacaa3, 835px 1964px #cacacaa3, 639px 1131px #cacacaa3, 659px 1463px #cacacaa3,
    21px 1232px #cacacaa3, 102px 1564px #cacacaa3, 1382px 1074px #cacacaa3, 1179px 1815px #cacacaa3,
    1755px 1569px #cacacaa3, 1576px 1807px #cacacaa3, 704px 1574px #cacacaa3,
    1244px 1101px #cacacaa3, 1085px 1057px #cacacaa3, 376px 1731px #cacacaa3, 1987px 206px #cacacaa3,
    1964px 1642px #cacacaa3, 711px 972px #cacacaa3, 121px 1629px #cacacaa3, 384px 338px #cacacaa3,
    404px 444px #cacacaa3, 364px 595px #cacacaa3, 1307px 971px #cacacaa3, 505px 229px #cacacaa3,
    618px 909px #cacacaa3, 1297px 1479px #cacacaa3, 1970px 1679px #cacacaa3, 1070px 1314px #cacacaa3,
    667px 398px #cacacaa3, 202px 414px #cacacaa3, 1871px 1960px #cacacaa3, 768px 398px #cacacaa3,
    80px 570px #cacacaa3, 1600px 1400px #cacacaa3, 1823px 1803px #cacacaa3, 1965px 285px #cacacaa3,
    1575px 1469px #cacacaa3, 1840px 264px #cacacaa3, 139px 725px #cacacaa3, 1744px 11px #cacacaa3,
    1982px 794px #cacacaa3, 545px 406px #cacacaa3, 1920px 446px #cacacaa3, 428px 937px #cacacaa3,
    154px 1745px #cacacaa3, 561px 266px #cacacaa3, 162px 54px #cacacaa3, 1479px 1280px #cacacaa3,
    1166px 1183px #cacacaa3, 610px 603px #cacacaa3, 731px 1715px #cacacaa3, 1549px 1716px #cacacaa3,
    1297px 1087px #cacacaa3, 1406px 125px #cacacaa3, 926px 663px #cacacaa3, 1384px 1965px #cacacaa3,
    354px 1822px #cacacaa3, 359px 764px #cacacaa3, 385px 1044px #cacacaa3, 1937px 974px #cacacaa3,
    686px 1122px #cacacaa3, 1823px 1582px #cacacaa3, 1038px 404px #cacacaa3, 462px 1616px #cacacaa3,
    128px 1173px #cacacaa3, 311px 23px #cacacaa3, 584px 350px #cacacaa3, 1856px 1502px #cacacaa3,
    1372px 48px #cacacaa3, 1326px 926px #cacacaa3, 725px 1563px #cacacaa3, 1514px 49px #cacacaa3,
    424px 189px #cacacaa3, 1845px 1382px #cacacaa3, 388px 1202px #cacacaa3, 1807px 870px #cacacaa3,
    1777px 908px #cacacaa3, 1518px 494px #cacacaa3, 1817px 234px #cacacaa3, 596px 226px #cacacaa3,
    382px 352px #cacacaa3, 1001px 88px #cacacaa3, 795px 804px #cacacaa3, 646px 975px #cacacaa3,
    1626px 867px #cacacaa3, 1967px 1920px #cacacaa3, 360px 1765px #cacacaa3, 1834px 1519px #cacacaa3,
    65px 849px #cacacaa3, 1483px 860px #cacacaa3, 621px 1077px #cacacaa3, 380px 376px #cacacaa3,
    1025px 185px #cacacaa3, 1349px 942px #cacacaa3, 1523px 1023px #cacacaa3, 1732px 1249px #cacacaa3,
    114px 623px #cacacaa3, 948px 618px #cacacaa3, 920px 830px #cacacaa3, 936px 1963px #cacacaa3,
    436px 1150px #cacacaa3, 578px 1796px #cacacaa3, 444px 1283px #cacacaa3, 220px 231px #cacacaa3,
    1821px 273px #cacacaa3, 1721px 64px #cacacaa3, 1242px 1963px #cacacaa3, 583px 1583px #cacacaa3,
    701px 1684px #cacacaa3, 1400px 1639px #cacacaa3, 993px 1100px #cacacaa3, 1163px 1637px #cacacaa3,
    33px 69px #cacacaa3, 1098px 1147px #cacacaa3, 163px 1055px #cacacaa3, 1657px 912px #cacacaa3,
    1046px 1263px #cacacaa3, 330px 175px #cacacaa3, 677px 1973px #cacacaa3, 278px 1199px #cacacaa3,
    1935px 539px #cacacaa3, 176px 1002px #cacacaa3, 1572px 368px #cacacaa3, 1830px 235px #cacacaa3,
    209px 208px #cacacaa3, 149px 1703px #cacacaa3, 1675px 383px #cacacaa3, 1103px 164px #cacacaa3,
    1211px 966px #cacacaa3, 1722px 1445px #cacacaa3, 1163px 831px #cacacaa3, 1236px 624px #cacacaa3,
    176px 832px #cacacaa3, 1045px 764px #cacacaa3, 726px 592px #cacacaa3, 234px 1809px #cacacaa3,
    1323px 228px #cacacaa3, 1055px 550px #cacacaa3, 1718px 1051px #cacacaa3, 898px 1729px #cacacaa3,
    63px 1595px #cacacaa3, 54px 1848px #cacacaa3, 1339px 1530px #cacacaa3, 587px 536px #cacacaa3,
    61px 841px #cacacaa3, 1010px 923px #cacacaa3, 1131px 1204px #cacacaa3, 1577px 1360px #cacacaa3,
    524px 864px #cacacaa3, 1470px 1142px #cacacaa3, 622px 215px #cacacaa3, 682px 1778px #cacacaa3,
    111px 1913px #cacacaa3, 1452px 1887px #cacacaa3, 75px 60px #cacacaa3, 538px 1364px #cacacaa3,
    1795px 207px #cacacaa3, 1217px 1161px #cacacaa3, 1684px 820px #cacacaa3, 609px 162px #cacacaa3,
    1927px 994px #cacacaa3, 1318px 1869px #cacacaa3, 1133px 491px #cacacaa3, 650px 1771px #cacacaa3,
    943px 1682px #cacacaa3, 1828px 285px #cacacaa3, 1756px 578px #cacacaa3, 1228px 615px #cacacaa3,
    1874px 344px #cacacaa3, 477px 375px #cacacaa3, 941px 314px #cacacaa3, 289px 168px #cacacaa3,
    548px 1134px #cacacaa3, 1115px 978px #cacacaa3, 931px 390px #cacacaa3, 1877px 1566px #cacacaa3,
    1114px 151px #cacacaa3, 42px 980px #cacacaa3, 1490px 1022px #cacacaa3, 293px 386px #cacacaa3,
    1444px 512px #cacacaa3, 621px 601px #cacacaa3, 947px 1398px #cacacaa3, 671px 430px #cacacaa3,
    1278px 1163px #cacacaa3, 669px 112px #cacacaa3, 25px 1238px #cacacaa3, 100px 698px #cacacaa3,
    2000px 815px #cacacaa3, 1652px 898px #cacacaa3, 104px 420px #cacacaa3, 1294px 682px #cacacaa3,
    1320px 1380px #cacacaa3, 1119px 715px #cacacaa3, 160px 751px #cacacaa3, 1047px 295px #cacacaa3,
    1931px 1033px #cacacaa3, 462px 922px #cacacaa3, 1032px 324px #cacacaa3, 779px 1130px #cacacaa3,
    1393px 826px #cacacaa3, 1146px 56px #cacacaa3, 1829px 1904px #cacacaa3, 1384px 1141px #cacacaa3,
    1695px 708px #cacacaa3, 1821px 1693px #cacacaa3, 887px 1820px #cacacaa3, 1338px 291px #cacacaa3,
    401px 1483px #cacacaa3, 1649px 1769px #cacacaa3, 810px 630px #cacacaa3, 1309px 809px #cacacaa3,
    1761px 920px #cacacaa3, 1565px 1683px #cacacaa3, 735px 1014px #cacacaa3, 1535px 1705px #cacacaa3,
    1552px 887px #cacacaa3, 9px 197px #cacacaa3, 1452px 319px #cacacaa3, 1069px 322px #cacacaa3,
    1145px 244px #cacacaa3, 240px 335px #cacacaa3, 205px 1065px #cacacaa3, 442px 498px #cacacaa3,
    1751px 1694px #cacacaa3, 1207px 1713px #cacacaa3, 1030px 1234px #cacacaa3,
    1115px 844px #cacacaa3, 1748px 1150px #cacacaa3, 1242px 1931px #cacacaa3, 1807px 30px #cacacaa3,
    1682px 12px #cacacaa3, 167px 692px #cacacaa3, 853px 1788px #cacacaa3, 486px 862px #cacacaa3,
    945px 1603px #cacacaa3, 990px 1531px #cacacaa3, 818px 146px #cacacaa3, 1991px 1721px #cacacaa3,
    72px 449px #cacacaa3, 532px 60px #cacacaa3, 1157px 1274px #cacacaa3, 1662px 1235px #cacacaa3,
    701px 12px #cacacaa3, 848px 1438px #cacacaa3, 1697px 1078px #cacacaa3, 1794px 1872px #cacacaa3,
    1996px 151px #cacacaa3, 434px 1307px #cacacaa3, 86px 1054px #cacacaa3, 79px 235px #cacacaa3,
    185px 1838px #cacacaa3, 1814px 404px #cacacaa3, 466px 1940px #cacacaa3, 472px 929px #cacacaa3,
    1798px 849px #cacacaa3, 467px 1410px #cacacaa3, 1433px 257px #cacacaa3, 1843px 1703px #cacacaa3,
    1313px 717px #cacacaa3, 1684px 1263px #cacacaa3, 1891px 838px #cacacaa3, 105px 658px #cacacaa3,
    896px 1687px #cacacaa3, 1632px 1657px #cacacaa3, 724px 1602px #cacacaa3, 685px 1087px #cacacaa3,
    1296px 593px #cacacaa3, 1220px 1305px #cacacaa3, 667px 1893px #cacacaa3, 1841px 1142px #cacacaa3,
    1986px 1025px #cacacaa3, 205px 1497px #cacacaa3, 1226px 996px #cacacaa3, 415px 456px #cacacaa3,
    950px 1736px #cacacaa3, 1546px 849px #cacacaa3, 454px 849px #cacacaa3, 886px 250px #cacacaa3,
    365px 1410px #cacacaa3, 423px 1480px #cacacaa3, 1290px 1357px #cacacaa3, 1766px 269px #cacacaa3,
    1060px 637px #cacacaa3, 779px 697px #cacacaa3, 61px 1477px #cacacaa3, 1054px 567px #cacacaa3,
    37px 538px #cacacaa3, 1842px 1874px #cacacaa3, 841px 71px #cacacaa3, 1079px 1268px #cacacaa3,
    1017px 1667px #cacacaa3, 1061px 1948px #cacacaa3, 919px 1279px #cacacaa3,
    1463px 1271px #cacacaa3, 1296px 1213px #cacacaa3, 159px 603px #cacacaa3, 1966px 983px #cacacaa3,
    1262px 1598px #cacacaa3, 759px 1525px #cacacaa3, 503px 1984px #cacacaa3, 1211px 1810px #cacacaa3,
    1365px 1123px #cacacaa3, 1085px 12px #cacacaa3, 1224px 1971px #cacacaa3, 1797px 1056px #cacacaa3,
    391px 1197px #cacacaa3, 1207px 887px #cacacaa3, 601px 934px #cacacaa3, 1617px 1433px #cacacaa3,
    457px 1983px #cacacaa3, 1374px 1316px #cacacaa3, 632px 502px #cacacaa3, 897px 455px #cacacaa3,
    889px 106px #cacacaa3, 847px 719px #cacacaa3, 1379px 1371px #cacacaa3, 1806px 532px #cacacaa3,
    1046px 290px #cacacaa3, 977px 1255px #cacacaa3, 1619px 966px #cacacaa3, 892px 826px #cacacaa3,
    485px 1477px #cacacaa3, 1216px 1681px #cacacaa3, 1046px 1944px #cacacaa3, 25px 1451px #cacacaa3,
    600px 798px #cacacaa3, 414px 1795px #cacacaa3, 1296px 1908px #cacacaa3, 1185px 1596px #cacacaa3,
    295px 1652px #cacacaa3, 105px 346px #cacacaa3, 1370px 852px #cacacaa3, 1568px 43px #cacacaa3,
    919px 337px #cacacaa3, 1970px 1964px #cacacaa3, 66px 1372px #cacacaa3, 1372px 1786px #cacacaa3,
    568px 278px #cacacaa3, 1402px 755px #cacacaa3, 1591px 1681px #cacacaa3, 1694px 1214px #cacacaa3,
    803px 727px #cacacaa3, 298px 1648px #cacacaa3, 1071px 908px #cacacaa3, 1897px 1262px #cacacaa3,
    662px 631px #cacacaa3, 98px 1265px #cacacaa3, 687px 149px #cacacaa3, 890px 1765px #cacacaa3,
    326px 1645px #cacacaa3, 1935px 966px #cacacaa3, 90px 1669px #cacacaa3, 1327px 1547px #cacacaa3,
    1205px 405px #cacacaa3, 1926px 1563px #cacacaa3, 132px 455px #cacacaa3, 1919px 298px #cacacaa3,
    976px 525px #cacacaa3, 1473px 575px #cacacaa3, 1887px 1197px #cacacaa3, 1841px 1949px #cacacaa3,
    1304px 401px #cacacaa3, 1126px 557px #cacacaa3, 721px 1073px #cacacaa3, 1911px 1201px #cacacaa3,
    541px 455px #cacacaa3, 1708px 1762px #cacacaa3, 237px 974px #cacacaa3, 695px 483px #cacacaa3,
    1676px 490px #cacacaa3, 1480px 1350px #cacacaa3, 350px 344px #cacacaa3, 939px 1697px #cacacaa3,
    640px 633px #cacacaa3, 1063px 1766px #cacacaa3, 1357px 241px #cacacaa3, 1559px 1824px #cacacaa3,
    1137px 780px #cacacaa3, 1249px 897px #cacacaa3, 865px 803px #cacacaa3, 1280px 616px #cacacaa3,
    278px 1610px #cacacaa3, 1832px 51px #cacacaa3, 979px 94px #cacacaa3, 1576px 277px #cacacaa3,
    1427px 1575px #cacacaa3, 470px 1494px #cacacaa3, 1345px 995px #cacacaa3;
  animation: animStar 60s linear infinite;
}

#stars {
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 440px 48px #cacacaa3, 1506px 1137px #cacacaa3, 731px 1535px #cacacaa3,
    1518px 1913px #cacacaa3, 444px 303px #cacacaa3, 1240px 1581px #cacacaa3, 297px 1321px #cacacaa3,
    997px 710px #cacacaa3, 1735px 272px #cacacaa3, 451px 1161px #cacacaa3, 1243px 1696px #cacacaa3,
    1338px 575px #cacacaa3, 1036px 143px #cacacaa3, 1384px 728px #cacacaa3, 422px 532px #cacacaa3,
    727px 1785px #cacacaa3, 603px 128px #cacacaa3, 551px 1397px #cacacaa3, 1073px 33px #cacacaa3,
    382px 1626px #cacacaa3, 342px 1270px #cacacaa3, 1890px 232px #cacacaa3, 92px 1689px #cacacaa3,
    1930px 665px #cacacaa3, 472px 1490px #cacacaa3, 270px 1665px #cacacaa3, 1094px 904px #cacacaa3,
    873px 1884px #cacacaa3, 264px 312px #cacacaa3, 1637px 1103px #cacacaa3, 1313px 1108px #cacacaa3,
    1322px 507px #cacacaa3, 547px 1194px #cacacaa3, 1502px 1918px #cacacaa3, 858px 416px #cacacaa3,
    1608px 571px #cacacaa3, 647px 1543px #cacacaa3, 1288px 111px #cacacaa3, 713px 1868px #cacacaa3,
    659px 741px #cacacaa3, 139px 99px #cacacaa3, 584px 1256px #cacacaa3, 932px 210px #cacacaa3,
    1482px 366px #cacacaa3, 912px 1296px #cacacaa3, 1412px 431px #cacacaa3, 1020px 664px #cacacaa3,
    1825px 1121px #cacacaa3, 621px 361px #cacacaa3, 1061px 672px #cacacaa3, 400px 1116px #cacacaa3,
    94px 721px #cacacaa3, 423px 674px #cacacaa3, 474px 1517px #cacacaa3, 1680px 1126px #cacacaa3,
    1301px 376px #cacacaa3, 1252px 843px #cacacaa3, 145px 180px #cacacaa3, 382px 31px #cacacaa3,
    11px 900px #cacacaa3, 516px 109px #cacacaa3, 1288px 1018px #cacacaa3, 1660px 209px #cacacaa3,
    636px 1326px #cacacaa3, 1046px 639px #cacacaa3, 879px 396px #cacacaa3, 1488px 1733px #cacacaa3,
    24px 1605px #cacacaa3, 1518px 293px #cacacaa3, 1780px 1810px #cacacaa3, 72px 597px #cacacaa3,
    1836px 1778px #cacacaa3, 439px 962px #cacacaa3, 1624px 765px #cacacaa3, 1472px 835px #cacacaa3,
    111px 152px #cacacaa3, 1493px 1771px #cacacaa3, 501px 237px #cacacaa3, 1396px 363px #cacacaa3,
    1634px 1447px #cacacaa3, 1460px 1658px #cacacaa3, 1985px 199px #cacacaa3, 1804px 858px #cacacaa3,
    1659px 336px #cacacaa3, 495px 1772px #cacacaa3, 131px 49px #cacacaa3, 1483px 288px #cacacaa3,
    491px 1000px #cacacaa3, 50px 274px #cacacaa3, 270px 1013px #cacacaa3, 1808px 88px #cacacaa3,
    1495px 545px #cacacaa3, 1825px 82px #cacacaa3, 718px 1635px #cacacaa3, 685px 874px #cacacaa3,
    669px 646px #cacacaa3, 1426px 828px #cacacaa3, 1375px 1744px #cacacaa3, 158px 1730px #cacacaa3,
    1904px 1028px #cacacaa3, 1669px 905px #cacacaa3, 1356px 1385px #cacacaa3, 1109px 686px #cacacaa3,
    1166px 1106px #cacacaa3, 1530px 1608px #cacacaa3, 781px 345px #cacacaa3, 1562px 293px #cacacaa3,
    861px 909px #cacacaa3, 26px 75px #cacacaa3, 154px 1774px #cacacaa3, 1859px 435px #cacacaa3,
    1153px 1072px #cacacaa3, 826px 1629px #cacacaa3, 1043px 469px #cacacaa3, 539px 1525px #cacacaa3,
    1851px 712px #cacacaa3, 640px 1009px #cacacaa3, 545px 1077px #cacacaa3, 73px 627px #cacacaa3,
    304px 425px #cacacaa3, 1876px 1347px #cacacaa3, 707px 831px #cacacaa3, 663px 408px #cacacaa3,
    1725px 730px #cacacaa3, 453px 1367px #cacacaa3, 428px 1800px #cacacaa3, 585px 1737px #cacacaa3,
    1351px 1599px #cacacaa3, 1093px 1202px #cacacaa3, 845px 1153px #cacacaa3, 614px 1394px #cacacaa3,
    702px 619px #cacacaa3, 743px 339px #cacacaa3, 439px 928px #cacacaa3, 1340px 1629px #cacacaa3,
    1031px 702px #cacacaa3, 977px 296px #cacacaa3, 1863px 75px #cacacaa3, 372px 128px #cacacaa3,
    62px 594px #cacacaa3, 832px 238px #cacacaa3, 961px 532px #cacacaa3, 1706px 446px #cacacaa3,
    1357px 1640px #cacacaa3, 1810px 1862px #cacacaa3, 738px 295px #cacacaa3, 1744px 1634px #cacacaa3,
    1777px 1461px #cacacaa3, 1496px 1844px #cacacaa3, 1639px 487px #cacacaa3,
    1276px 1924px #cacacaa3, 767px 959px #cacacaa3, 597px 1018px #cacacaa3, 1326px 1336px #cacacaa3,
    1106px 451px #cacacaa3, 1119px 91px #cacacaa3, 503px 257px #cacacaa3, 1442px 1px #cacacaa3,
    1487px 1998px #cacacaa3, 1093px 1485px #cacacaa3, 794px 702px #cacacaa3, 1498px 270px #cacacaa3,
    1037px 1886px #cacacaa3, 659px 616px #cacacaa3, 749px 1929px #cacacaa3, 1896px 1535px #cacacaa3,
    1541px 23px #cacacaa3, 1664px 194px #cacacaa3, 314px 59px #cacacaa3, 1767px 1243px #cacacaa3,
    383px 885px #cacacaa3, 222px 278px #cacacaa3, 889px 1184px #cacacaa3, 359px 1008px #cacacaa3,
    1081px 1604px #cacacaa3, 421px 483px #cacacaa3, 1140px 1667px #cacacaa3, 1666px 1744px #cacacaa3,
    838px 263px #cacacaa3, 302px 241px #cacacaa3, 1465px 1035px #cacacaa3, 1424px 1914px #cacacaa3,
    792px 352px #cacacaa3, 1539px 1442px #cacacaa3, 440px 1000px #cacacaa3, 395px 294px #cacacaa3,
    994px 125px #cacacaa3, 1049px 1095px #cacacaa3, 573px 575px #cacacaa3, 1516px 802px #cacacaa3,
    198px 1014px #cacacaa3, 1696px 956px #cacacaa3, 327px 1688px #cacacaa3, 1438px 686px #cacacaa3,
    369px 42px #cacacaa3, 474px 362px #cacacaa3, 762px 1712px #cacacaa3, 887px 1452px #cacacaa3,
    113px 815px #cacacaa3, 818px 1113px #cacacaa3, 1344px 1147px #cacacaa3, 1575px 1685px #cacacaa3,
    954px 1291px #cacacaa3, 825px 106px #cacacaa3, 1006px 727px #cacacaa3, 536px 1205px #cacacaa3,
    255px 1072px #cacacaa3, 1469px 805px #cacacaa3, 841px 1057px #cacacaa3, 1325px 910px #cacacaa3,
    1373px 352px #cacacaa3, 616px 1940px #cacacaa3, 188px 501px #cacacaa3, 1819px 1684px #cacacaa3,
    1958px 833px #cacacaa3, 481px 891px #cacacaa3, 967px 1983px #cacacaa3, 52px 1953px #cacacaa3,
    760px 1874px #cacacaa3, 495px 934px #cacacaa3, 1260px 1425px #cacacaa3, 696px 1688px #cacacaa3,
    1565px 499px #cacacaa3, 375px 139px #cacacaa3, 1138px 1314px #cacacaa3, 980px 1804px #cacacaa3,
    1674px 1332px #cacacaa3, 442px 254px #cacacaa3, 230px 557px #cacacaa3, 464px 1531px #cacacaa3,
    225px 694px #cacacaa3, 26px 178px #cacacaa3, 379px 1288px #cacacaa3, 965px 1030px #cacacaa3,
    427px 1391px #cacacaa3, 591px 269px #cacacaa3, 1673px 836px #cacacaa3, 131px 431px #cacacaa3,
    1900px 805px #cacacaa3, 1388px 1192px #cacacaa3, 378px 612px #cacacaa3, 433px 1097px #cacacaa3,
    698px 426px #cacacaa3, 863px 146px #cacacaa3, 206px 1982px #cacacaa3, 472px 1124px #cacacaa3,
    924px 1049px #cacacaa3, 367px 144px #cacacaa3, 1995px 97px #cacacaa3, 1349px 1648px #cacacaa3,
    182px 312px #cacacaa3, 1584px 375px #cacacaa3, 84px 90px #cacacaa3, 277px 1471px #cacacaa3,
    1732px 1273px #cacacaa3, 1354px 1982px #cacacaa3, 1464px 1735px #cacacaa3,
    748px 1917px #cacacaa3, 1158px 376px #cacacaa3, 342px 403px #cacacaa3, 1427px 1492px #cacacaa3,
    1369px 1126px #cacacaa3, 244px 1247px #cacacaa3, 1944px 1241px #cacacaa3,
    1502px 1770px #cacacaa3, 228px 397px #cacacaa3, 933px 441px #cacacaa3, 1962px 991px #cacacaa3,
    1544px 106px #cacacaa3, 1319px 1043px #cacacaa3, 45px 735px #cacacaa3, 1541px 769px #cacacaa3,
    56px 121px #cacacaa3, 892px 1677px #cacacaa3, 396px 466px #cacacaa3, 1588px 1904px #cacacaa3,
    415px 1325px #cacacaa3, 629px 741px #cacacaa3, 1128px 897px #cacacaa3, 1881px 425px #cacacaa3,
    656px 1758px #cacacaa3, 1280px 1813px #cacacaa3, 1995px 1632px #cacacaa3, 415px 1056px #cacacaa3,
    294px 1249px #cacacaa3, 753px 1190px #cacacaa3, 895px 1479px #cacacaa3, 910px 403px #cacacaa3,
    986px 1711px #cacacaa3, 99px 1480px #cacacaa3, 593px 1413px #cacacaa3, 1864px 1954px #cacacaa3,
    1517px 448px #cacacaa3, 259px 997px #cacacaa3, 967px 279px #cacacaa3, 683px 1423px #cacacaa3,
    1826px 255px #cacacaa3, 1936px 201px #cacacaa3, 1949px 1741px #cacacaa3, 437px 1006px #cacacaa3,
    432px 858px #cacacaa3, 1227px 1329px #cacacaa3, 1661px 593px #cacacaa3, 605px 1791px #cacacaa3,
    43px 70px #cacacaa3, 209px 117px #cacacaa3, 717px 1517px #cacacaa3, 1912px 919px #cacacaa3,
    593px 509px #cacacaa3, 299px 1592px #cacacaa3, 775px 1061px #cacacaa3, 135px 703px #cacacaa3,
    911px 1105px #cacacaa3, 835px 1964px #cacacaa3, 639px 1131px #cacacaa3, 659px 1463px #cacacaa3,
    21px 1232px #cacacaa3, 102px 1564px #cacacaa3, 1382px 1074px #cacacaa3, 1179px 1815px #cacacaa3,
    1755px 1569px #cacacaa3, 1576px 1807px #cacacaa3, 704px 1574px #cacacaa3,
    1244px 1101px #cacacaa3, 1085px 1057px #cacacaa3, 376px 1731px #cacacaa3, 1987px 206px #cacacaa3,
    1964px 1642px #cacacaa3, 711px 972px #cacacaa3, 121px 1629px #cacacaa3, 384px 338px #cacacaa3,
    404px 444px #cacacaa3, 364px 595px #cacacaa3, 1307px 971px #cacacaa3, 505px 229px #cacacaa3,
    618px 909px #cacacaa3, 1297px 1479px #cacacaa3, 1970px 1679px #cacacaa3, 1070px 1314px #cacacaa3,
    667px 398px #cacacaa3, 202px 414px #cacacaa3, 1871px 1960px #cacacaa3, 768px 398px #cacacaa3,
    80px 570px #cacacaa3, 1600px 1400px #cacacaa3, 1823px 1803px #cacacaa3, 1965px 285px #cacacaa3,
    1575px 1469px #cacacaa3, 1840px 264px #cacacaa3, 139px 725px #cacacaa3, 1744px 11px #cacacaa3,
    1982px 794px #cacacaa3, 545px 406px #cacacaa3, 1920px 446px #cacacaa3, 428px 937px #cacacaa3,
    154px 1745px #cacacaa3, 561px 266px #cacacaa3, 162px 54px #cacacaa3, 1479px 1280px #cacacaa3,
    1166px 1183px #cacacaa3, 610px 603px #cacacaa3, 731px 1715px #cacacaa3, 1549px 1716px #cacacaa3,
    1297px 1087px #cacacaa3, 1406px 125px #cacacaa3, 926px 663px #cacacaa3, 1384px 1965px #cacacaa3,
    354px 1822px #cacacaa3, 359px 764px #cacacaa3, 385px 1044px #cacacaa3, 1937px 974px #cacacaa3,
    686px 1122px #cacacaa3, 1823px 1582px #cacacaa3, 1038px 404px #cacacaa3, 462px 1616px #cacacaa3,
    128px 1173px #cacacaa3, 311px 23px #cacacaa3, 584px 350px #cacacaa3, 1856px 1502px #cacacaa3,
    1372px 48px #cacacaa3, 1326px 926px #cacacaa3, 725px 1563px #cacacaa3, 1514px 49px #cacacaa3,
    424px 189px #cacacaa3, 1845px 1382px #cacacaa3, 388px 1202px #cacacaa3, 1807px 870px #cacacaa3,
    1777px 908px #cacacaa3, 1518px 494px #cacacaa3, 1817px 234px #cacacaa3, 596px 226px #cacacaa3,
    382px 352px #cacacaa3, 1001px 88px #cacacaa3, 795px 804px #cacacaa3, 646px 975px #cacacaa3,
    1626px 867px #cacacaa3, 1967px 1920px #cacacaa3, 360px 1765px #cacacaa3, 1834px 1519px #cacacaa3,
    65px 849px #cacacaa3, 1483px 860px #cacacaa3, 621px 1077px #cacacaa3, 380px 376px #cacacaa3,
    1025px 185px #cacacaa3, 1349px 942px #cacacaa3, 1523px 1023px #cacacaa3, 1732px 1249px #cacacaa3,
    114px 623px #cacacaa3, 948px 618px #cacacaa3, 920px 830px #cacacaa3, 936px 1963px #cacacaa3,
    436px 1150px #cacacaa3, 578px 1796px #cacacaa3, 444px 1283px #cacacaa3, 220px 231px #cacacaa3,
    1821px 273px #cacacaa3, 1721px 64px #cacacaa3, 1242px 1963px #cacacaa3, 583px 1583px #cacacaa3,
    701px 1684px #cacacaa3, 1400px 1639px #cacacaa3, 993px 1100px #cacacaa3, 1163px 1637px #cacacaa3,
    33px 69px #cacacaa3, 1098px 1147px #cacacaa3, 163px 1055px #cacacaa3, 1657px 912px #cacacaa3,
    1046px 1263px #cacacaa3, 330px 175px #cacacaa3, 677px 1973px #cacacaa3, 278px 1199px #cacacaa3,
    1935px 539px #cacacaa3, 176px 1002px #cacacaa3, 1572px 368px #cacacaa3, 1830px 235px #cacacaa3,
    209px 208px #cacacaa3, 149px 1703px #cacacaa3, 1675px 383px #cacacaa3, 1103px 164px #cacacaa3,
    1211px 966px #cacacaa3, 1722px 1445px #cacacaa3, 1163px 831px #cacacaa3, 1236px 624px #cacacaa3,
    176px 832px #cacacaa3, 1045px 764px #cacacaa3, 726px 592px #cacacaa3, 234px 1809px #cacacaa3,
    1323px 228px #cacacaa3, 1055px 550px #cacacaa3, 1718px 1051px #cacacaa3, 898px 1729px #cacacaa3,
    63px 1595px #cacacaa3, 54px 1848px #cacacaa3, 1339px 1530px #cacacaa3, 587px 536px #cacacaa3,
    61px 841px #cacacaa3, 1010px 923px #cacacaa3, 1131px 1204px #cacacaa3, 1577px 1360px #cacacaa3,
    524px 864px #cacacaa3, 1470px 1142px #cacacaa3, 622px 215px #cacacaa3, 682px 1778px #cacacaa3,
    111px 1913px #cacacaa3, 1452px 1887px #cacacaa3, 75px 60px #cacacaa3, 538px 1364px #cacacaa3,
    1795px 207px #cacacaa3, 1217px 1161px #cacacaa3, 1684px 820px #cacacaa3, 609px 162px #cacacaa3,
    1927px 994px #cacacaa3, 1318px 1869px #cacacaa3, 1133px 491px #cacacaa3, 650px 1771px #cacacaa3,
    943px 1682px #cacacaa3, 1828px 285px #cacacaa3, 1756px 578px #cacacaa3, 1228px 615px #cacacaa3,
    1874px 344px #cacacaa3, 477px 375px #cacacaa3, 941px 314px #cacacaa3, 289px 168px #cacacaa3,
    548px 1134px #cacacaa3, 1115px 978px #cacacaa3, 931px 390px #cacacaa3, 1877px 1566px #cacacaa3,
    1114px 151px #cacacaa3, 42px 980px #cacacaa3, 1490px 1022px #cacacaa3, 293px 386px #cacacaa3,
    1444px 512px #cacacaa3, 621px 601px #cacacaa3, 947px 1398px #cacacaa3, 671px 430px #cacacaa3,
    1278px 1163px #cacacaa3, 669px 112px #cacacaa3, 25px 1238px #cacacaa3, 100px 698px #cacacaa3,
    2000px 815px #cacacaa3, 1652px 898px #cacacaa3, 104px 420px #cacacaa3, 1294px 682px #cacacaa3,
    1320px 1380px #cacacaa3, 1119px 715px #cacacaa3, 160px 751px #cacacaa3, 1047px 295px #cacacaa3,
    1931px 1033px #cacacaa3, 462px 922px #cacacaa3, 1032px 324px #cacacaa3, 779px 1130px #cacacaa3,
    1393px 826px #cacacaa3, 1146px 56px #cacacaa3, 1829px 1904px #cacacaa3, 1384px 1141px #cacacaa3,
    1695px 708px #cacacaa3, 1821px 1693px #cacacaa3, 887px 1820px #cacacaa3, 1338px 291px #cacacaa3,
    401px 1483px #cacacaa3, 1649px 1769px #cacacaa3, 810px 630px #cacacaa3, 1309px 809px #cacacaa3,
    1761px 920px #cacacaa3, 1565px 1683px #cacacaa3, 735px 1014px #cacacaa3, 1535px 1705px #cacacaa3,
    1552px 887px #cacacaa3, 9px 197px #cacacaa3, 1452px 319px #cacacaa3, 1069px 322px #cacacaa3,
    1145px 244px #cacacaa3, 240px 335px #cacacaa3, 205px 1065px #cacacaa3, 442px 498px #cacacaa3,
    1751px 1694px #cacacaa3, 1207px 1713px #cacacaa3, 1030px 1234px #cacacaa3,
    1115px 844px #cacacaa3, 1748px 1150px #cacacaa3, 1242px 1931px #cacacaa3, 1807px 30px #cacacaa3,
    1682px 12px #cacacaa3, 167px 692px #cacacaa3, 853px 1788px #cacacaa3, 486px 862px #cacacaa3,
    945px 1603px #cacacaa3, 990px 1531px #cacacaa3, 818px 146px #cacacaa3, 1991px 1721px #cacacaa3,
    72px 449px #cacacaa3, 532px 60px #cacacaa3, 1157px 1274px #cacacaa3, 1662px 1235px #cacacaa3,
    701px 12px #cacacaa3, 848px 1438px #cacacaa3, 1697px 1078px #cacacaa3, 1794px 1872px #cacacaa3,
    1996px 151px #cacacaa3, 434px 1307px #cacacaa3, 86px 1054px #cacacaa3, 79px 235px #cacacaa3,
    185px 1838px #cacacaa3, 1814px 404px #cacacaa3, 466px 1940px #cacacaa3, 472px 929px #cacacaa3,
    1798px 849px #cacacaa3, 467px 1410px #cacacaa3, 1433px 257px #cacacaa3, 1843px 1703px #cacacaa3,
    1313px 717px #cacacaa3, 1684px 1263px #cacacaa3, 1891px 838px #cacacaa3, 105px 658px #cacacaa3,
    896px 1687px #cacacaa3, 1632px 1657px #cacacaa3, 724px 1602px #cacacaa3, 685px 1087px #cacacaa3,
    1296px 593px #cacacaa3, 1220px 1305px #cacacaa3, 667px 1893px #cacacaa3, 1841px 1142px #cacacaa3,
    1986px 1025px #cacacaa3, 205px 1497px #cacacaa3, 1226px 996px #cacacaa3, 415px 456px #cacacaa3,
    950px 1736px #cacacaa3, 1546px 849px #cacacaa3, 454px 849px #cacacaa3, 886px 250px #cacacaa3,
    365px 1410px #cacacaa3, 423px 1480px #cacacaa3, 1290px 1357px #cacacaa3, 1766px 269px #cacacaa3,
    1060px 637px #cacacaa3, 779px 697px #cacacaa3, 61px 1477px #cacacaa3, 1054px 567px #cacacaa3,
    37px 538px #cacacaa3, 1842px 1874px #cacacaa3, 841px 71px #cacacaa3, 1079px 1268px #cacacaa3,
    1017px 1667px #cacacaa3, 1061px 1948px #cacacaa3, 919px 1279px #cacacaa3,
    1463px 1271px #cacacaa3, 1296px 1213px #cacacaa3, 159px 603px #cacacaa3, 1966px 983px #cacacaa3,
    1262px 1598px #cacacaa3, 759px 1525px #cacacaa3, 503px 1984px #cacacaa3, 1211px 1810px #cacacaa3,
    1365px 1123px #cacacaa3, 1085px 12px #cacacaa3, 1224px 1971px #cacacaa3, 1797px 1056px #cacacaa3,
    391px 1197px #cacacaa3, 1207px 887px #cacacaa3, 601px 934px #cacacaa3, 1617px 1433px #cacacaa3,
    457px 1983px #cacacaa3, 1374px 1316px #cacacaa3, 632px 502px #cacacaa3, 897px 455px #cacacaa3,
    889px 106px #cacacaa3, 847px 719px #cacacaa3, 1379px 1371px #cacacaa3, 1806px 532px #cacacaa3,
    1046px 290px #cacacaa3, 977px 1255px #cacacaa3, 1619px 966px #cacacaa3, 892px 826px #cacacaa3,
    485px 1477px #cacacaa3, 1216px 1681px #cacacaa3, 1046px 1944px #cacacaa3, 25px 1451px #cacacaa3,
    600px 798px #cacacaa3, 414px 1795px #cacacaa3, 1296px 1908px #cacacaa3, 1185px 1596px #cacacaa3,
    295px 1652px #cacacaa3, 105px 346px #cacacaa3, 1370px 852px #cacacaa3, 1568px 43px #cacacaa3,
    919px 337px #cacacaa3, 1970px 1964px #cacacaa3, 66px 1372px #cacacaa3, 1372px 1786px #cacacaa3,
    568px 278px #cacacaa3, 1402px 755px #cacacaa3, 1591px 1681px #cacacaa3, 1694px 1214px #cacacaa3,
    803px 727px #cacacaa3, 298px 1648px #cacacaa3, 1071px 908px #cacacaa3, 1897px 1262px #cacacaa3,
    662px 631px #cacacaa3, 98px 1265px #cacacaa3, 687px 149px #cacacaa3, 890px 1765px #cacacaa3,
    326px 1645px #cacacaa3, 1935px 966px #cacacaa3, 90px 1669px #cacacaa3, 1327px 1547px #cacacaa3,
    1205px 405px #cacacaa3, 1926px 1563px #cacacaa3, 132px 455px #cacacaa3, 1919px 298px #cacacaa3,
    976px 525px #cacacaa3, 1473px 575px #cacacaa3, 1887px 1197px #cacacaa3, 1841px 1949px #cacacaa3,
    1304px 401px #cacacaa3, 1126px 557px #cacacaa3, 721px 1073px #cacacaa3, 1911px 1201px #cacacaa3,
    541px 455px #cacacaa3, 1708px 1762px #cacacaa3, 237px 974px #cacacaa3, 695px 483px #cacacaa3,
    1676px 490px #cacacaa3, 1480px 1350px #cacacaa3, 350px 344px #cacacaa3, 939px 1697px #cacacaa3,
    640px 633px #cacacaa3, 1063px 1766px #cacacaa3, 1357px 241px #cacacaa3, 1559px 1824px #cacacaa3,
    1137px 780px #cacacaa3, 1249px 897px #cacacaa3, 865px 803px #cacacaa3, 1280px 616px #cacacaa3,
    278px 1610px #cacacaa3, 1832px 51px #cacacaa3, 979px 94px #cacacaa3, 1576px 277px #cacacaa3,
    1427px 1575px #cacacaa3, 470px 1494px #cacacaa3, 1345px 995px #cacacaa3;
  animation: animStar 50s linear infinite;
}

#stars2 {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 389px 1526px #cacacaa3, 1686px 381px #cacacaa3, 1739px 688px #cacacaa3,
    86px 1141px #cacacaa3, 1250px 1657px #cacacaa3, 966px 528px #cacacaa3, 1084px 552px #cacacaa3,
    1963px 493px #cacacaa3, 232px 956px #cacacaa3, 802px 1880px #cacacaa3, 1346px 1902px #cacacaa3,
    1304px 950px #cacacaa3, 43px 1371px #cacacaa3, 816px 1333px #cacacaa3, 962px 757px #cacacaa3,
    1271px 1523px #cacacaa3, 497px 380px #cacacaa3, 25px 1381px #cacacaa3, 1092px 1137px #cacacaa3,
    1552px 871px #cacacaa3, 1323px 1475px #cacacaa3, 1437px 156px #cacacaa3, 287px 588px #cacacaa3,
    1808px 721px #cacacaa3, 906px 454px #cacacaa3, 35px 1345px #cacacaa3, 1212px 264px #cacacaa3,
    1910px 1749px #cacacaa3, 1411px 1287px #cacacaa3, 304px 1495px #cacacaa3, 1935px 177px #cacacaa3,
    1956px 1494px #cacacaa3, 130px 1468px #cacacaa3, 23px 431px #cacacaa3, 1385px 198px #cacacaa3,
    1166px 1322px #cacacaa3, 671px 250px #cacacaa3, 1781px 502px #cacacaa3, 1513px 1020px #cacacaa3,
    1851px 514px #cacacaa3, 960px 1770px #cacacaa3, 1997px 1421px #cacacaa3, 1077px 824px #cacacaa3,
    1603px 1000px #cacacaa3, 914px 1295px #cacacaa3, 1143px 1658px #cacacaa3,
    1659px 1749px #cacacaa3, 331px 720px #cacacaa3, 421px 1845px #cacacaa3, 851px 1994px #cacacaa3,
    1678px 1880px #cacacaa3, 830px 1868px #cacacaa3, 1491px 245px #cacacaa3, 161px 1151px #cacacaa3,
    762px 291px #cacacaa3, 1491px 924px #cacacaa3, 41px 1025px #cacacaa3, 191px 914px #cacacaa3,
    587px 277px #cacacaa3, 472px 989px #cacacaa3, 283px 473px #cacacaa3, 813px 1434px #cacacaa3,
    1819px 1426px #cacacaa3, 1527px 406px #cacacaa3, 856px 1372px #cacacaa3, 567px 1077px #cacacaa3,
    1828px 1358px #cacacaa3, 1949px 510px #cacacaa3, 453px 506px #cacacaa3, 418px 675px #cacacaa3,
    476px 736px #cacacaa3, 1807px 1134px #cacacaa3, 739px 384px #cacacaa3, 1297px 799px #cacacaa3,
    785px 366px #cacacaa3, 379px 1544px #cacacaa3, 1548px 572px #cacacaa3, 1263px 1316px #cacacaa3,
    790px 682px #cacacaa3, 1703px 282px #cacacaa3, 1730px 198px #cacacaa3, 1440px 1491px #cacacaa3,
    696px 775px #cacacaa3, 1320px 952px #cacacaa3, 1029px 228px #cacacaa3, 911px 1769px #cacacaa3,
    1247px 1568px #cacacaa3, 895px 1804px #cacacaa3, 618px 457px #cacacaa3, 10px 1534px #cacacaa3,
    1107px 1795px #cacacaa3, 434px 1840px #cacacaa3, 255px 743px #cacacaa3, 1483px 1944px #cacacaa3,
    1740px 207px #cacacaa3, 1179px 571px #cacacaa3, 384px 1638px #cacacaa3, 1647px 1559px #cacacaa3,
    385px 1077px #cacacaa3, 730px 1679px #cacacaa3, 1097px 365px #cacacaa3, 602px 745px #cacacaa3,
    1075px 334px #cacacaa3, 1441px 546px #cacacaa3, 903px 683px #cacacaa3, 1289px 651px #cacacaa3,
    607px 1802px #cacacaa3, 469px 1661px #cacacaa3, 1986px 1837px #cacacaa3, 1285px 1255px #cacacaa3,
    1647px 1090px #cacacaa3, 1311px 1816px #cacacaa3, 1767px 970px #cacacaa3, 259px 203px #cacacaa3,
    626px 280px #cacacaa3, 405px 990px #cacacaa3, 1893px 1754px #cacacaa3, 1165px 930px #cacacaa3,
    1312px 375px #cacacaa3, 68px 201px #cacacaa3, 177px 1925px #cacacaa3, 872px 705px #cacacaa3,
    1568px 1874px #cacacaa3, 1700px 1684px #cacacaa3, 481px 1698px #cacacaa3, 808px 1809px #cacacaa3,
    598px 1338px #cacacaa3, 1476px 718px #cacacaa3, 1543px 1808px #cacacaa3, 1341px 855px #cacacaa3,
    688px 1586px #cacacaa3, 1518px 1501px #cacacaa3, 1493px 1545px #cacacaa3, 373px 758px #cacacaa3,
    1531px 107px #cacacaa3, 401px 1240px #cacacaa3, 55px 35px #cacacaa3, 928px 127px #cacacaa3,
    1751px 1120px #cacacaa3, 1752px 1097px #cacacaa3, 303px 462px #cacacaa3, 475px 1934px #cacacaa3,
    1217px 426px #cacacaa3, 847px 201px #cacacaa3, 52px 1909px #cacacaa3, 1395px 1601px #cacacaa3,
    462px 481px #cacacaa3, 251px 1397px #cacacaa3, 990px 482px #cacacaa3, 1612px 221px #cacacaa3,
    1457px 147px #cacacaa3, 1833px 423px #cacacaa3, 292px 310px #cacacaa3, 1403px 1311px #cacacaa3,
    1986px 727px #cacacaa3, 78px 1651px #cacacaa3, 1513px 1402px #cacacaa3, 1800px 579px #cacacaa3,
    587px 218px #cacacaa3, 1777px 1697px #cacacaa3, 1628px 403px #cacacaa3, 1115px 1886px #cacacaa3,
    332px 276px #cacacaa3, 244px 1990px #cacacaa3, 446px 85px #cacacaa3, 1915px 1336px #cacacaa3,
    76px 336px #cacacaa3, 986px 1121px #cacacaa3, 1183px 940px #cacacaa3, 168px 1918px #cacacaa3,
    261px 27px #cacacaa3, 1535px 606px #cacacaa3, 93px 316px #cacacaa3, 1733px 854px #cacacaa3,
    55px 1586px #cacacaa3, 481px 753px #cacacaa3, 278px 398px #cacacaa3, 1131px 436px #cacacaa3,
    229px 1369px #cacacaa3, 51px 873px #cacacaa3, 1111px 237px #cacacaa3, 131px 1394px #cacacaa3,
    1552px 332px #cacacaa3, 38px 543px #cacacaa3, 293px 1430px #cacacaa3, 1375px 320px #cacacaa3,
    1196px 1993px #cacacaa3, 1104px 1945px #cacacaa3, 1438px 1763px #cacacaa3, 113px 548px #cacacaa3,
    378px 1856px #cacacaa3, 1111px 1193px #cacacaa3, 446px 1049px #cacacaa3, 1733px 1018px #cacacaa3,
    1633px 496px #cacacaa3, 653px 502px #cacacaa3, 1353px 953px #cacacaa3, 919px 323px #cacacaa3,
    813px 469px #cacacaa3, 114px 166px #cacacaa3;
  animation: animStar 100s linear infinite;
}
#stars3 {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 1272px 1753px #cacacaa3, 1238px 1767px #cacacaa3, 1197px 1003px #cacacaa3,
    1845px 1375px #cacacaa3, 129px 873px #cacacaa3, 154px 1917px #cacacaa3, 274px 662px #cacacaa3,
    510px 397px #cacacaa3, 1398px 1144px #cacacaa3, 1053px 295px #cacacaa3, 1966px 146px #cacacaa3,
    723px 277px #cacacaa3, 1836px 1454px #cacacaa3, 1305px 255px #cacacaa3, 1967px 1394px #cacacaa3,
    1185px 1554px #cacacaa3, 559px 12px #cacacaa3, 907px 626px #cacacaa3, 1516px 149px #cacacaa3,
    329px 578px #cacacaa3, 176px 132px #cacacaa3, 1995px 1450px #cacacaa3, 1802px 1987px #cacacaa3,
    433px 179px #cacacaa3, 275px 989px #cacacaa3, 12px 74px #cacacaa3, 779px 1780px #cacacaa3,
    1732px 1067px #cacacaa3, 856px 580px #cacacaa3, 1740px 1527px #cacacaa3, 491px 1588px #cacacaa3,
    883px 1898px #cacacaa3, 662px 196px #cacacaa3, 1040px 1498px #cacacaa3, 1599px 305px #cacacaa3,
    735px 106px #cacacaa3, 779px 22px #cacacaa3, 35px 1953px #cacacaa3, 707px 902px #cacacaa3,
    982px 192px #cacacaa3, 1390px 1851px #cacacaa3, 1610px 578px #cacacaa3, 803px 1806px #cacacaa3,
    1745px 1319px #cacacaa3, 948px 648px #cacacaa3, 648px 716px #cacacaa3, 1236px 866px #cacacaa3,
    1119px 1301px #cacacaa3, 98px 1677px #cacacaa3, 65px 151px #cacacaa3, 474px 582px #cacacaa3,
    1130px 469px #cacacaa3, 1452px 186px #cacacaa3, 1034px 1578px #cacacaa3, 1628px 793px #cacacaa3,
    1430px 605px #cacacaa3, 999px 845px #cacacaa3, 521px 484px #cacacaa3, 258px 778px #cacacaa3,
    1743px 878px #cacacaa3, 1112px 1020px #cacacaa3, 1482px 1227px #cacacaa3, 1610px 143px #cacacaa3,
    296px 802px #cacacaa3, 197px 637px #cacacaa3, 1676px 962px #cacacaa3, 1060px 1499px #cacacaa3,
    712px 48px #cacacaa3, 440px 279px #cacacaa3, 1099px 504px #cacacaa3, 688px 328px #cacacaa3,
    40px 217px #cacacaa3, 1694px 787px #cacacaa3, 559px 1702px #cacacaa3, 1747px 534px #cacacaa3,
    1512px 50px #cacacaa3, 1239px 864px #cacacaa3, 1343px 1828px #cacacaa3, 1961px 638px #cacacaa3,
    1046px 921px #cacacaa3, 405px 531px #cacacaa3, 882px 1204px #cacacaa3, 1247px 273px #cacacaa3,
    1107px 230px #cacacaa3, 1494px 1404px #cacacaa3, 797px 1793px #cacacaa3, 338px 123px #cacacaa3,
    923px 1429px #cacacaa3, 804px 1286px #cacacaa3, 813px 1981px #cacacaa3, 1618px 100px #cacacaa3,
    1653px 651px #cacacaa3, 80px 1584px #cacacaa3, 402px 1951px #cacacaa3, 999px 1846px #cacacaa3,
    1837px 253px #cacacaa3, 588px 844px #cacacaa3, 447px 394px #cacacaa3, 474px 1104px #cacacaa3,
    1697px 209px #cacacaa3;
  animation: animStar 150s linear infinite;
}
@keyframes animStar {
  from {
    transform: translateX(-250px);
  }
  to {
    transform: translateX(500px);
  }
}

html,
body {
  margin: 0;
  padding: 0;
}
body {
  background: #000;
  background: rgba(0, 0, 0, 1);
  overflow-x: hidden;
}

/* ---------- Fog ---------- */
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
}
#foglayer_01,
#foglayer_02,
#foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}
#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}
#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
}
#foglayer_02,
#foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
  'size: cover' || 'size: 100%'; results remain the same
  'attachment: scroll' can be added or removed; results remain the same
  'attachment: fixed' causing unexpected results in Chrome
  'repeat-x' || 'no-repeat'; results remain the same
*/
#foglayer_01 .image01,
#foglayer_01 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog1.png")
    center center/cover no-repeat transparent;
}
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog2.png")
    center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-moz-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@-moz-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@-o-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-moz-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-o-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-moz-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-o-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  #foglayer_01 .image01,
  #foglayer_01 .image02,
  #foglayer_02 .image01,
  #foglayer_02 .image02,
  #foglayer_03 .image01,
  #foglayer_03 .image02 {
    width: 100%;
  }
}

.vjs-tree-node:hover {
  background-color: #595959 !important;
}
</style>
