import Vue from 'vue'
// import '../element-variables.scss'
// import locale from 'element-ui/lib/locale/lang/en'
// import 'element-theme-dark';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// configure language
locale.use(lang)
import {
    Loading,
    Pagination,
    Dialog,
    Input,
    InputNumber,
    Checkbox,
    Select,
    Option,
    Button,
    Table,
    TableColumn,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Tag,
    Row,
    Col,
    Upload,
    Card,
    ColorPicker,
    Container,
    Header,
    Main,
    Footer,
    PageHeader,
    Message,
    MessageBox
    // Notification
  } from 'element-ui';
  Vue.use(Loading.directive)
  Vue.use(Pagination)
  Vue.use(Dialog)
  Vue.use(Input)
  Vue.use(InputNumber)
  Vue.use(Checkbox)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Button)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Form)
  Vue.use(FormItem)
  Vue.use(Tabs)
  Vue.use(TabPane)
  Vue.use(Tag)
  Vue.use(Row)
  Vue.use(Col)
  Vue.use(Upload)
  Vue.use(Card)
  Vue.use(ColorPicker)
  Vue.use(Container)
  Vue.use(Header)
  Vue.use(Main)
  Vue.use(Footer)
  Vue.use(PageHeader)
//   Vue.use(Message)
//   Vue.use(Notification)
  Vue.prototype.$message = Message;
  Vue.prototype.$confirm = MessageBox.confirm;

// Vue.use(Element, { locale })
