function isContainer(containerNo) {
    if (!containerNo || typeof containerNo !== 'string') return false;

    const formattedCode = containerNo.toUpperCase();
    if (!isValidFormat(formattedCode)) return false;

    const checkDigit = parseInt(formattedCode.charAt(10), 10);
    const calculatedSum = calculateSum(formattedCode.substring(0, 10));
    return checkDigit === calculatedSum;
}

function isValidFormat(containerNo) {
    const isCorrectLength = containerNo.length === 11;
    const isISOFormat = /^[A-Z]{4}\d{7}$/.test(containerNo);
    return isCorrectLength && isISOFormat;
}

function calculateSum(containerNo) {
    const alphabet = getAlphabetValues();
    let sum = 0;

    for (let i = 0; i < containerNo.length; i++) {
        let value = i < 4 ? alphabet[containerNo[i]] : parseInt(containerNo[i], 10);
        sum += value * (i + 1);
    }
    return sum % 11 % 10;
}

function getAlphabetValues() {
    return {
        'A': 10, 'B': 12, 'C': 13, 'D': 14, 'E': 15, 'F': 16, 'G': 17, 'H': 18, 'I': 19,
        'J': 20, 'K': 21, 'L': 23, 'M': 24, 'N': 25, 'O': 26, 'P': 27, 'Q': 28, 'R': 29,
        'S': 30, 'T': 31, 'U': 32, 'V': 34, 'W': 35, 'X': 36, 'Y': 37, 'Z': 38
    };
}

export default isContainer;