import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    plugins: [
        createPersistedState()
    ],
    modules: {},
    state: {
        darkMode: true,
        token: null,
        accessState: {},
        tokenExpiry: null,
        user: {},
        authState: '',
        orgToken: null,
        fixNavBar: false,
        organisations: [],
        currentOrg: null,
        currentRoute: '',
        permissions: [],
        tenantSettings: null,
        hasAcceptedLatestTerms: false
    },
    getters: {
        tenantBrand(state) {
            return state.tenantSettings?.brand;
        },
        brandLabel(state) {
            return state.tenantSettings?.brand?.label || '';
        }
    },
    mutations: {
        setDarkMode(state, darkMode) {
            state.darkMode = darkMode;
        },
        setAccessState(state, accessState) {
            state.accessState = accessState;
        },
        setAuthState(state, authState) {
            state.authState = authState;
        },
        setFixNavBar(state, navbar) {
            state.fixNavBar = navbar;
        },
        setToken(state, token) {
            state.token = token;
        },
        setTokenExpiry(state, tokenExpiry) {
            state.tokenExpiry = tokenExpiry;
        },
        setOrgToken(state, orgToken) {
            state.orgToken = orgToken;
        },
        setOrganisations(state, orgs) {
            state.organisations = orgs;
        },
        setPermissions(state, permissions) {
            state.permissions = permissions;
        },
        setCurrentOrg(state, org) {
            state.currentOrg = org;
        },
        setCurrentRoute(state, route) {
            state.currentRoute = route;
        },
        setUser(state, user) {
            state.user = user;
        },
        setTenantSettings(state, data) {
            state.tenantSettings = data;
            document.title = data.brand.label;

            const icon = document.querySelector("link[rel*='icon']");
            if (icon) icon.href = data.brand.logoUrl.favicon;
        },
        setHasAcceptedLatestTerms(state, status) {
            state.hasAcceptedLatestTerms = status;
        },
        resetState(state) {
            state.darkMode = true;
            state.token = null;
            state.accessState = {};
            state.tokenExpiry = null;
            state.user = {};
            state.authState = '';
            state.orgToken = null;
            state.fixNavBar = false;
            state.organisations = [];
            state.currentOrg = null;
            state.currentRoute = '';
            state.permissions = [];
            state.tenantSettings = null;
            state.hasAcceptedLatestTerms = false;

            localStorage.clear();
            sessionStorage.clear();
        }
    },
    actions: {
        setAuthState({ commit }, authState) {
            commit('setAuthState', authState);
        },
        setAccessState({ commit }, accessState) {
            commit('setAccessState', accessState);
        },
        setFixNavBar({ commit }, navbar) {
            commit('setFixNavBar', navbar);
        },
        setDarkMode({ commit }, darkMode) {
            commit('setDarkMode', darkMode);
        },
        setToken({ commit }, token) {
            commit('setToken', token);
        },
        setTokenExpiry({ commit }, tokenExpiry) {
            commit('setTokenExpiry', tokenExpiry);
        },
        setOrgToken({ commit }, orgToken) {
            commit('setOrgToken', orgToken);
        },
        setOrganisations({ commit }, orgs) {
            commit('setOrganisations', orgs);
        },
        setPermissions({ commit }, permissions) {
            commit('setPermissions', permissions);
        },
        setCurrentOrg({ commit }, org) {
            commit('setCurrentOrg', org);
        },
        setCurrentRoute({ commit }, route) {
            commit('setCurrentRoute', route);
        },
        setUser({ commit }, user) {
            commit('setUser', user);
        },
        setTenantSettings({ commit }, data) {
            commit('setTenantSettings', data);
        },
        resetState({ commit }) {
            commit('resetState');
        },
        async checkUserAcceptance({ commit, state }) {
            try {
                const response = await this._vm.$API.checkUserAcceptance({
                    userId: state.user.id,
                    organisationId: state.currentOrg.id,
                });
                if (response.accepted) {
                    commit('setHasAcceptedLatestTerms', true);
                } else {
                    commit('setHasAcceptedLatestTerms', false);
                }
            } catch (error) {
                commit('setHasAcceptedLatestTerms', false);
                console.error('Error checking user acceptance:', error);
            }
        }
    }
});
