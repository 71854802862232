import Vue from 'vue';
import Vuetify from 'vuetify/lib'
import 'vuetify/src/styles/main.sass'
import 'vuetify/dist/vuetify.min.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
});

export default new Vuetify({
  icons: {
    iconfont: 'md', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: { customProperties: true },
    // },
    themes: {
      light: {
        // primary: "#ff6666",
        primary: "#729fd9",
        // secondary: "#f7b568",
        secondary: "#2a5b84",

        tertiary: '#007d5c',
        accent: '#8c9eff',
        error: '#e31010',
        redPop: '#E37169',
        // background: '#d4d4d4',
        background: '#f2f2f2',
        primaryText: '#050505',
        greyDarker: '#e3e3e3',
        greyRaised: "#eee",
        greyBase: "#729fd9",
        greyContrast: "#cbd0d8",
        topBar: '#b6b6b6',
        toolbar: "#fff",
        component: '#d9d9dc',
        modal: '#d9d9dc',
        edit: '#4287f5',
        contrastText: '#6c6969'
      },
      dark: {
        // primary: "#ff6666",
        primary: "#729fd9",
        // secondary: "#f7b568",
        secondary: "#2a5b84",
        tertiary: '#007d5c',
        // background: '#181818',
        redPop: '#E37169',
        background: '#3d3d3d',
        backgroundAccent: '#313846',
        primaryText: '#ffffff',
        greyRaised: "#282828",
        // greyBase: "#3d3d3d",
        greyDarker: '#333',
        greyBase: "#2a5b84",
        greyContrast: "#aaaaaa",
        toolbar: "#212121",
        topBar: '#3d3d3d',
        component: "#212121",
        danger: "#eb4034",
        modal: '#3d3d3d',
        edit: '#4287f5',
        contrastText: '#fff'

      },
    }
  }
});