<template>
    <div>
        <v-card >
            <v-toolbar flat color="transparent">
                <v-toolbar-title>
                    Manage Milestone
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if ='getTypeName(item.type) === "Unplugged"' text @click="save()" color="secondary" 
                    :loading="saving"><v-icon>save</v-icon></v-btn>
                <v-btn v-else text @click="save()" color="secondary" :disabled="!item.type || !item.poiId "
                    :loading="saving"><v-icon>save</v-icon></v-btn>
                    <v-btn v-if="item.id" icon @click="removePOI()" :loading="removingPOI" color="red"><v-icon>delete</v-icon></v-btn>
                <v-btn text @click="$emit('close')">X</v-btn>
            </v-toolbar>
            <v-card-subtitle class="mt-0 pt-0">
                {{ getTypeName(item.type) }}
            </v-card-subtitle>
            <v-card-text>
                <!-- <v-col cols="12" class="my-0 py-0">
                        <v-select label="Type" hide-details v-model="item.type" disabled outlined dense :items="types.filter(x=>x.type.includes(bookingType))" rounded
                            @input="updateDescription" item-text="name" item-value="value"></v-select>
                    </v-col> -->
                <v-list-item v-if=" !item.poi " @click="poiModal = true" class="mb-2" :disabled='getTypeName(item.type) === "Unplugged"'>
                    <v-list-item-action>
                        <v-btn icon color="primary">
                            <v-icon>location_on</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Add Point of Interest
                        </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-else @click="poiModal = true" class="mb-2">
                    <v-list-item-action>
                        <v-btn icon color="grey">
                            <v-icon>location_on</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-if="item.poi.friendlyName">
                            {{ item.poi.friendlyName }}
                        </v-list-item-title>
                        <v-list-item-title v-else>
                            {{ item.poi.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px" class="text-wrap">
                            {{ item.poi.address }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-row align="center" class="mt-2">
                    <!-- <v-col cols="12" class="my-0 py-0">
                        <v-text-field outlined label="Milestone Description" v-model="item.description" dense
                            rounded></v-text-field>
                    </v-col> -->
                    
                   

                    <v-col cols="12" sm="6" class="my-0 py-0" v-if="!hideDate">
                        <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on }">
                                <v-list-item v-on="on">
                                    <v-list-item-action>
                                        <v-icon color="grey">today</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="item.selectedDate">
                                            {{ formatDate(item.selectedDate) }}
                                        </v-list-item-title>
                                        <v-list-item-title v-else>
                                            -
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Event Date
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="item.selectedDate">
                                        <v-btn icon @click.stop="removeDate"><v-icon color="red">cancel</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                            
                            <v-date-picker v-model="item.selectedDate" no-title scrollable>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0" v-if="!hideDate">
                        <v-autocomplete hide-details dense :items="timeOptions" v-model="item.time" outlined rounded
                            label="Time" prepend-inner-icon="schedule"></v-autocomplete>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" class="my-0 py-0" v-if="!hideDate">
                        <v-switch v-model="item.isActual" label="Actual Time" color="blue" dense></v-switch>
                    </v-col> -->

                </v-row>

               
            </v-card-text>
        </v-card>

        <v-dialog v-model="poiModal" width="480px">
            <v-card :loading="poiSearchLoading">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Add Point of Interest
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu transition="slide-y-transition" offset-y style="min-width: 7vw;" rounded="xl"
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-bind="attrs" v-on="{ ...menu }">
                                        <v-icon v-on="{ ...tooltip }">filter_alt</v-icon>
                                    </v-btn>
                                </template>
                                <span>Filter Functions</span>
                            </v-tooltip>
                        </template>
                        <v-toolbar flat dense color="greyBase">Available Functions</v-toolbar>
                        <v-list dense color="greyBase" rounded class="px-0 pt-0">
                            <v-list-item v-for="(item, index) in poiFunctions" :key="index">
                                <v-chip @click="item.selected = !item.selected, searchPois(poiSearch)" dense
                                    :outlined="!item.selected" :color="item.selected ? 'tertiary' : ''"
                                    :filled="item.selected">{{ item.name }}</v-chip>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn icon @click="poiModal = false" color="red">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-chip-group column>
                        <v-chip @click="item.selected = !item.selected, searchPois(poiSearch)"
                            v-for="(item, index) in poiFunctions.filter(func => func.selected)" :key="index" filled dense
                            small color="tertiary">{{ item.name }}</v-chip>
                    </v-chip-group>
                    <v-text-field hide-details outlined dense clearable autofocus v-model="poiSearch" placeholder="Search"
                        prepend-inner-icon="search"></v-text-field>
                    <v-list dense style="max-height: 45vh; overflow-y: auto">
                        <!-- <v-progress-linear v-if="poiSearchLoading" color="primary" indeterminate></v-progress-linear> -->
                        <v-list-item v-for="point in pointsOfInterest" :key="point.id" @click="setPOI(point)">
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    point.name
                                }}</v-list-item-title>
                                 <v-list-item-subtitle style="font-size: 12px" class="text-wrap" v-if="point.friendlyName">
                                    {{ point.friendlyName }}</v-list-item-subtitle>
                                <v-list-item-subtitle style="font-size: 12px" class="text-wrap">
                                    {{ point.address }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="point.poiAliases.length>0" style="font-size: 12px" class="text-wrap">
                                        Aliases: 
                                    <v-chip x-small v-for="alias in point.poiAliases" :key="alias.id" class="mx-1">{{ alias.alias }}</v-chip></v-list-item-subtitle>
                                    
                                <v-list-item-subtitle style="font-size: 12px" v-if="point.poiFunctions && point.poiFunctions.length > 0">
                                  Functions:   <v-chip class="mx-1" x-small v-for="functionality in point.poiFunctions"
                                        :key="functionality.id">{{ functionality.functionality }}</v-chip>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import dateFormat from 'dateformat';
export default {
    props: ['item', 'hideDate', 'bookingType'],
    data: () => ({
        loadingFunctions: false,
        poiFunctions: [],
        poiParams: {
            limit: 50,
            offset: 0,
            search: null
        },
        poiModal: false,
        pointsOfInterest: [],
        poiSearch: null,
        poiSearchLoading: false,
        poiSearchTimeout: null,
        poiRequest: null,
        saving: false,
        timeOptions: [],
        types: [

            {
                name: 'Empty Pickup',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'EMPTY_PICKUP',
                type: ['EXPORT']
            },
            {
                name: 'Full Pickup',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'FULL_PICKUP',
                type: ['IMPORT']
            },
            {
                name: 'Stuff Date',
                eventType: 'EQUIPMENT',
                modeOfTransport: 'TRUCK',
                value: 'STUFF_DATE',
                type: ['EXPORT']
            },
            {
                name: 'Loading Point 1',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'LOADING_POINT_1',
                type: ['EXPORT']
            },
            {
                name: 'Loading Point 2',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'LOADING_POINT_2',
                type: ['EXPORT']
            },
            {
                name:'Gassing',
                eventType:'TRANSPORT',
                modeOfTransport:'TRUCK',
                value:'GASSING',
                type:['EXPORT']
            },
            {
                name:'En Route LP-1 ETA',
                value:'EN_ROUTE_LP1',
                eventType:'TRANSPORT',
                modeOfTransport:'TRUCK',
                type:['EXPORT']
            },
            {
                name:'En Route Port ETA',
                value:'EN_ROUTE_PORT',
                eventType:'TRANSPORT',
                modeOfTransport:'TRUCK',
                type:['EXPORT']
            },
            {
                name:'En Route LP-2 ETA',
                value:'EN_ROUTE_LP2',
                eventType:'TRANSPORT',
                modeOfTransport:'TRUCK',
                type:['EXPORT']
            },
            {
                name:'En Route WB ETA',
                value:'EN_ROUTE_WB',
                eventType:'TRANSPORT',
                modeOfTransport:'TRUCK',
                type:['EXPORT']
            },
            {
                name:'En Route Depot ETA',
                value:'EN_ROUTE_DEPOT',
                eventType:'TRANSPORT',
                modeOfTransport:'TRUCK',
                type:['EXPORT']
            },

            {
                name: 'Delivery Point 1',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'DELIVERY_POINT_1',
                type: ['IMPORT']
            },
            {
                name: 'Delivery Point 2',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'DELIVERY_POINT_2',
                type: ['IMPORT']
            },
            {
                name: 'Delivery Point 3',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'DELIVERY_POINT_3',
                type: ['IMPORT']
            },
            {
                name: 'Weigh Bridge',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'WEIGH_BRIDGE',
                type: ['EXPORT']
            },
            {
                name: 'Plugged In',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'PLUG_IN',
                type: ['EXPORT']
            },
            {
                name: 'Unplugged',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'PLUG_OUT',
                type: ['EXPORT']
            },
            {
                name: 'Port Holding',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'FULL_DROP_OFF',
                type: ['EXPORT']
            },
            {
                name: 'Drop Off Empty',
                eventType: 'TRANSPORT',
                modeOfTransport: 'TRUCK',
                value: 'EMPTY_DROP_OFF',
                type: ['IMPORT']
            }
        ],
        removingPOI: false,
    }),
    watch: {
        // 'item.id': {
        //     immediate: true,
        //     handler(val) {
        //         if (this.item.date) {
        //             this.item.selectedDate = new Date(this.item.date).toISOString().substring(0, 10)
        //             this.item.selectedTime = new Date(this.item.date).toISOString().substring(11, 16)
        //         }
        //     }
        // },
        'item.date': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.item.selectedDate = new Date(val).toISOString().substring(0, 10)
                    this.item.selectedTime = new Date(val).toISOString().substring(11, 16)
                }
            }
        },
        'poiSearch': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.searchPois(val)
                }
            }
        }
    },
    created() {
        console.log('item',this.item)
        this.timeOptions = this.getTimeOptions()
        this.getPoiFunctions();
    },
    methods: {
        removeDate(){
            console.log('insied');
            this.item.selectedDate = null
            this.item.time = null
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy")
            }
            return result;
        },
        getTypeName(type){
            let find = this.types.find(x=>x.value == type)
            return find ? find.name : null
        },
        getTimeOptions() {
            let start = new Date()
            start.setHours(0, 0, 0, 0)
            let end = new Date()
            end.setHours(23, 45, 0, 0)
            let timeOptions = []
            while (start <= end) {
                let hour = start.getHours()
                let minute = start.getMinutes()
                if(hour<10){
                    hour = '0'+hour
                }
                if(minute<10){
                    minute = '0'+minute
                }
                timeOptions.push(hour+':'+minute)
                start.setMinutes(start.getMinutes() + 15)
            }
            return timeOptions
        },
        async getPoiFunctions() {
            this.loadingFunctions = true;
            this.poiFunctions = await this.$API.getPoiFunctions();
            if (this.poiFunctions.length > 0) {
                this.loadingFunctions = false;
            } else {
                this.loadingFunctions = false;
                this.$message({
                    message: 'Error loading the Poi Functions. Please try again later.',
                    type: 'warning',
                });
            }
        },
        async save() {
            this.saving = true;
            console.log('item',this.item)
            this.item.isActive = true
            if (this.item.poi && !this.item.description) {
                let find = this.types.find(type => type.value === this.item.type)
                if(this.item.poi.friendlyName){
                    this.item.description = this.item.poi.friendlyName
                } else {
                    this.item.description = this.item.poi.name
                }
            }
            if (this.item.type) {
                let find = this.types.find(type => type.value === this.item.type)
                this.item.eventType = find.eventType
                this.item.modeOfTransport = find.modeOfTransport
                if (!this.item.description) {
                    this.item.description = find.name
                }
            }
            if (this.item.poi && this.item.poi.unlocode) {
                this.item.location = this.item.poi.unlocode
            }
            if (this.item.poi && this.item.poi.lat && this.item.poi.lng) {
                this.item.lat = this.item.poi.lat
                this.item.lng = this.item.poi.lng
            }
            if (!this.item.location) {
                this.item.location = this.item.pol
            }
            // if (this.item.selectedDate) {
            //     // let date = new Date(this.item.selectedDate + ' ' + this.item.selectedTime)
            //     this.item.date = this.item.selectedDate
            // }
            this.item.date = this.item.selectedDate
            if (this.item.autosave) {
                try {
                    let result = await this.$API.createMilestone(this.item)
                    console.log('result',result)
                    this.$emit('save', result)

                } catch (error) {
                    console.log(error)
                }
            } else {
                this.$emit('unsavedItem', this.item)
            }
            this.saving = false;
        },
        async searchPois(val) {
            if (this.poiSearchLoading) {
                clearTimeout(this.poiSearchTimeout);
            }
            if (this.poiRequest) {
                this.poiRequest.abort()
            }
            this.poiParams.filter = this.poiFunctions.filter(item => item.selected).map(item => item.name)
            this.poiParams.search = val
            if (val && val.length > 1) {
                this.poiSearchLoading = true;
                this.poiSearchTimeout = setTimeout(async () => {
                    this.poiRequest = new AbortController();
                    const signal = this.poiRequest.signal
                    this.pointsOfInterest = await this.$API.getPois(
                        {
                            params: this.poiParams,
                            signal
                        }
                    );
                    this.poiSearchLoading = false;
                }, 750);
            } else {
                clearTimeout(this.poiSearchTimeout);
                this.poiSearchLoading = false;
            }
        },
        async removePOI(){
            this.removingPOI = true
            this.item.isActive = false
            this.item.isDeleted= false
            let result = await this.$API.createMilestone(this.item)
            this.$emit('save', result)
            this.$emit('deleted', result)
            this.removingPOI = false
        },
        setPOI(point) {
            this.item.poi = point
            this.item.poiId = point.id
            let find = this.types.find(type => type.value === this.item.type)
            this.item.description = this.item.poi.friendlyName ?? this.item.poi.name
            this.poiModal = false
        },
        updateDescription() {
            let find = this.types.find(type => type.value === this.item.type)
            if (this.item.type && this.item.poi) {
                this.item.description = this.item.poi.friendlyName ?? this.item.poi.name
            } else {
                this.item.description = find.name
            }
        }
    }
}
</script>