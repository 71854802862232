import Vue from "vue";
import store from "@/store.js";
import API from "@/services/Api";
import VueRouter from "vue-router";
let access = [];
let currentInstance = null;
function goToRoute(route) {
  currentInstance.push(route);
}
Vue.use(VueRouter);
let defaultRoutes = [
  {
    path: "/",
    name: "Login",
    component: () => import("./components/Authentication/Login.vue"),
    meta: {
      protected: false,
      admin: false,
      systemAdmin: false,
      default: true,
    },
  },
  {
    path: "/login",
    name: "LoginScreen",
    component: () => import("./components/Authentication/Login.vue"),
    meta: {
      protected: false,
      admin: false,
      systemAdmin: false,
      default: true,
    },
  },
  {
    path: "/resetPassword",
    name: "LoginScreen",
    component: () => import("./components/Authentication/ResetPassword.vue"),
    meta: {
      protected: false,
      admin: false,
      systemAdmin: false,
      default: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./components/Authentication/Register.vue"),
    meta: {
      protected: false,
      admin: false,
      systemAdmin: false,
      default: true,
    },
  },
  {
    path: "/landing",
    name: "Home",
    component: () => import("./components/Landing/Landing.vue"),
    meta: {
      protected: true,
      admin: false,
      systemAdmin: false,
      default: true,
    },
  },
  {
    path: "/xero/callback",
    name: "Xero",
    component: () => import("./components/Notification/XeroResult.vue"),
    meta: {
      protected: false,
      admin: false,
      systemAdmin: false,
      default: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("./components/Navigation/404.vue"),
    meta: {
      protected: false,
      admin: false,
      systemAdmin: false,
      default: true,
    },
  },
];
function formatRoute(route) {
  try {
    let obj = {
      path: route.path,
      name: route.name,
      meta: {
        protected: route.protected,
        admin: route.admin,
        systemAdmin: route.systemAdmin,
        id: route.id,
      },
      component: () => import(`${route.component}`),
    };
    return obj;
  } catch (e) {
    console.log(e);
    return {};
  }
}

async function validateSession() {
  return store.state.tokenExpiry > new Date().getTime();
}

async function validateAdmin() {
  let result = await API.AuthAdmin();
  return result;
}
async function validateSystemAdmin() {
  let result = await API.AuthSystemAdmin();
  return result;
}
function router(routes, redirect) {
  routes = routes.map((x) => formatRoute(x)).filter((x) => x.component);
  access = [...defaultRoutes];
    if(routes.length === 0 && localStorage['routes']){
        try {
            routes = JSON.parse(localStorage['routes']);
            access = [...defaultRoutes, ...routes];
            routes = routes.map((x) => formatRoute(x)).filter((x) => x.component);
        } catch(e){
            console.log(e)
        }
    }
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [...defaultRoutes, ...routes],
  });
  router.push("/")
  router.onReady(() => {
    // if (redirect && redirect.path != router.currentRoute.path){
        router.push(redirect);
    // }
  });
  router.beforeResolve(async (to, from, next) => {
    // if (from.path !== to.path) {
        if(to.path === "/"){
            next()
        } else {
            if (to.matched.some((record) => access.map(y=>y.path).includes(record.path)) || to.path === "/404") {
                if (to.matched.some((record) => record.meta.systemAdmin)) {
                  let access = await validateSystemAdmin();
                  if (!access) {
                    confirm("Access Denied");
                  } else {
                    next();
                  }
                } else if (to.matched.some((record) => record.meta.admin)) {
                  let access = await validateAdmin();
                  if (!access) {
                    confirm("Access Denied");
                  } else {
                    next();
                  }
                } else if (to.matched.some((record) => record.meta.protected)) {
                  if (!validateSession()) {
                    next({
                      path: "/login",
                    });
                  } else {
                    next();
                  }
                } else {
                  next();
                }
              } else {
                next({
                  path: "/404",
                });
              }
        }
       
    // }
  });
  currentInstance = router;
  return router;
}
function resetRouter(instance, routes, allowedRoutes) {
  access = [...defaultRoutes, ...allowedRoutes];
  localStorage.setItem('routes', JSON.stringify(access));
  routes = routes.map((x) => formatRoute(x)).filter((x) => x.component);
  allowedRoutes = allowedRoutes
    .map((x) => formatRoute(x))
    .filter((x) => x.component);
  const newRouter = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [],
  });
  let allRoutes = [...defaultRoutes, ...allowedRoutes];

  newRouter.routes = [];
  for (let i = 0; i < allRoutes.length; i++) {
    newRouter.addRoute(allRoutes[i]);
  }
  instance.matcher = newRouter.matcher;
  let existingRoutes = instance.options.routes;
  // for(let i = 0; i < routes.length; i++){
  //     let find = existingRoutes.find(x => x.path === routes[i].path)
  //     if(!find){
  //         console.log("Adding Route", routes[i].path)
  //         instance.addRoute(routes[i])
  //     }
  // }
  currentInstance = instance;
}
export { goToRoute, resetRouter, router };
