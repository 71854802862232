<template>
  <div style="background: var(--ag-header-background-color)">
    {{ params.displayName }}
    <v-tooltip top v-if="params.enableFilterIcon">
      <template v-slot:activator="{ on }">
        <v-badge
          v-on="on"
          bordered
          :color="'primary'"
          overlap
          top
          dot
          :value="filterActive"
        >
          <v-btn
            v-on="on"
            x-small
            icon
            @contextmenu="filterRightClick($event)"
            @click="showColumnFilter()"
            :color="filterActive ? 'white' : 'grey'"
            style="visibility: inherit"
          >
            <v-icon small>filter_list</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span>Filter</span>
    </v-tooltip>

    <v-tooltip top v-if="params.enableSorting">
      <template v-slot:activator="{ on }">
        <v-badge
          v-on="on"
          bordered
          :color="'primary'"
          overlap
          top
          dot
          :value="activeSettings.sort == 'asc' || activeSettings.sort == 'desc'"
        >
          <v-btn
            v-on="on"
            x-small
            icon
            @click="sort()"
            style="visibility: inherit"
            :color="activeSettings.sort ? 'primary' : 'grey'"
          >
            <v-icon
              small
              v-if="!activeSettings.sort || activeSettings.sort == 'asc'"
              >arrow_upward</v-icon
            >
            <v-icon small v-else>arrow_downward</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span v-if="!activeSettings.sort">Sort</span>
      <span v-else-if="activeSettings.sort == 'asc'">Sorted: Ascending</span>
      <span v-else>Sorted: Descending</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
        style="visibility: inherit"
        v-on="on" x-small icon @click="showColumnMenu" color="grey">
          <v-icon small>more_vert</v-icon>
        </v-btn>
      </template>
      <span>Column Options</span>
    </v-tooltip>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense style="background: var(--v-modal-base) !important">
        <v-list-item @click="clearColumnFilter()">
          <v-list-item-action class="mr-1 pr-1">
            <v-icon small color="grey">cancel</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-left ml-0 pl-0">
            <v-list-item-title>Clear Column Filter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="clearAllFilter()">
          <v-list-item-action class="mr-1 pr-1">
            <v-icon small color="grey">filter_list_off</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-left ml-0 pl-0">
            <v-list-item-title>Clear All Filters</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
// filterActiveChanged
export default {
  data: () => ({
    activeSettings: {},
    filterActive: false,
    showMenu: false,
    x: 0,
    y: 0,
  }),
  mounted() {
    let sortModel = this.params.api.getColumnState({
      colId: this.params.column.colId,
    });
    this.activeSettings = sortModel.find(
      (item) => item.colId === this.params.column.colId
    );
    const column = this.params.api.getColumn(this.params.column.colId);
    if(column) {
      column.addEventListener("filterActiveChanged", this.filterListener)
    }
    this.filterListener();
  },
  methods: {
    clearColumnFilter() {
      let filter = this.params.api.getFilterModel();
      delete filter[this.params.column.colId];
      this.params.api.setFilterModel(filter);
      this.filterActive = false;
    },
    clearAllFilter() {
      this.params.api.setFilterModel({});
    },
    filterListener(event) {
      let filter = this.params.api.getFilterModel();
      this.filterActive = filter[this.params.column.colId] ? true : false;
    },
    filterRightClick(e) {
      e.preventDefault();
      if (this.filterActive) {
        this.showMenu = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.$nextTick(() => {
          this.showMenu = true;
        });
      }
    },
    showColumnFilter() {
      this.params.api.showColumnFilter(this.params.column.colId);
    },
    // showColumnMenu(e) {
    //   console.log(`showColumnMenu`, e)
    //   this.params.Column(e);
    // },
    showColumnMenu(e) {
      this.params.showColumnMenuAfterMouseClick(e);
      
    },
    sort() {
      this.params.progressSort();
      let sortModel = this.params.api.getColumnState({
        colId: this.params.column.colId,
      });
      this.activeSettings = sortModel.find(
        (item) => item.colId === this.params.column.colId
      );
    },
  },
};
</script>