const TENANTS = {
    LOGLIVE: "LOGLIVE",
    ECERT: "ECERT"
}

// const DEFAULT_BRAND = {
//     label: "LØGLIVE",
//     showNavLabel: true,
//     logoUrl: {
//         navBarLight: "https://cdn.loglive.io/loglive_logo_white.svg",
//         navBarDark: "https://cdn.loglive.io/loglive_logo_dark.svg",
//         loaderLight: "https://cdn.loglive.io/loglive_logo_white.svg",
//         loaderDark: "https://cdn.loglive.io/loglive_logo_dark.svg",
//         landingLight: "https://cdn.loglive.io/loglive_dark.svg",
//         landingDark: "https://cdn.loglive.io/loglive_light.svg",
//         authLight: "https://cdn.loglive.io/loglive_light.svg",
//         favicon: "https://cdn.loglive.io/favicon.ico",
//     }
// }
const DEFAULT_BRAND = {
    label: "LØGLIVE",
    showNavLabel: true,
    logoUrl: {
        navBarLight: "/Loglive.svg",
        navBarDark: "/Loglive.svg",
        loaderLight: "/Loglive.svg",
        loaderDark: "/Loglive.svg",
        landingLight: "/Loglive.svg",
        landingDark: "/Loglive.svg",
        authLight: "/Loglive.svg",
        favicon: "https://cdn.loglive.io/favicon.ico",
    }
}
const TENANT_SETTINGS = {
    [TENANTS.LOGLIVE]: {
        hostNames: ['portal.loglive.io', 'app.loglive.ai'],
        brand: DEFAULT_BRAND
    },
    [TENANTS.ECERT]: {
        hostNames: ['app.ecert.ai'],
        brand: {
            label: "eCert AI",
            showNavLabel: false,
            logoUrl: {
                navBarLight: "https://cdn.loglive.ai/tenants/ecert-global/images/ecertGlobal_dark.png",
                navBarDark: "https://cdn.loglive.ai/tenants/ecert-global/images/ecertGlobal_light.png",
                loaderLight: "https://cdn.loglive.ai/tenants/ecert-global/images/ecertGlobal_dark.png",
                loaderDark: "https://cdn.loglive.ai/tenants/ecert-global/images/ecertGlobal_light.png",
                landingLight: "https://cdn.loglive.ai/tenants/ecert-global/images/ecertGlobal_dark.png",
                landingDark: "https://cdn.loglive.ai/tenants/ecert-global/images/ecertGlobal_light.png",
                authLight: "https://cdn.loglive.ai/tenants/ecert-global/images/ecertGlobal_light.png",
                favicon: "https://cdn.loglive.ai/tenants/ecert-global/images/favicon.ico"
            }
        }
    }
}

export function getCurrentTenantSettings() {
    const hostName = window.location.hostname;
    let settings = TENANT_SETTINGS[TENANTS.LOGLIVE];

    for(const [, tenantSettings] of Object.entries(TENANT_SETTINGS)) {
        if(tenantSettings.hostNames.includes(hostName)) settings = tenantSettings;
    }

    return settings;
}