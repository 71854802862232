<template>
  <div>
    <div v-if="item">
      <v-chip
        v-if="item.iso2"
        outlined
        style="border: none; font-size: 11px"
        small
      >
        <v-avatar size="20" left>
          <v-img
            contain
            :src="`https://cdn.loglive.io/flags/4x3/${item.iso2.toLowerCase()}.svg`"
          ></v-img>
        </v-avatar>
        {{ item.name }}
      </v-chip>
    </div>
    <div v-else-if="pivot">{{ pivot }}</div>
    <div v-else-if="group">{{ group }}</div>
  </div>
</template>
<script>
export default {
  data: () => ({
    group: null,

    item: null,
    pivot: null,
  }),
  mounted() {
    if (typeof this.params.value == "object") {
      this.item = null;
      this.pivot = this.params.value.value;
    } else if (this.params.country) {
      this.item = this.params.country;
      this.pivot = null;
    }
    if(this.params.node && this.params.node.group && this.params.node.key){
        this.group = this.params.value
    }
  },
  methods: {},
};
</script>