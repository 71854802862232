<template>
  <v-card>
    <v-card-title>
      POI Mapping
      <v-spacer></v-spacer>
      <v-btn icon @click="closePOIView">
        <v-icon color="red" >cancel</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <gmap-map :center="{ lat: lat, lng: lng }" :zoom="13" style="height: 35vh;">
        <gmap-marker :position="{ lat: lat, lng: lng }" />
      </gmap-map>
      <v-container fluid>
        <v-row mt="6">
          <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="name" label="Name" outlined readonly></v-text-field>
            <v-text-field v-model="alias" label="Alias" outlined readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="lat" label="Latitude" outlined readonly></v-text-field>
            <v-text-field v-model="lng" label="Longitude" outlined readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="12">
            <v-text-field v-model="address" label="Address" outlined readonly></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
  
<script>
export default {
  props: {
    lat: { type: Number, required: true },
    lng: { type: Number, required: true },
    name: { type: String, required: true },
    alias: { type: String, required: true },
    address: { type: String, required: true },
  },
  methods: {
    closePOIView() {
      this.$emit('close');
    },
  },
}
</script>
  
  