<template>
  <div>
    <v-card flat style="background-color: transparent !important;">
      <v-card-text>
        <v-row align="center" justify="space-between">
          <v-tooltip bottom v-if="booking.customer">
            <template v-slot:activator="{ on }">
              <v-chip
                v-if="booking.customer"
                small
                class="primaryText--text"
                v-on="on"
                outlined
                style="border: none"
              >
                <v-icon
                  class="mr-2"
                  small
                  left
                  v-if="booking.clientStatus"
                  :color="getClientStatusColor(booking.clientStatus)"
                  >fiber_manual_record</v-icon
                >
                <v-avatar
                  size="20"
                  :color="
                    booking.customer && booking.customer.logo
                      ? 'white'
                      : 'secondary'
                  "
                  left
                >
                  <v-img
                    v-if="booking.customer.logo"
                    :src="booking.customer.logo"
                    contain
                  ></v-img>
                  <h3 v-else style="color: white">
                    {{ booking.customer.name.charAt(0) }}
                  </h3>
                </v-avatar>
                <span v-if="booking.customer.alias">{{
                  booking.customer.alias
                }}</span>
                <span v-else>{{ booking.customer.name }}</span>
              </v-chip>
            </template>
            <span
              >Customer
              <span v-if="booking.clientStatus">
                - Status: {{ booking.clientStatus }}</span
              ></span
            >
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="'grey'">outbound</v-icon>
                <span v-if="booking.shipper"
                  ><span v-if="booking.shipper.alias">{{
                    booking.shipper.alias
                  }}</span>
                  <span v-else>{{ booking.shipper.name }}</span></span
                >
                <span v-else>No Shipper</span>
              </v-chip>
            </template>
            <span>Shipper</span>
          </v-tooltip>
          <v-tooltip bottom v-if="booking.shipper && booking.onBehalfShipper">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="'grey'">outbound</v-icon>
                o.b.o
                <span v-if="booking.onBehalfShipper" class="ml-1"
                  ><span v-if="booking.onBehalfShipper.alias">{{
                    booking.onBehalfShipper.alias
                  }}</span>
                  <span v-else class="ml-1">{{
                    booking.onBehalfShipper.name
                  }}</span></span
                >
              </v-chip>
            </template>
            <span>On behalf of Shipper</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="'grey'">call_received</v-icon>
                <span v-if="booking.consignee">
                  <span v-if="booking.consignee.alias">{{
                    booking.consignee.alias
                  }}</span>
                  <span v-else>{{ booking.consignee.name }}</span>
                </span>
                <span v-else>No Consignee</span>
              </v-chip>
            </template>
            <span>Consignee</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="booking.incoTerm ? 'secondary' : 'grey'"
                  >swap_horiz</v-icon
                >
                <span v-if="booking.incoTerm">{{ booking.incoTerm }}</span>
                <span v-else>No Inco Term</span>
              </v-chip>
            </template>
            <span>Inco Term</span>
          </v-tooltip>

          <v-tooltip bottom v-if="booking.shippingLine">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-avatar
                  left
                  color="white"
                  size="48"
                  v-if="booking.shippingLine"
                >
                  <v-img
                    v-if="booking.shippingLine && booking.shippingLine.logo"
                    :src="booking.shippingLine.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-icon left v-else :color="'grey'">directions_boat</v-icon>
                <span v-if="booking.shippingLine"
                  ><span v-if="booking.shippingLine.friendlyName"
                    >{{ booking.shippingLine.friendlyName }}:
                  </span>
                  <span v-else>{{ booking.shippingLine.name }}: </span>
                </span>
                <span v-if="booking.vessel" class="ml-1"
                  >{{ booking.vessel.name }}
                  {{ booking.mainCarriageConveyanceNumber }}</span
                >
                <span v-else>No Vessel</span>
              </v-chip>
            </template>
            <span>Vessel</span>
          </v-tooltip>

          <v-chip
            v-if="booking.serviceType != 'BREAKBULK'"
            outlined
            style="border: none"
          >
          <v-progress-circular
            v-if="loadingContainers"
            indeterminate
            color="white"
            size="24"
          ></v-progress-circular>
          <div v-if="!loadingContainers">
            <v-icon left :color="'grey'">widgets</v-icon>
            {{ countContainers() }} Containers
          </div>
          </v-chip>
          <v-chip
            v-else-if="booking.serviceType == 'BREAKBULK'"
            outlined
            style="border: none"
          >
          <v-progress-circular
            v-if="loadingContainers"
            indeterminate
            color="white"
            size="24"
          ></v-progress-circular>
          <div v-if="!loadingContainers">
            <v-icon left :color="'grey'">widgets</v-icon>
            {{ summaryCountTotalPallets() }} Pallets
          </div>
          </v-chip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" outlined style="border: none">
                <v-icon left :color="'grey'">thermostat</v-icon>
                <span v-if="booking.regimeCode">{{ booking.regimeCode }}</span>
                <span v-else>No Regime Code</span>
                <v-icon
                  v-if="booking.regime && booking.regime.steri"
                  color="blue"
                  right
                  >ac_unit</v-icon
                >
              </v-chip>
            </template>
            <span
              >Regime Code
              <span v-if="booking.regime && booking.regime.steri" class="ml-1"
                >: STERI</span
              ></span
            >
          </v-tooltip>
          <v-chip outlined style="border: none">
            <v-progress-circular
            v-if="loadingContainers"
            indeterminate
            color="white"
            size="24"
          ></v-progress-circular>
          <div v-if="!loadingContainers">
            <v-icon left :color="'grey'">category</v-icon>
            <span class="ml-1" v-if="productList().length > 0">
              {{ productList() }}</span
            >
            <span v-else>No Products</span>
          </div>
          </v-chip>
          <v-chip v-if="booking.isHazardous" outlined style="border: none">
            <v-icon color="orange" left>warning</v-icon> Hazardous
          </v-chip>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
    <script>
export default {
  props: ["booking", "loadingContainers"],
  data: () => ({}),

  methods: {
    countContainers() {
      return this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" || x.status == null) &&
          x.bookingId == this.booking.id
      ).length;
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    productList() {
      let containers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" || x.status == null) &&
          x.bookingId == this.booking.id
      );
      let products = [].concat.apply(
        [],
        containers.map((x) => x.containerProducts)
      );
      let uniqueProducts = [
        ...new Set(
          products.map((x) => this.$Format.capitalizeFirstLetter(x.product.name))
        ),
      ];
      uniqueProducts.sort((a, b) => a - b);
      return uniqueProducts.join(", ");
    },

    summaryCountTotalPallets() {
      let containers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          (x.status == "Allocated" || x.status == null) &&
          x.bookingId == this.booking.id
      );
      let products = [].concat.apply(
        [],
        containers.map((x) => x.containerProducts)
      );
      return products
        .filter((x) => x.pallets)
        .map((x) => x.pallets)
        .reduce((a, b) => a + b, 0);
    },
  },
};
</script>