<template>
  <v-card flat>
    <v-toolbar flat dense v-if="!disableClose">
      <v-toolbar-title> Search Profiles </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon color="redPop" @click="$emit('close')">
        <v-icon small>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text style="height: 100%">
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-chip class="mr-1 searchChipWrap">
          <v-icon small color="grey">search</v-icon>
          <v-text-field
            placeholder="Search"
            class="mb-1 searchInput"
            hide-details
            rounded
            clearable
            dense
            style="margin-top: 2px; width: 300px"
            v-model="search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-tooltip top v-if="filterActive">
            <template v-slot:activator="{ on }">
              <v-btn
                rounded
                right
                small
                icon
                color="redPop"
                @click="resetFilters"
                v-on="on"
              >
                <v-icon>filter_alt_off</v-icon>
              </v-btn>
            </template>
            <span style="font-size: 12px">Clear Filters</span>
          </v-tooltip>
        </v-chip>
      </v-toolbar>
      <ag-grid-vue
        groupDisplayType="multipleColumns"
        :animateRows="true"
        :rowGroupPanelShow="'always'"
        :autoSizeStrategy="{
          type: 'fitCellContents',
          defaultMinWidth: 100,
          columnLimits: [],
        }"
        :style="{width: '100%', height: height}"
        :enableRangeSelection="true"
        :allowContextMenuWithControlKey="true"
        :columnDefs="columns"
        :rowData="profiles"
        :suppressAggFuncInHeader="true"
        :defaultColDef="defaultColDef"
        :initialState="initialState"
        :sideBar="false"
        :statusBar="statusBar"
        :postProcessPopup="postProcessPopup"
        :getRowId="getRowId"
        :getContextMenuItems="getContextMenuItems"
        @grid-ready="onGridReady"
        @state-updated="onStateUpdated"
      >
      </ag-grid-vue>
    </v-card-text>
  </v-card>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import Country from "../GridComponents/Country.vue";
import UserCell from "../GridComponents/User.vue";
import OrganisationName from "@/components/GridComponents/Organisation.vue";
import PortCell from "@/components/GridComponents/Port.vue";
import TableHeader from "@/components/GridComponents/TableHeader.vue";

export default {
  components: {
    AgGridVue,
    Country,
    OrganisationName,
    PortCell,
    UserCell,
    agColumnHeader: TableHeader,
  },
  props: {
    customerId: {
        type: Number,
        required: true,
        },
        height: {
            type: String,
            default: '650px'
        },
        disableClose: {
            type: Boolean,
            default: false
        },
        contractOwner: {
            type: String,
            default: null
        },
        filter: {
            type: Object,
            default: null
        }
  },
  data: () => ({
    columns: [
    {
        headerName: "System Ref",
        field: "systemReference",
      },
      {
        headerName: "Reference",
        field: "reference",
      },
      {
        headerName: "Consignee",
        field: "consignee.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.consignee
            ? {
                organisation: p.data.consignee,
              }
            : null;
        },
      },
      {
        headerName: "Shipper",
        field: "shipper.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.shipper
            ? {
                organisation: p.data.shipper,
              }
            : null;
        },
      },
      {
        headerName: "Forwarder",
        field: "forwarder.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data && p.data.forwarder
            ? {
                organisation: p.data.forwarder,
              }
            : null;
        },
      },
      {
        headerName: "Origin",
        field: "originCountry.name",
        cellRenderer: "Country",
        cellRendererParams: (p) => {
          return p.data && p.data.originCountry
            ? {
                country: p.data.originCountry
              }
            : null;
        },
      },
      {
        headerName: "Loading Ports",
        field: "loadingPorts"
      },
      {
        headerName: "Destination",
        field: "destinationCountry.name",
        cellRenderer: "Country",
        cellRendererParams: (p) => {
          return p.data && p.data.destinationCountry
            ? {
                country: p.data.destinationCountry
              }
            : null;
        },
      },

      {
        headerName: "Discharge Ports",
        field: "dischargePorts"
      },
      {
        headerName: "Final Dest.",
        field: "finalDestinationCity"
      },
      {
        headerName: "Inco Term(s)",
        field: "incoTerm"
      }
    ],
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    initialState: {},
    loading: false,
    profiles: null,
    search: null,
    searchTimeout: undefined,
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  created(){
    if (
      localStorage.getItem(
        "profileSearchTable_" + this.$store.state.currentOrg.id
      )
    ) {
      let state = JSON.parse(
        localStorage.getItem(
          "profileSearchTable_" + this.$store.state.currentOrg.id
        )
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
  },
  methods: {
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
        {
            name: "Select Profile",
            icon: '<span class="ag-icon ag-icon-tick" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                this.$emit('selected', params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    getRowId(params) {
      return params.data.id;
    },
    async getShipmentProfiles() {
      this.loading = true;
      let profiles = await this.$API.listCustomerConsigneeProfile(
        this.customerId,
        {
            filter: {
                status: ["Approved"]
            }
        }
      );
      let data = profiles.data
      if(this.contractOwner){
        data = data.filter(x=>x.consigneeProfileIncoTerms.some(y=>y.contractOwner === this.contractOwner))
      }
      console.log(this.contractOwner, this.filter)
      if(this.filter){
        let keys = Object.keys(this.filter)
        for(let i=0;i<keys.length;i++){
          if(this.filter[keys[i]])
            data = data.filter(x=>!x[keys[i]] || x[keys[i]] === this.filter[keys[i]])
        }
      }
      this.profiles = data
      this.loading = false;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.getShipmentProfiles();
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "profileSearchTable_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    
  },
};
</script>