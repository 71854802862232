<template>
  <div>
    <v-card class="mainCard">
      <v-toolbar flat dense>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-avatar
              size="24"
              v-on="on"
              :color="booking.movementType == 'EXPORT' ? 'deep-orange' : 'blue'"
              class="mx-1"
            >
              <h6 style="color: white">
                {{ booking.movementType.charAt(0) }}
              </h6>
            </v-avatar>
          </template>
          <span>{{ $Format.capitalizeFirstLetter(booking.movementType) }} Booking</span>
        </v-tooltip>
        <v-toolbar-title v-if="booking.orderNo">{{booking.orderNo}}</v-toolbar-title>
        <v-chip class="ml-5" small :color="statusColor(booking.state)">{{booking.state}}</v-chip>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn icon plain color="redPop" @click="closeModal">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-0">
        <v-card
          style="background-color: var(--v-greyRaised-lighten2) !important"
          flat
          class="mb-2"
        >
          <BookingHeader
            :booking="booking"
            :key="headerKey"
            :loadingContainers="loadingContainers"
          />
        </v-card>
        <v-row>
          <v-col cols="12" sm="12" lg="4">
            <v-card
              flat
              class=""
              style="background-color: var(--v-greyRaised-lighten2) !important"
            >
              <v-list dense subheader>
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2" color="primary">groups</v-icon>
                  Parties</v-subheader
                >
                <v-divider></v-divider>
                <v-progress-linear
                  v-if="loadingContainers"
                  indeterminate
                  height="1"
                ></v-progress-linear>
                <v-list-item v-if="booking.customer">
                  <v-list-item-avatar
                    :color="booking.customer.logo ? 'white' : 'grey'"
                  >
                    <v-img
                      v-if="booking.customer.logo"
                      :src="booking.customer.logo"
                      contain
                    ></v-img>
                    <!-- </v-avatar> -->
                    <!-- <v-avatar v-else color="secondary"> -->
                    <h2 v-else>
                      {{ booking.customer.name.charAt(0) }}
                    </h2>
                    <!-- </v-avatar> -->
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ booking.customer.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Selected Customer
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="booking.customer">
                  <v-list-item-action>
                    <v-icon color="grey">handshake</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="booking.consigneeProfile">
                      {{ booking.consigneeProfile.systemReference }}
                    </v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle>
                      Shipment Profile
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="booking.consigneeProfile">
                  <v-list-item-action>
                    <v-icon v-if="booking.dealTerm" color="grey"
                      >settings</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content v-if="booking.dealTerm">
                    <v-list-item-title>
                      {{ booking.dealTerm.incoTerm }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Profile Inco Term
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <div v-if="booking.customer">
                  <v-list-item v-if="booking.movementType !== 'IMPORT'">
                    <v-list-item-action>
                      <v-icon class="mt-0 pt-0" color="grey"
                        >directions_boat_filled</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.shipper">
                        {{ booking.shipper.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle v-if="booking.onBehalfShipper">
                        On behalf of
                        {{ booking.onBehalfShipper.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon x-small color="grey" class="mr-1">label</v-icon>
                        Shipper
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="mt-0 pt-0" color="grey"
                        >fast_forward</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.forwarder">
                        {{ booking.forwarder.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle v-if="booking.onBehalfForwarder">
                        On behalf of
                        {{ booking.onBehalfForwarder.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon x-small color="grey" class="mr-1">label</v-icon>
                        Forwarder
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="mt-0 pt-0" color="grey"
                        >call_received</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.consignee">
                        {{ booking.consignee.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle v-if="booking.onBehalfConsignee">
                        On behalf of
                        {{ booking.onBehalfConsignee.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon x-small color="grey" class="mr-1">label</v-icon>
                        Consignee
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="!booking.consigneeProfile || booking.buyer"
                  >
                    <v-list-item-action>
                      <v-icon class="mt-0 pt-0" color="grey"
                        >shopping_cart</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.buyer">
                        {{ booking.buyer.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle v-if="booking.onBehalfBuyer">
                        On behalf of {{ booking.onBehalfBuyer.name }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon x-small color="grey" class="mr-1">label</v-icon>
                        Buyer
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="!booking.consigneeProfile || booking.firstNotify"
                  >
                    <v-list-item-action>
                      <v-icon class="mt-0 pt-0" color="grey"
                        >notifications</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.firstNotify">
                        {{ booking.firstNotify.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon x-small color="grey" class="mr-1">label</v-icon>
                        Notify Party
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="!booking.consigneeProfile || booking.secondNotify"
                  >
                    <v-list-item-action>
                      <v-icon class="mt-0 pt-0" color="grey"
                        >notifications</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.secondNotify">
                        {{ booking.secondNotify.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon x-small color="grey" class="mr-1">label</v-icon>
                        2nd Notify Party
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="!booking.consigneeProfile || booking.courierParty"
                  >
                    <v-list-item-action>
                      <v-icon class="mt-0 pt-0" color="grey"
                        >local_post_office</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.courierParty">
                        {{ booking.courierParty.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon x-small color="grey" class="mr-1">label</v-icon>
                        Courier Party
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action>
                      <v-icon class="mt-0 pt-0" color="grey">fact_check</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.stockProvider">
                        {{ booking.stockProvider.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else> - </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-icon x-small color="grey" class="mr-1">label</v-icon>
                        Stock Provider
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-card>
            <v-card
              flat
              class="my-3"
              style="background-color: var(--v-greyRaised-lighten2) !important"
            >
              <v-list dense subheader>
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2" color="primary">tag</v-icon>
                  References</v-subheader
                >
                <v-divider></v-divider>
                <v-progress-linear
                  v-if="loadingContainers"
                  indeterminate
                  height="1"
                ></v-progress-linear>
                <v-row>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          booking.qxReference ?? "-"
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >QX Reference</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          booking.ucrNo ?? "-"
                        }}</v-list-item-title>
                        <v-list-item-subtitle>UCR Number</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          booking.integrationReference ?? "-"
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Integration Reference No</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          booking.carrierReferenceNumber ?? "-"
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Carrier Reference No</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          booking.shipperReferenceNo ?? "-"
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Shipper Reference No</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          booking.consigneeReferenceNo ?? "-"
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >Consignee Reference No</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" lg="5">
            <v-card
              flat
              class=""
              style="
                background-color: var(--v-greyRaised-lighten2) !important;
                height: 57.5vh;
              "
            >
              <v-list dense subheader>
                <v-subheader style="font-size: 16px">
                  <v-icon color="primary">widgets</v-icon>
                  Containers
                  <v-chip
                    style="
                      position: absolute;
                      right: 10px;
                      font-size: 16px;
                      color: #cccccc;
                    "
                    color="tertiary"
                    outlined
                    >Total: {{ booking.bookingContainers.length }}</v-chip
                  >
                </v-subheader>
                <v-divider></v-divider>
                <v-progress-linear
                  v-if="loadingContainers"
                  indeterminate
                  height="1"
                ></v-progress-linear>
                <div
                  v-if="booking.bookingContainers.length > 0"
                  style="height: calc(57.5vh - 40px); overflow-y: scroll"
                >
                  <v-card
                    flat
                    class="ma-5"
                    v-for="container in booking.bookingContainers"
                    :key="container.id"
                    style="
                      background-color: var(--v-greyRaised-lighten3) !important;
                    "
                  >
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-chip outlined style="border: none" class="mx-1">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                :color="containerStatusColor(container)"
                                small
                                >{{ containerStatusIcon(container) }}</v-icon
                              >
                            </template>
                            <div v-if="container.bookingId == booking.id">
                              <span v-if="container.isDeleted">Cancelled</span>
                              <span v-else>{{
                                container.status ?? "Unallocated"
                              }}</span>
                            </div>
                          </v-tooltip>
                          <span class="ml-2"> {{ container.ctoNo }}</span>
                        </v-chip>
                      </v-col>
                      <v-col cols="12" sm="3" class="d-flex align-center">
                        <v-chip
                          small
                          class="mx-1"
                          outlined
                          style="border: none"
                        >
                          <v-avatar left>
                            <v-img
                              src="../../../../public/icons/pallet.png"
                              contain
                            ></v-img>
                          </v-avatar>
                          {{ container.totalPallets ?? "0" }} Pallets
                        </v-chip>
                      </v-col>
                      <v-col cols="12" sm="3" class="d-flex align-center">
                        <v-chip
                          small
                          class="mx-1"
                          outlined
                          style="border: none"
                        >
                          <v-avatar left>
                            <v-img
                              src="../../../../public/icons/carton.png"
                              contain
                            ></v-img>
                          </v-avatar>
                          {{ container.totalPackageCount ?? "0" }} Units
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" class="pt-0">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>widgets</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ container.containerNo ?? "-" }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Container No.
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="4" class="pt-0">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>label</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ container.sealNo ?? "-" }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Seal No.
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="4" class="pt-0">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>groups</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ container.customerContainerRef ?? "-" }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Customer Ref.
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
                <div v-else>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title> No Containers </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-card>
            <v-card
              flat
              class="mt-3"
              style="
                background-color: var(--v-greyRaised-lighten2) !important;
                min-height: 20.5vh;
              "
            >
              <v-list dense subheader>
                <v-subheader style="font-size: 16px">
                  <v-icon color="primary">payments</v-icon>
                  Payment Terms
                </v-subheader>
                <v-divider></v-divider>
                <v-progress-linear
                  v-if="loadingContainers"
                  indeterminate
                  height="1"
                ></v-progress-linear>
                <v-list-item
                  v-if="
                    !booking.bookingPaymentDetails ||
                    booking.bookingPaymentDetails.length == 0
                  "
                >
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No payment details listed</span>
                  </v-list-item-content>
                </v-list-item>
                <div
                  v-if="
                    booking.bookingPaymentDetails &&
                    booking.bookingPaymentDetails.length > 0
                  "
                  style="height: calc(20.5vh - 40px); overflow-y: scroll"
                >
                  <v-list-item
                    v-for="payment in booking.bookingPaymentDetails"
                    :key="payment.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <b>{{ findChargeType(payment.chargeType) }}</b>
                        paid by
                        <b style="text-transform: capitalize">{{
                          payment.paymentParty
                        }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-wrap">
                        {{ payment.paymentTerm }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" lg="3">
            <v-card
              flat
              class=""
              style="background-color: var(--v-greyRaised-lighten2) !important"
            >
              <v-list dense subheader>
                <v-subheader style="font-size: 16px">
                  <v-icon color="primary">place</v-icon>
                  Locations
                </v-subheader>
                <v-divider></v-divider>
                <v-progress-linear
                  indeterminate
                  v-if="loadingContainers"
                  height="1"
                ></v-progress-linear>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon
                      :color="!booking.originCountry ? 'secondary' : 'grey'"
                      >public</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="booking.originCountry">
                      {{ booking.originCountry.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>
                      Origin Country
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img
                        contain
                        v-if="booking.originCountry"
                        :src="`https://cdn.loglive.io/flags/4x3/${booking.originCountry.iso2.toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon
                      :color="!booking.portOfLoadValue ? 'secondary' : 'grey'"
                      >anchor</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="booking.portOfLoadCity">
                      {{ booking.portOfLoadCity }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle> POL </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip v-if="booking.portOfLoadValue" pill small>
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          v-if="booking.portOfLoadValue"
                          :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ booking.portOfLoadValue }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>

                <!--Transhipment port-->
                <v-list-item
                  v-if="
                    booking.sailingSchedule && booking.sailingSchedule.legs > 1
                  "
                >
                  <v-list-item-action>
                    <v-icon
                      :color="!booking.sailingSchedule ? 'secondary' : 'grey'"
                      >anchor</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="
                        booking.sailingSchedule &&
                        booking.sailingSchedule.sailingScheduleLegs &&
                        booking.sailingSchedule.transhipmentCityName
                      "
                    >
                      {{ booking.sailingSchedule.transhipmentCityName }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>
                      Transhipment Port
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip
                      v-if="
                        booking.sailingSchedule &&
                        booking.sailingSchedule.sailingScheduleLegs[0]
                          .portOfDischargeValue
                      "
                      pill
                      small
                    >
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          v-if="
                            booking.sailingSchedule &&
                            booking.sailingSchedule.sailingScheduleLegs[0]
                              .portOfDischargeValue
                          "
                          :src="`https://cdn.loglive.io/flags/4x3/${booking.sailingSchedule.sailingScheduleLegs[0].portOfDischargeValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{
                        booking.sailingSchedule.sailingScheduleLegs[0]
                          .portOfDischargeValue
                      }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-icon
                      :color="
                        !booking.portOfDischargeValue ? 'secondary' : 'grey'
                      "
                      >anchor</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="booking.portOfDischargeCity">
                      {{ booking.portOfDischargeCity }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle> POD </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip v-if="booking.portOfDischargeValue" pill small>
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          v-if="booking.portOfDischargeValue"
                          :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfDischargeValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ booking.portOfDischargeValue }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon
                      :color="!booking.finalDestinationValue ? 'secondary' : 'grey'">
                      location_on
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="booking.finalDestinationCity">
                      {{ booking.finalDestinationCity }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>
                      Final Destination
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip
                      v-if="booking.finalDestinationValue"
                      pill
                      small
                      outlined
                    >
                      <v-avatar size="24" left>
                        <v-img
                          contain
                          v-if="booking.finalDestinationValue"
                          :src="`https://cdn.loglive.io/flags/4x3/${booking.finalDestinationValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ booking.finalDestinationValue }}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-icon
                      :color="
                        !booking.destinationCountry ? 'secondary' : 'grey'
                      "
                      >public</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="booking.destinationCountry">
                      {{ booking.destinationCountry.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>
                      Destination Country
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img
                        contain
                        v-if="booking.destinationCountry"
                        :src="`https://cdn.loglive.io/flags/4x3/${booking.destinationCountry.iso2.toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon
                      :color="!booking.bookingOffice ? 'secondary' : 'grey'"
                      >business</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="booking.bookingOffice">
                      {{ booking.bookingOfficeName }}
                    </v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>
                      Carrier Booking Office
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon :color="'grey'">not_listed_location</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-subtitle> On Carriage </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon
                      :color="booking.onCarriage ? 'success' : 'redPop'"
                      >{{
                        booking.onCarriage ? "task_alt" : "highlight_off"
                      }}</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
            <v-card
              flat
              class="mt-3"
              style="background-color: var(--v-greyRaised-lighten2) !important"
            >
              <v-subheader style="font-size: 16px">
                <v-icon class="mr-2" color="primary"> ac_unit </v-icon>
                Regime
                <v-spacer></v-spacer>
                <v-chip
                  small
                  v-if="
                    booking.regime &&
                    booking.regime.probeCount &&
                    booking.regime.probeType
                  "
                  class="mx-1"
                >
                  <span
                    >{{ booking.regime.probeCount }} x
                    {{ booking.regime.probeType }}</span
                  >
                </v-chip>
                <v-chip
                  v-if="booking.regime && booking.regime.steri"
                  outlined
                  style="border: none"
                  color="blue"
                  >STERI <v-icon right color="blue">ac_unit</v-icon></v-chip
                >
              </v-subheader>
              <v-divider></v-divider>
              <v-progress-linear
                v-if="loadingContainers"
                indeterminate
                height="1"
              ></v-progress-linear>
              <v-row justify="center" align="center" class="mt-3">
                <v-col cols="12" class="d-flex justify-center py-0 my-0">
                  <span style="display: flex; gap: 30px">
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <div
                        class="mr-3"
                        style="
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <b style="font-size: large">
                          {{ booking.regime ? booking.regime.code : "None" }}
                        </b>
                        <small>Code</small>
                      </div>
                    </div>

                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <v-icon color="#007d5c">thermostat</v-icon>
                        <small>Set</small>
                      </div>
                      <b style="font-size: large; padding: 0 0 15px 0">{{
                        booking.regime ? `${booking.regime.setPointTemp}°C` : ""
                      }}</b>
                    </div>
                    <div
                      v-if="booking.regime && booking.regime.protocol"
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <div
                        style="
                          font-size: 12px;
                          display: flex;
                          flex-direction: column;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <v-icon color="#9d904a" small>thermostat</v-icon>
                        <small>Protocol</small>
                      </div>
                      <b style="font-size: large; padding: 0 0 15px 0">{{
                        booking.regime ? `${booking.regime.protocol}°C` : ""
                      }}</b>
                    </div>
                  </span>
                </v-col>
                <v-col cols="12" class="my-4">
                  <v-row justify="center">
                    <v-chip small class="mx-1"
                      >Vents: {{ booking.vents }}
                      <span
                        v-if="
                          booking.vents &&
                          !['CA', 'MA', 'CLOSED'].includes(
                            booking.vents.toUpperCase()
                          )
                        "
                      >
                        CBM</span
                      ></v-chip
                    >

                    <v-chip
                      class="mx-1"
                      small
                      :color="booking.caProduct ? 'edit' : ''"
                      ><v-icon left>ac_unit</v-icon>
                      <span v-if="booking.caProduct">{{
                        booking.caProduct
                      }}</span>
                      <span v-else>No CA</span></v-chip
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              flat
              class="mt-6"
              style="
                background-color: var(--v-greyRaised-lighten2) !important;
                min-height: 12vh;
              "
            >
              <v-list dense subheader>
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2" color="grey">gavel</v-icon>
                  Contracts
                </v-subheader>
                <v-divider></v-divider>
                <v-progress-linear
                  v-if="loadingContainers"
                  indeterminate
                  height="1"
                ></v-progress-linear>
                <div style="max-height: 20vh; overflow-y: scroll">
                  <v-list-item
                    v-if="
                      !booking.linkedContracts ||
                      booking.linkedContracts.length == 0
                    "
                  >
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No Contracts</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <div
                    v-if="
                      booking.linkedContracts &&
                      booking.linkedContracts.length > 0
                    "
                  >
                    <v-list-item
                      v-for="contract in booking.linkedContracts"
                      :key="contract.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          contract.contractNo
                        }}</v-list-item-title>
                        <v-list-item-subtitle v-if="contract.contractOwner"
                          ><v-icon small color="grey" class="mr-1"
                            >groups</v-icon
                          >
                          {{
                            contract.contractOwner.name
                          }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          ><v-chip x-small label outlined style="border: none">
                            <v-icon x-small left color="grey">label</v-icon
                            >{{ contract.type }}
                            <v-chip
                              class="ml-1"
                              v-if="contract.bookingContainer"
                              x-small
                              label
                              outlined
                              style="border: none"
                              ><v-icon x-small left color="grey">widgets</v-icon
                              >{{ contract.bookingContainer.ctoNo }}</v-chip
                            >
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BookingHeader from "../../Bookings/BookingHeader.vue";
export default {
  props: ["bookingId", "bookingInfo", "systemReference"],
  components: {
    BookingHeader,
  },
  data: () => ({
    booking: null,
    headerKey: 0,
    containers: [],
    loadingContainers: true,
    bookingStatus: [
      { name: "Draft", color: "orange darken-2" },
      { name: "Pending", color: "teal" },
      { name: "Requested", color: "blue" },
      { name: "Confirmed", color: "success" },
      { name: "Rejected", color: "red" },
      { name: "Cancelled", color: "red" },
      { name: "Futile", color: "red" },
    ],
    containerStatus: [
      { name: "Unallocated", icon: "warning", color: "orange darken-2" },
      { name: "Ghost", icon: "category", color: "blue-grey" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Claim", icon: "attach_money", color: "indigo" },
    ],
    paymentTypes: [
      { key: "Additional", value: "Additional Charges" },
      { key: "OceanFreight", value: "Basic Freight" },
      { key: "DestinationHaulage", value: "Destination Haulage Charges" },
      { key: "DestinationTerminalHandling", value: "Destination Port Charges" },
      { key: "OriginTerminalHandling", value: "Origin Haulage Charges" },
      { key: "OriginHaulage", value: "Origin Port Charges" },
    ],
  }),
  mounted() {},

  watch: {
    systemReference: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchData();
        }
      },
    },
  },
  computed: {},
  methods: {
    async getBookingDetails() {
      this.booking = this.bookingInfo;
      this.booking.bookingContainers = [];
      return this.$API.getBookingDetailAll(this.systemReference);
    },
    async fetchData() {
      try {
        this.loadingContainers = true;
        const booking = await this.getBookingDetails();
        console.log(booking);
        this.booking = booking;
        this.loadingContainers = false;
        this.headerKey++;
      } catch (error) {
        console.error(error);
      }
    },

    closeModal() {
      this.$emit("close");
    },
    statusColor(status) {
      let color = this.bookingStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      ).color;
      return color;
    },
    containerStatusIcon(item) {
      if (item.isDeleted) {
        return "cancel";
      } else if (item.bookingId && item.bookingId != this.booking.id) {
        return "input";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.icon;
        } else {
          return "warning";
        }
      } else {
        return "warning";
      }
    },
    containerStatusColor(item) {
      if (item.isDeleted) {
        return "red";
      } else if (item.bookingId && item.bookingId != this.booking.id) {
        return "blue";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    findChargeType(type) {
      return this.paymentTypes.find((x) => x.key == type).value;
    },
  },
};
</script>

<style scoped>
</style>