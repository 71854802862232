var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:({
    background:
      _vm.$route.name == 'Login' ||
      _vm.$route.name == 'Register' ||
      _vm.$route.name == 'Share: Shipment' ||
      _vm.$route.name == 'Reset Password'
        ? _vm.$vuetify.theme.themes['dark'].background
        : _vm.$vuetify.theme.themes['dark'].background,
  })},[(
      _vm.$route.name == 'Login' ||
      _vm.$route.name == 'Register' ||
      _vm.$route.name == 'Reset Password'
    )?_c('div',[_c('div',{attrs:{"id":"stars2"}}),_c('div',{attrs:{"id":"stars3"}}),_c('div',{attrs:{"id":"stars4"}})]):_vm._e(),(
      _vm.$route.name != 'Login' &&
      _vm.$route.name != 'LoginScreen' &&
      _vm.$route.name != 'Register' &&
      _vm.$route.name != 'Share: Shipment' &&
      _vm.$route.name != 'Reset Password'
    )?_c('Navbar',{attrs:{"app":""}}):_vm._e(),_c('v-main',{staticClass:"ml-0"},[(
        _vm.$route.name != 'Login' &&
        _vm.$route.name != 'LoginScreen' &&
        _vm.$route.name != 'Register' &&
        _vm.$route.name != 'Share: Shipment' &&
        _vm.$route.name != 'Reset Password'
      )?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{key:_vm.key,staticClass:"mx-0 px-0 pl-0"})],1):_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{staticClass:"mx-0 px-0"})],1)],1),_c('v-snackbar',{attrs:{"bottom":"","center":"","value":_vm.updateExists,"timeout":-1,"color":"primary"}},[_c('span',{staticClass:"primaryText--text"},[_vm._v("An new version is available")]),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"text":"","color":"primaryText"},on:{"click":_vm.refreshApp}},[_vm._v(" Update Now ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }