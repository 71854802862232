<template>
    <div>
        <v-progress-linear v-if="loading" color="primary" indeterminate></v-progress-linear>
        <v-simple-table :class="transparent ? 'transparent' : ''" disable-pagination :key="tableKey" dense fixed-header :height="height" style="background: transparent !important">
            <template v-slot:default>
                <thead>
                    <tr >
                        <th class="text-center" :class="dense ? 'dense' : ''" :style="transparent ? {'background-color': 'transparent !important'} : ''" v-for="(header, index) in headers" :key="index">
                            {{ header.text }}
                        </th>
                    </tr>
                </thead>
                <tbody >
                    <tr v-for="(item, i) in items" :key="i">
                        <template v-for="(header, index) in headers">
                            <td :class="dense ? 'dense' : ''" :key="index" v-if="header.value!='cartonPrice' &&header.value!='kgPrice' && item[header.value] != null"
                            style="border: 0.4px solid rgb(95, 95, 95);background: transparent !important"
                                :rowspan="item[header.value + '_rowspan'] ? item[header.value + '_rowspan'] : 1"
                                class='text-center'>
                                <span>{{ item[header.value] }}</span>
                            </td>
                            <td :class="dense ? 'dense' : ''" v-else-if="item[header.value] != null"  :key="index + 1" class="text-center" style="border: 0.4px solid rgb(95, 95, 95)">
                                <v-icon color="amber">lock</v-icon>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
export default {
    props: ['headers', 'items', 'tableKey', 'loading', 'height', 'transparent', 'dense', 'noHeader'],
}
</script>

<style scoped>
.transparent ::v-deep .v-data-table__wrapper {
    background: transparent !important;
}

th.dense {
    max-height: 22px !important;
    height: 22px !important;
}
td.dense {
    max-height: 22px !important;
    height: 22px !important;
}
</style>