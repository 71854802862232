<template>
    <div>
        <v-card flat style="background: transparent !important">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" style="height: 35vh" class="my-0 py-0">
                        <TemperatureChart :title="'Temperature (°C)'" :labels="temperatureChartLabels"
                            :data="temperatureChartData" />
                    </v-col>

                    <v-col cols="12" sm="6" style="height: 35vh" class="my-0 py-0">
                        <TemperatureChart :title="'Light (lux)'" :labels="lightChartLabels" :data="lightChartData" />
                    </v-col>
                    <v-col cols="12" v-if="locationData.length > 0" class="my-0 py-0">
                        <v-card flat style="background: transparent !important">
                            <v-card-text style="height: 500px" class="my-0 py-0">
                                <v-row>
                                    <v-col cols="12" sm="3" style="height: 100%">
                                        <v-list dense subheader>
                                            <v-divider></v-divider>
                                            <v-subheader style="font-size: 16px">Devices</v-subheader>
                                            <v-divider></v-divider>
                                            <v-list-item v-for="device in locationData" :key="device.id">
                                                <v-list-item-action>
                                                    <v-avatar size="20" :color="device.color"></v-avatar>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ device.serial }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ device.provider
                                                        }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" sm="9" style="height: 400px">
                                        <gmap-map :center="{ lat: 0.00, lng: 0.00 }" :zoom="2"
                                            style="width: 100%; height: 100%;" v-bind:options="mapStyle" class="mapLocal">
                                            <gmap-polyline v-for="device in locationData" :key="device.id"
                                                v-bind:path.sync="device.path" v-bind:options="{
                                                    strokeColor: device.color,
                                                    strokeOpacity: 1
                                                }"></gmap-polyline>

                                            <gmap-custom-marker v-for="device in locationData" :key="device.id"
                                                alignment="center" :marker="device.lastLocation">
                                                <v-icon class="pulse-beacon" :color="device.color">adjust</v-icon>
                                            </gmap-custom-marker>
                                            <gmap-custom-marker v-for="device in locationData" :key="device.id"
                                                alignment="center" :marker="device.startLocation">
                                                <v-icon :color="device.color">location_on</v-icon>
                                            </gmap-custom-marker>
                                        </gmap-map>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";
import TemperatureChart from '../Charts/sensor/Temperature.vue'

export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    components: {
        GmapCustomMarker,
        TemperatureChart
    },
    data: () => ({
        mapStyle: {
            scrollwheel: true,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: [
                { elementType: "geometry", stylers: [{ color: "#283a54" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#283a54" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                    featureType: "administrative.locality",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }],
                },
                {
                    featureType: "poi",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }],
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#263c3f" }],
                },
                {
                    featureType: "poi.park",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#6b9a76" }],
                },
                {
                    featureType: "road",
                    elementType: "geometry",
                    stylers: [{ color: "#38414e" }],
                },
                {
                    featureType: "road",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#212a37" }],
                },
                {
                    featureType: "road",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#9ca5b3" }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [{ color: "#746855" }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#1f2835" }],
                },
                {
                    featureType: "road.highway",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#f3d19c" }],
                },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#2f3948" }],
                },
                {
                    featureType: "transit.station",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }],
                },
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#212121" }],
                },
                {
                    featureType: "water",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#515c6d" }],
                },
                {
                    featureType: "water",
                    elementType: "labels.text.stroke",
                    stylers: [{ color: "#17263c" }],
                },
            ],
        },
    }),
    computed: {
        locationData() {
            let result = []
            let colors = ['#e79941', '#439c2f', '#3dc1cb', '#b84f2d', '#ca1a32']
            if (this.data && this.data.length > 0) {
                result = this.data.map((x, i) => ({
                    id: x.id,
                    serial: x.serial,
                    provider: x.provider,
                    color: colors[i],
                    startLocation: x.locationData?{ lat: x.locationData[0].latitude, lng: x.locationData[0].longitude }:null,
                    lastLocation: x.locationData? { lat: x.locationData[x.locationData.length - 1].latitude, lng: x.locationData[x.locationData.length - 1].longitude }:null,
                    path: x.locationData? x.locationData.map(y => ({
                        lat: y.latitude,
                        lng: y.longitude
                    })):[]
                }))
            }
            return result
        },
        lightChartData() {
            let result = []
            if (this.data && this.data.length > 0) {
                for (let i = 0; i < this.data.length; i++) {
                    if(this.data[i].lightData){
                        let obj = {
                        name: this.data[i].serial,
                        data: this.data[i].lightData.map(x => x.light.toFixed(2)),
                        // stack: 'Light',
                        type: 'line'
                    }
                    result.push(obj)
                    }
                  
                }
            }
            return result
        },
        lightChartLabels() {
            let result = []
            if (this.data && this.data.length > 0) {
                if(this.data[0].lightData){
                    result = this.data[0].lightData.map(x => x.readingDate)

                }
            }
            return result
        },
        temperatureChartData() {
            let result = []
            if (this.data && this.data.length > 0) {
                for (let i = 0; i < this.data.length; i++) {
                    if(this.data[i].temperatureData){
                        let obj = {
                        name: this.data[i].serial,
                        data: this.data[i].temperatureData.map(x => x.temperature.toFixed(2)),
                        // stack: 'Temperature',
                        type: 'line'
                    }
                    result.push(obj)
                    }
                   
                }
            }
            return result
        },
        temperatureChartLabels() {
            let result = []
            if (this.data && this.data.length > 0) {
                if(this.data[0].temperatureData){
                    result = this.data[0].temperatureData.map(x => x.readingDate)

                }
            }
            return result
        }
    }
}
</script>
<style scoped>
.pulse-beacon {
  animation: pulse-beacon 2s infinite;
  z-index: 10;
}

@keyframes pulse-beacon {
  0% {
    border-radius: 50%;
    box-shadow: 0 0 0 0px rgb(135, 135, 135);
  }

  100% {
    border-radius: 50%;
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>