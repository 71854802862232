import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import store from './store.js'
import vuetify from './plugins/vuetify'
import i18n from './i18n.js'
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import API from './services/Api.js'
import Format from './services/Format.js'
import {router} from './router.js'
import './styles/element-dark.css';
import './styles/ag-grid-theme-builder.css';

import * as VueGoogleMaps from 'vue2-google-maps'
import GmapVue from 'gmap-vue'
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import VueNativeSock from 'vue-native-websocket'
import './registerServiceWorker'
import Loading from '@/components/SystemComponents/LoadingElement.vue'
import VueChatScroll from 'vue-chat-scroll'
import "./assets/tooltip.css";
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';
import VueSignaturePad from "vue-signature-pad";
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueVirtualScroller from 'vue-virtual-scroller'
import { getCurrentTenantSettings } from '@/utils/theming/themeConfig.js'
import Hotjar from 'vue-hotjar'
import 'ag-grid-enterprise';
import 'ag-charts-enterprise'
import { LicenseManager } from "ag-grid-enterprise";
import { AgCharts as AgChartsEnterprise } from "ag-charts-enterprise";

AgChartsEnterprise.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-065463}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{LOGLIVE_PTY_LTD}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Loglive}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Loglive}_need_to_be_licensed___{Loglive}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{17_September_2025}____[v3]_[0102]_MTc1ODA2MzYwMDAwMA==c8e0ec9a98b2e9deb39495f9946615b2");
LicenseManager.setLicenseKey("Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-065463}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{LOGLIVE_PTY_LTD}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Loglive}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Loglive}_need_to_be_licensed___{Loglive}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{17_September_2025}____[v3]_[0102]_MTc1ODA2MzYwMDAwMA==c8e0ec9a98b2e9deb39495f9946615b2");
Vue.use (Hotjar, {
  id: '4955167',
  isProduction: true
})
Vue.component("vue-json-pretty", VueJsonPretty)
Vue.use(VueChatScroll)
Vue.use(Cropper)
Vue.component('CircleStencil', CircleStencil)
Vue.use(VueSignaturePad) 
Vue.use(VueVirtualScroller)

const BundleAnalyzerPlugin = require('webpack-bundle-analyzer').BundleAnalyzerPlugin
Vue.component('valhalla-loading', Loading)

Vue.use(VueNativeSock, 'wss://ws.loglive.ai/?api-key=eNvg2t25Cu9Tt8xx660S', { 
  format: 'json', reconnection: true,
reconnectionDelay: 20 })
Vue.component("cluster", GmapCluster);

Vue.config.productionTip = true
Vue.prototype.$API = API
Vue.prototype.$Format = Format
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBZ2HBPenJbi2qRrJM5t1uFslffroNAl1M',
    libraries: 'places, drawing, visualization, geometry',
    installComponents: true
  }})
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBZ2HBPenJbi2qRrJM5t1uFslffroNAl1M',
    libraries: 'places, drawing, visualization, geometry',
  },
  installComponents: true
})

async function initiliase() {
  let result = await API.getAllRoutes()
  return result
}
Vue.mixin({
  methods: {
    async createAudit(resource, resourceId, action, description){
      let obj = {
        resource,
        resourceId,
        action,
        description
      }
     let result = await API.createAuditLog(obj)
     return result
    }
  }
})
// initiliase().then(async data => {
  let redirectObj = null
  if(window.location.pathname !== '/'){
    redirectObj = {
      path: window.location.pathname +window.location.search
    }
  }

  const settings = getCurrentTenantSettings()
  store.commit('setTenantSettings', settings) 
  var routerInstance = router([], redirectObj)
  new Vue({
    router: routerInstance,
    store,
    vuetify,
    i18n,
    BundleAnalyzerPlugin,
    render: h => h(App)
  }).$mount('#app')
// })