<template>
  <div>
    <v-card style="height: 95vh; overflow-y: auto">
      <v-toolbar flat color="transparent">
        <v-toolbar-title>
          <v-btn icon @click="$emit('close')" class="mr-1"
            ><v-icon>arrow_back</v-icon></v-btn
          >
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-0 pt-0" v-if="!loading">
        <v-row>
          <v-col cols="12" sm="3" md="2">
            <v-card
              flat
              style="background-color: var(--v-component-base) !important"
              v-if="item && item.id"
            >
              <v-card-text>
                <v-list dense subheader>
                  <v-subheader style="font-size: 16px">Details</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="item.containerNo">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.containerNo }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="item.shipmentFile">
                        {{
                          !item.shipmentFile.breakBulkShipment
                            ? "Container No."
                            : "Deck No."
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.ctoNo">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.ctoNo }}
                      </v-list-item-title>
                      <v-list-item-subtitle> CTO Number </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.sealNo">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.sealNo }}
                      </v-list-item-title>
                      <v-list-item-subtitle> Seal Number </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ containerGrossWeight(item) }} KGS
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Gross Weight
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.containerNo">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ containerNettWeight(item) }} KGS
                      </v-list-item-title>
                      <v-list-item-subtitle> Nett Weight </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider> </v-divider>
                  <v-subheader style="font-size: 16px">
                    Commodities</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    v-for="product in containerProducts"
                    :key="product.productId"
                  >
                    <!-- <v-list-item-action>
                                        <v-chip>{{ index + 1 }}</v-chip>
                                      </v-list-item-action> -->
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.product.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ product.noPallets }} Pallets
                      </v-list-item-subtitle>
                      <div v-if="binsAndCartons">
                        <v-list-item-subtitle style="font-size: 12px">
                          <v-icon small class="mr-1">widgets</v-icon>
                          {{ calculateProductBins(product.productId) }}
                          BINS
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 12px">
                          <v-icon small class="mr-1">widgets</v-icon>
                          {{ calculateProductCartons(product.productId) }}
                          CARTONS
                        </v-list-item-subtitle>
                      </div>
                      <div v-else>
                        <v-list-item-subtitle style="font-size: 12px">
                          <v-icon small class="mr-1">widgets</v-icon>
                          {{
                            getPackType() == "BIN"
                              ? calculateProductBins(product.productId)
                              : calculateProductCartons(product.productId)
                          }}
                          {{ getPackType() + "S" }}
                        </v-list-item-subtitle>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider> </v-divider>
                  <v-subheader style="font-size: 16px"> Device(s) </v-subheader>
                  <v-divider></v-divider>
                  <v-progress-linear
                    color="primary"
                    indeterminate
                    v-if="loadingSensor"
                  ></v-progress-linear>
                  <v-list dense :key="telemetryKey">
                    <v-list-item
                      v-for="device in item.telemetry"
                      :key="device.id"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ device.serial }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-avatar
                            size="16"
                            class="mr-1"
                            v-if="device.provider == 'SensiTech'"
                          >
                            <img
                              contain
                              src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwEKCgkBDRYDDQEBARsIFRAWIB0iIiAdExMkKDQsJCYxJx8fLTEtMTU3Ojo6Iys/RD84QzQ5OisBCgoKDQwBGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Kzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIABAAEAMBIgACEQEDEQH/xAAWAAADAAAAAAAAAAAAAAAAAAAEBQb/xAAnEAAABQMCBQUAAAAAAAAAAAABAgMEBgUHEQBBFCEyUWESFRYjMf/EABQBAQAAAAAAAAAAAAAAAAAAAAT/xAAYEQACAwAAAAAAAAAAAAAAAAABAgAGEf/aAAwDAQACEQMRAD8Av6zWFDleuBnSyNPsI4CFhCbQSUEDuFsh1Kj0FARAOXnOmkdVVXSEVIy8bmh5uCPCbtXSGoAbsJFd/wB0J7Q8IvUGKUn9Le+6/wA3JcyTwIrwuRx60lScsh28b8tGx+hlopnhizApguscHgW3t7AeESSwGPrTyOM76Q5QVnBDIGNg0z//2Q==
                    "
                            />
                          </v-avatar>
                          {{ device.provider }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        !loadingSensor &&
                        item.telemetry &&
                        item.telemetry.length == 0
                      "
                    >
                      <v-list-item-content class="text-center">
                        <span style="color: grey; font-size: 12px"
                          >No devices found</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9" md="10">
            <v-tabs v-model="containerTab" :key="telemetryKey">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab href="#summary"> Summary </v-tab>
              <v-tab href="#telemetry" v-if="displayTelemetry">
                Telemetry
              </v-tab>
              <v-tab href="#contents"> Contents </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="containerTab"
              style="background-color: transparent"
            >
              <v-tab-item value="summary" style="background-color: transparent">
                <v-card flat style="border-radius: 0px" class="mt-0 pt-0">
                  <v-card-text v-if="item && item.id">
                    <v-row class="mt-3">
                      <v-col cols="12">
                        <v-card
                          flat
                          style="background: var(--v-component-base) !important"
                        >
                          <v-subheader style="font-size: 16px"
                            ><v-icon color="secondary" class="mr-2"
                              >category</v-icon
                            >
                            Product Breakdown</v-subheader
                          >
                          <v-divider></v-divider>
                          <v-card-text>
                            <ProductBreakdownTable
                              :headers="containerSummaryHeaders"
                              :items="item.productSummary"
                              :tableKey="productKey"
                              :loading="loadingContainerBreakdown"
                              :height="'100%'"
                            />
                          </v-card-text>
                        </v-card>
                        <v-card
                          flat
                          style="
                            background: var(--v-component-base) !important;
                            border-radius: 20px;
                          "
                          class="mt-3"
                        >
                          <v-card-title>
                            <v-icon color="secondary" class="mr-2"
                              >category</v-icon
                            >
                            Container Events
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <ul class="events">
                                  <li
                                    v-for="eventData in item.containerMilestones"
                                    :key="eventData.id"
                                    style="list-style-type: none"
                                    class="my-3"
                                  >
                                    <v-avatar
                                      class="mb-1 mr-2"
                                      size="10"
                                      :color="
                                        eventData.isActual
                                          ? 'success'
                                          : 'secondary'
                                      "
                                    ></v-avatar>
                                    <strong style="font-size: 14px">{{
                                      formatDateTime(eventData.date)
                                    }}</strong>
                                    <v-chip
                                      outlined
                                      style="border: none"
                                      small
                                      v-if="eventData.location"
                                    >
                                      <v-avatar size="20" left>
                                        <v-img
                                          contain
                                          :src="`https://cdn.loglive.io/flags/4x3/${eventData.location
                                            .substring(0, 2)
                                            .toLowerCase()}.svg`"
                                        ></v-img>
                                      </v-avatar>
                                      {{ eventData.location }}
                                    </v-chip>
                                    <span
                                      class="ml-2"
                                      style="font-size: 14px"
                                      >{{ eventData.description }}</span
                                    >
                                    <span
                                      v-if="eventData.vesselName"
                                      class="ml-2"
                                      >-
                                      {{ eventData.vesselName }}
                                      {{ eventData.voyageNo }}</span
                                    >
                                  </li>
                                </ul>
                                <div
                                  style="width: 100%"
                                  class="text-center"
                                  v-if="item.containerMilestones.length == 0"
                                >
                                  No container events
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="telemetry">
                <v-card
                  flat
                  style="background: var(--v-component-base) !important"
                >
                  <!-- <v-card-title>
                                      <v-icon color="secondary" class="mr-2"
                                        >thermostat_auto</v-icon
                                      >
                                      Telemetry
                                    </v-card-title> -->
                  <v-card-text>
                    <div
                      v-if="item.telemetry && item.telemetry.length > 0"
                      class="my-0 py-0"
                    >
                      <v-divider></v-divider>
                      <v-subheader style="font-size: 16px"
                        ><v-icon class="mr-2" color="secondary"
                          >thermostat</v-icon
                        >
                        Monitoring
                      </v-subheader>
                      <v-divider></v-divider>
                      <ContainerTelemetry :data="item.telemetry" />
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="contents">
                <v-card flat style="border-radius: 0px">
                  <v-card-text>
                    <v-row justify="end">
                      <v-col cols="12" sm="4">
                        <el-input
                          ref="searchBar"
                          suffix-icon="el-icon-search"
                          v-model="searchPallets"
                          clearable
                          placeholder="Search"
                        >
                        </el-input>
                      </v-col>
                    </v-row>
                    <v-data-table
                      :headers="palletHeaders"
                      :items="item.containerPallets"
                      :search="searchPallets"
                      dense
                      fixed-header
                      disable-pagination
                      hide-default-footer
                    >
                      <!-- <template
                                          v-slot:[`item.line`]="{ item }"
                                        >
                                          {{
                                            uniquePallets.indexOf(item.sscc) + 1
                                          }}
                                        </template> -->
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else style="height: 90vh; width: 100%">
        <valhalla-loading />
      </v-card-text>
    </v-card>
    <!-- <div v-else style="height: 70vh; padding-top: 0; margin-top: 0" class="text-center"> -->
  </div>
</template>
<script>
import axios from "axios";
import ContainerTelemetry from "../Telemetry/ContainerTelemetry.vue";
// import ContainerMilestone from "../ContainerTracking/ContainerMilestone.vue";

import dateFormat from "dateformat";
// import DCSAContainerTracking from "../ContainerTracking/DCSA.vue";
import ProductBreakdownTable from "../SystemComponents/ProductBreakdownTable.vue";
export default {
  props: ["containerItem"],
  components: {
    ContainerTelemetry,
    // ContainerMilestone,
    // DCSAContainerTracking,
    ProductBreakdownTable,
  },
  data: () => ({
    containerTab: "summary",
    containerSummaryHeaders: [
      {
        text: "Product",
        value: "productName",
        align: "center",
      },
      {
        text: "Variety",
        value: "varietyName",
        align: "center",
      },
      {
        text: "Grade/Class",
        value: "gradeClassCode",
        align: "center",
      },
      {
        text: "Pack Code",
        value: "packCode",
        align: "center",
      },
      {
        text: "Count/Size",
        value: "countSize",
        align: "center",
      },
      {
        text: "No. Cartons",
        value: "noCartons",
        align: "center",
      },
      {
        text: "Percentage",
        value: "percentage",
        align: "center",
      },
    ],
    comments: [],
    comment: null,
    containerProducts: [],
    displayTelemetry: false,
    internalComment: false,
    events: [],
    eventType: "DCSA",
    item: {},
    loading: false,
    loadingBreakdown: false,
    loadingContainerBreakdown: false,
    loadingSensor: false,
    palletHeaders: [
      {
        text: "Line",
        value: "line",
        sortable: false,
        width: "5px",
        align: "center",
      },
      {
        text: "SSCC / Pallet ID",
        value: "sscc",
        sortable: false,
        align: "center",
      },
      {
        text: "Producer/ PUC(s)",
        value: "farm",
        sortable: false,
        align: "center",
      },
      {
        text: "Orchard",
        value: "orchard",
        sortable: false,
        align: "center",
      },
      {
        text: "Phyto Data",
        value: "phytoReference",
        sortable: false,
        align: "center",
      },
      {
        text: "PHC",
        value: "packHouse",
        sortable: false,
        align: "center",
      },
      {
        text: "Production Area",
        value: "productionArea",
        sortable: false,
        align: "center",
      },
      {
        text: "Commodity",
        value: "commodity",
        sortable: false,
        align: "center",
      },
      {
        text: "Variety",
        value: "variety",
        sortable: false,
        align: "center",
      },
      {
        text: "Flesh Colour",
        value: "fleshColour",
        sortable: false,
        align: "center",
      },
      {
        text: "Class/ Grade",
        value: "gradeClassCode",
        width: "20px",

        sortable: false,
        align: "center",
      },
      {
        text: "Count",
        value: "countSize",
        width: "20px",
        sortable: false,
        align: "center",
      },
      {
        text: "Pack Type",
        value: "packType",
        sortable: false,
        width: "10px",
        align: "right",
      },
      {
        text: "Quantity",
        value: "noCartons",
        sortable: false,
        width: "10px",
        align: "right",
      },
      {
        text: "Nett Weight (KG)",
        value: "nettWeight",
        sortable: false,
        align: "right",
      },
      {
        text: "Gross Weight (KG)",
        value: "grossWeight",
        sortable: false,
        align: "right",
      },

      {
        text: "PPECB Inspection Date",
        value: "inspectionDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Stuff Date",
        value: "stuffDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Manifest No.",
        value: "inspectionManifestNo",
        sortable: false,
        align: "center",
      },
      {
        text: "Inspection Point",
        value: "inspectionPoint",
        sortable: false,
        align: "center",
      },
    ],
    productKey: 100,
    internalMessage: false,
    searchPallets: null,
    sendingMessage: false,
    sensorData: [],
    summaryItems: [],
    tableKey: 300,
    telemetryKey: 200,
  }),
  watch: {
    "containerItem.id": {
      immediate: true,
      handler(val) {
        this.item = {};
        if (val) {
          this.load();
        }
      },
    },
  },
  computed: {
    temperatureDevices() {
      let result = [];
      if (this.item && this.item.containerPallets) {
        result = [
          ...new Set(
            this.item.containerPallets
              .map((item) => item.sensorSerial)
              .filter(Boolean)
          ),
        ];
        result = result.map((x) => ({
          sensor: x,
          sscc: this.item.containerPallets.find((y) => y.tempSerialNo == x)
            .sscc,
        }));
      }
      return result;
    },
    uniquePallets() {
      let result = [];
      if (this.item && this.item.containerPallets) {
        result = [
          ...new Set(this.item.containerPallets.map((item) => item.sscc)),
        ];
      }
      return result;
    },
  },
  methods: {
    calculateProductCartons(productId) {
      let pallets = this.item.containerPallets.filter(
        (x) => x.productId == productId && x.packType == "CARTON"
      );
      return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateProductBins(productId) {
      let bins = this.item.containerPallets.filter(
        (x) => x.productId == productId && x.packType == "BIN"
      );
      return bins.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateUniquePallets(pallets) {
      return [...new Set(pallets.map((item) => item.sscc))].length;
    },
    calculateCartons(pallets) {
      let result = pallets.map((item) => parseInt(item.noCartons));
      return result && result.length > 0 ? result.reduce((a, b) => a + b) : 0;
    },
    calculateActualGrossWeight(pallets) {
      return pallets.map((item) => item.grossWeight).reduce((a, b) => a + b);
    },
    calculateActualNettWeight(pallets) {
      return pallets.map((item) => item.nettWeight).reduce((a, b) => a + b);
    },
    containerGrossWeight(container) {
      console.log(container);
      return container.containerPallets
        .map((item) => Math.round(parseFloat(item.grossWeight) * 100) / 100)
        .reduce((a, b) => a + b, 0)
        .toFixed(2);
    },
    containerNettWeight(container) {
      return container.containerPallets
        .map((item) => Math.round(parseFloat(item.nettWeight) * 100) / 100)
        .reduce((a, b) => a + b, 0)
        .toFixed(2);
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getContainerTelemetry(item) {
      item.telemetry = [];
      let result = await this.$API.getContainerTelemetryData(item.id);
      item.telemetry = result;
      this.telemetryKey++;
    },
    getPackType() {
      let type = this.item.containerPallets.find((x) => x.packType);
      return type ? type.packType : "CARTONS";
    },
    async getSensorDetails() {
      if (this.temperatureDevices && this.temperatureDevices.length > 0) {
        this.loadingSensor = true;
        for (let i = 0; i < this.temperatureDevices.length; i++) {
          let data = await this.$API.getSensorDetailsBySerial(
            this.temperatureDevices[i].sensor
          );
          if (data && data.locationData && data.locationData.length > 0) {
            data.lastLocation = {
              lat: data.locationData.at(-1).latitude,
              lng: data.locationData.at(-1).longitude,
            };
            data.lastLocationDate = data.locationData.at(-1).readingDate;
          }
          if (data && data.temperatureData && data.temperatureData.length > 0) {
            data.lastTemperature = data.temperatureData.at(-1).temperature;
            data.lastTemperatureDate = data.temperatureData.at(-1).readingDate;
          }
          if (data && data.lightData && data.lightData.length > 0) {
            data.lastLightData = data.lightData.at(-1).light;
            data.lastLightData = data.lightData.at(-1).readingDate;
          }
          if (data && data.id) {
            this.sensorData.push(data);
          }
        }
        this.loadingSensor = false;
      } else if (this.item.tempSerialNo) {
        this.loadingSensor = true;
        let data = await this.$API.getSensorDetailsBySerial(
          this.item.tempSerialNo
        );
        if (data && data.locationData && data.locationData.length > 0) {
          data.lastLocation = {
            lat: data.locationData.at(-1).latitude,
            lng: data.locationData.at(-1).longitude,
          };
          data.lastLocationDate = data.locationData.at(-1).readingDate;
        }
        if (data && data.temperatureData && data.temperatureData.length > 0) {
          data.lastTemperature = data.temperatureData.at(-1).temperature;
          data.lastTemperatureDate = data.temperatureData.at(-1).readingDate;
        }
        if (data && data.lightData && data.lightData.length > 0) {
          data.lastLight = data.lightData.at(-1).light;
          data.lastLightDate = data.lightData.at(-1).readingDate;
        }
        if (data && data.id) {
          this.sensorData.push(data);
        }
        this.loadingSensor = false;
      }
    },
    async load() {
      this.loading = true;
      this.item = await this.$API.getContainer(this.containerItem.id);
      console.log(this.item);
      this.getSensorDetails();
      this.getContainerTelemetry(this.item);
      this.loading = false;
      this.loadingBreakdown = true;
      console.log("Loading container summary", this.loading);
      this.summaryItems = await this.$API.getContainerSummary(
        this.item.shipmentId,
        this.item.id
      );
      this.loadingBreakdown = false;
    },
    async sendComment() {
      this.sendingMessage = true;
      let result = await this.$API.commentOnContainer({
        bookingContainerId: this.item.id,
        comment: this.comment,
        isInternal: this.internalMessage,
      });
      if (result) {
        result.user = this.$store.state.user;
        this.comments.push(result);
        this.comment = null;
      }
      this.sendingMessage = false;
    },
    productVarieties(productId) {
      let result = this.item.containerProducts.filter(
        (x) => x.type == "VARIETY" && x.productId == productId
      );
      return result;
    },
  },
};
</script>