<template>
  <div>
    <div v-if="item">
      <v-tooltip top v-if="item.locode" open-delay="500">
        <template v-slot:activator="{ on }">
          <v-chip
            outlined
            style="border: none; font-size: 11px"
            v-on="on"
            small
          >
            <v-avatar size="20" left>
              <v-img
                contain
                :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ item.locode }}
          </v-chip>
        </template>
        <span style="font-size: 12px" v-if="item.name">{{ item.name }}</span>
        <span style="font-size: 12px" v-else>{{ item.locode }}</span>
      </v-tooltip>
    </div>
    <div v-else-if="pivot">{{ pivot }}</div>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: null,
    pivot: null,
  }),
  mounted() {
    if (typeof this.params.value == "object") {
      this.item = null;
      this.pivot = this.params.value.value;
    } else {
      this.item = {
        name: this.params.name,
        locode: this.params.locode ?? this.params.value,
      };
      this.pivot = null;
    }
  },
  methods: {},
};
</script>