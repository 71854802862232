<template>
  <!-- LL130945/01 -->
  <v-card width="94vw" v-if="container && booking" style="contain: content">
    <v-toolbar dense flat style="background-color: var(--v-toolbar-lighten1) !important">
      <v-toolbar-title>Container:
        <b>{{ title }}</b>
      </v-toolbar-title>

      <v-spacer></v-spacer>


      <v-chip :color="getStatusColor(container.transportStatus)" style="width: 180px; text-align: center">
        <v-select :items="transportStatuses" item-text="text" item-value="text" v-model="container.transportStatus"
          hide-details="auto" flat @change="updateTransportStatus"></v-select>
      </v-chip>
      <v-btn class="ml-2" icon color="secondary" :disabled="!change" :loading="savingChange"
        @click="updateContainer()"><v-icon>save</v-icon></v-btn>
      <v-btn color="redPop" icon @click="closeDialog()"><v-icon>close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="pt-3">
      <v-row>
        <v-col cols="12" sm="8">
          <v-row>
            <v-col cols="12" sm="6">
              <v-card width="100%" height="75vh" class="my-3" :loading="loadingDetails" style="
                  background-color: var(--v-toolbar-lighten1) !important;
                  max-height: 75vh;
                  overflow-y: auto;
                ">
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2" color="secondary">
                    local_shipping
                  </v-icon>
                  Assigned Transporter
                </v-subheader>
                <v-select :disabled="container.isCarrierHaulage || container.privateLoad
    " :items="contractors" item-text="name" rounded :loading="loadingContractors" :placeholder="'Select Transporter'"
                  item-value="id" style="background-color: primary; margin: 1vh 1vw 0.5vh 1vw" hide-details outlined
                  return-object @change="updateTransporter" v-model="container.transporter" clearable dense>
                  <template v-slot:selection="data">
                    <v-row align="center">
                      <v-avatar size="32" class="mr-2" v-if="data.item">
                        <v-img v-if="data.item.logo" contain :src="data.item.logo"></v-img>
                      </v-avatar>
                      <span v-if="data.item">
                        {{ data.item.name }}
                      </span>
                    </v-row>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img contain v-if="data.item.logo" :src="data.item.logo"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
    data.item.name
  }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>

                <div v-if="container.PLUG_IN &&
    container.PLUG_IN.isActive &&
    !container.PLUG_OUT
    ">
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="secondary">
                      local_shipping
                    </v-icon>
                    Cross Haul Transporter

                  </v-subheader>
                  <v-select :items="contractors" item-text="name" rounded :loading="loadingContractors"
                    :placeholder="'Select Transporter'" item-value="id"
                    style="background-color: primary; margin: 1vh 1vw 0.5vh 1vw" hide-details outlined return-object
                    @change="updateCrosshaulTransporter" v-model="container.crossHaulTranporter" clearable dense>
                    <template v-slot:selection="data">
                      <v-row align="center">
                        <v-avatar size="32" class="mr-2" v-if="data.item">
                          <v-img v-if="data.item.logo" contain :src="data.item.logo"></v-img>
                        </v-avatar>
                        <span v-if="data.item">
                          {{ data.item.name }}
                        </span>
                      </v-row>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <v-img contain v-if="data.item.logo" :src="data.item.logo"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
    data.item.name
  }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </div>
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2" color="secondary"> task_alt </v-icon>
                  Milestones
                </v-subheader>
                <v-divider></v-divider>
                <v-card-text>
                  <v-list dense v-if="milestones.length == 0">
                    <v-list-item v-if="!milestones">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey">
                          No milestones
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-list dense v-if="milestones && milestones.length > 0">
                    <v-list-item v-for="(milestone, index) in availableMilestones.filter(
    (x) =>
      x.type == booking.movementType &&
      (x.value !== 'GASSING' ||
        (x.value === 'GASSING' && booking.caProduct && !container.containerProducts.some(product => product.productId === 226)))
  )" :key="index">
                      <v-list-item-action class="mr-0 pr-0">
                        <v-avatar size="10" v-if="container[milestone.value]" :color="container[milestone.value].isActual
    ? 'success'
    : 'secondary'
    "></v-avatar>
                        <v-icon v-else color="grey" small>warning</v-icon>
                      </v-list-item-action>
                      <v-list-item-content v-if="container[milestone.value]">
                        <v-list-item-title style="font-size: 14px">{{
    container[milestone.value].description
  }}</v-list-item-title>
                        <v-list-item-subtitle style="font-size: 12px">{{
      formatDateTime(
        container[milestone.value].date,
        container[milestone.value].time
      )
    }}</v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 14px">{{
      milestone.name
    }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content v-else>
                        <v-list-item-subtitle style="font-size: 14px">{{
      milestone.name
    }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-btn v-if="['GASSING', 'WEIGH_BRIDGE', 'FULL_DROP_OFF'].includes(milestone.value)" color="grey"
                        small icon @click="addMilestone(milestone, milestone.value, container[milestone.value])">
                        <v-icon small>add_circle_outline</v-icon>
                      </v-btn>
                      <v-btn icon v-if="container[milestone.value] && container[milestone.value].poi"
                        @click="openPOIVew(container[milestone.value])">
                        <v-icon color="secondary">
                          location_on
                        </v-icon>
                      </v-btn>
                      <v-switch v-if="['DETOUR'].includes(milestone.value)" v-model="container[milestone.value]"
                        @change="updateDetourMilestone(container[milestone.value])"></v-switch>

                    </v-list-item>


                  </v-list>



                  <v-list v-if="legs.length > 0" dense>
                    <v-subheader style="font-size: 16px"><v-icon color="secondary" class="mr-2">local_shipping</v-icon>
                      Transport Legs
                      <v-spacer></v-spacer>


                      <v-chip outlined style="border: none" v-if="loadedDistance"><v-icon color="blue"
                          left>route</v-icon>
                        Loaded
                        Distance: {{ loadedDistance }} KM</v-chip>
                    </v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-for="leg in legs" :key="leg.leg" class="my-0 py-0">
                      <v-list-item-action>
                        <v-chip small class="mr-2">{{ leg.leg }}</v-chip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <v-icon color="blue" small class="mr-2">location_on</v-icon>
                          {{ leg.start.poi.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="leg.start.poi && leg.start.poi.city" style="font-size: 12px">
                          <v-icon color="grey" small class="mr-2">location_on</v-icon>
                          {{ leg.start.poi.city }}
                          <span v-if="leg.start.poi.country">, {{ leg.start.poi.country }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-icon color="green" small class="mr-2">where_to_vote</v-icon>
                          {{ leg.end.poi.name }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="leg.end.poi && leg.end.poi.city" style="font-size: 12px">
                          <v-icon color="grey" small class="mr-2">location_on</v-icon>
                          {{ leg.end.poi.city }}
                          <span v-if="leg.end.poi.country">, {{ leg.end.poi.country }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <v-chip small class="mx-1" outlined style="border: none">
                            <v-icon color="grey" small left>route</v-icon>
                            {{ leg.distance }} KM</v-chip>
                          <v-chip small class="mx-1" outlined style="border: none" v-if="leg.travelTimeText">
                            <v-icon color="grey" small left>schedule</v-icon>
                            {{ leg.travelTimeText.substring(0, 5) }} Travel
                            Time</v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card flat class="my-3" style="background-color: var(--v-toolbar-lighten1) !important">
                <v-card-text>


                  <v-row>

                    <v-col cols="12" sm="6" class="pt-1 pl-1">
                      <v-subheader style="font-size: 16px">
                        <v-icon class="mr-2" color="secondary"> description </v-icon>
                        Documents
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-progress-linear v-if="loadingDetails" indeterminate></v-progress-linear>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-if="!container.ctoDocument">-</span>
                            <span v-else>Version: {{ container.ctoDocument.version }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle> CTO </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row justify="center">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn icon @click="generateCTO(container.id, container)" :loading="loadingCto"
                                  v-on="on">
                                  <v-icon color="teal">post_add</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px">Generate new CTO</span>
                            </v-tooltip>
                            <v-tooltip top v-if="container.ctoDocument">
                              <template v-slot:activator="{ on }">
                                <v-btn icon @click="viewCTO(container.ctoDocument)" v-on="on">
                                  <v-icon color="blue">launch</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px">View Document</span>
                            </v-tooltip>
                            <v-tooltip top v-if="container.ctoDocument">
                              <template v-slot:activator="{ on }">
                                <v-btn icon @click="downloadDocument(container.ctoDocument)" v-on="on">
                                  <v-icon>download</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px">Download CTO</span>
                            </v-tooltip>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-1 pl-1">
                      <v-subheader style="font-size: 16px">
                        <v-icon class="mr-2" color="secondary"> router </v-icon>
                        Services
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-list dense>
                          <v-list-item :key="preadviseKey" v-if="container.preAdviseRequired" @click="viewPreadvise()">
                            <v-list-item-action>
                              <v-icon
                                :color="preadviseStatusColor(container.preadviseStatus, container.manuallyPreadvised)">{{
    preadviseIcon(container.preadviseStatus, container.manuallyPreadvised) }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 14px">
                                <v-chip small
                                  :color="preadviseStatusColor(container.preadviseStatus, container.manuallyPreadvised)">
                                  <span v-if="container.preadviseStatus && !container.manuallyPreadvised">{{
    container.preadviseStatus }}</span>
                                  <span v-else>Not submitted</span>
                                </v-chip>
                              </v-list-item-title>

                              <v-list-item-subtitle v-if="container.preadviseMessage">
                                {{ container.preadviseMessage }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                NAVIS Preadvise
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="container.preAdviseRequired">
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 14px">
                                Preadvised
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch color="blue" v-model="container.preadvised"
                                @change="setPreadvised(container.preadvised)"></v-switch>
                            </v-list-item-action>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title style="font-size: 14px">
                                Dummy Weight
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-switch color="blue" v-model="container.dummyWeight"
                                @change="setDummyWeight(container.dummyWeight)"></v-switch>
                            </v-list-item-action>
                          </v-list-item>



                        </v-list>
                      </v-card-text>

                    </v-col>
                  </v-row>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="secondary"> chat </v-icon>
                    Comments
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-card height="45vh" width="inherit" style="
                      overflow-y: auto;
                      background-color: transparent !important;
                    " flat :loading="loadingComments" id="messagesContainer">
                    <v-list dense>
                      <v-list-item v-if="container.containerComments &&
    container.containerComments.length == 0
    ">
                        <v-list-item-content class="text-center">
                          <span style="font-size: 12px; color: grey">
                            No comments.
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                      <div v-if="container.containerComments">
                        <v-list-item v-for="(
                            comment, index
                          ) in container.containerComments" :key="comment.id" :id="'comment_' + index"
                          class="py-0 my-1">
                          <v-list-item-avatar v-if="comment.userId != $store.state.user.id">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="36"
                                  class="mt-0 pt-0">
                                  <span v-if="!comment.user.avatar" class="primaryText--text text-h5">{{
    $Format.avatarAbbreviation(
      comment.user.firstName +
      " " +
      comment.user.surname
    )
  }}</span>
                                  <img v-else :src="comment.user.avatar" referrerpolicy="no-referrer" />
                                </v-avatar>
                              </template>
                              <span style="font-size: 12px">{{ comment.user.firstName }}
                                {{ comment.user.surname }}</span>
                            </v-tooltip>
                          </v-list-item-avatar>
                          <v-list-item-content
                            v-if="comment.userId == $store.state.user.id && comment.isDeleted == false">

                            <div style="display: flex;flex-direction: row;justify-content: space-evenly;">

                              <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">

                                  <v-btn v-on="{ ...tooltip }" text @click="openDialog(comment)"><v-icon
                                      color="blue">edit</v-icon></v-btn>

                                </template>
                                <span>Edit Comment</span>
                              </v-tooltip>

                              <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">

                                  <v-btn :loading="loadingDeleteComment" v-on="{ ...tooltip }" text
                                    @click="deleteContainerComment(comment.id)">
                                    <v-icon color="red">delete</v-icon>

                                  </v-btn>

                                </template>
                                <span>Remove Comment</span>
                              </v-tooltip>
                            </div>
                          </v-list-item-content>
                          <v-list-item-content :class="comment.userId == $store.state.user.id
    ? 'text-right'
    : 'text-left'
    ">
                            <v-list-item-title class="text-wrap" v-if="comment.isDeleted"
                              style="text-decoration: line-through;color: gray;">
                              {{ comment.comment }}
                            </v-list-item-title>
                            <v-list-item-title class="text-wrap" v-else>
                              {{ comment.comment }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <i style="font-size: 12px; color: grey">{{
    formatDate(comment.createdAt).dateTime
  }}</i>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-avatar v-if="comment.userId == $store.state.user.id">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="36"
                                  class="mt-0 pt-0">
                                  <span v-if="!comment.user.avatar" class="primaryText--text text-h5">{{
    $Format.avatarAbbreviation(
      comment.user.firstName +
      " " +
      comment.user.surname
    )
  }}</span>
                                  <img v-else :src="comment.user.avatar" referrerpolicy="no-referrer" />
                                </v-avatar>
                              </template>
                              <span style="font-size: 12px">{{ comment.user.firstName }}
                                {{ comment.user.surname }}</span>
                            </v-tooltip>
                          </v-list-item-avatar>
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-card>
                </v-card-text>
                <v-card flat height="7vh" width="100%" class="mx-0 px-0" style="
                    position: absolute;
                    bottom: 0;
                    background-color: var(--v-toolbar-lighten1) !important;
                    display: flex;
                    align-items: center;
                    overflow-y: hidden;
                  ">
                  <v-textarea :key="chatContainerUpdateKey" persistent-placeholder placeholder="Type a Message"
                    @keydown.enter="commentContainer()" class="ml-2" no-resize hide-details outlined height="6vh"
                    style="width: 80%; contain: content; border-radius: 20px" v-model="containerChatArea"
                    :value="containerChatArea"></v-textarea>
                  <v-btn :loading="sendingMessage" icon class="ml-3 mr-2"
                    @click="commentContainer()"><v-icon>send</v-icon></v-btn>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card width="100%" style="
              background-color: var(--v-toolbar-lighten1) !important;
              margin: 1vh 0 2vh 0;
            ">
            <v-subheader style="font-size: 16px">
              <v-icon class="mr-2" color="secondary"> tag </v-icon>
              Container Details
              <v-spacer></v-spacer>
              <span style="display: flex; gap: 30px">
                <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        ">
                  <div class="mr-3" style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          ">
                    <b style="font-size: small">
                      {{ regime ? regime.code : "None" }}
                      <v-icon v-if="regime && regime.steri" color="blue">ac_unit</v-icon>
                    </b>
                    <small>Code</small>
                  </div>
                </div>

                <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        ">
                  <div style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          ">
                    <v-icon color="#007d5c">thermostat</v-icon>
                    <small>Set</small>
                  </div>
                  <b style="font-size: small; padding: 0 0 15px 0">{{
    regime ? `${regime.setPointTemp}°C` : ""
  }}</b>
                </div>

                <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        " v-if="regime && regime.protocol">
                  <div style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          ">
                    <v-icon color="#9d904a">thermostat</v-icon>
                    <small>Protocol</small>
                  </div>
                  <b style="font-size: small; padding: 0 0 15px 0">{{
    regime ? `${regime.protocol}°C` : "" }}</b>
                </div>

                <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        ">
                  <div style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          ">
                    <v-icon color="blue">hvac</v-icon>
                    <small>Vents</small>
                  </div>
                  <b style="font-size: small; padding: 0 0 15px 0">{{
    container.vents
  }}</b>
                </div>
              </span>
            </v-subheader>
            <v-divider></v-divider>
            <v-list dense class="my-0 py-0 mt-4 custom-mb-2">
              <v-row >
                <v-col cols="12" sm="6" class="mt-0 py-0">
                  <v-col cols="12" sm="12" class="mt-0 py-0">
                    <v-card-text class="mt-2 pt-0">

                      <v-text-field outlined dense rounded label="Container No" v-model="container.containerNo"
                        :rules="[rules.containerNo]" @input="setChange"></v-text-field>

                      <v-text-field outlined dense rounded label="Seal No" v-model="container.sealNo"
                        @input="setChange"></v-text-field>
                      <p style="color:#ff6666; font-size:12px; margin-top:-22px; margin-left:10px"
                        v-if="container.stockSealNo">
                        Stock Seal No : {{ container.stockSealNo }}
                      </p>

                      <v-text-field outlined dense rounded label="Temp Serail No" v-model="container.tempSerialNo"
                        @input="setChange"></v-text-field>
                    </v-card-text>
                  </v-col>
                </v-col>

                <v-col cols="12" sm="6" class="my-0 py-0">
                  <v-col cols="12" sm="12" class="my-0 py-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="container.containerType">
                          {{ container.containerType }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Container Type
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="12" class="my-0 py-0">

                    <v-list-item>
                      <v-chip class="mx-1" small :color="container && container.gensetRequired ? '#cfb055' : ''
    "><v-icon left>bolt</v-icon>
                        <span v-if="container.gensetRequired">Genset Required</span>
                        <span v-else>No Genset Required</span></v-chip>
                    </v-list-item>

                    <v-list-item>
                      <v-chip class="mx-1" small :color="container && container.tempRecorderType ? 'green' : ''
    "><v-icon left>router</v-icon>
                        <span v-if="container.tempRecorderType">Devices Required:
                          {{ container.tempRecorderType }}</span>
                        <span v-else>No Devices Required</span></v-chip>
                    </v-list-item>
                    <v-list-item>
                      <v-chip class="mx-1" small :color="container && container.booking.caProduct
    ? 'edit'
    : ''
    " v-bind="attrs" v-on="on">
                        <v-icon left>propane_tank</v-icon>
                        <span v-if="container.booking.caProduct">Controlled Atmosphere</span>
                        <span v-else>No CA</span>
                      </v-chip>
                    </v-list-item>
                    <v-list-item>
                      <v-chip small v-if="regime && regime.probeCount && regime.probeType" class="mx-1">
                        <v-icon left>sensors</v-icon>

                        <span>{{ regime.probeCount }} x {{ regime.probeType }}</span>
                      </v-chip>
                    </v-list-item>
                  </v-col>
                </v-col>
              </v-row>
            </v-list>
            <v-col cols="12" sm="12" class="my-0 py-0">
              <v-row no-gutters>
                <v-col cols="12" sm="12" class="pl-1">
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="secondary"> scale </v-icon>
                    Weights
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-card-text class="mt-2 pt-0">
                    <v-row no-gutters>
                      <v-col cols="12" md="6">
                        <v-text-field prefix="Nett" disabled hide-details class="mt-1" type="number" step="0.00"
                          suffix="KG" v-model="container.nettWeight" outlined dense rounded></v-text-field>
                        <v-text-field prefix="Gross" disabled hide-details class="mt-1" type="number" step="0.00"
                          suffix="KG" v-model="container.grossWeight" outlined dense rounded></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field prefix="VGM" hide-details suffix="KG" type="number" :step="0.0"
                          v-model="container.vgmWeight" @input="setChange" outlined dense rounded
                          class="mt-1"></v-text-field>
                        <v-text-field prefix="Tare" hide-details class="mt-1" type="number" :step="0.0" suffix="KG"
                          v-model="container.tareWeight" @input="setVGM" outlined dense rounded></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" class="pt-0">

              <v-subheader style="font-size: 16px">
                <v-icon class="mr-2" color="secondary"> confirmation_number </v-icon>
                Booking Details <v-spacer></v-spacer> <v-chip class="ml-2" small v-if="container.booking.user">Created
                  By:
                  <v-icon class="ml-1" left small>person</v-icon>
                  {{ container.booking.user.firstname }}
                  {{ container.booking.user.surname }}</v-chip>
              </v-subheader>
              <v-divider></v-divider>
              <v-list dense>
                <v-row> <v-col cols="12" sm="12" class="my-0 py-0">
                    <v-list-item>

                      <v-list-item-subtitle>
                        Route:
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-chip outlined style="border: none" class="ml-2" :key="routeKey">
                        <v-tooltip bottom v-for="(location, index) in fullRoute" :key="index">
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <v-chip pill small outlined class="primaryText--text">
                                <v-avatar size="24" left>
                                  <v-img contain v-if="location.locode" :src="`https://cdn.loglive.io/flags/4x3/${location.locode
    .substring(0, 2)
    .toLowerCase()}.svg`"></v-img> </v-avatar>{{ location.locode }}
                              </v-chip>

                              <v-icon class="mx-3" color="grey" small v-if="index < fullRoute.length - 1">east</v-icon>
                            </div>
                          </template>
                          <span style="font-size: 12px">{{ location.type }}: {{ location.locode }}</span>
                        </v-tooltip>
                      </v-chip>

                    </v-list-item>

                  </v-col>
                  <!-- {{container}} -->


                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-list-item v-if="container.booking.vessel">
                      <v-list-item-content>
                        <v-list-item-title v-if="container.booking.vessel && container.booking.vessel.name" style="font-size: 14px">
                          {{ container.booking.vessel.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle> Vessel Name </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-list-item v-if="container.booking.mainCarriageConveyanceNumber">
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="container.booking.mainCarriageConveyanceNumber && container.booking.mainCarriageConveyanceNumber" style="font-size: 14px">
                          {{ container.booking.mainCarriageConveyanceNumber }}
                          
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle> Voyage </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-list-item v-if="container.booking.customer">
                      <v-list-item-content>
                        <v-list-item-title v-if="container.booking.customer && container.booking.customer.name" style="font-size: 14px">
                          {{ container.booking.customer.name }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle> Customer </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-list-item v-if="container.booking.shipper">
                      <v-list-item-content>
                        <v-list-item-title v-if="container.booking.shipper && container.booking.shipper.name" style="font-size: 14px">

                          {{ container.booking.shipper.name }}

                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle> Shipper </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="container.verificationMethod" style="font-size: 14px">
                          {{ container.verificationMethod }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Verification Method
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="container.carrierReferenceNumber" style="font-size: 14px">
                          {{ container.booking.carrierReferenceNumber }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle> Booking ref </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>

                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="container.booking.shipper.solasAccreditationUser
    " style="font-size: 14px">
                          {{
    container.booking.shipper.solasAccreditationUser
  }}
                        </v-list-item-title>

                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Verification Person
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="container.booking.shipper.solasAccreditationNo
    " style="font-size: 14px">
                          {{
    container.booking.shipper.solasAccreditationNo
  }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Verification Code
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>


                </v-row>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" class="pt-0">
              <v-subheader style="font-size: 16px" class="pl-2">
                <v-icon class="mr-2" color="secondary"> widgets </v-icon>
                Cargo
                <v-spacer></v-spacer>


              </v-subheader>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item v-for="cargo in container.containerProducts" :key="cargo.id">
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 14px">
                      <b>{{ cargo.product.name }}</b>
                      <span style="font-size: 12px" v-if="cargo.hsCode">
                        - {{ cargo.hsCode }}</span>
                      <span style="font-size: 12px" v-if="cargo.quantity">
                        - {{ cargo.quantity }}
                        {{ cargo.quantityType }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="cargo.description" style="font-size: 12px">
                      {{ cargo.description }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: 12px" v-if="cargo.nettWeight || cargo.grossWeight">
                      NETT {{ cargo.nettWeight }} KG - GROSS
                      {{ cargo.grossWeight }} KG
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>


            <!-- <v-col cols="12" class="my-0 py-0">
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2" color="secondary"> ac_unit </v-icon>
                  Regime <v-spacer></v-spacer>
                  <v-chip small v-if="regime && regime.probeCount && regime.probeType" class="mx-1">
                    <span>{{ regime.probeCount }} x {{ regime.probeType }}</span>
                  </v-chip>
                  <v-chip v-if="regime && regime.steri" outlined style="border: none" color="blue">STERI <v-icon right
                      color="blue">ac_unit</v-icon></v-chip>
                </v-subheader>
                <v-divider></v-divider>
                <v-progress-linear v-if="loadingDetails" indeterminate></v-progress-linear>
                <v-row justify="center" align="center" class="mt-3">
                  <v-col cols="12" class="d-flex justify-center py-0 my-0">
                    <span style="display: flex; gap: 30px">
                      <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        ">
                        <div class="mr-3" style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          ">
                          <b style="font-size: large">
                            {{ regime ? regime.code : "None" }}
                          </b>
                          <small>Code</small>
                        </div>
                      </div>

                      <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        ">
                        <div style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          ">
                          <v-icon color="#007d5c">thermostat</v-icon>
                          <small>Set</small>
                        </div>
                        <b style="font-size: large; padding: 0 0 15px 0">{{
    regime ? `${regime.setPointTemp}°C` : ""
  }}</b>
                      </div>
                      <div v-if="regime && regime.protocol" style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        ">
                        <div style="
                            font-size: 12px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          ">
                          <v-icon color="#9d904a" small>thermostat</v-icon>
                          <small>Protocol</small>
                        </div>
                        <b style="font-size: large; padding: 0 0 15px 0">{{
    regime ? `${regime.protocol}°C` : ""
  }}</b>
                      </div>
                    </span>
                  </v-col>
                  <v-col cols="12" class="my-4">
                    <v-row justify="center">
                      <v-chip small class="mx-1">Vents: {{ container.vents }}
                        <span v-if="container.vents &&
    !['CA', 'MA', 'CLOSED'].includes(container.vents)
    ">
                          CBM</span></v-chip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip class="mx-1" small :color="container && container.booking.caProduct
    ? 'edit'
    : ''
    " v-bind="attrs" v-on="on">
                            <v-icon left>ac_unit</v-icon>
                            <span v-if="container.booking.caProduct">Controlled Atmosphere</span>
                            <span v-else>No CA</span>
                          </v-chip>
                        </template>
                        <span v-if="container.booking.caProduct">{{
    container.booking.caProduct
  }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col> -->
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="previewModal" width="1200px" :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
      <v-card v-if="item && item.name">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ item.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(item)" icon><v-icon>download</v-icon></v-btn>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon text @click="(previewModal = false), (item = {})">X</v-btn>
        </v-toolbar>
        <v-card-text :style="{
    'max-height': fullscreen ? '90vh' : '75vh',
    'overflow-y': 'auto',
  }">
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div v-if="item.type && item.type.includes('image/')">
                <v-img :src="item.url" contain style="max-height: 70vh" @load="imageLoading = false">
                </v-img>
              </div>
              <div v-else-if="item.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" sm="9" class="text-center" v-if="item.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed :width="fullscreen ? 1200 : 900" :source="item.url" @loaded="imageLoading = false" />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span>This file is in a format that cannot be previewed.</span>
                    <br /><br />
                    <v-btn color="primary" style="text-transform: none" @click="downloadDocument(item)">
                      <v-icon class="mr-1">download</v-icon> Download</v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editButton" max-width="500px">



      <v-card>
        <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: baseline;">
          <v-card-title>Edit Comment</v-card-title>
          <v-card-actions>
            <v-btn :loading="loadingEditComment" text @click="updateContainerComment(newEditedComment.id)"><v-icon
                color="blue">save</v-icon></v-btn>
            <v-btn text @click="closeEditDialog()">X</v-btn>

          </v-card-actions>
        </div>
        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
          <v-textarea v-model="newEditedComment.comment" style="width: 80%; contain: content; border-radius: 20px;"
            outlined rows="4">
          </v-textarea>
        </div>

      </v-card>
    </v-dialog>

    <v-dialog v-model="preadviseModal" width="1000px" persistent>
      <v-card :key="preadviseKey">
        <v-toolbar flat>
          <v-toolbar-title>
            <v-chip small :color="preadviseStatusColor(container.preadviseStatus, container.manuallyPreadvised)"
              class="mr-2">
              <span v-if="container.preadviseStatus && !container.manuallyPreadvised">{{ container.preadviseStatus
                }}</span>
              <span v-else>Not submitted</span>
            </v-chip>
            NAVIS Preadvise </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="container.preadviseStatus && container.preadviseStatus == 'Confirmed'" color="red" rounded small
            :disabled="!preadviseItem.ready" @click="cancelPreadvise" :loading="loadingPreadvise">Cancel
            Preadvise</v-btn>

          <v-btn v-if="!container.preadviseStatus" color="blue" rounded small :disabled="!preadviseItem.ready"
            @click="submitPreadvise" :loading="loadingPreadvise">Submit</v-btn>
          <v-btn v-else color="blue" rounded small :disabled="!preadviseItem.ready" @click="submitPreadvise"
            :loading="loadingPreadvise">Update Preadvise</v-btn>
          <v-btn v-if="container.preadviseStatus" color="orange" rounded small :disabled="!preadviseItem.ready"
            @click="getPreadvisePayload" :loading="loadingPrePayload">Payload</v-btn>
          <v-btn text @click="preadviseModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-alert v-if="container.preadviseStatus && container.preadviseStatus == 'Error'" prominent dark outlined
            dense text type="warning" class="body-2">
            {{ container.preadviseMessage }}
          </v-alert>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ container.shippingLineName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Shipping Line
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="info">visibility</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ container.containerNo }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Container Number
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="container.containerNo
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ container.sealNo }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Seal Number
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="container.sealNo
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>


            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ container.vgmWeight ?? ' - ' }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  VGM
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="container.vgmWeight
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-if="container.booking.vessel">
                  {{ container.booking.vessel.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Vessel
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="container.booking.vessel &&
    container.booking.vessel.name
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ container.booking.mainCarriageConveyanceNumber }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Voyage
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="container.booking.mainCarriageConveyanceNumber
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ container.booking.carrierReferenceNumber }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Booking Ref
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="container.booking.carrierReferenceNumber
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title v-if="container.FULL_DROP_OFF">
                  {{ container.FULL_DROP_OFF.description }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Terminal
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="container.FULL_DROP_OFF
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item v-if="preadviseItem.solasUser && container.verificationMethod === 'Method 2'">
              <v-list-item-content>
                <v-list-item-title>
                  {{ preadviseItem.solasUser }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  SOLAS Verification
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="preadviseItem.solasUser
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-text-field v-else-if="container.verificationMethod == 'Method 2'" v-model="preadviseItem.solasUser"
              label="SOLAS Verification User" outlined dense clearable></v-text-field>
            <v-list-item v-if="preadviseItem.solasAccount && container.verificationMethod == 'Method 2'">
              <v-list-item-content>
                <v-list-item-title>
                  {{ preadviseItem.solasAccount }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  SOLAS Verification No.
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="preadviseItem.solasAccount
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-text-field v-else-if="container.verificationMethod == 'Method 2'" v-model="preadviseItem.solasAccount"
              label="SOLAS Account" outlined dense clearable></v-text-field>

            <v-list-item v-if="container.verificationMethod == 'Method 1'">
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="container['WEIGH_BRIDGE'] && container['WEIGH_BRIDGE'].poi">
                    <span v-if="container['WEIGH_BRIDGE'].poi.friendlyName">{{
                      container['WEIGH_BRIDGE'].poi.friendlyName }}</span>
                    <span v-else>{{ container['WEIGH_BRIDGE'].poi.name }}</span>
                  </span>
                  <span v-else>-</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Weigh Bridge
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="success" v-if="container['WEIGH_BRIDGE'] && container['WEIGH_BRIDGE'].poi
    ">check_circle_outline</v-icon>
                <v-icon v-else color="red">cancel</v-icon>
              </v-list-item-action>
            </v-list-item>
            <!-- <v-text-field  v-else v-model="preadviseItem.solasUser" label="SOLAS Verification User" outlined dense clearable></v-text-field> -->

          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewPayload" width="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Preadvise Payload </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="viewPayload = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(item, key) in preadvisePayloads" :key="key">
              <v-list-item-content>
                <v-list-item-title>
                  {{ key }} : {{ item }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addMilestoneModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
      <ManageContainerMilestone :item="milestoneItem" :key="milestoneKey" @close="addMilestoneModal = false"
        @save="milestoneUpdate" :bookingType="this.bookingType" />
    </v-dialog>


    <v-dialog v-model="openPoiView" width="700px" height="550px" :fullscreen="$vuetify.breakpoint.mobile">
      <SensorMapping :lat="poiLat" :lng="poiLng" :name="poiName" :alias="poiAlias" :address="poiAdress"
        @close="openPoiView = false, poiLat = 0, poiLng = 0, poiName = '', poiAlias = '', poiAdress = ''" />
    </v-dialog>
  </v-card>
</template>

<script>
import dateFormat from "dateformat";
// import GmapCustomMarker from "vue2-gmap-custom-marker";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import ManageContainerMilestone from "./ManageContainerMilestone.vue";
import SensorMapping from "../../Telemetry/SensorMapping.vue";
import isContainer from "../../../utils/containerNo";
export default {
  props: ["booking", "container", "modalOpen"],
  components: {
    // GmapCustomMarker,
    VuePdfEmbed,
    ManageContainerMilestone,
    SensorMapping
  },
  data: () => ({
    viewPayload: false,
    openPoiView: false,
    poiLat: 0,
    poiLng: 0,
    availableMilestones: [
      { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
      { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
      { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
      { value: "GASSING", type: "EXPORT", name: "Gassing" },
      { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
      { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },

      { value: "DETOUR", type: "EXPORT", name: "Detour" },
      { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },
    ],
    milestoneKey: 4000,
    center: { lat: 0.0, lng: 0.0 },
    change: false,
    contractors: [],
    regime: null,
    loadingCto: false,
    milestones: [],
    fullscreen: false,
    editButton: false,
    newEditedComment: "",
    loadingContractors: false,
    loadingDetails: true,
    loadingComments: true,
    loadingEditComment: false,
    sendingMessage: false,
    routeKey: 3000,
    containerChatArea: "",
    chatContainerUpdateKey: 0,
    imageLoading: true,
    item: {},
    legs: [],
    loadedDistance: 0,
    loadingPreadvise: false,
    key: 2000,
    mapStyle: {
      scrollwheel: true,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#283a54" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#283a54" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#212121" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ],
    },
    milestoneTypes: [
      {
        name: "Empty Pickup",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "EMPTY_PICKUP",
      },
      {
        name: "Stuff Date",
        eventType: "EQUIPMENT",
        modeOfTransport: "TRUCK",
        value: "STUFF_DATE",
      },
      {
        name: "Loading Point 1",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "LOADING_POINT_1",
      },
      {
        name: "Loading Point 2",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "LOADING_POINT_2",
      },
      {
        name: "Weigh Bridge",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "WEIGH_BRIDGE",
      },
      {
        name: "Plugged In",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_IN",
      },
      {
        name: "Unplugged",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_OUT",
      },
      {
        name: "Port Holding",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "FULL_DROP_OFF",
      },
    ],
    preadviseModal: false,
    preadviseItem: {},
    previewModal: false,
    preadviseKey: 1000,
    statuses: [
      { id: 4, state: "AT", color: "#006bc3", location: null },
      { id: 3, state: "ASSIGNED", color: "#0094db", location: null },
      { id: 8, state: "COMPLETED", color: "#008a61", location: null },
      { id: 6, state: "DONE LOADING", color: "#2cc194", location: null },
      { id: 5, state: "EN ROUTE TO", color: "#ccb50a", location: null },
      { id: 7, state: "STOPPED", color: "#e55000", location: null },
      { id: 9, state: "CANCELLED", color: "#ff6666", location: null },
      { id: 10, state: "ISSUE", color: "#7f0000", location: null },
      { id: 2, state: "PENDING", color: "#657583", location: null },
      { id: 1, state: "TO BE ALLOCATED", color: "#b4bbc1", location: null },
    ],
    statusLocations: [
      { name: "LOADING POINT 1", value: "LP1" },
      { name: "LOADING POINT 2", value: "LP2" },
      { name: "EMPTY DEPOT", value: "EMPTY" },
      { name: "PORT OF LOADING", value: "POL" },
      { name: "DROP OFF", value: "DROPOFF" },
      { name: "YARD", value: "YARD" },
      { name: "FUEL STATION", value: "FUEL STATION" },
    ],
    savingChange: false,
    statusFilters: {
      at: false,
      assigned: false,
      completed: false,
      "done Loading": false,
      "en Route To": false,
      stopped: false,
      cancelled: false,
      issue: false,
      pending: false,
      "to Be Allocated": false,
    },
    milestoneItem: {},
    bookingType: "",
    milestoneContainer: {},
    addMilestoneModal: false,
    changeWeight: false,
    loadingPrePayload: false,
    preadvisePayloads: [],
    transportStatusChange: false,
    poiName: "",
    poiAlias: "",
    poiAdress: "",
    transportStatuses: [
      {
        text: 'On Hold',
        value: 'onHold',
        color: 'brown lighten-1'
      },
      {
        text: 'Breakdown',
        value: 'BREAKDOWN',
        color: 'red'
      },
      {
        text: "Unallocated",
        value: "PENDING",
        color: "orange",
      },
      {
        text: 'Turn Load',
        value: 'turnLoad',
        color: 'yellow'
      },
      // {
      //     text: 'Booking Confirmed',
      //     value: 'BOOKING_CONFIRMED',
      //     color: 'green lighten-1'
      // },
      // {
      //   text: "Pre-Allocated",
      //   value: "PRE_ALLOCATED",
      //   color: "green darken-2",
      // },
      {
        text: "Allocated",
        value: "ALLOCATED",
        color: "lime darken-3",
      },
      {
        text: 'Pre-Loaded',
        value: 'PRE_LOADED',
        color: 'blue-grey darken-2'
      },
      {
        text: 'Collect Tomorrow',
        value: 'COLLECT_TOMORROW',
        color: 'orange darken-2'
      },
      {
        text: 'Collect Later Today',
        value: 'COLLECT_LATER_TODAY',
        color: 'orange darken-2'
      },
      {
        text: "Instr. Sent",
        value: "INSTRUCTION_SENT",
        color: "blue-grey",
      },
      {
        text: "Empty Picked Up",
        value: "GATE_OUT_EMPTY_PICKUP",
        color: "teal",
      },
      {
        text: "En route LP-1",
        value: "EN_ROUTE_LP1",
        color: "cyan darken-2",
      },
      {
        text: "At LP-1",
        value: "AT_LP1",
        color: "cyan darken-4",
      },
      {
        text: "En route LP-2",
        value: "EN_ROUTE_LP2",
        color: "purple darken-2",
      },
      {
        text: "At LP-2",
        value: "AT_LP2",
        color: "purple darken-4",
      },
      {
        text: "En route Gas",
        value: "EN_ROUTE_GAS",
        color: "green lighten-2",
      },
      {
        text: "At Gassing",
        value: "AT_GASSING",
        color: "green lighten-3",
      },
      {
        text: "En route WB",
        value: "EN_ROUTE_WB",
        color: "blue",
      },
      {
        text: "AT WB",
        value: "AT_WB",
        color: "blue lighten-1",
      },
      {
        text: "En route Depot",
        value: "EN_ROUTE_DEPOT",
        color: "blue darken-4",
      },
      {
        text: "At Depot",
        value: "AT_DEPOT",
        color: "blue darken-5",
      },
      {
        text: 'Overnight Genset',
        value: 'OVERNIGHT_GENSET',
        color: '#363062'
      },
      {
        text: 'En route Plug-in',
        value: 'EN_ROUTE_PLUG_IN',
        color: 'blue lighten-2'
      },
      {
        text: 'Plugged-in',
        value: 'PLUG_IN',
        color: 'blue lighten-3'
      },
      {
        text: "En route Port",
        value: "EN_ROUTE_PORT",
        color: "blue darken-1",
      },
      {
        text: "At Port",
        value: "AT_PORT",
        color: "blue darken-2",
      },
      {
        text: "Gate In",
        value: "FULL_DROP_OFF",
        color: "#183A1D",
      },
      {
        text: 'Stacked',
        value: 'STACKED',
        color: 'success'
      },
      {
        text: 'Preadvise Only',
        value: 'preadviseOnly',
        color: 'pink darken-2'
      },
      {
        text: 'Arrange Plugged-In',
        value: 'preadviseOnly',
        color: '#F4CE14'
      },
      {
        text: 'Cancelled',
        value: 'cancelled',
        color: 'red darken-2'
      }
    ],

    rules: {
      containerNo(value) {
        if (/\b[A-Z]{4}\d{7}\b/.test(value)) {
          return true;
        }
        return "Container number needs to be in the format: ABCD1234567";
      },
    },
    params: {
      search: "",
      filter: {},
    },
  }),
  computed: {
    fullRoute() {
      console.log('booking', this.booking)
      let result = [];
      let setTypes = [
        { type: "Port of Load", locode: this.booking.portOfLoadValue },
        {
          type: "Port of Discharge",
          locode: this.booking.portOfDischargeValue,
        },
        {
          type: "Final Destination",
          locode: this.booking.finalDestinationValue,
        },
      ];

      // POL
      result.push(setTypes[0])

      // Transhipments
      if (this.booking.sailingSchedule && this.booking.sailingSchedule.sailingScheduleLegs.length > 1) {
        const findLeg = this.booking.sailingSchedule.sailingScheduleLegs.find(x => x.leg === 1)
        if (findLeg && findLeg.portOfDischargeValue) {
          result.push({ type: "TS Port 1", locode: findLeg.portOfDischargeValue });
        }

      }

      // POD & FD
      if (this.booking.portOfDischargeValue === this.booking.finalDestinationValue) {
        result.push({
          type: "POD & Final Destination",
          locode: this.booking.finalDestinationValue
        })
      } else {
        result.push(setTypes[1])
        result.push(setTypes[2])
      }
      return result;
    },
    terminal() {
      if (this.container.containerMilestones.find(x => x.type == 'FULL_DROP_OFF')) {
        let terminalData = {}
        terminalData.locode = this.container.containerMilestones.find(x => x.type == 'FULL_DROP_OFF')?.location
        terminalData.name = this.container.containerMilestones.find(x => x.type == 'FULL_DROP_OFF')?.description
        console.log('terminalData', terminalData)
        return terminalData
      }
      return null
    },
    title() {
      if (this.container.customerContainerRef) {
        return `${this.container.ctoNo} (${this.container.customerContainerRef})`;
      }
      return this.container.ctoNo;
    },
    //take in my item and return thoose whoose product = TelemPlus
    filteredProducts() {
      return this.container.temporaryProducts.filter(
        (product) => product.product == "TelemPlus"
      );
    },
  },
  async created() {

  },
  watch: {
    "container.vgmWeight": function () {
      this.changeWeight = true
    },
    "modalOpen": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getContainerDetails();
          this.getContainerComments(this.container.id);
        }
      },
    },
    // 'container.containerMilestones': {
    //   handler() {
    //     this.availableMilestones = this.computeAvailableMilestones();
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    this.getContractors();
  },
  methods: {
    openDialog(comment) {
      this.loadingEditComment = false;
      this.newEditedComment = comment

      this.editButton = true;

    },
    closeEditDialog() {
      this.editButton = false;
    },
    checkContainer(value) {
      if(isContainer(value)){
        return true
      }
      else{
        return false
      }
    },
    async updateDetourMilestone(value) {

      let obj = {
        bookingContainerId: this.container.id,
        value: value,
        type: 'DETOUR',
      }
      let result = await this.$API.updateDetourMilestone(obj)
      if (result) {
        this.container.containerMilestones = result;
        this.$message({
          type: "success",
          message: `Detour Milestone Updated`,
        });
      }
      else {
        this.$message({
          type: "error",
          message: `Could not update Detour Milestone`,
        });
      }
    },
    openPOIVew(milestone) {
      console.log('milestone', milestone)
      //conver string to number
      this.poiLat = parseFloat(milestone.lat)
      this.poiLng = parseFloat(milestone.lng)
      this.poiName = milestone.poi.name
      this.poiAlias = milestone.poi.friendlyName
      this.poiAdress = milestone.poi.address
      this.openPoiView = true
    },
    setDummyWeight(dummy) {
      this.container.dummyWeight = dummy
      this.change = true
    },
    async getPreadvisePayload() {
      this.loadingPrePayload = true
      let result = await this.$API.getPreadvisePayload(this.container.id)
      if (result) {
        this.preadvisePayloads = result
        console.log('preadvisePayloads', this.preadvisePayloads)
        this.viewPayload = true
      }
      else {
        this.$message({
          type: "error",
          message: `Could not get payload`,
        });
      }
      this.loadingPrePayload = false
    },
    // computeAvailableMilestones() {
    //   return this.milestones.filter(
    //     (x) =>
    //       x.type == this.booking.movementType &&
    //       (x.value !== 'GASSING' ||
    //         (x.value === 'GASSING' && this.booking.caProduct))
    //   );
    // },
    async milestoneUpdate(data) {
      // console.log('data', data)
      this.container.containerMilestones = data;
      //loop over the data
      for (let x = 0; x < data.length; x++) {
        this.container[data[x].type] = {
          description: data[x].description,
          poi: data[x].poi,
          poiId: data[x].poiId,
          time: data[x].time,
          date: data[x].date,
        }
      }
      this.change = true;
      this.addMilestoneModal = false;

    },
    async addMilestone(item, type = null, description, autosave = true) {
      console.log('item', item);
      item.id = this.container.id
      if (type && item[type]) {
        console.log('item addMilestone', item)
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
        this.milestoneContainer = item;
        this.milestoneItem.date = null;
        this.milestone.poi = description ? description.poi : null
        this.milestone.poiId = description ? description.poiId : null
      } else {
        this.milestoneItem = {
          type,
          bookingContainerId: item.id,
          pol: item.portOfLoadValue,
          date: null,
          autosave,
          isActual: false,
          poi: description ? description.poi : null,
          poiId: description ? description.poiId : null,
        };
        this.milestoneContainer = item;
      }
      console.log('this.container[item.value]', this.container[item.value])
      this.container[item.value] = {
        description: description ? description.description : null,
        poi: description ? description.poi : null,
        poiId: description ? description.poiId : null,
      }
      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    async getContainerDetails() {
      console.log('this.container', this.container)
      this.loadingDetails = true;
      let body = {
        regimeCode: this.container.regimeCode,
        containerId: this.container.id,
        bookingId: this.booking.id,
        regimeId: this.booking.regimeId,
      };
      let result = await this.$API.getContainer(body.containerId);
      if (result) {
        this.regime = result.regime;
        this.milestones = result.containerMilestones;
        this.legs = result.legs ?? [];
        // console.log('this.legs', this.legs)
        console.log('result', result)
        if (this.legs.length > 0) {
          for (let x = 0; x < this.legs.length; x++) {
            this.loadedDistance += parseFloat(this.legs[x].distance)
          }
          // this.center = this.legs[0].start;
          // let findLoad = this.legs.findIndex(
          //   (leg) => leg.start.type == "LOADING_POINT_1"
          // );
          // let endLoad = this.legs.findIndex(
          //   (leg) => leg.start.type == "FULL_DROP_OFF"
          // );
          // if (findLoad > -1 && endLoad > -1) {
          //   let loadedLegs = this.legs.slice(findLoad, endLoad + 1);
          //   this.loadedDistance = 0;
          //   loadedLegs.forEach((leg) => {
          //     this.loadedDistance += parseFloat(leg.distance);
          //   });
          //   this.loadedDistance = this.loadedDistance.toFixed(2);
          // }
        }
      }
      this.key++;
      this.loadingDetails = false;
    },
    async getContainerComments(containerId) {
      this.loadingComments = true;
      let result = await this.$API.getContainerComments(containerId);
      if (result) {
        this.container.containerComments = result;

        this.loadingComments = false;
        this.$nextTick(() => {
          let objDiv = document.getElementById("messagesContainer");
          if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        });
      }
    },
    async deleteContainerComment(id) {
      let result = await this.$API.deleteContainerComment({
        id: id,
        isDeleted: true,
        isActive: false,
        bookingContainerId: this.container.id
      });

      if (result) {
        this.loadingDeleteComment = false;
        this.containerComments = result
      }
    },
    async updateContainerComment(id) {
      this.loadingEditComment = true;
      let result = await this.$API.updateContainerComment({
        id: id,
        isDeleted: true,
        isActive: false,
        bookingContainerId: this.container.id,
        organisationId: this.newEditedComment.organisationId,
        userId: this.newEditedComment.userId,
        comment: this.newEditedComment.comment
      });

      if (result) {
        this.loadingEditComment = false;
        this.containerComments = result
        this.closeEditDialog();
      }
    },
    async commentContainer() {
      if (this.containerChatArea != "") {
        this.containerChatArea.trim();
        this.chatContainerUpdateKey++;
        // check if last 2 characters are not spaces or new line characters
        this.sendingMessage = true;
        let obj = {
          comment: this.containerChatArea,
          bookingContainerId: this.container.id,
          user: this.$store.state.user,
        };
        let result = await this.$API.commentOnContainer(obj);
        if (result) {
          result.user = this.$store.state.user;
          this.container.containerComments.push(result);
          this.containerChatArea = "";
          this.sendingMessage = false;
        } else {
          this.$message({
            type: "error",
            message: `Message could not go through. Please check your connection and try again.`,
          });
        }
      }
    },
    formatDate(date) {
      let isoFormat = null;
      let fullDate = null;
      let dateTime = null;
      let dayOfWeek = null;
      let shorterDate = null;
      let time = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "dd-mm-yyyy");
        fullDate = dateFormat(new Date(date), "dddd, mmmm dS, yyyy");
        dateTime = dateFormat(new Date(date), "dddd, mmmm dS, yyyy HH:MM");
        time = dateFormat(new Date(date), "HH:MM");
        dayOfWeek = dateFormat(new Date(date), "ddd");
        shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
      }
      return { isoFormat, fullDate, dateTime, dayOfWeek, shorterDate, time };
    },
    formatDateTime(date, time) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
        if (time) {
          result += " " + time;
        }
      }
      return result;
    },
    milestoneType(type) {
      let find = this.milestoneTypes.find((x) => x.value == type);
      return find ? find.name : null;
    },

    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    async generateCTO(id, container) {
      try {
        this.loadingCto = true;
        let obj = {
          containerId: id,
          movementType: container.booking.movementType,
        };
        let result = await this.$API.generateCTO(obj);
        if (result.error) {
          this.$message.error(result.error)
          this.loadingCto = false;
          return
        }
        else {
          this.container.ctoDocument = result;
          this.container.ctoDocumentId = result.id;
          this.loadingCto = false;
        }

      } catch (e) {
        this.$message.error("An error ocurred :(");
        this.loadingCto = false;
      }
    },
    async getContractors() {
      this.loadingContractors = true;
      let result = await this.$API.getContractors();
      if (result) {
        this.contractors = result;
        this.loadingContractors = false;
      }
    },
    getStatusColor(status) {
      let find = this.transportStatuses.find((x) => x.text == status);
      return find ? find.color : "grey";
    },
    matchColor(itemStatus) {
      let hex = "";
      let result = this.statuses.find((status) => status.state === itemStatus);
      if (result) {
        hex = result.color;
      }
      return hex;
    },
    preadviseIcon(status, manaulyPreadvised) {
      if (!manaulyPreadvised) {
        switch (status) {
          case 'Submitted':
            return 'forward_to_inbox'
          case 'Confirmed':
            return 'check_circle_outline'
          case 'Cancelled':
            return 'cancel'
          case 'Error':
            return 'warning'
          default: return 'notifications'
        }
      }
      else {
        return 'notifications'
      }

    },
    preadviseStatusColor(status, manualyPreadvised) {
      if (!manualyPreadvised) {
        switch (status) {
          case 'Submitted':
            return 'blue'
          case 'Confirmed':
            return 'success'
          case 'Cancelled':
            return 'red'
          case 'Error':
            return 'orange'
          default: return 'grey'
        }
      }
      else {
        return 'grey'
      }
    },
    closeDialog() {
      this.change = false;
      this.milestones = [];
      this.legs = [];
      this.regime = null;
      this.loadedDistance = 0;
      this.$emit("close");
    },
    setChange() {
      this.change = true;
    },
    updateTransportStatus(status) {
      this.container.transportStatus = status;
      this.transportStatusChange = true;
      this.change = true;
    },
    setVGM() {
      this.change = true;
      if (this.container.loadOutReceived) {
        this.container.vgmWeight =
          parseInt(this.container.tareWeight) +
          parseInt(this.container.grossWeight);
      }
      console.log("setVgm", this.container.vgmWeight);
    },

    setPreadvised(preadvised) {
      this.change = true;
      this.container.manuallyPreadvised = preadvised;
      if (preadvised) {
        this.container.navisStatus = "Approved";
        this.container.preadviseStatus = 'Confirmed'

      } else {
        this.container.navisStatus = "";
        this.container.preadviseStatus = null

      }
    },
    async cancelPreadvise() {
      this.loadingPreadvise = true
      let result = await this.$API.cancelPreadvise(this.container.id, this.preadviseItem)
      console.log(result)
      this.container = { ...this.container, ...result.data }
      if (result.success) {
        this.$message.success("Successfully cancelled!")
      } else {
        this.$message.error(result.data.preadviseMessage)
      }
      this.loadingPreadvise = false
      this.preadviseKey++
    },
    async submitPreadvise() {
      this.loadingPreadvise = true
      let success = false;
      let updateContainerSuccess = false;
      if (this.container.containerNo) {
        success = await this.$API.checkContainerNumber(
          this.container.containerNo
        );
      } else {
        success = true;
      }
      let obj =
      {
        id: this.container.id,
        tareWeight: this.container.tareWeight,
        vgmWeight: this.container.vgmWeight,
        containerNo: this.container.containerNo,
        sealNo: this.container.sealNo,
        preadvised: this.container.preadvised,
        transporterId: this.container.transporterId,
        manuallyPreadvised: this.container.manuallyPreadvised,
        navisStatus: this.container.navisStatus,
        preadviseStatus: this.container.preadviseStatus,
        crossHaulTransporterId: this.container.crossHaulTransporterId,
        intermodalUpdate: true,
        dummyWeight: this.container.dummyWeight,
      }
      if (this.transportStatusChange == true) {
        obj.transportStatus = this.container.transportStatus
        this.transportStatusChange = false

      }
      if (success) {
        updateContainerSuccess = await this.$API.updateContainer(obj);
      }
      if (updateContainerSuccess) {
        let result = await this.$API.submitPreadvise(this.container.id, this.preadviseItem)
        this.container = { ...this.container, ...result.data }
        this.change = true
        if (result.success) {
          this.preadviseModal = false
          this.preadviseItem = {}
          this.$message.success("Successfully submitted!")
        } else {
          this.$message.error(result.data.preadviseMessage)
        }
      }
      else {
        this.$message.error("Container update failed");
      }

      this.loadingPreadvise = false
      this.preadviseKey++
    },

    async updateContainer() {
      this.savingChange = true;
      let status = this.container.transportStatus;
      let success = false;
      if (this.container.containerNo) {
        //validate container
        success = await this.$API.checkContainerNumber(
          this.container.containerNo
        );
        // console.log("success", success);
      } else {
        success = true;
      }

      let obj =
      {
        id: this.container.id,
        tareWeight: this.container.tareWeight,
        vgmWeight: this.container.vgmWeight,
        containerNo: this.container.containerNo,
        sealNo: this.container.sealNo,
        preadvised: this.container.preadvised,
        transporterId: this.container.transporterId,
        manuallyPreadvised: this.container.manuallyPreadvised,
        navisStatus: this.container.navisStatus,
        preadviseStatus: this.container.preadviseStatus,
        crossHaulTransporterId: this.container.crossHaulTransporterId,
        intermodalUpdate: true,
        dummyWeight: this.container.dummyWeight,
        tempSerialNo: this.container.tempSerialNo,
      }
      if (this.transportStatusChange == true) {
        obj.transportStatus = this.container.transportStatus
        this.transportStatusChange = false

      }
      if (success) {
        await this.$API.updateContainer(obj);
        this.$message.success("Successfully updated!");

        if (this.changeWeight) {
          this.savingChange = false;
          this.changeWeight = false
        }
        else {
          this.savingChange = false;
          this.closeDialog();
        }

      } else {
        this.$message.error("Container number is invalid");
        this.savingChange = false;
      }
    },
    updateTransporter() {
      this.transportStatusChange = true
      if (this.container.transporter) {
        this.container.transporterId = this.container.transporter.id;
        this.container.transporterName = this.container.transporter
          ? this.container.transporter.alias
            ? this.container.transporter.alias
            : this.container.transporter.friendlyName ??
            this.container.transporter.name
          : null;
      } else {
        this.container.transporterId = null;
        this.container.transporterName = null;
      }
      if (!this.container.transporterId) {
        this.container.transportStatus = "Pending";
      } else {
        this.container.transportStatus = "Allocated";
      }
      this.transportStatusChange = true
      this.change = true;
    },
    updateCrosshaulTransporter() {
      if (this.container.crossHaulTranporter) {
        this.container.crossHaulTransporterId =
          this.container.crossHaulTranporter.id;
        this.container.crossHaulTransporterName = this.container
          .crossHaulTranporter
          ? this.container.crossHaulTranporter.alias
            ? this.container.crossHaulTranporter.alias
            : this.container.crossHaulTranporter.friendlyName ??
            this.container.crossHaulTranporter.name
          : null;
      } else {
        this.container.crossHaulTransporterId = null;
        this.container.crossHaulTransporterName = null;
      }
      this.change = true;
    },
    viewCTO(item) {
      this.item = item;
      if (
        this.item.type &&
        (this.item.type.includes("image/") ||
          this.item.type.includes("application/pdf"))
      ) {
        this.imageLoading = true;
      }
      this.previewModal = true;
    },
    viewPreadvise() {
      this.preadviseItem = {
        bookingContainerId: this.container.id,
        solasUser: this.container.booking.shipper ? this.container.booking.shipper.solasAccreditationUser : null,
        solasAccount: this.container.booking.shipper ? this.container.booking.shipper.solasAccreditationNo : null
      }
      this.preadviseItem.ready = this.container.containerNo && this.container.sealNo && this.container.vgmWeight && this.container.booking.mainCarriageConveyanceNumber &&
        this.container.booking.vessel && this.container.FULL_DROP_OFF && this.container.FULL_DROP_OFF.poi && this.container.FULL_DROP_OFF.poi.locationCode && this.container.verificationMethod
        && (this.container.verificationMethod == 'Method 1' ? this.container.WEIGH_BRIDGE && this.container.WEIGH_BRIDGE.poi && this.container.WEIGH_BRIDGE.poi.friendlyName : true)
      if (this.container.verificationMethod == 'Method 1' && this.container.WEIGH_BRIDGE && this.container.WEIGH_BRIDGE.poi) {
        this.preadviseItem.solasUser = this.container.WEIGH_BRIDGE.poi.friendlyName ? this.container.WEIGH_BRIDGE.poi.friendlyName.toUpperCase() : this.container.WEIGH_BRIDGE.poi.name.toUpperCase()
        this.preadviseItem.solasAccount = null
      }

      this.preadviseModal = true
    }
    // matchLocation(itemStatus) {
    //     let result = this.statusLocations.find(status => status.name === itemStatus).value
    //     return result
    // },
  },
};
</script>

<style scoped>
.localClass .v-timeline-item__body {
  --timeline-right-slot: 27vw;
  min-width: var(--timeline-right-slot - 30%);
  max-width: var(--timeline-right-slot);
  width: var(--timeline-right-slot);
}

.localClass.v-timeline--dense ::v-deep .v-timeline-item__opposite {
  --timeline-left-slot: 9vw;
  display: inline-block;
  text-align: end;
  flex: none;
  width: var(--timeline-left-slot);
  min-width: var(--timeline-left-slot);
  max-width: var(--timeline-left-slot);
}

.v-application--is-ltr .localClass.v-timeline--dense:not(.v-timeline--reverse)::before,
.v-application--is-rtl .v-timeline--reverse.v-timeline--dense::before {
  left: calc(9vw + ((60px - 2px) / 2));
  width: 2px;
  height: 200%;
  right: auto;
}

.localClass ::v-deep .v-timeline-item__divider {
  min-width: 60px;
}

.map-marker::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  /* top: -5px; */
  bottom: -20px;
  left: 10px;
  border: 10px solid transparent;
  border-top: 17px solid black;
}
.custom-mb-2 {
  margin-bottom: -4px; /* or any other value you need */
}

</style>