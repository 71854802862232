import axios from "axios";
import store from "../store";
import dotenv from "dotenv";
import {goToRoute} from "../router.js";
import http from "http";
import https from "https";
const httpAgent = new http.Agent({ keepAlive: true });
const httpsAgent = new https.Agent({ keepAlive: true });

let requests = [];
dotenv.config();
// let Api = null;
let url =
  process.env.NODE_ENV === "production"
    ? "https://api.loglive.ai"
    : process.env.NODE_ENV === "staging"
      ? "https://api.loglive.ai"
      : "http://localhost:3000";
let Api = axios.create({
  baseURL: url,
  headers: {
    // Authorization: `${store.state.token}`,
  },
  httpAgent: httpAgent,
  httpsAgent: httpsAgent,
  maxContentLength: 100000000,
  maxBodyLength: 1000000000,
});

async function getAllRoutes() {
  let res = await Api.get("/route/all");
  return res.data;
}
Api.interceptors.request.use(async (config) => {
  if (store.state.token) config.headers.Authorization = `${store.state.token}`;
  if (store.state.orgToken) {
    config.headers.apikey = `${store.state.orgToken}`;
  }
  config.headers["Content-Type"]= "application/json"
  config.headers['Time-Zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
  return config;
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      (error && error.response && error.response.status === 403) ||
      (error.response && error.response.status === 401)
    ) {
      // let result = await getAllRoutes();
      // var router = await routerModule(result);
      await store.dispatch("setAuthState", "signedout");
      await store.dispatch("setToken", null);
      await store.dispatch("setUser", null);
      await store.dispatch("setTokenExpiry", null);
      await store.dispatch("setAuthState", null);
      await store.dispatch("setOrganisations", null);
      await store.dispatch("setCurrentOrg", null);
      await store.dispatch("setOrgToken", null);
      // router.push({ path: "/" });
      goToRoute({ path: "/" });
    } else if(error && error.response && error.response.data ){
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error.message);
    }
  }
);

export default {
  async getBillingOverview() {
    let res = await Api.get(`/billing/admin/overview`);
    return res.data;
  },
  async globalSearch(query) {
    let res = await Api.get(`/global/search`, query);
    return res.data;
  },
  async getMonthlyBillingBreakdown(year, month) {
    let res = await Api.get(`/billing/admin/${year}/${month}`);
    return res.data;
  },
  async getPlatformMetrics() {
    let res = await Api.get(`/admin/metrics`);
    return res.data;
  },
  async getCustomerUsageMetrics(organisationId) {
    let res = await Api.get(`/admin/customer/usage/${organisationId}`);
    return res.data;
  },
  async getCustomerUsageBreakdown(organisationId) {
    let res = await Api.get(`/admin/customer/breakdown/${organisationId}`);
    return res.data;
  },
  async getCustomerPlatformMetrics() {
    let res = await Api.get(`/admin/customer/metrics`);
    return res.data;
  },
  async downloadCustomerMetrics(key) {
    let res = await Api.get(`/admin/customer/metrics/download`, {
      params: {
        key,
      }
    });
    return res.data;
  },
  // Accounting
  async getAccountingContacts() {
    let res = await Api.get(`/accounting/contacts`);
    return res.data;
  },
  async createAccountingContact(obj) {
      let res = await Api.post(`/accounting/contacts`, obj);
      return res.data;
  },
  async createInvoice(obj) {
    let res = await Api.post(`/accounting/invoice`, obj);
    return res.data;
  }, 
  async updateInvoice(obj) {
    let res = await Api.post(`/accounting/invoice/update`, obj);
    return res.data;
  }, 
  async getInvoiceCredit(id) {
    let res = await Api.get('/accounting/invoice/credit/' + id)
    return res.data
  },
  async createCreditNote(obj) {
    let res = await Api.post(`/accounting/creditNote`, obj);
    return res.data;
  }, 
  async updateCreditNote(obj) {
    let res = await Api.post(`/accounting/creditNote/update`, obj);
    return res.data;
  }, 
  async getAccountingItems() {
    let res = await Api.get(`/accounting/items`);
    return res.data;
  },
  async getAccountingTaxTypes() {
    let res = await Api.get(`/accounting/taxTypes`);
    return res.data;
  },
  async getAccountingIntegration() {
    let res = await Api.get(`/accounting/integration`);
    return res.data;
  },
  // Activty Points
  async getOfficeActivityPoints() {
    let res = await Api.get(`/activityPoints/office`);
    return res.data;
  },

  // Audit Logs
  async createAuditLog(obj) {
    let res = await Api.post(`/auditLog`, obj);
    return res.data;
  },
  async getAuditLog(resource, id) {
    let res = await Api.get(`/auditLog/${resource}/${id}`);
    return res.data;
  },

  // Audit lines
  async getAddendumData(id) {
    let res = await Api.get("/ppecb/addendum/" + id);
    return res.data;
  },
  async getPPECBChecklist(obj) {
    let res = await Api.get("/ppecb/checklist", obj);
    return res.data;
  },
  async generatePPECBCheckList(obj) {
    let res = await Api.post("/ppecb/checklist", obj);
    return res.data;
  },
  async createActivityPointAuditLine(obj) {
    let res = await Api.post(`/ppecb/auditLines`, obj);
    return res.data;
  },
  async updateActivityPointAuditLine(obj) {
    let res = await Api.put(`/ppecb/auditLines/${obj.id}`, obj);
    return res.data;
  },
  async getPPECBMetrics() {
    let res = await Api.get(`/ppecb/metrics`);
    return res.data;
  },
  async getPPECBOffices() {
    let res = await Api.get(`/ppecb/offices`);
    return res.data;
  },
  async getActivityPointPPECBAuditLines(id) {
    let res = await Api.get(`/ppecb/auditLines/` + id);
    return res.data;
  },
  async getPPECBAuditLines() {
    let res = await Api.get(`/ppecb/auditLines`);
    return res.data;
  },
  async getPPECBVoyages() {
    let res = await Api.get(`/ppecb/voyages`);
    return res.data;
  },
  async confirmExportNotificationItems(data) {
    let res = await Api.post(`/ppecb/confirmBookingItems/` + data.bookingId, data);
    return res.data;
  },
  async getExportNotificationData(data) {
    let res = await Api.post(`/ppecb/exportNotification`, data);
    return res.data;
  },

  //Auth
  async AuthAdmin() {
    let res = await Api.post("/authentication/admin");
    return res.data;
  },
  async AuthSystemAdmin() {
    let res = await Api.post("/authentication/systemAdmin");
    return res.data;
  },
  async systemLogin(data) {
    let res = await Api.post("/authentication/login", data);
    return res.data;
  },
  async forgotPassword(data) {
    let res = await Api.post("/authentication/forgotPassword", data);
    return res.data;
  },
  async submitPasswordChange(data, params) {
    let res = await Api.post("/forgotPassword/submit", data, params);
    return res.data;
  },
  async updateUserToken(data) {
    let res = await Api.post("/api/auth/update", data);
    return res.data;
  },
  async authoriseScope(data) {
    let res = await Api.post("/auth/scopes", data);
    return res.data;
  },
  async getUserScope(data) {
    let res = await Api.post("/auth/scopes/user", data);
    return res.data;
  },
  async validateToken() {
    let res = await Api.get("/authentication/validate");
    return res.data;
  },
  async authGoogleUser(obj) {
    let res = await Api.post("/authentication/google", obj);
    return res.data;
  },
  async authGoogleRegisterUser(obj, params) {
    let res = await Api.post("/register/google", obj, params);
    return res.data;
  },

  async getGoogleUser(obj) {
    let res = await Api.post("/authentication/google/user", obj);
    return res.data;
  },

  async registerUser(obj, params) {
    let res = await Api.post("/user/register", obj, params);
    return res.data;
  },

  // Bookings
  async getBookings(obj) {
    let res = await Api.get("/booking", obj);
    return res.data;
  },
  async getAssignedStockBookings(obj) {
    let res = await Api.get("/booking-assigned", obj);
    return res.data;
  },
  async getOpenAssignedStockBookings(obj) {
    let res = await Api.get("/booking-assigned/open", obj);
    return res.data;
  },
  async getBookingRequests() {
    let res = await Api.get("/bookingRequest");
    return res.data;
  },
  async getBookingRequestCount() {
    let res = await Api.get("/bookingRequest/count");
    return res.data;
  },
  async acceptBookingRequest(id){
    let res = await Api.post("/bookingRequest/accept/"+id);
    return res.data;
  },
  async rejectBookingRequest(id){
    let res = await Api.post("/bookingRequest/reject/"+id);
    return res.data;
  },
  async getUpcomingBookings(obj) {
    let res = await Api.get("/booking/upcoming", obj);
    return res.data;
  },

  async getBookingOverview(obj) {
    let res = await Api.get("/bookingOverview", obj);
    return res.data;
  },
  async bookingOverviewUpdated(obj) {
    let res = await Api.get("/bookingOverviewUpdated", obj);
    return res.data;
  },  
  async getBookignShipmentOverview(obj) {
    let res = await Api.get("/bookingShipmentOverview", obj);
    return res.data;
  },
  async getBookingContainerOverviews(obj) {
    let res = await Api.get("/booking/containerOverview", obj);
    return res.data
  },
  async getBookingContainerOverviewUpdated(obj) {
    let res = await Api.get("/booking/containerOverviewUpdated", obj);
    return res.data
  },

  
  async getBookingOverviewStatus(obj) {
    let res = await Api.get("/booking/overview/status", obj);
    return res.data;
  },
  async syncBookings() {
    let res = await Api.get("/booking/sync");
    return res.data;
  },
  async quickSearchBookings(obj) {
    let res = await Api.get("/booking/quickSearchBookings", obj);
    return res.data;
  },
  async getBookingUserCustom() {
    let res = await Api.get("/booking/customLayout");
    return res.data;
  },
  async saveBookingCustomLayout(obj) {
    let res = await Api.post("/booking/saveCustomLayout", obj);
    return res.data;
  },
  async updateBookingContainers(obj) {
    let res = await Api.put(`/booking/containersUpdate`, obj);
    return res.data;
  },
  async updateBookingContents(obj) {
    let res = await Api.put(`/booking/contentsUpdate`, obj);
    return res.data;
  },
  async updateContainerStatus(obj) {
    let res = await Api.put(`/booking/containerStatus`, obj);
    return res.data;
  },
  async getBookingBulkDownload(obj) {
    let res = await Api.post("/booking/bulkDownload", obj);
    return res.data;
  },

  // Bundles
  async getBundles() {
    let res = await Api.get("/bundles");
    return res.data;
  },
  async updateBundle(obj) {
    let res = await Api.put("/bundles/" + obj.id, obj);
    return res.data;
  },
  async createBundle(obj) {
    let res = await Api.post("/bundles", obj);
    return res.data;
  },

  // Commodity
  async getCommodity(code) {
    let res = await Api.get(`/commodity/${code}`);
    return res.data;
  },

  // Consignment Notes
  async getConsignmentNotes(obj) {
    let res = await Api.get(`/consignmentNotes`, obj);
    return res.data;
  },
  async getConsignmentSSCC(obj) {
    let res = await Api.get(`/consignmentNotes/sscc`, obj);
    return res.data;
  },
  async getCorruptUploads(obj) {
    let res = await Api.get(`/consignmentNotes/corrupt`, obj);
    return res.data;
  },
  async uploadConsignmentNotes(obj) {
    let res = await Api.post(`/upload/consignmentNote`, obj);
    return res.data;
  },
  async getConsignmentNotePalletData(id) {
    let res = await Api.get(`/consignmentNote/` + id);
    return res.data;
  },
  // Consignee Profile
  async getConsigneeProfile(id) {
    let res = await Api.get("/consigneeProfile/" + id);
    return res.data;
  },
  async getConsigneeProfileReference(reference) {
    let res = await Api.get("/consigneeProfile/reference/" + reference);
    return res.data;
  },
  async listConsigneeProfile(obj) {
    let res = await Api.get("/consigneeProfile", obj);
    return res.data;
  },
  async listCustomerConsigneeProfile(id, obj) {
    let res = await Api.get("/consigneeProfile/customer/" + id, obj);
    return res.data;
  },
  async listCreateBookingFileConsigneeProfiles(obj) {
    let res = await Api.post("/consigneeProfile/booking/createFile", obj);
    return res.data;
  },
  async listDashbaordCustomerConsigneeProfiles(obj) {
    let res = await Api.post("/consigneeProfile/customer/dashboard", obj);
    return res.data;
  },
  async getShipmentProfiles(obj) {
    let res = await Api.get("/shipmentProfiles", obj);
    return res.data;
  },
  async getShipmentProfile(id) {
    let res = await Api.get(`/shipmentProfile/${id}`);
    return res.data;
  },

  async updateConsigneeProfile(obj) {
    let res = await Api.put("/consigneeProfile/" + obj.id, obj);
    return res.data;
  },
  async createConsigneeProfile(obj) {
    let res = await Api.post("/consigneeProfile", obj);
    return res.data;
  },

  // Consignee Profile Items
  async getConsigneeProfileDocuments(id) {
    let res = await Api.get("/consigneeProfile/documents/" + id);
    return res.data;
  },
  async getConsigneeProfileDocComments(id) {
    let res = await Api.get("/consigneeProfile/documents/comments/" + id);
    return res.data;
  },
  async getConsigneeProfileForms(id) {
    let res = await Api.get("/consigneeProfile/forms/" + id);
    return res.data;
  },
  async getConsigneeProfilePorts(id) {
    let res = await Api.get("/consigneeProfile/ports/" + id);
    return res.data;
  },
  async getConsigneeProfileContracts(id) {
    let res = await Api.get("/consigneeProfile/contracts/" + id);
    return res.data;
  },
  async getConsigneeProfileDistributionList(id) {
    let res = await Api.get("/consigneeProfile/distributionList/" + id);
    return res.data;
  },
  async getConsigneeProfileIncoTerms(id) {
    let res = await Api.get("/consigneeProfile/incoTerms/" + id);
    return res.data;
  },
  async getConsigneeProfileSubscribers(id) {
    let res = await Api.get("/consigneeProfile/subscribers/" + id);
    return res.data;
  },
  async getConsigneeProfileRelationships(id) {
    let res = await Api.get("/consigneeProfile/relationship/" + id);
    return res.data;
  },
  async getConsigneeProfileShareSetting(id) {
    let res = await Api.get("/consigneeProfile/share/" + id);
    return res.data;
  },
  async updateConsigneeProfileShareSetting(profileId, obj) {
    let res = await Api.put("/consigneeProfile/share/"+ profileId+'/'+ obj.id, obj);
    return res.data;
  },
  async createConsigneeProfileShareSetting(obj) {
    let res = await Api.post("/consigneeProfile/share/" + obj.consigneeProfileId, obj);
    return res.data;
  },

  async getConsigneeProfileProducts(id) {
    let res = await Api.get("/consigneeProfile/product/" + id);
    return res.data;
  },
  async updateConsigneeProfileDocument(obj) {
    let res = await Api.put("/consigneeProfile/documents/" + obj.id, obj);
    return res.data;
  },
  async updateConsigneeProfileForm(obj) {
    let res = await Api.put("/consigneeProfile/forms/" + obj.id, obj);
    return res.data;
  },
  async updateConsigneeProfilePort(obj) {
    let res = await Api.put("/consigneeProfile/ports/" + obj.id, obj);
    return res.data;
  },
  async updateConsigneeProfileContract(obj) {
    let res = await Api.put("/consigneeProfile/contracts/" + obj.id, obj);
    return res.data;
  },
  async updateConsigneeProfileIncoTerm(obj) {
    let res = await Api.put("/consigneeProfile/incoTerms/" + obj.id, obj);
    return res.data;
  },
  async updateConsigneeProfileDistributionList(obj) {
    let res = await Api.put("/consigneeProfile/distribution/" + obj.id, obj);
    return res.data;
  },
  async updateConsigneeProfileSubscriber(obj) {
    let res = await Api.put("/consigneeProfile/subscribers/" + obj.id, obj);
    return res.data;
  },
  async updateConsigneeProfileRelationship(obj) {
    let res = await Api.put("/consigneeProfile/relationship/" + obj.id, obj);
    return res.data;
  },
  async updateConsigneeProfileProduct(obj) {
    let res = await Api.put("/consigneeProfile/product/" + obj.id, obj);
    return res.data;
  },
  async createConsigneeProfileDocument(obj) {
    let res = await Api.post("/consigneeProfile/documents", obj);
    return res.data;
  },
  async createConsigneeProfileForm(obj) {
    let res = await Api.post("/consigneeProfile/forms", obj);
    return res.data;
  },
  async createConsigneeProfilePort(obj) {
    let res = await Api.post("/consigneeProfile/ports", obj);
    return res.data;
  },
  async createConsigneeProfileContract(obj) {
    let res = await Api.post("/consigneeProfile/contracts", obj);
    return res.data;
  },
  async createConsigneeProfileIncoTerm(obj) {
    let res = await Api.post("/consigneeProfile/incoTerms", obj);
    return res.data;
  },
  async createConsigneeProfileDistributionList(obj) {
    let res = await Api.post("/consigneeProfile/distribution", obj);
    return res.data;
  },
  async createConsigneeProfileSubscriber(obj) {
    let res = await Api.post("/consigneeProfile/subscribers", obj);
    return res.data;
  },
  async createConsigneeProfileRelationship(obj) {
    let res = await Api.post("/consigneeProfile/relationship", obj);
    return res.data;
  },
  async createConsigneeProfileProduct(obj) {
    let res = await Api.post("/consigneeProfile/product", obj);
    return res.data;
  },
  // Container Pallet
  async updatePallet(obj) {
    let res = await Api.put("/containerPallet/" + obj.id, obj);
    return res.data;
  },
  async bulkUpdatePallets(obj) {
    let res = await Api.put("/containerPallet", obj);
    return res.data;
  },

  // Contracts
  async createContract(obj) {
    let res = await Api.post(`/contract`, obj);
    return res.data;
  },
  async updateContract(obj) {
    let res = await Api.put(`/contract/${obj.id}`, obj);
    return res.data;
  },
  async listPartyOptions(type) {
    let res = await Api.get(`/contracts/partyOptions/${type}`);
    return res.data;
  },
  async listCustomerOptions() {
    let res = await Api.get(`/contracts/customerOptions`);
    return res.data;
  },
  // Comments

  async getConsigneeProfileComment(id) {
    let res = await Api.get("/consigneeProfile/comment/" + id);
    return res.data;
  },
  async createConsigneeProfileComment(obj) {
    let res = await Api.post("/consigneeProfile/comment", obj);
    return res.data;
  },
  async updateConsigneeProfileComment(obj) {
    let res = await Api.put("/consigneeProfile/comment/" + obj.id, obj);
    return res.data;
  },

  // Currency
  async getCurrencyInfo() {
    let res = await Api.get("/currency");
    return res.data;
  },

  // Customers
  async getCustomers(obj) {
    let res = await Api.get("/customers", obj);
    return res.data;
  },
  async getSelectedCustomer(id) {
    let res = await Api.get("/customers/" + id);
    return res.data;
  },
  async getCompanyContacts(id) {
    let res = await Api.get("/customers/contacts/" + id);
    return res.data;
  },




// Working on this API call 

  async getSelectedCustomerRelationship(id) {
    let res = await Api.get("/customers/relationship/" + id);
    return res.data;
  },






  async addRelationshipRole(obj) {
    let res = await Api.post("/relationship/user", obj);
    return res.data;
  },
  async getCustomerRoles(id) {
    let res = await Api.get("/customers/roles/" + id);
    return res.data;
  },
  async getCustomerRelationships(id) {
    let res = await Api.get("/customer/related/" + id);
    return res.data;
  },
  async getShipmentCustomerRelationships(id) {
    let res = await Api.get("/shipment/customer/related/" + id);
    return res.data;
  },
  async updateCustomerStatus(obj) {
    let res = await Api.put(`/customers/relationship/${obj.id}`, obj);
    return res.data;
  },
  async getOrganisationRelationshipCountrySetting(id, countryCode) {
    let res = await Api.get(`/relationship/setting/${id}/${countryCode}`);
    return res.data;
  },
  async saveOrganisationCountrySettings(obj) {
    let res = await Api.put("/relationship/setting/" + obj.id, obj);
    return res.data;
  },

  async getRelationshipBillingItems(id) {
    let res = await Api.get("/relationship/billingItems/" + id);
    return res.data;
  },
  async updateRelationshipBillingItem(obj) {
    let res = await Api.put("/relationship/billingItems/" + obj.id, obj);
    return res.data;
  },
  async createRelationshipBillingItem(obj) {
    let res = await Api.post("/relationship/billingItems", obj);
    return res.data;
  },
  async addOrganisationRelationshipUser(obj) {
    let res = await Api.post("/relationship/user", obj);
    return res.data;
  },
  async updateOrganisationRelationshipUser(obj) {
    let res = await Api.put("/relationship/user/" + obj.id, obj);
    return res.data;
  },
  async addOrganisationRelationshipCountry(obj) {
    let res = await Api.post("/relationship/country", obj);
    return res.data;
  },
  async updateOrganisationRelationshipCountry(obj) {
    let res = await Api.put("/relationship/country/" + obj.id, obj);
    return res.data;
  },
  async addOrganisationRelationshipProduct(obj) {
    let res = await Api.post("/relationship/product", obj);
    return res.data;
  },
  async updateOrganisationRelationshipProduct(obj) {
    let res = await Api.put("/relationship/product/" + obj.id, obj);
    return res.data;
  },
  async addOrganisationRelationshipTransport(obj) {
    let res = await Api.post("/relationship/transport", obj);
    return res.data;
  },
  async updateOrganisationRelationshipTransport(obj) {
    let res = await Api.put("/relationship/transport/" + obj.id, obj);
    return res.data;
  },

  // Document Signing Usage
  async getDocumentSigningUsage() {
    let res = await Api.get("/documentSigning/usage");
    return res.data;
  },
  // Suppliers
  async getSuppliers(obj) {
    let res = await Api.get("/suppliers", obj);
    return res.data;
  },

  // essCert
  async getEssCertConsignees() {
    let res = await Api.get("/essCert/consignees");
    return res.data;
  },
  async getEssCertExporters() {
    let res = await Api.get("/essCert/exporters");
    return res.data;
  },

  // Exceptions
  async getExceptions(params) {
    let res = await Api.get("/exceptions", params);
    return res.data;
  },
  async updateAffectedPOI(data) {
    let res = await Api.post("/exceptions/location", data);
    return res.data;
  },
  async updateAffectedProduct(data) {
    let res = await Api.post("/exceptions/product", data);
    return res.data;
  },
  async updateAffectedVariety(data) {
    let res = await Api.post("/exceptions/variety", data);
    return res.data;
  },

  // FBO Codes
  async getFbo(obj) {
    let res = await Api.get("/fbo", obj);
    return res.data;
  },
  async createFBO(obj) {
    let res = await Api.post("/fbo", obj);
    return res.data;
  },
  async updateFBO(obj) {
    let res = await Api.put("/fbo/" + obj.id, obj);
    return res.data;
  },
  // Finance
  async getAllFinanceCategories() {
    let res = await Api.get("/finance/categories");
    return res.data;
  },
  async createFinanceCategory(obj) {
    let res = await Api.post("/finance/categories", obj);
    return res.data;
  },
  async updateFinanceCategory(obj) {
    let res = await Api.put(`/finance/categories/${obj.id}`, obj);
    return res.data;
  },

  async getAllFinanceSubCategories() {
    let res = await Api.get("/finance/subcategories");
    return res.data;
  },
  async createFinanceSubCategory(obj) {
    let res = await Api.post("/finance/subcategories", obj);
    return res.data;
  },
  async updateFinanceSubCategory(obj) {
    let res = await Api.put(`/finance/subcategories/${obj.id}`, obj);
    return res.data;
  },

  async getFinanceChargeItems() {
    let res = await Api.get("/finance/items");
    return res.data;
  },
  async createFinanceChargeItem(obj) {
    let res = await Api.post("/finance/items", obj);
    return res.data;
  },
  async updateFinanceChargeItem(obj) {
    let res = await Api.put(`/finance/items/${obj.id}`, obj);
    return res.data;
  },
  async getAdminFinanceChargeItems() {
    let res = await Api.get("/finance/admin/items");
    return res.data;
  },
  async createAdminFinanceChargeItem(obj) {
    let res = await Api.post("/finance/admin/items", obj);
    return res.data;
  },
  async updateAdminFinanceChargeItem(obj) {
    let res = await Api.put(`/finance/admin/items/${obj.id}`, obj);
    return res.data;
  },
  async getFinanceChargeItemArea(id) {
    let res = await Api.get("/finance/items/area/" + id);
    return res.data;
  },
  async createFinanceChargeItemArea(obj) {
    let res = await Api.post("/finance/items/area", obj);
    return res.data;
  },
  async updateFinanceChargeItemArea(obj) {
    let res = await Api.put(`/finance/items/area/${obj.id}`, obj);
    return res.data;
  },
  // Forecast
  async createForecast(data){
    let res = await Api.post("/forecast",data);
    return res.data;
  },
  async updateForecast(data){
    let res = await Api.put("/forecast/"+data.id,data);
    return res.data;
  },
  async getForecast(data){
    let res = await Api.get("/forecast",data);
    return res.data;
  },
  async createForecastLineItem(data){
    let res = await Api.post("/forecast/item",data);
    return res.data;
  },
  async updateForecastLineItem(data){
    let res = await Api.put("/forecast/item/"+data.id,data);
    return res.data;
  },
  // Forms
  async getForms() {
    let res = await Api.get("/forms");
    return res.data;
  },
  async updateForm(obj) {
    let res = await Api.put("/forms/" + obj.id, obj);
    return res.data;
  },
  async createForm(obj) {
    let res = await Api.post("/forms", obj);
    return res.data;
  },
  async updateFormItem(obj) {
    let res = await Api.put("/formItem/" + obj.id, obj);
    return res.data;
  },
  async createFormItem(obj) {
    let res = await Api.post("/formItem", obj);
    return res.data;
  },
  async updateFormItemOption(obj) {
    let res = await Api.put("/formItemOption/" + obj.id, obj);
    return res.data;
  },
  async createFormItemOption(obj) {
    let res = await Api.post("/formItemOption", obj);
    return res.data;
  },
  
  // FTP
  // async createFTPCredentials(data) { // DUD
  //   let res = await Api.post("/ftp/credentials", data);
  //   return res.data;
  // },
  async updateFTPCredentials(data) {
    let res = await Api.put("/ftp/credentials/" + data.id, data);
    return res.data;
  },
  async getFTPGrabIPAddress() {
    let res = await Api.get("/ftp/getIPAddress/");
    return res.data;
  },
  // async getFTPCredentials() { // DUD
  //   let res = await Api.get("/ftp/credentials");
  //   return res.data;
  // },
  async createFTPFolder(data) {
    let res = await Api.post("/ftp/folder", data);
    return res.data;
  },
  async updateFTPFolder(data) {
    let res = await Api.put("/ftp/folder", data);
    return res.data;
  },
  async deleteFTPFolder(data) {
    let res = await Api.post("/ftp/folder/remove", data);
    return res.data;
  },
  async createFTPFolderConfig(data) {
    let res = await Api.post("/ftp/folder/config", data);
    return res.data;
  },
  async getFTPFolderConfig(path, signal) {
    let res = await Api.get("/ftp/folder/config", {
      signal,
      params: {
        path
      }
    });
    return res.data;
  },
  async getFTPChildFolders(data) {
    let res = await Api.put("/ftp/folder/childFolders", data);
    return res.data;
  },
  async getFTPConfig() {
    let res = await Api.get("/ftp/config");
    return res.data;
  },
  async getFTPRoutes(query) {
    let res = await Api.get("/ftp/list", query);
    return res.data;
  },
  async generateFTPUsername(data) {
    let res = await Api.post("/ftp/credentials/newUsername", data);
    return res.data;
  },

  // SFTPGo
  async createSFTPGoUser(data) {
    let res = await Api.post("/sftpgo/user", data);
    return res.data;
  },
  async updateSFTPGoUser(data) {
    let res = await Api.put("/sftpgo/user/update", data);
    return res.data;
  },
  async deleteSFTPGoUser(data) {
    let res = await Api.put("/sftpgo/user/delete", data);
    return res.data;
  },
  
  // HS Codes
  async getHSCodes(obj) {
    let res = await Api.get("/hsCode", obj);
    return res.data;
  },
  async getHSCodeSections(obj) {
    let res = await Api.get("/hsCode/section", obj);
    return res.data;
  },
  async getAllHSCodeSubSections() {
    let res = await Api.get("/hsCode/subsections");
    return res.data;
  },
  async getHSCodeSubSections(section) {
    let res = await Api.get("/hsCode/subsections/" + section);
    return res.data;
  },
  async createHSCodeProduct(obj) {
    let res = await Api.post("/hsCode/product", obj);
    return res.data;
  },

  // Notifications
  async getNotifications(obj) {
    let res = await Api.get("/notifications", obj);
    return res.data;
  },
  async createEmailNotification(template, obj) {
    let res = await Api.post("/notifications/" + template, obj);
    return res.data;
  },
  async getNotificationTotals(obj) {
    let res = await Api.get("/notifications/totals", obj);
    return res.data;
  },
  async updateNotification(obj) {
    let res = await Api.put("/notifications/" + obj.id, obj);
    return res.data;
  },
  async getUserNotificationSettings() {
    let res = await Api.get("/notifications/settings");
    return res.data;
  },

  async updateUserNotificationSetting(obj) {
    let res = await Api.put("/notifications/settings/" + obj.id, obj);
    return res.data;
  },

  async createUserNotificationSetting(array) {
    let res = await Api.post("/notifications/settings", array);
    return res.data;
  },
  async emailLoadOutInstructions(obj){
    let res = await Api.post("/stock/emailLoadOutInstructions", obj);
    return res.data;
  },
  async emailBulkLoadOutInstructions(obj){
    let res = await Api.post("/stock/emailBulkLoadOutInstructions", obj);
    return res.data;
  },
  // Notification Templates
  async getUserNotificationTemplates() {
    let res = await Api.get("/notificationTemplates/user");
    return res.data;
  },
  async getNotificationTemplates(obj) {
    let res = await Api.get("/notificationTemplates", obj);
    return res.data;
  },
  async createNotificationTemplate(obj) {
    let res = await Api.post("/notificationTemplates", obj);
    return res.data;
  },
  async updateNotificationTemplate(obj) {
    let res = await Api.put("/notificationTemplates/" + obj.id, obj);
    return res.data;
  },

  // Organisations
  async listHauliers() {
    let res = await Api.get("/hauliers");
    return res.data;
  },
  async listOrganisationsAdmin(obj) {
    let res = await Api.get("/organisation/admin", obj);
    return res.data;
  },
  async createOrganisationAdmin(obj) {
    let res = await Api.post("/organisation/admin", obj);
    return res.data;
  },
  async searchGlobalOrganisation(obj) {
    let res = await Api.get("/organisation/globalDirectory", obj);
    return res.data;
  },
  async getVesselOperators() {
    let res = await Api.get("/organisation/type/vesselOperator");
    return res.data;
  },
  async getShippingLines() {
    let res = await Api.get("/organisation/type/shippingLines");
    return res.data;
  },
  async createOrganisation(obj) {
    let res = await Api.post("/organisation", obj);
    return res.data;
  },
  // async createLLCode(obj) {
  //   let res = await Api.post("/organisation/logliveCode", obj);
  //   return res.data;
  // },
  async updateOrganisationAdmin(obj) {
    let res = await Api.put("/organisation/admin/" + obj.id, obj);
    return res.data;
  },
  async getOrganisationAdmin(id) {
    let res = await Api.get("/organisation/" + id);
    return res.data;
  },
  async getRelevantShippers(id) {
    let res = await Api.get("/organisation/relevant/shippers/" + id);
    return res.data;
  },
  async updateOrganisation(obj) {
    let res = await Api.put("/organisation", obj);
    return res.data;
  },
  async getOrganisation() {
    let res = await Api.get("/organisation");
    return res.data;
  },
  async getOrganisationNetworkFunctions() {
    let res = await Api.get("/organisation/networkFunctions");
    return res.data;
  },
  async getOrganisationNetworkFunctionsAdmin() {
    let res = await Api.get("/organisation/networkFunctions/admin");
    return res.data;
  },

  async getSubsidaryOrganisations() {
    let res = await Api.get("/organisation/subsidary");
    return res.data;
  },

  async getOrganisationDetails(obj) {
    let res = await Api.get("/organisation/details", obj);
    return res.data;
  },

  // Organisation Address

  async updateOrganisationAdminAddress(obj) {
    let res = await Api.put("/organisationAddress/admin/" + obj.id, obj);
    return res.data;
  },
  async createOrganisationAdminAddress(obj) {
    let res = await Api.post("/organisationAddress/admin", obj);
    return res.data;
  },

  async updateOrganisationAddress(obj) {
    let res = await Api.put("/organisationAddress/" + obj.id, obj);
    return res.data;
  },
  async createOrganisationAddress(obj) {
    let res = await Api.post("/organisationAddress", obj);
    return res.data;
  },
  async getOrganisationAddress() {
    let res = await Api.get("/organisationAddress");
    return res.data;
  },
  async getOrganisationUserAddress() {
    let res = await Api.get("/organisationUser/address");
    return res.data;
  },
  async getSingleOrganisionAdress(id) {
    let res = await Api.get("/organisationAddress/" + id);
    return res.data;
  },
  async getSingleAdress(id) {
    let res = await Api.get("/singleAdress/" + id);
    return res.data;
  },
  async listOrganisationAddresses(id) {
    let res = await Api.get("/listOrganisationAddresses/" + id);
    return res.data;
  },

  // Organisation Modules

  async createOrganisationModule(obj) {
    let res = await Api.post("/organisationModule", obj);
    return res.data;
  },
  async updateOrganisationModule(obj) {
    let res = await Api.put("/organisationModule/" + obj.id, obj);
    return res.data;
  },
  async getOrganisationModuleAdmin(id) {
    let res = await Api.get("/organisationModule/" + id);
    return res.data;
  },

  // Organisation Tags
  async createOrganisationTag(tag) {
    let res = await Api.post("/organisationTag", tag);
    return res.data;
  },
  async updateOrganisationTag(tag) {
    let res = await Api.put("/organisationTag/" + tag.id, tag);
    return res.data;
  },

  // Organisation Users

  async createOrganisationUserAdmin(obj) {
    let res = await Api.post("/organisationUser/admin", obj);
    return res.data;
  },
  async createOrganisationUser(obj) {
    let res = await Api.post("/organisationUser", obj);
    return res.data;
  },
  async createRelatedOrganisationUser(obj) {
    let res = await Api.post("/organisationUser/related", obj);
    return res.data;
  },
  async updateOrganisationUser(obj) {
    let res = await Api.put("/organisationUser/" + obj.id, obj);
    return res.data;
  },
  async updateOrganisationUserAdmin(obj) {
    let res = await Api.put(`/organisationUser/admin/${obj.id}`, obj);
    return res.data;
  },
  async getOrganisationUsersAdmin(id) {
    let res = await Api.get("/organisationUser/admin/" + id);
    return res.data;
  },
  async getOrganisationUsers() {
    let res = await Api.get("/organisationUser");
    return res.data;
  },
  async getSharedContacts(obj) {
    let res = await Api.get("/organisationUser/sharedContacts", obj);
    return res.data;
  },

  // Ports
  async getPorts(query) {
    let res = await Api.get(`/ports`, query);
    return res.data;
  },
  async getPortInfo(id, locode) {
    let res = await Api.get(`/ports/info/${id}`);
    return res.data;
  },
  async createFavouritePort(obj) {
    let res = await Api.post(`/ports/favourite`, obj);
    return res.data;
  },
  async createOrgFavouritePort(obj) {
    let res = await Api.post(`/ports/favourite/org`, obj);
    return res.data;
  },
  async getSuggestedPorts() {
    let res = await Api.get(`/ports/suggested`);
    return res.data;
  },
  async updateFavouritePort(obj) {
    let res = await Api.put(`/ports/favourite/` + obj.id, obj);
    return res.data;
  },
  async createFavouriteRoute(obj) {
    let res = await Api.post(`/routes/favourite`, obj);
    return res.data;
  },
  async getFavouriteRoutes() {
    let res = await Api.get(`/routes/favourite`);
    return res.data;
  },
  async updateFavouriteRoute(obj) {
    let res = await Api.put(`/routes/favourite/` + obj.id, obj);
    return res.data;
  },
  async updateFavouriteRoutePorts(id, obj) {
    let res = await Api.post(`/routes/favourite/ports/${id}`, obj);
    return res.data;
  },
  async updateOrgFavouritePort(obj) {
    let res = await Api.put(`/ports/favourite/org/` + obj.id, obj);
    return res.data;
  },
  async getFavouritePorts() {
    let res = await Api.get(`/ports/favourite`);
    return res.data;
  },
  async getPortsByCountry(countryCode) {
    let res = await Api.get(`/ports/byCountry/${countryCode}`);
    return res.data;
  },
  async createPort(obj) {
    let res = await Api.post(`/ports`, obj);
    return res.data;
  },
  async updatePort(obj) {
    let res = await Api.put(`/ports/${obj.id}`, obj);
    return res.data;
  },

  // Integrations
  async getIntegrations() {
    let res = await Api.get("/integration");
    return res.data;
  },
  async getAllAdminCountryIntegrations() {
    let res = await Api.get(`/admin/integration/countryRegion`);
    return res.data;
  },
  async getAdminCountryIntegrations(countryId) {
    let res = await Api.get(`/admin/integration/countryRegion/${countryId}`);
    return res.data;
  },
  async createAdminCountryIntegration(data) {
    let res = await Api.post(`/admin/integration/countryRegion`, data);
    return res.data;
  },
  async updateAdminCountryIntegration(data) {
    let res = await Api.put(
      `/admin/integration/countryRegion/${data.id}`,
      data
    );
    return res.data;
  },
  async updateIntegrationForms(id, data) {
    let res = await Api.put(`/integration/forms/${id}`, data);
    return res.data;
  },
  async getOAuthURL(code){
    let res = await Api.get(`/integration/oauth/${code}`);
    return res.data;
  },
  async getCountryIntegrations(countryId) {
    let res = await Api.get(`/integration/countryRegion/${countryId}`);
    return res.data;
  },
  async getAvailableIntegrations(countryId) {
    let res = await Api.get(`/integration/available/${countryId}`);
    return res.data;
  },
  async getIntegrationsByCountry(code) {
    let res = await Api.get(`/integration/country/${code}`);
    return res.data;
  },
  async updateIntegration(obj) {
    let res = await Api.put(`/integration/${obj.id}`, obj);
    return res.data;
  },
  async updateAuthIntegration(obj) {
    let res = await Api.post("/integration/auth/update", obj);
    return res.data;
  },
  async createIntegration(obj) {
    let res = await Api.post("/integration", obj);
    return res.data;
  },
  async createAuthIntegration(obj) {
    let res = await Api.post("/integration/auth", obj);
    return res.data;
  },
  async getIntegrationsAdmin(organisationId) {
    let res = await Api.get(`/integration/admin/${organisationId}`);
    return res.data;
  },
  async updateIntegrationAdmin(obj) {
    let res = await Api.put(`/integration/admin/${obj.id}`, obj);
    return res.data;
  },
  async createIntegrationAdmin(organisationId, obj) {
    let res = await Api.post(`/integration/admin/${organisationId}`, obj);
    return res.data;
  },
  async deleteIntegration(id) {
    let res = await Api.post(`/integration/delete/${id}`);
    return res.data;
  },
  async deleteAuthIntegration(id) {
    let res = await Api.post(`/integration/auth/delete/${id}`);
    return res.data;
  },
  async getIntegrationByID(id) {
    let res = await Api.get(`/integration/getBy/${id}`);
    return res.data;
  },
  async getIntegrationAuthByID(id) {
    let res = await Api.get(`/integration/auth/getBy/${id}`);
    return res.data;
  },
  async generateIntegrationDocument(locationDocumentId, containerId) {
    let res = await Api.post(
      `/integration/container/${locationDocumentId}/${containerId}`
    );
    return res.data;
  },
  async executeDocument(documentationCode, taskId, data) {
    let res = await Api.post(
      `/integration/manual/${documentationCode}/${taskId}`,
      data
    );
    return res.data;
  },
  async executeInspection(inspectionCode, taskId, inspectionResultId, data) {
    let res = await Api.post(
      `/integration/inspection/${inspectionCode}/${taskId}/${inspectionResultId}`,
      data
    );
    return res.data;
  },
  async excecutePPECBPhytClean(
    inspectionCode,
    taskId,
    inspectionResultId,
    data
  ) {
    let res = await Api.post(
      `/integration/inspection/phytclean/${inspectionCode}/${taskId}/${inspectionResultId}`,
      data
    );
    return res.data;
  },
  async executeIntegration(integrationCode, taskId, data, params={}) {
    let res = await Api.post(`/integration/${integrationCode}/${taskId}`, data,{
      params
    });
    return res.data;
  },
  async executeEvent(shipmentEventId, code, shipmentId) {
    let res = await Api.post(`/integration/event/${shipmentEventId}/${code}`, {
      shipmentId: shipmentId,
    });
    return res.data;
  },

  async searchBookingContainer(obj) {
    let res = await Api.get(`/container`, obj);
    return res.data;
  },
  //TruckU
  async getAvailableConstituents() {
    let res = await Api.get("/truck/units/available");
    return res.data;
  },
  async getDrivers() {
    let res = await Api.get("/truck/drivers");
    return res.data;
  },
  async getDriverDocs(id) {
    let res = await Api.get(`/truck/drivers/docs/${id}`);
    return res.data;
  },
  async getVehicles() {
    let res = await Api.get("/truck/vehicles");
    return res.data;
  },
  async getVehiclesByDriverId(id) {
    let res = await Api.get(`/truck/vehicles/${id}`);
    return res.data;
  },
  async getDriverUnits(id) {
    let res = await Api.get(`/truck/drivers/${id}`);
    return res.data;
  },
  async getTransportUnits() {
    let res = await Api.get("/truck/transportUnits");
    return res.data;
  },
  async getJobCountByOrg() {
    let res = await Api.get("/truck/jobs/count");
    return res.data;
  },
  async getTransportBookingItems(obj) {
    let res = await Api.get("/truck/jobs/all", obj);
    return res.data;
  },
  async getReportCustom(id) {
    let res = await Api.get(`/truck/jobs/reportCustom/${id}`);
    return res.data;
  },
  async saveReportCustom(obj) {
    let res = await Api.post("/truck/jobs/reportCustom", obj);
    return res.data;
  },
  async updateBookingStatus(obj) {
    let res = await Api.put("/truck/jobs/statusUpdate", obj);
    return res.data;
  },
  async createContainerHaulage(obj) {
    let res = await Api.post("/truck/jobs/createContainerHaulage", obj);
    return res.data;
  },
  async removeHaulageDriver(obj) {
    let res = await Api.post("/truck/jobs/removeDriver", obj);
    return res.data;
  },
  async getJobsByOrg(obj) {
    let res = await Api.get("/truck/jobs/org", obj);
    return res.data;
  },
  async getEmptyPickups() {
    let res = await Api.get("/truck/jobs/emptyPickup");
    return res.data;
  },
  async getAvailableUnits() {
    let res = await Api.get("/truck/jobs/units");
    return res.data;
  },
  async getJobs(params) {
    let res = await Api.get("/truck/jobs", params);
    return res.data;
  },
  async getActiveJobs() {
    let res = await Api.get("/truck/jobs/activeJobs/jobs");
    return res.data;
  },
  async getTrailers() {
    let res = await Api.get("/truck/trailers");
    return res.data;
  },
  async getFines() {
    let res = await Api.get("/truck/fines");
    return res.data;
  },
  async getVehicleDocs(id) {
    let res = await Api.get(`/truck/vehicle/docs/${id}`);
    return res.data;
  },
  async getTrailerDocs(id) {
    let res = await Api.get(`/truck/trailers/docs/${id}`);
    return res.data;
  },
  async getDriverDocuments(id) {
    let res = await Api.get(`/truck/drivers/docs/${id}`);
    return res.data;
  },
  async getDriverFines(id) {
    let res = await Api.get(`/truck/drivers/fines/${id}`);
    return res.data;
  },
  async getVehicleFines(id) {
    let res = await Api.get(`/truck/vehicles/fines/${id}`);
    return res.data;
  },
  async createHaul(obj) {
    let res = await Api.post("/truck/jobs/createHaul", obj);
    return res.data;
  },
  async createFine(obj) {
    let res = await Api.post("/truck/fines/createFine", obj);
    return res.data;
  },
  async createDriver(obj) {
    let res = await Api.post("/truck/drivers/createDriver", obj);
    return res.data;
  },
  async createVehicle(obj) {
    let res = await Api.post("/truck/vehicles/createVehicle", obj);
    return res.data;
  },
  async createTrailer(obj) {
    let res = await Api.post("/truck/trailers/createTrailer", obj);
    return res.data;
  },
  async createTransportUnit(obj) {
    let res = await Api.post("/truck/units/createUnit", obj);
    return res.data;
  },
  async updateDriver(obj) {
    let res = await Api.put(`/truck/drivers/updateDriver/${obj.id}`, obj);
    return res.data;
  },
  async deleteDriver(obj) {
    let res = await Api.put(`/truck/drivers/deleteDriver`, obj);
    return res.data;
  },
  async deleteTransportDoc(obj) {
    let res = await Api.put(`/truck/deleteDoc`, obj);
    return res.data;
  },
  async updateTrailer(obj) {
    let res = await Api.put(`/truck/trailers/updateTrailer/${obj.id}`, obj);
    return res.data;
  },
  async deleteTrailer(obj) {
    let res = await Api.put(`/truck/trailers/deleteTrailer`, obj);
    return res.data;
  },
  async updateVehicle(obj) {
    let res = await Api.put(`/truck/vehicles/${obj.id}`, obj);
    return res.data;
  },
  async updateFine(obj) {
    let res = await Api.put(`/truck/fines/updateFine/${obj.id}`, obj);
    return res.data;
  },
  async updateTransportUnit(obj) {
    let res = await Api.put(`/truck/units/updateUnit`, obj);
    return res.data;
  },
  async createTrailerLink(obj) {
    let res = await Api.post("/truck/trailers/link", obj);
    return res.data;
  },
  async updateTrailerLink(obj) {
    let res = await Api.post("/truck/trailers/update/link", obj);
    return res.data;
  },
  async deleteTransportUnit(id) {
    let res = await Api.put(`/truck/units/deleteUnit/${id}`);
    return res.data;
  },
  async getBookingsByClient(id) {
    let res = await Api.get(`/truck/jobs/bookings/${id}`);
    return res.data;
  },
  async getGensets() {
    let res = await Api.get("/truck/genset");
    return res.data;
  },
  async createGenset(obj) {
    let res = await Api.post("/truck/genset/create", obj);
    return res.data;
  },
  async updateGenset(obj) {
    let res = await Api.put("/truck/genset/update", obj);
    return res.data;
  },
  async deleteGenset(id) {
    let res = await Api.put(`/truck/genset/delete/${id}`);
    return res.data;
  },

  //Intermodal
  async getTransnetStatus(){
    let res = await Api.get('/intermodal/transnet/status')
    return res.data
  },
  async updateDetourMilestone(obj){
    let res = await Api.post("/intermodal/milestone/detour", obj);
  },
  async updateBookingContainerNo(obj) {
    let res = await Api.post('/intermodal/bookingContainer/containerNo', obj)
    return res.data
  },
  async getSelectedDashData(obj) {
    let res = await Api.post('/intermodal/getSelectedDashData', obj)
    return res.data
  },
  async getIntermodalDashData(obj) {
    let res = await Api.post('/intermodal/getDashData', obj)
    return res.data
  },
  async getPluggedContainers() {
    let res = await Api.get('/intermodal/plugIn/totals')
    return res.data
  },
  async getPluggInContainers(obj) {
    let res = await Api.post('/intermodal/getPlugginContainers', obj)
    return res.data
  },
  async getContainers(obj) {
    let res = await Api.get("/intermodal/containers", obj);
    return res.data;
  },

  async getContainerComments(id) {
    let res = await Api.get(`/intermodal/containers/comments/${id}`);
    return res.data;
  },
  async getContainerDetails(obj) {
    let res = await Api.post("/intermodal/containers/details", obj);
    return res.data;
  },
  async getBookingContainerData(obj) {
    let res = await Api.post("/intermodal/bookingContainer", obj);
    return res.data;
  },
  async commentOnContainer(obj) {
    let res = await Api.post("/intermodal/containers/newComment", obj);
    return res.data;
  },
  async deleteContainerComment(obj) {
    let res = await Api.post("/intermodal/containers/deleteComment",obj);
    return res.data;
  },
  async updateContainerComment(obj) {
    let res = await Api.post("/intermodal/containers/editComment",obj);
    return res.data;
  },

  async deleteShipmentComment(obj) {
    let res = await Api.post("/shipment/deleteComment",obj);
    return res.data;
  },
  async updateShipmentComment(obj) {
    let res = await Api.post("/shipment/editComment",obj);
    return res.data;
  },


  async getContractors() {
    let res = await Api.get(`/intermodal/dispatch/contractors`);
    return res.data;
  },
  async validateDispatchPalletQauntity(obj){
    let res = await Api.post('/stock/validateDispatchPalletQauntity',obj)
    return res.data
  },
  async getShippersCount() {
    let res = await Api.get(`/intermodal/dispatch/shipperBookings`);
    return res.data;
  },
  async getShipperContainers(obj) {
    let res = await Api.get(`/intermodal/dispatch/shipperContainers`, obj);
    return res.data;
  },
  async getSavedContainers() {
    let res = await Api.get(`/intermodal/bookings/saved`);
    return res.data;
  },
  async getUserLayout() {
    let res = await Api.get(`/intermodal/userLayout`);
    return res.data;
  },
  async saveUserLayout(obj) {
    let res = await Api.post("/intermodal/userLayout", obj);
    return res.data;
  },
  async getIntermodalBookings() {
    let res = await Api.get(`/intermodal/bookings/bookings`);
    return res.data;
  },
  async getContainerPois(obj) {
    let res = await Api.post(`/intermodal/containerPois`, obj);
    return res.data;
  },
  async dispatchContainers(obj) {
    let res = await Api.post("/intermodal/dispatch/dispatchContainers", obj);
    return res.data;
  },
  async changeFirmStatus(obj) {
    let res = await Api.put(`/intermodal/bookings/firmStatus`, obj);
    return res.data;
  },

  async dispatchBookings(obj) {
    let res = await Api.post(`/intermodal/dispatch/dispatchBookings`, obj);
    return res.data;
  },
  async saveUserDateRange(obj) {
    let res = await Api.post("/intermodal/userDateRange", obj);
    return res.data;
  },

  async obtainIntermodalShippers(obj) {
    let res = await Api.post("/intermodal/obtain/shippers", obj);
    return res.data;
  },

  async obtainIntermodalTransporters(obj) {
    let res = await Api.post("/intermodal/obtain/transporters", obj);
    return res.data;
  },

  async obtainIntermodalTransporterLoads(obj) {
    let res = await Api.post("/intermodal/obtain/transporterLoads", obj);
    return res.data;
  },
  async getIntermodalTransporterSelectionLoads(obj) {
    let res = await Api.post('/intermodal/obtain/selection/transporterLoads', obj)
    return res.data
  },
  async updateTransporterStatuses(obj) {
    let res = await Api.post("/intermodal/obtain/updateTransporterStatuses", obj);
    return res.data;
  },
  async sentTransportCTO(obj) {
    let res = await Api.post('/intermodal/cto/email', obj)
    return res.data
  },
  async createPluggedInMail(obj){
    let res = await Api.post('/intermodal/email/plugIn', obj)
    return res.data
  },
  async sendPackingListMail(obj){
    let res = await Api.post('/shipment/email/packingList', obj)
    return res.data
  },
  async getShipperLoads(obj) {
    let res = await Api.post("/intermodal/obtain/shipperLoads", obj);
    return res.data;
  },
  async getShipperPluggedIn(obj) {
    let res = await Api.post("/intermodal/obtain/shipperLoads/pluggedIn", obj);
    return res.data;
  },

  // Intermodal Docs
  async generateQ67(obj) {
    let res = await Api.post("/intermodalDocs/generateQ67", obj);
    return res.data;
  },
  async cancelPreadvise(id, data) {
    let res = await Api.post("/intermodal/preadvise/cancel/" + id, data);
    return res.data;
  },
  async submitPreadvise(id, data) {
    let res = await Api.post("/intermodal/preadvise/" + id, data);
    return res.data;
  },
  async generateMergedQ67(obj) {
    let res = await Api.post("/intermodalDocs/generateMergedQ67", obj);
    return res.data;
  },
  async generateQ67Excel(obj) {
    let res = await Api.post("/intermodalDocs/generateQ67/excel", obj);
    return res.data;
  },
  async generateLAR(obj) {
    let res = await Api.post("/intermodalDocs/generateLAR", obj);
    return res.data;
  },
  async generateEarlyArrival(obj) {
    let res = await Api.post("/intermodalDocs/generateEarlyArrival", obj);
    return res.data;
  },
  async bulkGenerateLAR(obj) {
    let res = await Api.post("/intermodalDocs/bulkGenerateLAR", obj);
    return res.data;
  },
  async generateCTO(obj) {
    let res = await Api.post("/intermodalDocs/generateCTO", obj);
    return res.data;
  },
  async generatePackingList(obj) {
    let res = await Api.post("/intermodalDocs/generatePackingList", obj);
    return res.data;
  },
  
  async getCTO(id) {
    let res = await Api.get(`/intermodalDocs/getCTO/${id}`);
    return res.data;
  },
  async getLAR(id) {
    let res = await Api.get(`/intermodalDocs/getLAR/${id}`);
    return res.data;
  },
  async getContainerCTOs(obj) {
    let res = await Api.post(`/intermodalDocs/containerDocuments`, obj);
    return res.data;
  },

  //Points of Interest
  async getOrganisationsPOI(obj) {
    let res = await Api.get("/truck/poi/organisation", obj);
    return res.data;
  },
  async getMarkersInBounds(obj) {
    let res = await Api.post("/truck/poi/bounds", obj);
    return res.data;
  },
  async getOrgFboCodes(id) {
    let res = await Api.get(`/truck/poi/organisationFbo/${id}`);
    return res.data;
  },
  async getFboCodes(obj) {
    let res = await Api.get("/truck/poi/fbo", obj);
    return res.data;
  },
  async searchLocodes(obj) {
    let res = await Api.get("/truck/poi/locode", obj);
    return res.data;
  },
  async checkFboCode(code) {
    let res = await Api.get("/truck/poi/checkFbo/" + code);
    return res.data;
  },
  async getActivityPoints(obj) {
    let res = await Api.get("/truck/poi/activityPoint", obj);
    return res.data;
  },
  async getPois(obj) {
    let res = await Api.get("/truck/poi", obj);
    return res.data;
  },
  async getPoiFunctions() {
    let res = await Api.get("/poi/functions");
    return res.data;
  },
  async getPOIByFunction(functionName) {
    let res = await Api.get(`/poi/byFunction/${functionName}`);
    return res.data;
  },
  async filterPois(obj) {
    let res = await Api.get("/truck/poi/filter", obj);
    return res.data;
  },
  async createPoi(obj) {
    let res = await Api.post("/truck/poi/createPoi", obj);
    return res.data;
  },
  async createPoiFunction(obj) {
    let res = await Api.post("/truck/poi/functions", obj);
    return res.data;
  },
  async createPoiAlias(obj) {
    let res = await Api.post("/truck/poi/alias", obj);
    return res.data;
  },
  async updatePoi(obj) {
    let res = await Api.put(`/truck/poi/updatePoi`, obj);
    return res.data;
  },
  async updatePoiFunction(obj) {
    let res = await Api.put(`/truck/poi/updatePoiFunction`, obj);
    return res.data;
  },
  async updatePoiAlias(obj) {
    let res = await Api.put(`/truck/poi/updateAlias`, obj);
    return res.data;
  },
  async deletePoi(id) {
    let res = await Api.put(`/truck/poi/deletePoi/${id}`);
    return res.data;
  },
  async deletePoiFunction(id) {
    let res = await Api.put(`/truck/poi/deletePoiFunction/${id}`);
    return res.data;
  },
  async deletePoiAlias(id) {
    let res = await Api.put(`/truck/poi/deleteAlias/${id}`);
    return res.data;
  },
  async updateActivityPoint(obj) {
    let res = await Api.put("/truck/poi/updateActivityPoint", obj);
    return res.data;
  },
  async getPluggedInDepots(){
    let res = await Api.get("/poi/pluggedInDepots")
    return res.data
  },
  // DataCapturing
  async getDriverData() {
    let res = await Api.get("/truck/data/driver");
    return res.data;
  },
  async updateDriverData(obj) {
    let res = await Api.put("/truck/data/driver", obj);
    return res.data;
  },

  //ChatPortal
  async getDriverContacts() {
    let res = await Api.get("/transport/drivers");
    return res.data;
  },
  async getMessages(id, params) {
    let res = await Api.get(`/transport/messages/${id}`, params);
    return res.data;
  },
  async sendMessage(obj) {
    let res = await Api.post("/transport/messages/sent", obj);
    return res.data;
  },
  async sendHaul(obj) {
    let res = await Api.post("/transport/messages/sendHaul", obj);
    return res.data;
  },
  async sendTemplate(obj) {
    let res = await Api.post("/transport/messages/sendTemplate", obj);
    return res.data;
  },
  async uploadWhatsappImage(obj) {
    let res = await Api.post("/transport/messages/uploadImage", obj);
    return res.data;
  },

  // ChatPortal Bot
  async createWhatsappTemplate(obj) {
    let res = await Api.post("/transport/waTemplate", obj);
    return res.data;
  },
  async saveWhatsappMessage(obj) {
    let res = await Api.post("/transport/waMessage", obj);
    return res.data;
  },
  async updateWhatsappTemplate(obj) {
    let res = await Api.put("/transport/waTemplate", obj);
    return res.data;
  },
  async getWhatsappTemplates() {
    let res = await Api.get("/transport/waTemplates");
    return res.data;
  },
  async getTemplateMessages(id) {
    let res = await Api.get(`/transport/waTemplate/messages/${id}`);
    return res.data;
  },
  async deleteWhatsappTemplate(id) {
    let res = await Api.put(`/transport/waTemplate/delete/${id}`);
    return res.data;
  },
  async deleteTemplateMessages(obj) {
    let res = await Api.put(`/transport/waTemplate/messages/delete`, obj);
    return res.data;
  },
  async saveWhatsappTemplate(obj) {
    let res = await Api.put(`/transport/waTemplate/update`, obj);
    return res.data;
  },
  async getWhatsappTemplateConfig(id) {
    let res = await Api.get(`/transport/waTemplate/config/${id}`);
    return res.data;
  },
  async updateWhatsappTemplateConfig(obj) {
    let res = await Api.put("/transport/waTemplate/config", obj);
    return res.data;
  },

  // Inco Terms
  async getIncoTerms() {
    let res = await Api.get("/incoTerms");
    return res.data;
  },


  // Intra
  async intraBookings(obj) {
    let res = await Api.post("/intra/bookings", obj);
    return res.data;
  },
  async listCarriers() {
    let res = await Api.get("/carriers");
    return res.data;
  },
  async listContainerTypes() {
    let res = await Api.get("/containerTypes");
    return res.data;
  },
  async getOceanSchedules(obj) {
    let res = await Api.get("/intra/oceanSchedules", obj);
    return res.data;
  },
  async trackContainer(obj) {
    let res = await Api.get("/intra/trackContainer", obj);
    return res.data;
  },
  async searchCarrierPlaces(obj) {
    let res = await Api.get("/intra/searchCarrierPlaces", obj);
    return res.data;
  },

  // Marine Traffic

  async getVesselDetail(id) {
    let res = await Api.get("/track/vessel/" + id);
    return res.data;
  },
  async getVesselVoyageTracking(id) {
    let res = await Api.get("/track/vesselVoyageTracking/" + id);
    return res.data;
  },
  async getShipmentMap() {
    let res = await Api.get("/track/activeShipmentMap");
    return res.data;
  },

  async getShipmentWeekVessels(obj, signal) {
    let res = await Api.post("/shipment/vessel/week", obj, signal);
    return res.data;
  },
  async searchVessels(obj) {
    let res = await Api.get("/vessel", obj);
    return res.data;
  },
  async getActiveShipments() {
    for (let i = 0; i < requests.length; i++) {
      await requests[i].abort("redirect");
    }
    requests = [];
    let controller = new AbortController();
    requests.push(controller);
    let res = await Api.get("/shipment/active", {
      signal: controller.signal,
    });
    return res.data;
  },
  async getActiveWeeks(obj) {
    let res = await Api.get("/shipment/activeWeeks", obj);
    return res.data;
  },
  async getActiveVessels() {
    let res = await Api.get("/shipment/activeVessels");
    return res.data;
  },
  async getVesselProducts(id) {
    let res = await Api.get("/shipment/vessel/products/" + id);
    return res.data;
  },
  async getVoyageLegs(id) {
    let res = await Api.get("/shipment/vessel/legs/" + id);
    return res.data;
  },


      //MarkdownEditor
      async markdownDocumentUpload(data) {
        let res = await Api.post('/markdown/upload', data);
        return res.data;
      },
      async markdownDocumentDownload(params) {
        let res = await Api.get('/markdown/download', { params });
        return res.data;
      },
      async markdownDocumentDelete(data) {
        let res = await Api.post('/markdown/delete', data);
        return res.data;
      },
      async markdownDocumentCreate(data) {
        let res = await Api.post('/markdown/create-folder', data);
        return res.data;
      },
      async markdownDocumentGetFilesAndFolders(params) {
        let res = await Api.get('/markdown/get-files-and-folders', { params });
        return res.data;
      },
      async markdownDocumentGetFileContent(params) {
        let res = await Api.get('/markdown/content', { params });
        return res.data;
      },
  
    async getPictureUrl(params) {
      let res = await Api.get('/markdown/getPictureUrl', {params} );
      return res.data
    },
      
  
      async pictureUpload(data) {
        let res = await Api.post('/markdown/Pictureupload', data);
        return res.data
      },
  
      async deletePicture(data) {
        let res = await Api.post('/markdown/deletePicture', data);
        return res.data
      },

      async shareDocument(data) {
        let res = await Api.post('/markdown/share', data);
        return res.data;
      },
  
      async fetchSharedDocuments(params) {
        let res = await Api.get('/markdown/shared-documents', { params });
        return res.data;
      },
      
  
      async sharedCreateFolder(data) {
        let res = await Api.post('/markdown/shared-create-folder', data);
        return res.data;
      },
      
      async sharedMoveFile(data) {
        let res = await Api.post('/markdown/shared-move-file', data);
        return res.data;
      },
  
      
      async fetchSharedFoldersAndFiles(params) {
        let res = await Api.get('/markdown/shared-folders-and-files', { params });
        return res.data;
      }, 

  // Milestones
  async createMilestone(obj) {
    let res = await Api.post("/container/milestone", obj);
    return res.data;
  },


  // Models
  async getSharedModels() {
    let res = await Api.get("/models/shared");
    return res.data;
  },
  async createSharedModel(obj) {
    let res = await Api.post("/models/shared", obj);
    return res.data;
  },
  async updateSharedModel(obj) {
    let res = await Api.put(`/models/shared/${obj.id}`, obj);
    return res.data;
  },
  async updateSharedModelField(obj) {
    let res = await Api.put(`/models/shared/field/${obj.id}`, obj);
    return res.data;
  },
  async getModels() {
    let res = await Api.get("/models/system");
    return res.data;
  },
  async getModelFields(model) {
    let res = await Api.get(`/models/detail/${model}`);
    return res.data;
  },

  // Modules
  async getSystemRoles() {
    let res = await Api.get("/systemRole");
    return res.data;
  },
  async getAvailableRoles() {
    let res = await Api.get("/systemRole/available");
    return res.data;
  },
  async setModuleRole(id, data) {
    let res = await Api.post(`/moduleRole/${id}`, data);
    return res.data;
  },
  async getModuleRoles(id) {
    let res = await Api.get(`/moduleRole/${id}`);
    return res.data;
  },
  async getModules(query) {
    let res = await Api.get("/module", query);
    return res.data;
  },

  async getModule(id) {
    let res = await Api.get("/module/" + id);
    return res.data;
  },
  async getShareableModules(){
    let res = await Api.get("/shareable/module");
    return res.data;
  },
  async setSharedModule(sharedOrganisationId, obj) {
    let res = await Api.post("/shared/module/"+sharedOrganisationId, obj);
    return res.data;
  },
  async getSharedModules(organisationId){
    let res = await Api.get(`/shared/module/${organisationId}`);
    return res.data;
  },
  async getRelationshipFunctions(id) {
    let res = await Api.get(`/relationship/role/${id}`);
    return res.data;
  },
  async setRelationshipFunctions(id, data) {
    let res = await Api.post(`/relationship/role/${id}`, data);
    return res.data;
  },
  async getRelationshipModuleSettings(id, params) {
    let res = await Api.get(`/relationship/moduleSetting/${id}`, params);
    return res.data;
  },
  async updateRelationshipModuleSettings(id, obj) {
    let res = await Api.put(`/relationship/moduleSetting/${id}`, obj);
    return res.data;
  },
  async createModule(obj) {
    let res = await Api.post("/module", obj);
    return res.data;
  },
  async updateModule(obj) {
    let res = await Api.put("/module/" + obj.id, obj);
    return res.data;
  },

  async getModuleIntegrations(id){
    let res = await Api.get(`/module/integration/${id}`);
    return res.data;
  },
  async createModuleIntegration(obj){
    let res = await Api.post(`/module/integration`, obj);
    return res.data;
  },
  async updateModuleIntegration(obj){
    let res = await Api.put(`/module/integration/${obj.id}`, obj);
    return res.data;
  },

  // Module Route
  async getModuleRoute(id) {
    let res = await Api.get("/moduleRoute/" + id);
    return res.data;
  },

  async createModuleRoute(obj) {
    let res = await Api.post("/moduleRoute", obj);
    return res.data;
  },

  async updateModuleRoute(obj) {
    let res = await Api.put("/moduleRoute/" + obj.id, obj);
    return res.data;
  },

  // Module Scope
  async getModuleScope(id) {
    let res = await Api.get("/moduleScope/" + id);
    return res.data;
  },

  async createModuleScope(obj) {
    let res = await Api.post("/moduleScope", obj);
    return res.data;
  },

  async updateModuleScope(obj) {
    let res = await Api.put("/moduleScope/" + obj.id, obj);
    return res.data;
  },
  // Phytclean
  async getOrchardData(farm, orchard) {
    let res = await Api.get(`/phytclean/${farm}/${orchard}`);
    return res.data;
  },
  async getPhytCleanContainerVerification(id) {
    let res = await Api.get(`/phytclean/${id}`);
    return res.data;
  },
  async submitPhytClean(obj) {
    let res = await Api.post(`/phytclean`, obj);
    return res.data;
  },

  // Products
  async listCountryProductGroups(countryCode) {
    let res = await Api.get(`/productGroup/country/${countryCode}`);
    return res.data;
  },
  async updateProductGroup(obj) {
    let res = await Api.put(`/productGroup/${obj.id}`, obj);
    return res.data;
  },

  async listCountryProducts(countryCode, obj) {
    let res = await Api.get(`/products/country/${countryCode}`, obj);
    return res.data;
  },
  async listGroupProducts(id) {
    let res = await Api.get(`/products/group/${id}`);
    return res.data;
  },
  async createProduct(obj) {
    let res = await Api.post(`/products`, obj);
    return res.data;
  },
  async getProductExceptions() {
    let res = await Api.get(`/products/exceptions`);
    return res.data;
  },
  async updateProduct(obj) {
    let res = await Api.put(`/products/${obj.id}`, obj);
    return res.data;
  },
  async getProducts(obj) {
    let res = await Api.get(`/products`, obj);
    return res.data;
  },
  async getProductGroupByProduct(obj) {
    let res = await Api.post('/productGroup/products', obj)
    return res.data
  },
  async getProductsByCountry(countryId, obj) {
    let res = await Api.get(`/products/country/${countryId}`, obj);
    return res.data;
  },
  async getProductSubItems(id, obj) {
    let res = await Api.get(`/products/subitems/${id}`, obj);
    return res.data;
  },

  async getProductMetadata(id) {
    let res = await Api.get(`/products/metadata/${id}`);
    return res.data;
  },
  async updateProductMetadata(obj) {
    let res = await Api.put(`/products/metadata/${obj.id}`, obj);
    return res.data;
  },
  async createProductMetadata(obj) {
    let res = await Api.post(`/products/metadata`, obj);
    return res.data;
  },
  async getProductTree(id) {
    let res = await Api.get(`/products/tree/` + id);
    return res.data;
  },
  async createProductCountry(obj) {
    let res = await Api.post("/products/productCountry", obj);
    return res.data;
  },
  async getCountryProduct(productId, countryRegionId) {
    let res = await Api.get(
      "/products/productCountry/" + productId + "/" + countryRegionId
    );
    return res.data;
  },

  // Product Services
  async getProductServices() {
    let res = await Api.get("/productService");
    return res.data;
  },
  async createProductService(obj) {
    let res = await Api.post("/productService", obj);
    return res.data;
  },
  async updateProductService(obj) {
    let res = await Api.put(`/productService/${obj.id}`, obj);
    return res.data;
  },

  async createProductServiceSale(obj) {
    let res = await Api.post("/productServiceSale", obj);
    return res.data;
  },
  async updateProductServiceSale(obj) {
    let res = await Api.put(`/productServiceSale/${obj.id}`, obj);
    return res.data;
  },

  async getProductBundles(){
    let res = await Api.get("/productBundle");
    return res.data;
  },
  async updateProductBundle(obj) {
    let res = await Api.put(`/productBundle/${obj.id}`, obj);
    return res.data
  },
  async createProductBundle(obj) {
    let res = await Api.post("/productBundle", obj);
    return res.data;
  },


  // Related Organisations
  async getRelation(params) {
    let res = await Api.get("/related", params);
    return res.data;
  },
  async getRelationBasic() {
    let res = await Api.get("/related/basic");
    return res.data;
  },
  async createRelation(obj) {
    let res = await Api.post("/related", obj);
    return res.data;
  },
  async updateRelation(obj) {
    let res = await Api.put("/related/" + obj.id, obj);
    return res.data;
  },

  async createCreditLimits(id, creditLimits) {
    // console.log("Sending to backend for update:", creditLimits);
    let res = await Api.post(`/related/createCredit/${id}`, { creditLimits });
    return res.data;
  },

  async getCreditLimits(id) {
    let res = await Api.get(`/related/getCreditLimits/${id}`);
    return res.data;
  },
  async getAccountState(id) {
    let res = await Api.get(`/related/getAccountState/${id}`)
    return res.data
  },
  async updateCreditLimits(changes) {
    // Log the changes being sent to the backend
    // console.log("Sending to backend for update:", changes);
  
    // Extract the ID from the changes object
    const { id, ...data } = changes;

    // Make the PUT request with the ID in the URL
    let res = await Api.put(`/related/updateCreditLimits/${id}`, data);
    return res.data;
  },

  async deleteCreditLimit(id) {
    let res = await Api.post('/related/deleteCreditLimit', { id });
    return res.data;
  },  

  async updateCreditWarning(obj) {
    let res = await Api.post('/related/updateCreditWarning', obj)
    return res.data
  },

  async updateCreditRanks(obj) {
    let res = await Api.post('/related/creditRanks', obj)
    return res.data
  },

  async createPaymentTerms(id, obj) {
    let res = await Api.post('/related/createPaymentTerm/' + id, obj)
    return res.data
  },
  async updatePaymentTerms(id, obj) {
    let res = await Api.post('related/updatePaymentTerm/' +id, obj) 
    return res.data
  },
  async removePaymentTermsGroup(id, obj) {
    let res = await Api.post('/related/removePaymentTermsGroup/' + id, obj)
    return res.data
  },


  // Regions

  async getCountryRegions() {
    let res = await Api.get("/countriesRegions");
    return res.data;
  },
  async getRegions() {
    let res = await Api.get("/region");
    return res.data;
  },
  async getRegionDetail(id) {
    let res = await Api.get("/region/" + id);
    return res.data;
  },
  async getOrganisationExportDocumentation(id) {
    let res = await Api.get("/region/exportDocumentation/" + id);
    return res.data;
  },
  async createRegionDocument(obj) {
    let res = await Api.post("/region/document", obj);
    return res.data;
  },
  async updateRegionDocument(obj) {
    let res = await Api.put(`/region/document/${obj.id}`, obj);
    return res.data;
  },
  async getRegionDocuments(id) {
    let res = await Api.get(`/locationDocument/region/${id}`);
    return res.data;
  },
  async createLocationDocument(obj) {
    let res = await Api.post("/locationDocument", obj);
    return res.data;
  },
  async updateLocationDocument(obj) {
    let res = await Api.put(`/locationDocument/${obj.id}`, obj);
    return res.data;
  },
  async updateLocationDocumentVersion(obj) {
    let res = await Api.put(`/locationDocument/version/${obj.id}`, obj);
    return res.data;
  },
  async getCountrySupportingDocuments(id) {
    let res = await Api.get(`/supportingDocument/country/${id}`);
    return res.data;
  },
  async getRegionSupportingDocuments(id) {
    let res = await Api.get(`/supportingDocument/region/${id}`);
    return res.data;
  },
  async createSupportingDocument(obj) {
    let res = await Api.post(`/supportingDocument`, obj);
    return res.data;
  },
  async updateSupportingDocument(obj) {
    let res = await Api.put(`/supportingDocument/${obj.id}`, obj);
    return res.data;
  },
  async getSupportingDocuments(id) {
    let res = await Api.get(`/locationDocument/supporting/${id}`);
    return res.data;
  },
  async updateCarrierHaulContainers(obj){
    let res = await Api.post(`/containerData/carrierHaulage`, obj)
    return res.data
  },
  async createSupportingLocationDocument(obj) {
    let res = await Api.post(`/locationDocument/supporting`, obj);
    return res.data;
  },
  async updateSupportingLocationDocument(obj) {
    let res = await Api.put(`/locationDocument/supporting/${obj.id}`, obj);
    return res.data;
  },
  async createPECSXML(obj){
    let res = await Api.post(`/document/pecs/xml`, obj);
    return res.data;
  },
  async documentLookup(obj) {
    let res = await Api.post(`/locationDocument/lookup`, obj);
    return res.data;
  },

  async getSystemVariables() {
    let res = await Api.get(`/system/variable`);
    return res.data;
  },
  async getGlobalSystemVariables() {
    let res = await Api.get(`/system/variable/global`);
    return res.data;
  },
  async createSystemVariable(obj) {
    let res = await Api.post(`/system/variable`, obj);
    return res.data;
  },
  async updateSystemVariable(obj) {
    let res = await Api.put(`/system/variable/${obj.id}`, obj);
    return res.data;
  },
  // Document Label
  async createDocumentLabel(obj) {
    let res = await Api.post(`/documentLabel`, obj);
    return res.data;
  },
  async createSupportingDocumentLabel(obj) {
    let res = await Api.post(`/locationDocument/supporting/documentLabel`, obj);
    return res.data;
  },
  async updateSupportingDocumentLabel(obj) {
    let res = await Api.put(
      `/locationDocument/supporting/documentLabel/${obj.id}`,
      obj
    );
    return res.data;
  },
  async createCountryDocument(obj) {
    let res = await Api.post("/country/document", obj);
    return res.data;
  },
  async updateCountryDocument(obj) {
    let res = await Api.put(`/country/document/${obj.id}`, obj);
    return res.data;
  },
  async updateCountry(obj) {
    let res = await Api.put(`/country/${obj.id}`, obj);
    return res.data;
  },
  async updateLocationDocumentArea(obj) {
    let res = await Api.post("/locationDocument/area", obj);
    return res.data;
  },
  async updateLocationDocumentAttest(obj) {
    let res = await Api.post("/locationDocument/attest", obj);
    return res.data;
  },
  async updateLocationDocumentPoint(obj) {
    let res = await Api.post("/locationDocument/point", obj);
    return res.data;
  },
  async updateLocationDocumentProduct(obj) {
    let res = await Api.post("/locationDocument/product", obj);
    return res.data;
  },
  async updateLocationDocumentSubdivision(obj) {
    let res = await Api.post("/locationDocument/subdivision", obj);
    return res.data;
  },
  async getLocationDocument(id) {
    let res = await Api.get("/locationDocument/detail/" + id);
    return res.data;
  },

  async searchSupportingDocuments(obj) {
    let res = await Api.get(`/locationDocument/supporting`, obj);
    return res.data;
  },
  // Country
  async getCountryByCode(code) {
    let res = await Api.get(`/country/${code}`);
    return res.data;
  },
  async searchCountries(obj) {
    let res = await Api.get(`/country`, obj);
    return res.data;
  },
  async searchCountriesBasic(obj) {
    let res = await Api.get(`/country/basic`, obj);
    return res.data;
  },
  async getCountryDocuments(id, params={}) {
    let res = await Api.get(`/locationDocument/country/${id}`,{
      params
    });
    return res.data;
  },
  async getCountrySubdivisions(id) {
    let res = await Api.get(`/country/subdivision/${id}`);
    return res.data;
  },
  async getCountrySubdivisionsAdmin(id) {
    let res = await Api.get(`/country/subdivision/admin/${id}`);
    return res.data;
  },
  async getCountrySubdivisionDescription(id) {
    let res = await Api.get(`/country/subdivisionDescription/${id}`);
    return res.data;
  },
  async getCountryTowns(id, params) {
    let res = await Api.get(`/country/town/${id}`, params);
    return res.data;
  },
  async searchTowns(params) {
    let res = await Api.get(`/country/search/town`, params);
    return res.data;
  },
  async createSubdivision(obj) {
    let res = await Api.post(`/country/subdivision`, obj);
    return res.data;
  },
  async updateSubdivision(obj) {
    let res = await Api.put(`/country/subdivision/${obj.id}`, obj);
    return res.data;
  },

  // Regulatory Body

  async createRegulatoryBody(obj) {
    let res = await Api.post("/regulatoryBody", obj);
    return res.data;
  },
  async updateRegulatoryBody(obj) {
    let res = await Api.put("/regulatoryBody/" + obj.id, obj);
    return res.data;
  },

  async getRegulatoryBodyByCountry(countryId) {
    let res = await Api.get("/regulatoryBody/country/" + countryId);
    return res.data;
  },
  async getRegulatoryBodyByRegion(regionId) {
    let res = await Api.get("/regulatoryBody/region/" + regionId);
    return res.data;
  },
  // Roles
  async getRoleDetailsAdmin(id) {
    let res = await Api.get("/role/detail/admin/" + id);
    return res.data;
  },
  async getOrganisationRoleAdmin(id) {
    let res = await Api.get("/role/admin/" + id);
    return res.data;
  },
  async createRoleAdmin(obj) {
    let res = await Api.post("/role/admin", obj);
    return res.data;
  },
  async updateRoleAdmin(obj) {
    let res = await Api.put("/role/admin/" + obj.id, obj);
    return res.data;
  },

  async getRoleDetails(id) {
    let res = await Api.get("/role/detail/" + id);
    return res.data;
  },
  async createRole(obj) {
    let res = await Api.post("/role", obj);
    return res.data;
  },
  async updateRole(obj) {
    let res = await Api.put("/role/" + obj.id, obj);
    return res.data;
  },
  async getOrganisationRole() {
    let res = await Api.get("/role");
    return res.data;
  },
  async getOrganisationPermission() {
    let res = await Api.get("/permission/organisation");
    return res.data;
  },
  async getRolePermissions(id) {
    let res = await Api.get("/role/permission/" + id);
    return res.data;
  },

  // Role Access
  async getRoleAccess(id) {
    let res = await Api.get("/roleAccess/" + id);
    return res.data;
  },
  async createRoleAccess(obj) {
    let res = await Api.post("/roleAccess", obj);
    return res.data;
  },
  async createBatchRoleAccess(obj) {
    let res = await Api.post("/roleAccess/batch", obj);
    return res.data;
  },
  async updateRoleAccess(obj) {
    let res = await Api.put("/roleAccess/" + obj.id, obj);
    return res.data;
  },

  async getRoleAccessAdmin(id) {
    let res = await Api.get("/roleAccess/admin/" + id);
    return res.data;
  },
  async createRoleAccessAdmin(obj) {
    let res = await Api.post("/roleAccess/admin", obj);
    return res.data;
  },
  async updateRoleAccessAdmin(obj) {
    let res = await Api.put("/roleAccess/admin/" + obj.id, obj);
    return res.data;
  },

  // Routes
  async getRoutes(query) {
    let res = await Api.get("/route", query);
    return res.data;
  },
  async getAllRoutes() {
    let res = await Api.get("/route/all");
    return res.data;
  },
  async getAllOrganisationRoutes() {
    let res = await Api.get("/route/all/organisation");
    return res.data;
  },
  async createRoute(obj) {
    let res = await Api.post("/route", obj);
    return res.data;
  },
  async updateRoute(obj) {
    let res = await Api.put("/route/" + obj.id, obj);
    return res.data;
  },
  async createRouteTab(obj) {
    let res = await Api.post("/routeTab", obj);
    return res.data;
  },
  async updateRouteTab(obj) {
    let res = await Api.put("/routeTab/" + obj.id, obj);
    return res.data;
  },

  async createVesselSchedule(obj) {
    let res = await Api.post("/vesselSchedule", obj);
    return res.data;
  },
  async createSailingSchedule(obj) {
    let res = await Api.post("/sailingSchedule", obj);
    return res.data;
  },
  async deleteSailingSchedule(id) {
    let res = await Api.put(`/deleteSailingSchedule/${id}`);
    return res.data;
  },
  async checkManualSailingSchedule(id) {
    let res = await Api.get(`/checkManualSailingSchedule/${id}`);
    return res.data;
  },
  async getSailingSchedule(query) {
    let res = await Api.get("/sailingSchedules", query);
    return res.data;
  },
  async getLogLiveSailingSchedule(query) {
    let res = await Api.get("/loglive/sailingSchedules", query);
    return res.data;
  },
  async getSailingScheduleLegs(id) {
    let res = await Api.get("/schedules/legs/" + id);
    return res.data;
  },

  // Scopes

  async getScopes(query) {
    let res = await Api.get("/scope", query);
    return res.data;
  },

  async createScope(obj) {
    let res = await Api.post("/scope", obj);
    return res.data;
  },

  async updateScope(obj) {
    let res = await Api.put("/scope/" + obj.id, obj);
    return res.data;
  },
  async getSeasons() {
    let res = await Api.get("/season");
    return res.data;
  },
  async updateSeason(data) {
    let res = await Api.put("/season/" + data.id, data);
    return res.data;
  },
  async createSeason(data) {
    let res = await Api.post("/season", data);
    return res.data;
  },
  async shiftSeason(data) {
    let res = await Api.post("/season/shift/"+data.seasonId, data);
    return res.data;
  },
  // Share
  async getShipmentShareItem(hash) {
    let res = await Api.get(`/share/shipment/${hash}`);
    return res.data;
  },
  // Service failure
  async getServiceFailureCategories() {
    let res = await Api.get(`/serviceFailure/category`);
    return res.data;
  },

  async createServiceFailureCategory(obj) {
    let res = await Api.post(`/serviceFailure/category`, obj);
    return res.data;
  },
  async updateServiceFailureCategory(obj) {
    let res = await Api.put(`/serviceFailure/category/${obj.id}`, obj);
    return res.data;
  },
  async createServiceFailureSubcategory(obj) {
    let res = await Api.post(`/serviceFailure/subcategory`, obj);
    return res.data;
  },
  async updateServiceFailureSubcategory(obj) {
    let res = await Api.put(`/serviceFailure/subcategory/${obj.id}`, obj);
    return res.data;
  },
  async getCustomerServiceFailures(id) {
    let res = await Api.get(`/serviceFailure/customer/${id}`);
    return res.data;
  },
  async createServiceFailure(obj) {
    let res = await Api.post(`/serviceFailure`, obj);
    return res.data;
  },
  async updateServiceFailure(obj) {
    let res = await Api.put(`/serviceFailure/${obj.id}`, obj);
    return res.data;
  },
  async getServiceFailureByShipment(id) {
    let res = await Api.get(`/serviceFailure/shipment/${id}`);
    return res.data;
  },
  async getServiceFailureByShipmentDocument(id) {
    let res = await Api.get(`/serviceFailure/document/${id}`);
    return res.data;
  },

  async bulkUpdateShipmentDocuments(obj) {
    let res = await Api.post("/shipment/document/bulkUpdate", obj);
    return res.data;
  },

  // Shipments

  async hookShipmentDetails(id){
    let res = await Api.post(`/shipment/hook/${id}`)
    return res.data;
  },
  async resetShipmentFileSync(id) {
    let res = await Api.put(`/shipment/file/resync/${id}`);
    return res.data;
  },
  async unlockShipment(id) {
    let res = await Api.put(`/shipment/unlock/admin/${id}`);
    return res.data;
  },
  async shareShipment(obj) {
    let res = await Api.post("/shipment/share", obj);
    return res.data;
  },
  async getSingleShipment(id) {
    let res = await Api.get("/shipment/" + id);
    return res.data;
  },
  async isShipmentLocked(id) {
    let res = await Api.get("/shipment/locked/" + id);
    return res.data;
  },
  async getShipmentContainerEvents(id) {
    let res = await Api.get("/shipment/container/events/" + id);
    return res.data;
  },
  async getShipmentFolders(id, params) {
    let res = await Api.get("/shipment/folder/" + id, params);
    return res.data;
  },
  async getSharedShipmentFolders(id, params) {
    let res = await Api.get("/shipment/folder/shared/" + id, params);
    return res.data;
  },
  async approveDocument(obj) {
    let res = await Api.post("/shipment/shared/approve/" + obj.id, obj);
    return res.data;
  },
  async getShipmentSubfolders(id) {
    let res = await Api.get("/shipment/folder/sub/" + id);
    return res.data;
  },
  async createShipmentFolders(obj) {
    let res = await Api.post("/shipment/folder", obj);
    return res.data;
  },
  async updateShipmentFolders(obj) {
    let res = await Api.put("/shipment/folder/" + obj.id, obj);
    return res.data;
  },
  async listFolderDocuments(shipmentId, params) {
    let res = await Api.get(`/shipment/folder/document/${shipmentId}`, params);
    return res.data;
  },
  //Temporary products
  async getBookingContainersProducts(obj) {
    let res = await Api.post("/products/bookingContainers", obj);
    return res.data;
  },
  async bulkUpdateAllContainersProducts(obj) {
    let res = await Api.post("/products/bulkUpdateContainers", obj);
    return res.data;
  },
  async bulkUpdateTemporaryProducts(obj) {
    let res = await Api.post("/products/bulkUpdateTemporary", obj);
    return res.data;
  },

  async updateDeviceCount(obj) {
    let res = await Api.post("/products/telemPlus", obj);
    return res.data;
  },
  async removeDevices(obj) {
    let res = await Api.post("/products/telemPlus/remove", obj);
    return res.data;
  },
  async listMyDraftDocuments(shipmentId, params) {
    let res = await Api.get(`/shipment/folder/draft/${shipmentId}`, params);
    return res.data;
  },
  async getSharedDraftDocuments(shipmentId, params) {
    let res = await Api.get(
      `/shipment/folder/draft/shared/${shipmentId}`,
      params
    );
    return res.data;
  },
  async getLoadData() {
    let res = await Api.get(`/shipment/loadData`);
    return res.data;
  },
  async getSharedFinalDocuments(shipmentId, params) {
    let res = await Api.get(
      `/shipment/folder/final/shared/${shipmentId}`,
      params
    );
    return res.data;
  },

  async listMyFinalDocuments(shipmentId, params) {
    let res = await Api.get(`/shipment/folder/final/${shipmentId}`, params);
    return res.data;
  },
  async shareShipmentDocuments(obj) {
    let res = await Api.post("/shipment/folder/share", obj);
    return res.data;
  },
  async updateShareShipmentDocuments(obj) {
    let res = await Api.put("/shipment/folder/share/" + obj.id, obj);
    return res.data;
  },
  async getDocumentCount(shipmentId) {
    let res = await Api.get(`/shipment/document/count/${shipmentId}`);
    return res.data;
  },
  async updateBulkShipmentDocument(obj) {
    let res = await Api.post("/shipment/document/bulk", obj);
    return res.data;
  },
  async createManualShipmentDocument(obj) {
    let res = await Api.post("/shipment/document/manual", obj);
    return res.data;
  },
  async refreshShipmentDocuments(id) {
    let res = await Api.post("/shipment/refresh/" + id);
    return res.data;
  },
  async getShipments(query) {
    let res = await Api.get("/shipment", query);
    return res.data;
  },
  async getOverviewShipments(query) {
    let res = await Api.get("/shipmentOverview", query);
    return res.data;
  },
  async getOverviewShipmentContainers(query) {
    let res = await Api.get("/shipmentOverviewContainers", query);
    return res.data;
  },
  async getOverviewShipmentsByCustomer(relationshipId, query) {
    let res = await Api.get(
      "/shipmentOverview/relationship/" + relationshipId,
      query
    );
    return res.data;
  },
  async getShipmentOverviewDetail(query) {
    let res = await Api.post("/shipmentOverview", query);
    return res.data;
  },
  async getShipmentOverviewProductSummary(query) {
    let res = await Api.post("/shipmentOverview/product/summary", query);
    return res.data;
  },
  async getShipmentExport() {
    let res = await Api.get("/shipment/export");
    return res.data;
  },
  async getShipmentRelationships(id) {
    let res = await Api.get("/shipment/relationships/" + id);
    return res.data;
  },
  async getShipmentMassActions(id) {
    let res = await Api.get("/shipment/massActions/" + id);
    return res.data;
  },
  async startMassAction(id, code) {
    let res = await Api.post("/shipment/massActions/" + id + "/" + code);
    return res.data;
  },
  async getShipmentActions(id) {
    let res = await Api.get("/shipment/actions/" + id);
    return res.data;
  },
  async getShipmentActionDetail(id) {
    let res = await Api.get("/shipment/actions/detail/" + id);
    return res.data;
  },
  async getShipmentEvents(id) {
    let res = await Api.get("/shipment/events/" + id);
    return res.data;
  },
  async updateShipmentEvent(obj) {
    let res = await Api.put("/shipment/events/" + obj.id, obj);
    return res.data;
  },
  async createShipmentEventLink(obj) {
    let res = await Api.post("/shipment/eventsLink", obj);
    return res.data;
  },
  async createShipmentEvent(obj) {
    let res = await Api.post("/shipment/events", obj);
    return res.data;
  },
  async getShipmentEventActions(id) {
    let res = await Api.get("/shipment/events/actions/" + id);
    return res.data;
  },
  async getShipmentEventAttachments(id) {
    let res = await Api.get("/shipment/events/attachments/" + id);
    return res.data;
  },
  async submitShipmentActions(obj) {
    let res = await Api.put("/shipment/actions/" + obj.id, obj);
    return res.data;
  },
  async getShipmentProducts(id) {
    let res = await Api.get("/shipment/products/" + id);
    return res.data;
  },

  async getCustomerShipments(id, query) {
    let res = await Api.get("/shipment/customer/" + id, query);
    return res.data;
  },
  async getCustomerShipmentMetrics(id) {
    let res = await Api.get("/shipment/customer/metrics/" + id);
    return res.data;
  },
  async getShipmentMetrics(type, quantityType) {
    let res = await Api.get(`/metrics/shipment/${type}/${quantityType}`);
    return res.data;
  },
  async getLatestContainerMetrics(obj) {
    let res = await Api.post("/metrics/container/latest", obj);
    return res.data;
  },
  async getCustomerCommodityOverview(obj) {
    let res = await Api.post("/metrics/container/commodity", obj);
    return res.data;
  },
  async saveActiveDocFavorite(obj) {
    let res = await Api.post("/shipment/user/active/documentation", obj);
    return res.data;
  },

  async saveUserFilterEvents(obj) {
    let res = await Api.post("/documentation/dashboard/events/fav/user", obj);
    return res.data;
  },
  async getDocumentDashboardData(query, signal) {
    let res = await Api.get("/documentation/dashboard", query, {
    signal});
    return res.data;
  },
  async getDocumentDashboardUserPerformance(query, signal) {
    let res = await Api.get("/documentation/user",query, {
    signal});
    return res.data;
  },
  async getShipmentDocumentationPerformance(shipmentId){
    let res = await Api.get("/documentation/file/performance/"+shipmentId);
    return res.data;
  },
  async getDashboardDocs(query, signal) {
    let res = await Api.get("/documentation/getDashboardDocs", query, {
      signal
    });
    return res.data;
  },

  async getEventsDocumentMetrics(eventTypeId, obj) {
    let res = await Api.post(
      "/documentation/documentMetrics/events/" + eventTypeId,
      obj
    );
    return res.data;
  },
  async getCustomerLocationOverview(obj) {
    let res = await Api.post("/metrics/container/location", obj);
    return res.data;
  },
  async getContainerMetrics() {
    let res = await Api.get("/shipment/metrics/month");
    return res.data;
  },
  async getActiveFiles(obj, signal) {
    let res = await Api.post("/shipment/documentMetrics/files", obj, {
      signal,
    });
    return res.data;
  },
  async getShipmentOverdueDocumentActive(obj, signal) {
    let res = await Api.post("/shipment/documentMetrics/active", obj, {
      signal,
    });
    return res.data;
  },
  async getShipmentActiveDocumentDetails(locationDocumentId, obj) {
    let res = await Api.post(
      "/shipment/documentMetrics/active/" + locationDocumentId,
      obj
    );
    return res.data;
  },
  async saveNotLinkedFav(obj) {
    let res = await Api.post(
      "/shipment/dash/user/notLinked/saveFavourite",
      obj
    );
    return res.data;
  },
  async getShipmentOverdueDocumentMetrics(obj, signal) {
    let res = await Api.post("/shipment/documentMetrics/overdue", obj, {
      signal,
    });
    return res.data;
  },
  async getShipmentOverdueDocumentDetails(locationDocumentId, obj) {
    let res = await Api.post(
      "/shipment/documentMetrics/overdue/" + locationDocumentId,
      obj
    );
    return res.data;
  },
  async notReadyToInvoiceCount(obj) {
    let res = await Api.post("/shipment/notReadyToInvoice/count", obj);
    return res.data;
  },
  async notReadyToInvoice(obj) {
    let res = await Api.get("/shipment/notReadyToInvoice", obj);
    return res.data;
  },
  async getOpenFiles(obj) {
    let res = await Api.get("/shipment/openFiles", obj);
    return res.data;
  },
  async getAllDocumentsForOrg(body, signal) {
    let res = await Api.post("/getDocs", body, {
      signal,
    });
    return res.data;
  },
  async getUpcomingShipments(obj, signal) {
    let res = await Api.post("/shipment/metrics/upcoming", obj, {
      signal,
    });
    return res.data;
  },
  async getShippersFiles(obj) {
    let res = await Api.post("/shipment/metrics/upcoming/shipperFiles", obj);
    return res.data;
  },
  async getCustomerPerformance(obj) {
    let res = await Api.post("/shipment/contact/performance", obj);
    return res.data;
  },
  async createShipment(obj) {
    let res = await Api.post("/shipment", obj);
    return res.data;
  },
  async createShipmentChange(shipmentId, obj) {
    let res = await Api.post("/shipment/change/" + shipmentId, obj);
    return res.data;
  },
  async getShipmentChanges(shipmentId) {
    let res = await Api.get("/shipment/change/" + shipmentId);
    return res.data;
  },
  async createShipmentFromFile(obj) {
    let res = await Api.post("/shipment/file", obj);
    return res.data;
  },
  async generateFileCover(id) {
    let res = await Api.get("/shipmentFile/cover/" + id);
    return res.data;
  },
  async generateShipmentFileCover(id) {
    let res = await Api.get("/shipment/cover/" + id);
    return res.data;
  },
  async cancelFile(id) {
    let res = await Api.post("/shipment/file/cancel/" + id);
    return res.data;
  },
  async getDraftFiles() {
    let res = await Api.get("/shipment/file/draft");
    return res.data;
  },
  async getReadyFiles(query) {
    let res = await Api.get("/shipment/file/ready", query);
    return res.data;
  },
  async getReadyFileCount(obj) {
    let res = await Api.post("/shipment/file/readyCount", obj);
    return res.data;
  },
  async getRejectedDocumentFiles() {
    let res = await Api.get("/shipment/file/rejectedDocument");
    return res.data;
  },
  async getRejectedDocumentFileCount() {
    let res = await Api.get("/shipment/file/rejectedDocumentCount");
    return res.data;
  },
  async addContainersToFile(obj) {
    let res = await Api.post("/shipment/file/container", obj);
    return res.data;
  },
  async removeContainersFromFile(obj) {
    let res = await Api.post("/shipment/remove/container", obj);
    return res.data;
  },

  async updateShipment(obj) {
    let res = await Api.put("/shipment/" + obj.id, obj);
    return res.data;
  },
  async updateShipmentFileStatus(shipmentId, status) {
    let res = await Api.put("/shipment/fileStatus/" + shipmentId, status);
    return res.data;
  },
  async updateShipmentDetail(obj) {
    let res = await Api.put("/shipment/detail/" + obj.id, obj);
    return res.data;
  },
  async createShipmentRelationship(obj) {
    let res = await Api.post("/shipment/relationship", obj);
    return res.data;
  },
  async updateShipmentRelationship(obj) {
    let res = await Api.put("/shipment/relationships/" + obj.id, obj);
    return res.data;
  },
  async getShipmentVesselVoyage(reference) {
    let res = await Api.get("/shipment/vessel/" + reference);
    return res.data;
  },
  async getVesselOverview(query) {
    let res = await Api.get("/shipment/vessel", query);
    return res.data;
  },
  async getShipmentsOverview(query, options) {
    let res = await Api.post("/shipment/vessel", query, options);
    return res.data;
  },

  // Shipment Bookings

  async getShipmentBookings(id) {
    let res = await Api.get("/shipmentBooking/" + id);
    return res.data;
  },
  async createBooking(obj) {
    let res = await Api.post("/booking", obj);
    return res.data;
  },
  async bulkUpdateBookings(obj) {
    let res = await Api.post("/booking/bulkUpdate", obj);
    return res.data;
  },
  async submitBooking(id, obj) {
    let res = await Api.post(`/booking/submit/${id}`, obj);
    return res.data;
  },
  async cancelBookingIntegration(id) {
    let res = await Api.put(`/booking/cancelIntegration/${id}`, {});
    return res.data;
  },
  async createBookingSubscriber(obj) {
    let res = await Api.post("/bookingSubscriber", obj);
    return res.data;
  },
  async updateBookingSubscriber(obj) {
    let res = await Api.put("/bookingSubscriber/"+obj.id, obj);
    return res.data;
  },
  async createBookingComment(obj) {
    let res = await Api.post(`/booking/comment`, obj);
    return res.data;
  },
  async getBookingComment(id) {
    let res = await Api.get(`/booking/comment/${id}`);
    return res.data;
  },
  async deleteBookingComment(obj) {
    let res = await Api.post("/booking/comment/deleteComment",obj);
    return res.data;
  },
 async updateBookingComment(obj) {
  let res = await Api.post("/booking/comment/editComment",obj);
  return res.data;
},

  async getPackCodes(productId) {
    let res = await Api.get(`/booking/packCodes/${productId}`);
    return res.data;
  },
  async submitBookingUpdate(id) {
    let res = await Api.post(`/booking/submit/update/${id}`);
    return res.data;
  },
  async createSplitBooking(obj) {
    let res = await Api.post("/booking/split", obj);
    return res.data;
  },
  async cancelBookingContainers(obj) {
    let res = await Api.post("/booking/cancel/container", obj);
    return res.data;
  },
  async reinstateBookingContainer(obj) {
    let res = await Api.post("/booking/reinstate/container", obj);
    return res.data;
  },
  async updateContainerVents(obj) {
    let res = await Api.post("/booking/vents/container", obj);
    return res.data;
  },
  async createContainerItem(id, obj) {
    let res = await Api.post("/booking/container/" + id, obj);
    return res.data;
  },
  async createProfileContainerItem(id, obj) {
    let res = await Api.post("/consigneeProfile/container/" + id, obj);
    return res.data;
  },
  async updateProfileContainerItem(id, obj) {
    let res = await Api.put("/consigneeProfile/container/" + id, obj);
    return res.data;
  },
  async createProfileCargoItem(id, obj) {
    let res = await Api.post("/consigneeProfile/cargo/" + id, obj);
    return res.data;
  },
  async updateProfileCargoItem(id, obj) {
    let res = await Api.put("/consigneeProfile/cargo/" + id, obj);
    return res.data;
  },
  async getConsigneeProfileBillingItems(id) {
    let res = await Api.get("/consigneeProfile/billingItems/" + id);
    return res.data;
  },
  async updateConsigneeProfileBillingItem(obj) {
    let res = await Api.put("/consigneeProfile/billingItems/" + obj.id, obj);
    return res.data;
  },
  async createConsigneeProfileBillingItem(obj) {
    let res = await Api.post("/consigneeProfile/billingItems", obj);
    return res.data;
  },

  async createContainerProduct(obj) {
    let res = await Api.post("/booking/product/container", obj);
    return res.data;
  },
  async updateContainerProduct(obj) {
    let res = await Api.put("/booking/container/product/" + obj.id, obj);
    return res.data;
  },
  async createShipmentContainerProduct(obj) {
    let res = await Api.post("/booking/shipment/container", obj);
    return res.data;
  },
  async updateShipmentContainerProduct(obj) {
    let res = await Api.put("/booking/shipment/product/" + obj.id, obj);
    return res.data;
  },
  async getBookingContracts(query) {
    let res = await Api.get("/booking/contract", query);
    return res.data;
  },
  async createBookingPayment(obj) {
    let res = await Api.post("/booking/paymentDetail", obj);
    return res.data;
  },
  async updateBookingPayment(obj) {
    let res = await Api.put("/booking/paymentDetail/" + obj.id, obj);
    return res.data;
  },
  async createBookingFile(obj) {
    let res = await Api.post("/booking/file", obj);
    return res.data;
  },
  async createFileFromShipment(obj) {
    let res = await Api.post("/shipmentFile/create", obj);
    return res.data;
  },
  async updateBooking(obj) {
    let res = await Api.put("/booking/" + obj.id, obj);
    return res.data;
  },
  async getBookingDetail(id) {
    let res = await Api.get("/booking/" + id);
    return res.data;
  },
  async getBookingDetailAll(id) {
    let res = await Api.get("/bookingAll/" + id);
    return res.data;
  },
  async getBookingEmissions(id, obj) {
    let res = await Api.get("/booking/emissions/" + id, obj);
    return res.data;
  },
  async getBookingContainers(id) {
    let res = await Api.get("/booking/container/" + id);
    return res.data;
  },
  async getBookingForms(id) {
    let res = await Api.get("/booking/integrations/" + id);
    return res.data;
  },
  async updateBookingForms(obj) {
    let res = await Api.put("/booking/integrations/" + obj.id, obj);
    return res.data;
  },
  async getBookingContainersBasic(id) {
    let res = await Api.get("/booking/container/basic/" + id);
    return res.data;
  },
  async getMatchingBookingContainers(params) {
    let res = await Api.get("/matching/container", params);
    return res.data;
  },
  async getLinkedFileBookings(id) {
    let res = await Api.get("/file/linkedBookings/" + id);
    return res.data;
  },
  async getContainerExceptions() {
    let res = await Api.get("/booking/exceptions");
    return res.data;
  },
  async updatePrimaryFileBooking(id, obj) {
    let res = await Api.put("/file/linkedBooking/" + id, obj);
    return res.data;
  },
  async updateContainerNo(obj) {
    let res = await Api.put("/booking/containerNo/update", obj);
    return res.data;
  },
  async updateSealNo(obj) {
    let res = await Api.put("/booking/sealNo/update", obj);
    return res.data;
  },
  async updateCustomerRef(obj) {
    let res = await Api.put("/booking/customerRef/update", obj);
    return res.data;
  },
  async updateVerificationMethod(obj) {
    let res = await Api.put("/booking/verification/update/" + obj.id, obj);
    return res.data;
  },
  async addPoiToContainers(obj) {
    let res = await Api.put(`/booking/addPoiToContainers`, obj);
    return res.data;
  },
  async addPoiToContainerItem(obj) {
    let res = await Api.put(`/booking/addPoiToContainerItem`, obj);
    return res.data;
  },
  async getCustomerBookings(id) {
    let res = await Api.get("/booking/customer/" + id);
    return res.data;
  },
  async updateManaulUpdateStatus(obj) {
    let res = await Api.post("/booking/manualUpdate/status", obj)
    return res.data
  },
  async getPreadvisePayload(id) {
    let res = await Api.get("/container/preadvise/payload/" + id);
    return res.data;
  },
  async manaulUpdateBookingType(obj) {
    let res = await Api.post("/booking/manualUpdate/type", obj)
    return res.data
  },
  async manaulUpdateServiceType(obj) {
    let res = await Api.post("/booking/manualUpdate/serviceType", obj)
    return res.data
  },
  async removeExistingBookingPaymentDetails(id) {
    let res = await Api.put("/booking/payment/" + id)
    return res.data
  },

  // Orders
  async createOrder(obj) {
    let res = await Api.post("/order", obj);
    return res.data;
  },
  async convertBookingToOrder(id) {
    let res = await Api.put("/order/convert/"+id, {});
    return res.data;
  },
  async createPackingList(id) {
    let res = await Api.put("/order/generatePackingList/"+id, {});
    return res.data;
  },
  async createOrderAccountSale(id) {
    let res = await Api.post("/order/accountSale/"+id);
    return res.data;
  },
  async getAccountSales(id){
    let res = await Api.get("/order/accountSale/"+id);
    return res.data;
  },
  async createOrderInvoice(obj,orderId) {
    let res = await Api.post("/order/invoice/"+orderId, obj);
    return res.data;
  },
  async updateOrderInvoice(obj) {
    let res = await Api.put("/order/invoice/" + obj.id, obj);
    return res.data;
  },
  async getOrderInvoices(id){
    let res = await Api.get("/order/invoice/"+id);
    return res.data;
  },
  async getOrderCreditNotes(id){
    let res = await Api.get("/order/creditNote/"+id);
    return res.data;
  },
  async deleteCreditNote(id) {
    let res = await Api.post("/order/removeCreditNote/"+id)
    return res.data
  },
  // async deleteCreditNote(obj) {
  //   let res = await Api.post(`/accounting/creditNote/delete`, obj);
  //   return res.data;
  // }, 
  async deleteInvoice(id) {
    let res = await Api.post("/order/removeInvoice/"+id)
    return res.data
  },
  async createInvoicePayment(obj, invoiceId) {
    let res = await Api.post("/order/invoice/payment/"+invoiceId, obj);
    return res.data;
  },
  async updateInvoicePayment(obj) {
    let res = await Api.put("/order/invoice/payment/" + obj.id, obj);
    return res.data;
  },
  async createOrderItem(bookingId, obj) {
    let res = await Api.post("/order/item/" + bookingId, obj);
    return res.data;
  },
  async updateOrderItem(obj) {
    let res = await Api.put("/order/item/" + obj.id, obj);
    return res.data;
  },
  async bulkUpdateOrderItemStatus(obj) {
    let res = await Api.post("/order/bulkUpdate", obj);
    return res.data;
  },
  async getOrderItems(obj){
    let res = await Api.get("/order/item/basic", obj);
    return res.data;
  },
  async getManagedBookingItems(obj){
    let res = await Api.get("/booking/managed/item", obj);
    return res.data;
  },
  async getOrderItemDetail(id){
    let res = await Api.get("/order/item/detail/"+id);
    return res.data;
  },
  async removeOrderContainer(obj) {
    let res = await Api.put("/order/remove/container/" + obj.id, obj);
    return res.data;
  },
  async updateOrder(data) {
    let res = await Api.put("/order/" + data.id, data);
    return res.data;
  },
  async getOrders(obj) {
    let res = await Api.get("/order", obj);
    return res.data;
  },
  async getBasicOrders(obj) {
    let res = await Api.get("/order/basic", obj);
    return res.data;
  },
  async getBasicAvailableOrders(obj) {
    let res = await Api.get("/order/basic/available", obj);
    return res.data;
  },
  async getOpenOrders() {
    let res = await Api.get("/order/open");
    return res.data;
  },
  async getOrderAllocations(obj) {
    let res = await Api.post("/order/allocation", obj);
    return res.data;
  },
  async getAllocatedPallets(id) {
    let res = await Api.get("/order/allocation/pallets/"+id);
    return res.data;
  },
  async getBulkAllocatedPallets(array){
    let res = await Api.get("/order/allocation/bulk/pallets", {
      params: {
        items: array
      }
    });
    return res.data;
  },
  async getManagedAllocatedPallets(id) {
    let res = await Api.get("/order/allocation/managed/pallets/"+id);
    return res.data;
  },
  async allocateOrder(containerId, obj) {
    let res = await Api.post("/order/allocation/" + containerId, obj);
    return res.data;
  },
  async allocateManagedOrder(containerId, obj) {
    let res = await Api.post("/order/managed/allocation/" + containerId, obj);
    return res.data;
  },
  async getOrderDetail(reference) {
    let res = await Api.get("/order/" + reference);
    return res.data;
  },
  async getOrderDashboardSummary() {
    let res = await Api.get("/order/dashboard/summary");
    return res.data;
  },
  async getOrderContainers(id) {
    let res = await Api.get("/order/container/" + id);
    return res.data;
  },
  async getContainerStockAllocation(id) {
    let res = await Api.get("/order/allocation/" + id);
    return res.data;
  },
  async getStockLoadoutDetails(obj){
    let res = await Api.post('/order/loadout', obj)
    return res.data
  },
  async getOrderDocuments(id) {
    let res = await Api.get("/order/documents/" + id);
    return res.data;
  },
  async uploadOrderDocument(obj) {
    let res = await Api.post("/order/upload", obj);
    return res.data;
  },
  async getOrderFinancialSettings(id) {
    let res = await Api.get("/order/financialSettings/" + id);
    return res.data;
  },
  async updateOrderFinancialSettings(obj){
    let res = await Api.put("/order/financialSettings/"+obj.id, obj);
    return res.data;
  },
  async createChildAccountSaleSetting(obj){
    let res = await Api.post("/order/createChildSetting", obj)
    return res.data;
  },
  async createBulkChildAccountSaleSetting(obj){
    let res = await Api.post("/order/createBulkChildSetting", obj)
    return res.data
  },
  async updateChildAccountSaleSetting(obj){
    let res = await Api.post("/order/updateChildSetting", obj)
    return res.data
  },
  async getChildSetting(obj) {
    let res = await Api.get("/order/getChildSetting", {params: obj})
    return res.data;
  },
  async getActiveChildSettings(id) {
    let res = await Api.get("/order/getActiveChildSettings/"+id)
    return res.data;
  },
  async deleteChildSettings(arr) {
    let res = await Api.post("/order/deleteChildSettings", arr)
    return res.data;
  },
  async getOrderBillingItems(id) {
    let res = await Api.get("/order/billingItems/" + id);
    return res.data;
  },
  
  async createOrderBillingItems(obj) {
    let res = await Api.post("/order/billingItem", obj);
    return res.data;
  },
  async updateOrderBillingItems(obj) {
    let res = await Api.put("/order/billingItem/"+obj.id, obj);
    return res.data;
  },
  async getOrderSalesItems(id) {
    let res = await Api.get("/order/salesItems/" + id);
    return res.data;
  },
  async createOrderSalesItems(obj) {
    let res = await Api.post("/order/salesItem", obj);
    return res.data;
  },
  async updateOrderSalesItems(obj) {
    let res = await Api.put("/order/salesItem/"+obj.id, obj);
    return res.data;
  },

  async getRelatedStock(id) {
    let res = await Api.get("/order/stock/" + id);
    return res.data;
  },
  async getRelatedManagedStock(id) {
    let res = await Api.get("/order/managed/stock/" + id);
    return res.data;
  },
  async getStockDashboardPallets(params) {
    let res = await Api.get("/stock/dashboard/pallets",{params});
    return res.data;
  },
  async getStockStoreOverview() {
    let res = await Api.get("/stock/stores");
    return res.data;
  },
  async deleteStock(pallets) {
    let res = await Api.post("/stock/delete", { pallets });
    return res.data;
  },
  async getStockExport(params={}) {
    let res = await Api.get("/stock/export",{params});
    return res.data;
  },
  async getStockExportEmail(params={}) {
    let res = await Api.get("/stock/export/email",{params});
    return res.data;
  },
  async updateStockPalletStatus(pallets, status) {
    let res = await Api.put("/stock/pallet/status", { pallets, status });
    return res.data;
  },
  async getStockWarnings() {
    let res = await Api.get("/stock/warnings");
    return res.data;
  },
  async acceptStockDiscrepancy(id) {
    let res = await Api.post("/stock/discrepancies/accept/" + id);
    return res.data;
  },
  async getStockDiscrepancies() {
    let res = await Api.get("/stock/discrepancies");
    return res.data;
  },
  async getStockDiscrepancyDetail(id) {
    let res = await Api.get("/stock/discrepancies/"+id);
    return res.data;
  },
  async createLoadOutInstructions(id) {
    let res = await Api.post("/order/loadout/" + id);
    return res.data;
  },
  async sendLoadOutInstruction(id) {
    let res = await Api.post("/order/loadout/send/" + id);
    return res.data;
  },
  async getAllOrderContainersBasic(batch) {
    let res = await Api.post("/order/container/basic", batch);
    return res.data;
  },
  async getAllManagedOrderContainers(batch){
    let res = await Api.post("/order/container/managed", batch);
    return res.data;
  },
  async getOrderContainersBasic(id) {
    let res = await Api.get("/order/container/basic/" + id);
    return res.data;
  },
  async createOrderSetting(data) {
    let res = await Api.post("/orderSettings", data);
    return res.data;
  },
  async updateOrderSetting(data) {
    let res = await Api.put("/orderSettings/" + data.id, data);
    return res.data;
  },
  async getOrderSettings(productId) {
    let res = await Api.get("/orderSettings/" + productId);
    return res.data;
  },
  async getAllOrderSettings() {
    let res = await Api.get("/orderSettings");
    return res.data;
  },
  async getOrganisationTradedProduct(productId) {
    let res = await Api.get("/tradedProducts/" + productId);
    return res.data;
  },
  async getProductionAliases(obj={}){
    let res = await Api.get("/productionAlias", {
      params: obj
    });
    return res.data;
  },
  async updateProductionAlias(obj){
    let res = await Api.put("/productionAlias/"+obj.id, obj);
    return res.data;
  },
  async createProductionAlias(obj){
    let res = await Api.post("/productionAlias", obj);
    return res.data;
  },
  async updateProductionAliasName(id, obj){
    let res = await Api.post("/productionAlias/name/"+id, obj);
    return res.data;
  },

  async createTradedProduct(data) {
    let res = await Api.post("/order/tradedProducts", data);
    return res.data;
  },
  async updateTradedProduct(data) {
    let res = await Api.put("/order/tradedProducts/" + data.id, data);
    return res.data;
  },
  async updateProductionAliasStock(data) {
    let res = await Api.post("/order/productionAlias", data);
    return res.data;
  },
  async getTradedProducts() {
    let res = await Api.get("/tradedProducts");
    return res.data;
  },
  async createTradedVariation(data) {
    let res = await Api.post("/tradeVariation", data);
    return res.data;
  },
  async getAllTradeVariations() {
    let res = await Api.get("/tradeVariation");
    return res.data;
  },
  async updateTradedVariation(data) {
    let res = await Api.put("/tradeVariation/" + data.id, data);
    return res.data;
  },
  async getTradedVariations(id) {
    let res = await Api.get("/tradeVariation/"+id);
    return res.data;
  },
  async getTradedVariationByProduct(productId) {
    let res = await Api.get("/tradeVariation/product/"+productId);
    return res.data;
  },

  
  async getStockReport() {
    let res = await Api.get("/stock/report");
    return res.data;
  },

  // Orders - Update old relationshipBillingItems
  // async bulkUpdateBillingItemsScript() {
  //   let res = await Api.post("/order/updateBillingItems");
  //   return res.data
  // },
  // Shipping Contracts

  async getShippingContracts() {
    let res = await Api.get("/shippingContract");
    return res.data;
  },
  async createShippingContract(obj) {
    let res = await Api.post("/shippingContract", obj);
    return res.data;
  },
  async updateShippingContract(obj) {
    let res = await Api.put("/shippingContract/" + obj.id, obj);
    return res.data;
  },

  async createShippingContractTarget(obj) {
    let res = await Api.post("/shippingContractTarget", obj);
    return res.data;
  },
  async updateShippingContractTarget(obj) {
    let res = await Api.put("/shippingContractTarget/" + obj.id, obj);
    return res.data;
  },

  //General Contracts
  async getOrganisationContracts() {
    let res = await Api.get("/contracts");
    return res.data;
  },
  async getDangerContractCount() {
    let res = await Api.get("/contracts/dangerCount");
    return res.data;
  },
  async getDangerContractItems(type) {
    let res = await Api.get(`/contracts/dangerItems/${type}`);
    return res.data;
  },
  // Shipment Containers
  async resetContainerSync(id) {
    let res = await Api.put(`/shipment/container/resync/${id}`);
    return res.data;
  },

  async getShipmentContainers(id) {
    let res = await Api.get("/shipment/container/" + id);
    return res.data;
  },
  async getBasicShipmentContainers(id) {
    let res = await Api.get("/shipment/container/basic/" + id);
    return res.data;
  },
  async getSingleShipmentContainer(id) {
    let res = await Api.get("/shipment/container/single/" + id);
    return res.data;
  },

  async validateContainerNo(containerNo) {
    let res = await Api.post(`/validateContainer/${containerNo}`);
    return res.data;
  },
  async updatePalletWeights(obj) {
    let res = await Api.post(`/booking/updatePalletWeights`, obj);
    return res.data;
  },

  async getContainer(id) {
    let res = await Api.get("/container/" + id);
    return res.data;
  },
  async getContainerTelemetryData(id) {
    let res = await Api.get("/container/telemetry/" + id);
    return res.data;
  },
  async getContainerMilestones(id) {
    let res = await Api.get("/container/milestone/" + id);
    return res.data;
  },
  async getBookingContainerOverview(params) {
    let res = await Api.get("/containerOverview", params);
    return res.data;
  },
  async getBookingTransportContainerOverview(params) {
    let res = await Api.get("/transport/containerOverview", params);
    return res.data;
  },
  async getContainersNoPOH(){
    let res = await Api.get("/transport/container/noPOH");
    return res.data;
  },


  async getTransporterContainerBookings(params) {
    let res = await Api.get("/transporter/container-bookings", params);
    return res.data;
  },
  async getContainerPortMonitoringData(id, vesselVoyageId) {
    let res = await Api.get(
      "/telemetry/shipment/portMonitoring/" + id + "/" + vesselVoyageId
    );
    return res.data;
  },
  async getContainerSummary(shipmentId, id) {
    let result = await Api.get("/container/summary/" + shipmentId + "/" + id);
    return result.data;
  },
  async getShipmentFileContainer(id) {
    let res = await Api.get("/container/file/" + id);
    return res.data;
  },
  async assignGhostContainers(obj) {
    let res = await Api.post("/container/assignGhost", obj);
    return res.data;
  },
  async updateContainer(obj) {
    let res = await Api.put("/container/" + obj.id, obj);
    return res.data;
  },
  async updatePartyIdToNull(obj) {
    let res = await Api.put("/container/" + obj.id, obj);
    return res.data;
  },
  async getContainerHistory(id) {
    let res = await Api.get("/container/history/" + id);
    return res.data;
  },
  async bulkUploadCTO(obj) {
    let res = await Api.post("/container/bulkUploadCTO", obj);
    return res.data;
  },
  async updatePPECBContainer(obj) {
    let res = await Api.put("/container/ppecb/" + obj.id, obj);
    return res.data;
  },
  async getContainerDocuments(id) {
    let res = await Api.get("/container/document/" + id);
    return res.data;
  },
  async getContainerDocumentTypes(id, shipmentId) {
    let res = await Api.get("/container/documentType/" + id + "/" + shipmentId);
    return res.data;
  },
  async getContainerTasks(id, shipmentId) {
    let res = await Api.get("/container/tasks/" + id + "/" + shipmentId);
    return res.data;
  },
  async getTempLogsDataByContainer(containerNumber) {
    let res = await Api.get("/tempLogs/tempData/" + containerNumber);
    return res.data;
  },
  // Shipment Documents
  async getShipmentDocuments(id) {
    let res = await Api.get("/shipment/document/" + id);
    return res.data;
  },
  async getShipmentDocumentsAdmin() {
    let res = await Api.get("/admin/shipment/document/");
    return res.data;
  },
  async createShipmentDocumentsTemplate() {
    let res = await Api.post("/admin/shipment/document/");
    return res.data;
  },
  async getShipmentProductSummary(shipmentId) {
    let result = await Api.get("/shipment/product/summary/" + shipmentId);
    return result.data;
  },
  async mergeShipmentDocuments(obj) {
    let res = await Api.post("/shipment/document/merge", obj);
    return res.data;
  },
  async createDocumentPack(obj) {
    let res = await Api.post("/shipment/document/container", obj);
    return res.data;
  },
  async getShipmentDocumentDetail(id) {
    let res = await Api.get("/shipment/document/detail/" + id);
    return res.data;
  },
  async getShipmentSubscribers(id) {
    let res = await Api.get("/shipment/subscribers/" + id);
    return res.data;
  },
  async updateShipmentSubscribers(obj) {
    let res = await Api.put("/shipment/subscribers/" + obj.id, obj);
    return res.data;
  },
  async createShipmentSubscribers(obj) {
    let res = await Api.post("/shipment/subscribers", obj);
    return res.data;
  },
  async getShipmentTasks(id) {
    let res = await Api.get("/shipment/tasks/" + id);
    return res.data;
  },
  async updateTaskPoll(obj){
    let res = await Api.post("/shipment/task/poll", obj);
    return res.data;
  },

  async resetAllShipmentTasks(id) {
    let res = await Api.put("/shipment/tasks/reset/" + id);
    return res.data;
  },
  async resetDocumentTasks(id, containers, documents) {
    let res = await Api.put("/shipment/tasks/document/reset/" + id, {
      containers: containers,
      documents: documents
    });
    return res.data;
  },
  async startDocumentTasks(id, obj) {
    let res = await Api.put("/shipment/tasks/start/" + id, obj);
    return res.data;
  },
  async updateShipmentDocument(obj) {
    let res = await Api.put("/shipment/document/" + obj.id, obj);
    return res.data;
  },
  async copyShipmentDocument(obj) {
    let res = await Api.post("/shipment/document/copy", obj);
    return res.data;
  },
  async getShipmentDocumentMetadata(id) {
    let res = await Api.get("/shipment/metadata/" + id);
    return res.data;
  },
  async getShipmentDocumentTypes(id) {
    let res = await Api.get("/shipment/documentType/" + id);
    return res.data;
  },
  async addAttachmentToDocumentType(obj) {
    let res = await Api.post("/shipment/documentType/attachment", obj);
    return res.data;
  },
  async updateAttachmentToDocumentType(obj) {
    let res = await Api.put("/shipment/documentType/attachment/" + obj.id, obj);
    return res.data;
  },
  async getShipmentDocumentTypeDetail(id) {
    let res = await Api.get("/shipment/documentType/detail/" + id);
    return res.data;
  },
  async getShipmentDoucmentTypeByShipment(obj) {
    let res = await Api.post("/shipment/shipmentdocumenttype/data", obj);
    return res.data;
  },
  async getShipmentDocumentTasks(id) {
    let res = await Api.get("/shipment/documentType/tasks/" + id);
    return res.data;
  },
  async getShipmentDocumentLog(id) {
    let res = await Api.get("/shipment/documentType/log/" + id);
    return res.data;
  },
  async getBillableShipmentDocumentTypeLog(id) {
    let res = await Api.get("/shipment/documentType/log/billable/" + id);
    return res.data;
  },
  async getShipmentLinkedDocuments(id) {
    let res = await Api.get("/shipment/linked/" + id);
    return res.data;
  },
  async getOpenWaybills(obj) {
    let res = await Api.post("/shipment/waybill/" + obj.id, obj);
    return res.data;
  },
  async getAllShipmentDocumentTypes(params) {
    let res = await Api.get("/shipment/documentType", params);
    return res.data;
  },
  async createShipmentDocumentType(obj) {
    let res = await Api.post("/shipment/documentType", obj);
    return res.data;
  },
  async updateShipmentDocumentType(obj) {
    let res = await Api.put("/shipment/documentType/" + obj.id, obj);
    return res.data;
  },
  async getAllShipmentDocuments(obj) {
    let res = await Api.get("/document", obj);
    return res.data;
  },
  async createShipmentContainerDocument(obj) {
    let res = await Api.post("/shipment/containerDoc", obj);
    return res.data;
  },
  async updateShipmentContainerDocuments(obj) {
    let res = await Api.put("/shipment/containerDoc/" + obj.id, obj);
    return res.data;
  },
  async addShipmentDocumentTypeComment(obj) {
    let res = await Api.post("/documnet/comment", obj);
    return res.data;
  },
  async updateShipmentDocumentTypeLog(obj) {
    let res = await Api.put("/shipment/documentType/log/" + obj.id, obj);
    return res.data;
  },
  async createShipmentTypeDocument(obj) {
    let res = await Api.post("/shipment/documentType/document", obj);
    return res.data;
  },
  async updateShipmentTypeDocument(obj) {
    let res = await Api.put("/shipment/documentType/document/" + obj.id, obj);
    return res.data;
  },

  async createShipmentTypeSupportingDocument(obj) {
    let res = await Api.post("/shipment/documentType/supporting", obj);
    return res.data;
  },

  async generateDocument(obj) {
    let res = await Api.post("/generate/document", obj);
    return res.data;
  },
  async createShipmentDocumentClassification(obj) {
    let res = await Api.post("/shipment/documentClassification", obj);
    return res.data;
  },
  async updateShipmentDocumentClassifications(obj) {
    let res = await Api.put("/shipment/documentClassification", obj);
    return res.data;
  },

  async getShipmentFilterDetails(obj) {
    let res = await Api.post("/filter", obj);
    return res.data;
  },
  async getVesselFilterDetails(obj) {
    let res = await Api.post("/filter/vessel", obj);
    return res.data;
  },

  // Shipment Comments

  async updateShipmentDocumentComments(obj) {
    let res = await Api.put("/document/comments/" + obj.id, obj);
    return res.data;
  },
  async deleteShipmentDocumentComments(id) {
    let res = await Api.put(`/documents/comments/delete/${id}`);
    return res.data;
  },
  async getShipmentComments(shipmentId) {
    let res = await Api.get(`/shipment/comments/${shipmentId}`);
    return res.data;
  },
  // async deleteShipmentComment(id) {
  //   let res = await Api.put(`/shipment/comments/delete/${id}`);
  //   return res.data;
  // },
  async getShipmentDocumentComments(shipmentId) {
    let res = await Api.get(`/shipment/documents/comments/${shipmentId}`);
    return res.data;
  },
  async getShipmentDocumentTypeComments(id) {
    let res = await Api.get(`/shipment/documentType/comments/${id}`);
    return res.data;
  },
  async createShipmentComment(obj) {
    let res = await Api.post(`/shipment/comments/`, obj);
    return res.data;
  },
  // async updateShipmentComment(obj) {
  //   let res = await Api.put(`/shipment/comments/${obj.id}`, obj);
  //   return res.data;
  // },

  // Shipment files
  async checkShipmentFileExist(id) {
    let res = await Api.get(`/shipmentFile/exist/${id}`);
    return res.data;
  },
  async listShipmentFiles(obj) {
    let res = await Api.get("/shipmentFile", obj);
    return res.data;
  },
  async listShipmentFile(obj) {
    let res = await Api.get("/shipmentFile", obj);
    return res.data;
  },
  async listShipmentFileBooking(obj) {
    let res = await Api.get("/shipmentFile/booking", obj);
    return res.data;
  },
  async updateShipmentFileSync(id) {
    let res = await Api.put("/shipmentFile/adminUnlink/" + id);
    return res.data;
  },

  async getShipmentFile(obj) {
    let res = await Api.get("/shipmentFile/adminUnlink", obj);
    return res.data;
  },

  async fetchShipmentFile(fileNumber) {
    let res = await Api.post(`/shipmentFile/adminFileFetch/${fileNumber}`);
    return res.data;
  },

  async updateShipmentReSync(id) {
    let res = await Api.put("/shipmentFile/adminReSync/" + id);
    return res.data;
  },

  async shipmentFileData(id) {
    let res = await Api.get("/shipmentFile/data/" + id);
    return res.data;
  },
  async getShipmentFileDetail(id) {
    let res = await Api.get("/shipmentFile/detail/" + id);
    return res.data;
  },
  async getShipmentBookingDetail(id) {
    let res = await Api.get("/shipmentFile/booking/detail/" + id);
    return res.data;
  },
  async updateBookingFile(obj) {
    let res = await Api.post("/shipmentFile/booking/update", obj);
    return res.data;
  },
  async updateFileStatus(id, obj) {
    let res = await Api.put("/shipmentFile/status/" + id, obj);
    return res.data;
  },
  async getUnlinkShipmentContainers(fileNumber) {
    let res = await Api.get(`/shipmentFile/unlink/containers/${fileNumber}`);
    return res.data;
  },
  async getShipmentContainerPalletData(obj) {
    let res = await Api.post("/shipmentFile/unlink/pallets", obj);
    return res.data;
  },

  // Shipping Instruction documents
  async getAllDocumentTypes() {
    let res = await Api.get("/documentTypes");
    return res.data;
  },
  async createDocumentType(obj) {
    let res = await Api.post("/documentType", obj);
    return res.data;
  },
  async updateDocumentType(obj) {
    let res = await Api.put("/documentType/" + obj.id, obj);
    return res.data;
  },
  async getConsigneeDocuments() {
    let res = await Api.get("/consigneeInstruction/document");
    return res.data;
  },
  async getConsigneeSupportingDocuments() {
    let res = await Api.get("/consigneeInstruction/supportingDocument");
    return res.data;
  },
  async getConsigneeMilestones() {
    let res = await Api.get("/consigneeInstruction/milestone");
    return res.data;
  },
  async documentationPODName(obj) {
    let res = await Api.post("/consigneProfile/docPOD", obj);
    return res.data;
  },

  //Shipping Instruction
  async getShippingInstructionPreview(id, intergationCode, query) {
    let url = `/shipment/${id}/shipping-instruction/${intergationCode}/preview`;
    if(query) {
      const queryString = Object.keys(query).map(k => `${k}=${query[k]}`).join('&');
      url += `?${queryString}`;
    }

    const { data } = await Api.get(url, query);
    return data;
  },

  async submitShippingInstructionDraft(id, intergationCode, inputs) {
    const { data } = await Api.post(`/shipment/${id}/shipping-instruction/${intergationCode}/draft`, inputs);
    return data;
  },

  // Tasks
  async updateInspectionComments(obj) {
    let res = await Api.post("/task/inspection/comments", obj);
    return res.data;
  },
  async getRoboDanieResubRejecData() {
    let res = await Api.get("/task/roboDanieResubRejecData");
    return res.data;
  },

  async getTasks(query) {
    let res = await Api.get("/task", query);
    return res.data;
  },
  async getTask(id) {
    let res = await Api.get("/task/single/" + id);
    return res.data;
  },
  async getTaskMetrics() {
    let res = await Api.get("/task/metrics");
    return res.data;
  },
  async getTaskTypes() {
    let res = await Api.get("/task/types");
    return res.data;
  },
  async getAllDocumentsLinked(shipDocTypeId) {
    let res = await Api.get(`/task/documentType/${shipDocTypeId}`)
    return res.data
  },
  async getTaskLogs(id) {
    let res = await Api.get("/task/logs/" + id);
    return res.data;
  },
  async getTaskJSONData(id) {
    let res = await Api.get("/task/data/" + id);
    return res.data;
  },
  async getTaskAttachments(id) {
    let res = await Api.get("/task/attachments/" + id);
    return res.data;
  },
  
  async getTaskValidation(id) {
    let res = await Api.get("/task/validation/" + id);
    return res.data;
  },
  async getInspectionTask(id) {
    let res = await Api.get("/task/inspection/" + id);
    return res.data;
  },
  async getInspectionAttachments(id) {
    let res = await Api.get("/task/inspection/attachment/" + id);
    return res.data;
  },
  async createInspectionAttachment(obj) {
    let res = await Api.post("/task/inspection/attachment", obj);
    return res.data;
  },
  async updateInspectionTaskAttachment(obj) {
    let res = await Api.put("/task/inspection/attachment/" + obj.id, obj);
    return res.data;
  },
  async submitTaskForInspection(id, obj) {
    let res = await Api.post("/task/submitInspection/" + id, obj);
    return res.data;
  },
  async resubmitTaskForInspection(id, obj) {
    let res = await Api.post("/task/resubmitInspection/" + id, obj);
    return res.data;
  },
  async cancelInspection(obj) {
    let res = await Api.put("/task/cancelInspection/" + obj.id);
    return res.data;
  },
  async createTaskAttachment(obj) {
    let res = await Api.post("/task/attachments", obj);
    return res.data;
  },
  async deleteTaskAttachment(obj) {
    let res = await Api.put("/task/delete/attachments/" + obj.id, obj);
    return res.data;
  },
  async updateTaskAttachment(obj) {
    let res = await Api.put("/task/attachments/" + obj.id, obj);
    return res.data;
  },
  async getTaskActions(id) {
    let res = await Api.get("/task/actions/" + id);
    return res.data;
  },
  async updateTask(task) {
    let res = await Api.put("/task/" + task.id, task);
    return res.data;
  },
  async resetTaskStatus(id, shipmentId) {
    let res = await Api.put("/task/container/" + id + "/" + shipmentId);
    return res.data;
  },
  async startTask(task) {
    let res = await Api.put("/task/start/" + task.id, task);
    return res.data;
  },
  async updateInspectionTask(task) {
    let res = await Api.put("/task/inspection/" + task.id, task);
    return res.data;
  },

  // Task Inspection
  async getInspectionResultData(id) {
    let res = await Api.get("/task/inspectionResult/" + id);
    return res.data;
  },
  async getInspectionResultDocuments(id) {
    let res = await Api.get("/task/inspectionResult/documents/" + id);
    return res.data;
  },
  async getInspectionResult(id) {
    let res = await Api.get("/task/inspectionResult/specific/" + id);
    return res.data;
  },
  async getAllInspectionResults(taskId) {
    let res = await Api.get("/task/inspectionResult/all/" + taskId);
    return res.data;
  },
  async createInspectionResult(obj) {
    let res = await Api.post("/task/inspectionResult", obj);
    return res.data;
  },
  async updateInspectionResult(obj) {
    let res = await Api.put("/task/inspectionResult/" + obj.id, obj);
    return res.data;
  },
  async submitFinalInspectionResult(obj) {
    let res = await Api.put("/task/inspectionResult/submit/" + obj.id, obj);
    return res.data;
  },
  async createInspectionField(obj) {
    let res = await Api.post("/task/inspectionResult/field", obj);
    return res.data;
  },
  async updateInspectionField(obj) {
    let res = await Api.put("/task/inspectionResult/field/" + obj.id, obj);
    return res.data;
  },
  async revokeInspection(obj) {
    let res = await Api.post("/task/inspection/revoke/", obj);
    return res.data;
  },
  async getPhytCleanActionData(id) {
    let res = await Api.get(`/task/phytCleanAction/${id}`);
    return res.data;
  },
  // Teams
  async getTeamsAdmin(id) {
    let res = await Api.get(`/team/admin/${id}`);
    return res.data;
  },
  async updateTeamAdmin(obj) {
    let res = await Api.put(`/team/admin/${obj.id}`, obj);
    return res.data;
  },
  async createTeamAdmin(obj) {
    let res = await Api.post("/team/admin", obj);
    return res.data;
  },

  async getTeams() {
    let res = await Api.get("/team");
    return res.data;
  },
  async updateTeam(obj) {
    let res = await Api.put("/team/" + obj.id, obj);
    return res.data;
  },
  async createTeam(obj) {
    let res = await Api.post("/team", obj);
    return res.data;
  },

  async updateTeamUserAdmin(obj) {
    let res = await Api.put("/teamUser/admin/" + obj.id, obj);
    return res.data;
  },
  async createTeamUserAdmin(obj) {
    let res = await Api.post("/teamUser/admin", obj);
    return res.data;
  },

  async updateTeamUser(obj) {
    let res = await Api.put("/teamUser/" + obj.id, obj);
    return res.data;
  },
  async getUserTeams() {
    let res = await Api.get("/teamUser");
    return res.data;
  },
  async createTeamUser(obj) {
    let res = await Api.post("/teamUser", obj);
    return res.data;
  },

  async createRelationshipTeam(obj) {
    let res = await Api.post("/related/team", obj);
    return res.data;
  },
  async updateRelationshipTeam(obj) {
    let res = await Api.put("/related/team/" + obj.id, obj);
    return res.data;
  },
  async getRelationshipTeam(id) {
    let res = await Api.get("/related/team/" + id);
    return res.data;
  },
  async getRelationshipResponsibilities(reference) {
    let res = await Api.get("/related/responsibilities/" + reference);
    return res.data;
  },

      //For terms and conditions
      async getTerms(params) {
        let res = await Api.get('/terms/termsandconditions', {params});
        return res.data;
      },
      async createTermsAndConditions(data) {
        let res = await Api.post('/terms/create', data);
        return res.data;
      }, 
      async checkUserAcceptance(params) {
        let res = await Api.get('/terms/check', {params});
        return res.data
      },
      async acceptTermsAndConditions(data) {
        let res =  await Api.post('/terms/accept', data);
        return res.data
      },
      
  // Titan
  async getTitanStatus(obj) {
    let res = await Api.post(`/titan/status`, obj);
    return res.data;
  },
  async getTitanPallets(containerId) {
    let res = await Api.get(`/titan/${containerId}`);
    return res.data;
  },
  async createTitanAddendum(containerId) {
    let res = await Api.post(`/titan/addendum/${containerId}`);
    return res.data;
  },
  async getTitanTransaction(transactionId) {
    let res = await Api.get(`/titan/addendumStatus/${transactionId}`);
    return res.data;
  },
  async getRegimeCodes(params) {
    let res = await Api.get(`/regimeCodes`, params);
    return res.data;
  },

  // Subscriptions
  async getSubscriptions() {
    let res = await Api.get("/subscription");
    return res.data;
  },
  async getOrganisationSubscriptions(id) {
    let res = await Api.get("/subscription/" + id);
    return res.data;
  },
  async updateSubscription(obj) {
    let res = await Api.put("/subscription/" + obj.id, obj);
    return res.data;
  },
  async createSubscription(obj) {
    let res = await Api.post("/subscription", obj);
    return res.data;
  },

  async createSubscriptionBundle(obj) {
    let res = await Api.post("/subscription/bundle", obj);
    return res.data;
  },
  async updateSubscriptionBundle(obj) {
    let res = await Api.put("/subscription/bundle/" + obj.id, obj);
    return res.data;
  },

  // TUM
  async getTransactionLogs(query) {
    let res = await Api.get(`/tum/transactionLogs`, query);
    return res.data
  },
  async readTransactionLog(obj) {
    console.log(obj)
    let res = await Api.post("/tum/readLog", obj)
    return res.data
  },
  async getMetrics(query) {
    let res = await Api.get(`/tum/metrics`, query);
    return res.data
  },
  async getTUMData(query) {
    let res = await Api.get(`/tum/data`, query);
    return res.data
  },
  async getTUMCount(query) {
    let res = await Api.get(`/tum/count`, query);
    return res.data
  },
  async getTUMPalletWeekCount(query) {
    let res = await Api.get(`/tum/weekCount`, query);
    return res.data
  },
  async getTUMPalletSeasonCount(query) {
    let res = await Api.get(`/tum/seasonCount`, query);
    return res.data
  },
  async getTUMVendorTUs() {
    let res = await Api.get(`/tum/tradeUnitEvents`);
    return res.data
  },
  async getTUMVendorMOVs() {
    let res = await Api.get(`/tum/movementEvents`);
    return res.data
  },
  async getAllPHCTUs() {
    let res = await Api.get(`/tum/phcTUCount`);
    return res.data
  },
  async getDetailedPHCTU(obj) {
    let res = await Api.post(`/tum/phcTUDetailed`, obj);
    return res.data
  },
  async getPHCList(obj) {
    let res = await Api.post(`/tum/phcList`, obj);
    return res.data
  },

  // Users
  async getUserTableLayout(query) {
    let res = await Api.get("/userTableLayout", query);
    return res.data;
  },
  async saveUserFilters(obj) {
    let res = await Api.post("/userFilters", obj);
    return res.data;
  },
  async getUserFilters() {
    let res = await Api.get("/userFilters");
    return res.data;
  },
  async getDefaultTableLayout(query) {
    let res = await Api.get("/defaultTableLayout", query);
    return res.data;
  },
  async updateUserTableLayout(data) {
    let res = await Api.put("/userTableLayout/" + data.id, data);
    return res.data;
  },
  async createUserTableLayout(data) {
    let res = await Api.post("/userTableLayout", data);
    return res.data;
  },
  async searchOrganisationUser(search) {
    let res = await Api.get("/user/organisationUser", search);
    return res.data;
  },
  async getUserByHash(hash) {
    let res = await Api.get("/user/hash/" + hash);
    return res.data;
  },
  async getUsersAdmin(query) {
    let res = await Api.get("/user/admin", query);
    return res.data;
  },
  async getUsers(query) {
    let res = await Api.get("/userSearch", query);
    return res.data;
  },
  async getDocUsers(){
    let res = await Api.get("/user/docUsers");
    return res.data;
  },
  async createUserAdmin(obj) {
    let res = await Api.post("/user/admin", obj);
    return res.data;
  },

  async createUser(obj) {
    let res = await Api.post("/user", obj);
    return res.data;
  },

  async updateUserAdmin(obj) {
    let res = await Api.put("/user/admin/" + obj.id, obj);
    return res.data;
  },
  async updateUser(obj) {
    let res = await Api.put(`/user/${obj.id}`, obj);
    return res.data;
  },
  async getSingleUserOrgAdress() {
    let result = await Api.get("/user/organisation/adress");
    return result.data;
  },
  async getAllOrgAdresses(id) {
    let result = await Api.get(
      '/organisation/adresses/' + id
    );
    return result.data;
  },
  async getUserOrganisations() {
    let res = await Api.get("/user/organisation");
    return res.data;
  },
  async getUserPermissions() {
    let res = await Api.get("/user/permission");
    return res.data;
  },

  // UN Locodes
  async getUnLocodes(code) {
    let res = await Api.get("/unLocode/" + code);
    return res.data;
  },
  // User Roles
  async createUserRoleAdmin(obj) {
    let res = await Api.post("/userRole/admin", obj);
    return res.data;
  },
  async createUserRole(obj) {
    let res = await Api.post("/userRole", obj);
    return res.data;
  },
  async updateUserRoleAdmin(obj) {
    let res = await Api.put(`/userRole/admin/${obj.id}`, obj);
    return res.data;
  },
  async updateUserRole(obj) {
    let res = await Api.put(`/userRole/${obj.id}`, obj);
    return res.data;
  },

  // Upload File
  async uploadFile(obj) {
    let res = await Api.post("/upload", obj);
    return res.data;
  },
  async uploadShippingDocument(obj) {
    let res = await Api.post("/upload/document", obj);
    return res.data;
  },
  async roboDanieUploadShippingDocument(obj) {
    let res = await Api.post("/upload/document/roboDanie", obj);
    return res.data;
  },
  async uploadFinanceDocument(obj) {
    let res = await Api.post("/upload/finance", obj);
    return res.data;
  },
  async uploadLogo(obj) {
    let res = await Api.post("/upload/logo", obj);
    return res.data;
  },
  async uploadIcon(obj) {
    let res = await Api.post("/upload/icon", obj);
    return res.data;
  },
  async uploadStamp(obj) {
    let res = await Api.post("/upload/stamp", obj);
    return res.data;
  },
  async uploadAdminLogo(obj, organisationId) {
    let res = await Api.post(`/upload/admin/logo/${organisationId}`, obj);
    return res.data;
  },
  async uploadAdminIcon(obj, organisationId) {
    let res = await Api.post(`/upload/admin/icon/${organisationId}`, obj);
    return res.data;
  },
  async uploadDriverAvatar(obj) {
    let res = await Api.post("/upload/driver/avatar", obj);
    return res.data;
  },
  async uploadTransportPhotos(obj) {
    let res = await Api.post("/upload/transport/photo", obj);
    return res.data;
  },
  async uploadTransportDocs(obj) {
    let res = await Api.post("/upload/transport/docs", obj);
    return res.data;
  },

  async downloadFile(obj) {
    let res = await Api.post("/download", obj);
    return res.data;
  },
  async getDocumentLink(obj) {
    let res = await Api.post("/download/signedURL", obj);
    return res.data;
  },
  async getSecureLink(obj) {
    let res = await Api.post("/secure/signed", obj);
    return res.data;
  },
  async downloadSecureLink(url) {
    let res = await axios.get(url, {
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    return res.data;
  },
  async downloadSharedFile(obj) {
    let res = await Api.post("/download/shared", obj);
    return res.data;
  },
  async sendEmail() {
    let res = await Api.get("/email");
    return res.data;
  },

  async extractText() {
    let res = await Api.post("/extract", {});
    return res.data;
  },

  // Tags
  async createTagAdmin(obj) {
    let res = await Api.post(`/tags/admin`, obj);
    return res.data;
  },
  async createTag(obj) {
    let res = await Api.post(`/tags`, obj);
    return res.data;
  },
  async updateTagAdmin(obj) {
    let res = await Api.put(`/tags/admin/${obj.id}`, obj);
    return res.data;
  },

  async getTags() {
    let res = await Api.get(`/tags`);
    return res.data;
  },
  async getTagsAdmin() {
    let res = await Api.get(`/tags/admin`);
    return res.data;
  },
  async getTagsByType(type) {
    let res = await Api.get(`/tags/${type}`);
    return res.data;
  },

  // Regime Management
  async createRegimeCountry(obj) {
    let res = await Api.post(`/regimes/country`, obj);
    return res.data;
  },
  async bulkCreateRegimeCountry(obj) {
    let res = await Api.post(`/regimes/bulkCountry`, obj);
    return res.data;
  },
  async updateRegimeCountry(obj) {
    let res = await Api.put(`/regimes/country/${obj.id}`, obj);
    return res.data;
  },
  async createRegimeProduct(obj) {
    let res = await Api.post(`/regimes/product`, obj);
    return res.data;
  },
  async createRegimeProducts(obj) {
    let res = await Api.post(`/regimes/products`, obj);
    return res.data;
  },
  async updateRegimeProduct(obj) {
    let res = await Api.put(`/regimes/product/${obj.id}`, obj);
    return res.data;
  },
  async createRegimeProductGroup(obj) {
    let res = await Api.post(`/regimes/productGroup`, obj);
    return res.data;
  },
  async updateRegimeProductGroup(obj) {
    let res = await Api.put(`/regimes/productGroup/${obj.id}`, obj);
    return res.data;
  },
  async createRegimeVent(obj) {
    let res = await Api.post(`/regimes/vent`, obj);
    return res.data;
  },
  async updateRegimeVent(obj) {
    let res = await Api.put(`/regimes/vent/${obj.id}`, obj);
    return res.data;
  },
  async getRegimeVent(id) {
    let res = await Api.get(`/regimes/vent/${id}`);
    return res.data;
  },
  async createRegimeClone(id) {
    let res = await Api.post(`/regimes/clone/${id}`);
    return res.data;
  },
  async validateRegimeCode(query) {
    let res = await Api.get(`/regimes/validateCode`, {
      params: query,
    });
    return res.data;
  },
  async getRegimes(obj) {
    let res = await Api.get(`/regimes/admin`, obj);
    return res.data;
  },
  async getRegimeDetail(id) {
    let res = await Api.get(`/regimes/admin/detail/${id}`);
    return res.data;
  },
  async createRegime(obj) {
    let res = await Api.post(`/regimes/admin`, obj);
    return res.data;
  },
  async updateRegime(obj) {
    let res = await Api.put(`/regimes/admin/${obj.id}`, obj);
    return res.data;
  },
  async getRegimeRules(id) {
    let res = await Api.get(`/regimes/admin/${id}`);
    return res.data;
  },
  async createRegimeRule(obj) {
    let res = await Api.post(`/regime/rules/admin`, obj);
    return res.data;
  },
  async updateRegimeRule(obj) {
    let res = await Api.put(`/regime/rules/admin/${obj.id}`, obj);
    return res.data;
  },
  async bulkUpdateRegimeRulesAdmin(obj) {
    let res = await Api.post(`/regime/bulkRules/admin`, obj);
    return res.data;
  },
  // async getRegimeByCode(code) {
  //   let res = await Api.get(`/regimes/admin/rules/${code}`)
  //   return res.data
  // },
  async getRegimeById(id) {
    let res = await Api.get(`/regime/rules/${id}`);
    return res.data;
  },
  async getRegimeByCode(code) {
    let res = await Api.get(`/regime/rules/code/${code}`);
    return res.data;
  },

  async submitRemovalPermit(obj) {
    let res = await Api.post(`/removalPermit`, obj);
    return res.data;
  },


  // Container Telemetry Integrations
  async getTelemetryBookingContainer(containerReference, bookingId, integrationCodes) {
    const queryString = integrationCodes.map(x => `integrationCodes=${x}`).join('&');
    let res = await Api.get(`/telemetry/container/${containerReference}/booking/${bookingId}?${queryString}`);
    return res.data;
  },



  // Sensor Integration - SensiTech
  async getSensiShipmentById(programId, id) {
    let res = await Api.get(`/sensor/sensi/${programId}/${id}`);
    return res.data;
  },
  async getSensiShipmentAlarms(id, locationId) {
    let res = await Api.get(`/sensor/sensi/alarms/${id}/${locationId}`);
    return res.data;
  },
  async getSensiShipmentTempReadings(programId, id, locationId, productId) {
    let res = await Api.get(
      `/sensor/sensi/tempReadings/${programId}/${id}/${locationId}/${productId}`
    );
    return res.data;
  },
  async getSensiShipmentLightReadings(programId, id, locationId, productId) {
    let res = await Api.get(
      `/sensor/sensi/lightReadings/${programId}/${id}/${locationId}/${productId}`
    );
    return res.data;
  },

  // Sensor Integration -  DeltaTrak
  async getDeltaShipmentById(id) {
    let res = await Api.get(`/sensor/delta/${id}`);
    return res.data;
  },
  // Sensor Integration -  DB Sensors
  async getSensorIdByContainerID(containerId) {
    let res = await Api.get(`/sensor/dataId/${containerId}`);
    return res.data;
  },
  async getSensorDevices(search) {
    let res = await Api.get("/sensor/devices", search);
    return res.data;
  },

  async getSensorDeviceById(id) {
    let res = await Api.get(`/sensor/devices/${id}`);
    return res.data;
  },
  async getSensorDetails(sensorId) {
    let res = await Api.get(`/sensor/details/${sensorId}`);
    return res.data;
  },
  async getSensorDetailsBySerial(serial) {
    let res = await Api.get(`/sensor/details/serial/${serial}`);
    return res.data;
  },
  async getDeviceTempReadings(sensorId) {
    let res = await Api.get(`/sensor/tempReadings/${sensorId}`);
    return res.data;
  },

  async getDeviceLightReadings(sensorId) {
    let res = await Api.get(`/sensor/lightReadings/${sensorId}`);
    return res.data;
  },

  async getDeviceLocationReadings(sensorId) {
    let res = await Api.get(`/sensor/locationReadings/${sensorId}`);
    return res.data;
  },

  // Stock
  async getActiveStockSources(id) {
    let res = await Api.get(`/stock/sources/active/${id}`);
    return res.data;
  },
  async getAvailableStockSources(id) {
    let res = await Api.get(`/stock/sources/${id}`);
    return res.data;
  },
  async createStockFromFile(id){
    let res = await Api.post(`/stock/create/${id}`);
    return res.data;
  },
  async getStockOverview() {
    let res = await Api.get(`/stock/overview`);
    return res.data;
  },
  async getActiveStockOverview(params = {}, signal = undefined) {
    let res = await Api.get(`/stock/overview/active`,{params,signal});
    return res.data;
  },
  async getActiveStockProducts() {
    let res = await Api.get(`/stock/overview/products`);
    return res.data;
  },
  async updateStockFileContainer(obj) {
    let res = await Api.put(`/stock/container/${obj.id}`, obj);
    return res.data;
  },
  async getStockDetailTable(params) {
    let res = await Api.get(`/stock/detail/table`, params);
    return res.data;
  },
  async updateDocumentSource(obj) {
    let res = await Api.post(`/stock/source`, obj);
    return res.data;
  },
  async getStockFiles(params) {
    let res = await Api.get("/stock/files", params);
    return res.data;
  },
  async updateStockFile(obj) {
    let res = await Api.put("/stock/files/" + obj.id, obj);
    return res.data;
  },
  async getFileMappings() {
    let res = await Api.get("/stock/mapped");
    return res.data;
  },
  async createFileMapping(obj) {
    let res = await Api.post("/stock/mapping", obj);
    return res.data;
  },
  async updateFileMapping(obj) {
    let res = await Api.put("/stock/mapping/" + obj.id, obj);
    return res.data;
  },
  async getMappingHeaders() {
    let res = await Api.get("/stock/mapping");
    return res.data;
  },
  async updateStockItems(obj) {
    let res = await Api.put("/stock/update", obj);
    return res.data;
  },
  async searchCTOLink(query) {
    let res = await Api.get("/stock/cto", query);
    return res.data;
  },
  async getStockLiteVessels(params) {
    let res = await Api.get("/stock/lite/vesselSummary",{params});
    return res.data;
  },
  async getStockPremiumVessels(params) {
    let res = await Api.get("/stock/premium/vesselSummary",{params});
    return res.data;
  },

  // Temp Logs
  async getTempLogsTotalAudit(obj) {
    let res = await Api.get("/tempLogs/totalAudit", obj);
    return res.data;
  },
  async getContainerPallet(containerNo) {
    let res = await Api.get(`/containerPallet/${containerNo}`);
    return res.data;
  },

  // Shipment Custom Events
  async getCustomEventTypes() {
    let res = await Api.get(`/customEvent/types`);
    return res.data;
  },
  async getShipmentCustomEvents(shipmentId) {
    let res = await Api.get(`/customEvents/${shipmentId}`);
    return res.data;
  },
  async createShipmentCustomEvent(obj) {
    let res = await Api.post(`/customEvent`, obj);
    return res.data;
  },
  async updateShipmentCustomEvent(obj, id) {
    let res = await Api.put(`/customEvent/${id}`, obj);
    return res.data;
  },

  // S3 FTP Controller
  async createOrganisationFtpFolder(orgId) {
    let res = await Api.get(`/s3ftp/createOrgFolder/${orgId}`);
    return res.data;
  },
  async createOrganisationFtpSubfolder(orgId, subFolderName) {
    let res = await Api.get(`/s3ftp/createSubFolder/${orgId}/${subFolderName}`);
    return res.data;
  },
  async createOrganisationFtpCredentials(orgId) {
    let res = await Api.get(`/s3ftp/generateFtpCredentials/${orgId}`);
    return res.data;
  },

  // Xero
  async getXeroAccounts() {
    let res = await Api.get(`/xero/admin/accounts`);
    return res.data;
  },
  async getXeroContacts() {
    let res = await Api.get(`/xero/admin/contacts`);
    return res.data;
  },
  async createXeroContact(obj) {
    let res = await Api.post(`/xero/admin/contacts`, obj);
    return res.data;
},
  // Clean Ecert
  async cleanEcert(obj) {
    let res = await Api.post(`/cleanEcert`, obj);
    return res.data;
  },

  // Dark Matter

  async getDistinctOrgs(params) {
    let res = await Api.get(`/getOrgs`, { params });
    return res.data;
  },
  async newDMRule(obj) {
    let res = await Api.post(`/newDMRule`, obj);
    return res.data;
  },
  async setAsActive(id) {
    let res = await Api.post(`/setAsActive/${id}`);
    return res.data;
  },
  async countConsigneeProfiles(id) {
    let res = await Api.get(`/countSP/${id}`);
    return res.data;
  },
  async findOrg(id) {
    let res = await Api.get(`/findOrg/${id}`);
    return res.data;
  },
  async countOrgUsers(id) {
    let res = await Api.get(`/countOrgUsers/${id}`);
    return res.data;
  },
  async countBookings(id) {
    let res = await Api.get(`/countBookings/${id}`);
    return res.data;
  },
  async countConsigneeProfileRelationships(id) {
    let res = await Api.get(`/countConsigneeProfileRelationships/${id}`);
    return res.data;
  },
  async countOrganisationAddresses(id) {
    let res = await Api.get(`/countOrganisationAddresses/${id}`);
    return res.data;
  },
  async countShipmentRelationships(id) {
    let res = await Api.get(`/countShipmentRelationships/${id}`);
    return res.data;
  },
  async countShipments(id) {
    let res = await Api.get(`/countShipments/${id}`);
    return res.data;
  },
  async countShipmentFiles(id) {
    let res = await Api.get(`/countShipmentFiles/${id}`);
    return res.data;
  },
  async assignDocumentUser(obj) {
    let res = await Api.post(`/assignDocumentUser`, obj);
    return res.data;
  },
  async getDistinctBillingParties(params) {
    let res = await Api.get(`/getBP`, { params });
    return res.data;
  },
  async getDistinctBillingPartiesWithId(params) {
    let res = await Api.get(`/getBPWithId`, { params });
    return res.data;
  },
  async getDistinctExporters(params) {
    let res = await Api.get(`/getEX`, { params });
    return res.data;
  },
  async getDistinctExportersWithId(params) {
    let res = await Api.get(`/getEXWithId`, { params });
    return res.data;
  },
  async getDistinctConsignees(params) {
    let res = await Api.get(`/getCS`, { params });
    return res.data;
  },
  async getDistinctConsigneesWithId(params) {
    let res = await Api.get(`/getCSWithId`, { params });
    return res.data;
  },
  async cbrOrgCheckBPEX(obj, params) {
    let res = await Api.post(`/cbrOrgCheckBPEX`, obj, { params });
    return res.data;
  },
  async cbrOrgCheckCS(obj, params) {
    let res = await Api.post(`/cbrOrgCheckCS`, obj, { params });
    return res.data;
  },
  async commenceMapping(obj) {
    try {
      let res = await Api.post(`/commenceMapping`, obj);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  },
  async displayMapRules() {
    try {
      let res = await Api.get(`/displayMapRules`);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  },
  async deleteMapRule(obj) {
    try {
      let res = await Api.post(`/deleteMapRule`, obj);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  },
  async getMRList(obj, params) {
    try {
      let res = await Api.post(`/getMRList`, obj, { params });
      return res.data;
    } catch (e) {
      console.log(e);
    }
  },

  // Organisation PU Controller
  async fetchFBOs(params) {
    try {
      let res = await Api.get(`/fetchFBOs`, { params });
      return res.data;
    } catch (e) {
      console.log(e);
    }
  },
  async fetchSummaryData(params) {
    try {
      let res = await Api.get(`/fetchSummaryData`, { params });
      return res.data;
    } catch (e) {
      console.log(e);
    }
  },
  async fetchOrchardInfo(obj, params) {
    try {
      let res = await Api.post(`/fetchOrchardInfo`, obj, { params });
      return res.data;
    } catch (e) {
      console.log(e);
    }
  },
  async uploadGeoJSON(obj) {
    try {
      let res = await Api.post(`/uploadGeoJSON`, obj);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  },
  async readOrchardGeoJSON(params) {
    try {
      let res = await Api.get(`/readOrchardGeoJSON`, { params });
      return res.data
    } catch (e) {
      console.log(e)
    }
  },

  // EDIFACTS
  async fetchFileTemplates(params) {
    let res = await Api.get(`/fetchMessageTemplates`, { params });
    return res.data;
  },
  async fetchFileData(obj) {
    let res = await Api.post(`/getFileData`, obj);
    return res.data;
  },
  async fetchJSONOptions() {
    let res = await Api.get(`/fetchJSONOptions`);
    return res.data;
  },
  async fetchJSONData(obj) {
    let res = await Api.post(`/getJSONData`, obj);
    return res.data;
  },
  async createEDIFACTTemplate(obj) {
    let res = await Api.post(`/createEDIFACTTemplate`, obj);
    return res.data;
  },
  async writeEDIFACTMessage(obj) {
    let res = await Api.post(`/writeEDIFACTMessage`, obj);
    return res.data;
  },
  async fetchUNVersions() {
    let res = await Api.get(`/fetchUNVersions`);
    return res.data;
  },
  async fetchUNMessageTypes(params) {
    let res = await Api.get(`/fetchUNMessageTypes`, { params });
    return res.data;
  },
  async createBuildFileUN(obj) {
    let res = await Api.post(`/createBuildFileUN`, obj);
    return res.data;
  },
  async submitIncompleteTemplate(obj) {
    let res = await Api.post(`/submitIncompleteTemplate`, obj);
    return res.data;
  },
  async fetchIncompletes(params) {
    let res = await Api.get(`fetchIncompletes`, { params });
    return res.data;
  },
  async fetchIncompleteData(obj) {
    let res = await Api.post(`/getIncompleteData`, obj);
    return res.data;
  },
  async newBuildFromOld(obj) {
    let res = await Api.post(`/newBuildFromOld`, obj);
    return res.data;
  },
  async removeMessageTemplate(obj) {
    let res = await Api.post(`/deleteMessageTemplate`, obj);
    return res.data;
  },
  async uploadCopyOfBuild(obj) {
    let res = await Api.post(`/uploadCopyOfBuild`, obj);
    return res.data;
  },
  async previewEDIFACTMessage(obj) {
    let res = await Api.post(`/previewEDIFACTMessage`, obj);
    return res.data;
  },
  async uploadJSON(obj) {
    let res = await Api.post(`/uploadJSON`, obj);
    return res.data;
  },
  async fetchJSONs(params) {
    let res = await Api.get(`/fetchJSONs`, { params });
    return res.data;
  },
  async fetchJSONDataInfo(obj) {
    let res = await Api.post(`/fetchJSONData`, obj);
    return res.data;
  },
  async saveCurrent(obj) {
    let res = await Api.post(`/saveCurrent`, obj);
    return res.data;
  },
  async uploadMsg(obj) {
    let res = await Api.post(`/uploadMsg`, obj);
    return res.data;
  },
  async fetchMessages(params) {
    let res = await Api.get(`/fetchMessages`, { params });
    return res.data;
  },
  async decodeMessage(obj) {
    let res = await Api.post(`/decodeMsg`, obj);
    return res.data;
  },
  async fetchMessageData(obj) {
    let res = await Api.post(`/fetchMsgData`, obj);
    return res.data;
  },
  async removeStorageFile(obj) {
    let res = await Api.post(`/removeStorageFile`, obj);
    return res.data;
  },
  async fetchSegmentsList() {
    let res = await Api.get(`/fetchSegments`);
    return res.data
  },
  async getChosenSegment(params) {
    let res = await Api.get(`/getChosenSegment`, { params });
    return res.data
  },
    
  // Guide
  async getGuides() {
    let res = await Api.get(`/getGuides`);
    return res.data;
  },
  async getAllGuideFolders() {
    let res = await Api.get(`/getAllGuideFolders`);
    return res.data;
  },
  async getFolderGuides(data) {
    let res = await Api.put(`/getFolderGuides`, data);
    return res.data;
  },
  async getGuideMainFolderConfig(data) {
    let res = await Api.put(`/getGuideMainFolderConfig`, data);
    return res.data;
  },
  async getGuideSecondaryFolderConfig(data) {
    let res = await Api.put(`/getGuideSecondaryFolderConfig`, data);
    return res.data;
  },

  async getGuideSteps(data) {
      let res = await Api.put(`/getGuideSteps`, data);
      return res.data;
  },
  async createGuide(data) {
    let res = await Api.put(`/createGuide`, data);
    return res.data;
  },
  async updateGuide(data) {
    let res = await Api.put(`/updateGuide`, data);
    return res.data;
  },
  async deleteGuide(data) {
    let res = await Api.put(`/deleteGuide`, data);
    return res.data;
  },
//   async createAllGuides(data) {
//     let res = await Api.put(`/createAllGuides`, data);
//     return res.data;
//   },
  async createAllMissingSteps(data) {
    let res = await Api.put(`/createAllMissingSteps`, data);
    return res.data;
  },

  //Glen custom events:
  async getCustomEvents(obj) {
    let res = await Api.post(`/documentation/dashboard/customEvents`, obj);
    return res.data;
  },

  async getCustomEventsFiles(obj) {
    let res = await Api.post(`/documentation/dashboard/customEventsFiles`, obj);
    return res.data;
  },
  async getVesselWeekEvents(obj) {
    let res = await Api.post(
      `/documentation/dashboard/customEventsVessel`,
      obj
    );
    return res.data;
  },

  async getPhytoData() {
    let res = await Api.get(`/getPhytoData`);
    return res.data;
  },
  async checkContainerNumber(containerNo) {
    let res = await Api.post(`/validateContainer/${containerNo}`)
    return res.data
  },

  // News Articles
  async fetchArticles(params) {
    let res = await Api.get(`/news/fetchArticles`, { params });
    return res.data
  },
  async allArticles(params) {
    let res = await Api.get(`/news/allArticles`, { params });
    return res.data
  },
  async readArticle(params) {
    let res = await Api.get(`/news/readArticle`, { params });
    return res.data
  },
  async addAuthor(obj) {
    let res = await Api.post(`/news/addAuthor`, obj);
    return res.data
  },
  async getAuthors(params) {
    let res = await Api.get(`/news/getAuthors`, { params });
    return res.data
  },
  async editAuthor(obj) {
    let res = await Api.post(`/news/editAuthor`, obj);
    return res.data
  },
  async deleteAuthor(obj) {
    let res = await Api.post(`/news/deleteAuthor`, obj);
    return res.data
  },
  async generateManualArticle(obj) {
    let res = await Api.post("/news/generateManualArticle", obj)
    return res.data
  },
  async setPublishStatus(obj) {
    let res = await Api.post("/news/setPublishStatus", obj)
    return res.data
  },
  async deleteArticleFile(obj) {
    let res = await Api.post("/news/deleteArticleFile", obj)
    return res.data
  },
  async regenImage(obj) {
    let res = await Api.post("/news/regenImage", obj)
    return res.data
  },
  async replaceImage(obj) {
    let res = await Api.post("/news/replaceImage", obj)
    return res.data
  },
  async getAuthor(params) {
    let res = await Api.get(`/news/getAuthor`, { params })
    return res.data
  },
  async regenerateArticle(obj) {
    let res = await Api.post("/news/regenerateArticle", obj)
    return res.data
  },
  async changeRegion(params) {
    let res = await Api.get("/news/changeRegion", { params })
    return res.data
  },
  async fetchLogs(params) {
    let res = await Api.get("/news/fetchLogs", {params})
    return res.data
  },
  async readLog(params) {
    let res = await Api.get("/news/readLog", {params})
    return res.data
  },

  // Ecert Global
  async fetchEGOrganisations(params) {
    let res = await Api.get(`/ecertGlobal/fetchEGOrganisations`, { params })
    return res.data
  },
  async getEGOrgInfo(params) {
    let res = await Api.get(`/ecertGlobal/getEGOrgInfo`, { params });
    return res.data
  },
  async createPlot(obj) {
    let res = await Api.post(`/ecertGlobal/addNewPlot`, obj);
    return res.data
  },
  async fetchPlots(params) {
    let res = await Api.get(`/ecertGlobal/fetchPlots`, { params });
    return res.data
  },
  async saveGeoJSON(obj) {
    let res = await Api.post(`/ecertGlobal/saveGeoJSON`, obj);
    return res.data
  },
  async readGeoJSON(obj) {
    let res = await Api.post("/ecertGlobal/readGeoJSON", obj);
    return res.data
  },
  async getCities(obj) {
    let res = await Api.post("/ecertGlobal/getCities", obj)
    return res.data
  },
  async getProductGroups() {
    let res = await Api.get(`/ecertGlobal/getProductGroups`);
    return res.data
  },
  async getEGProducts(obj) {
    let res = await Api.post("/ecertGlobal/getProducts", obj)
    return res.data
  },
  async getEGVarieties(obj) {
    let res = await Api.post("/ecertGlobal/getVarieties", obj)
    return res.data
  },
  async deletePlot(params) {
    let res = await Api.get("/ecertGlobal/deletePlot", {params});
    return res.data
  },
  async editPlot(obj) {
    let res = await Api.post("/ecertGlobal/editPlot", obj);
    return res.data
  },

  // Vessel Management

  async getVesselVoyages(obj) {
    let res = await Api.get(`/admin/vesselVoyage`, obj);
    return res.data
  },
  async getSailedVesselVoyages() {
    let res = await Api.get(`/admin/sailedVesselVoyage`);
    return res.data
  },
  async saveVesselVoyage(obj) {
    let res = await Api.post(`/admin/vesselVoyage`, obj);
    return res.data
  },
  async getBookingsAdmin(obj) {
    let res = await Api.get(`/admin/bookings`, obj);
    return res.data
  },
  async getSailedBookingsAdmin(obj) {
    let res = await Api.get(`/admin/sailedBookings`, obj);
    return res.data
  },
  async updateBookingAdmin(obj) {
    let res = await Api.put(`/admin/bookings`, obj);
    return res.data
  },
  async getInterationQueues() {
    let res = await Api.get("/admin/countIntegrationQueue");
    return res.data;
  },
  async getUpcomingNotLinkedContainers(obj) {
    let res = await Api.post('/container/metrics/upcoming/notLinkedContainers', obj)
    return res.data
  },
  // TEMPORARY IMPORT OF PROPHET CODE FOR TESTING
  async createQXDispatches(obj) {
    let res = await Api.post(`/container/createQXDispatches`, obj)
    return res.data
  },
  //Widgets
  async getTagsAndScopes() {
    let res = await Api.get(`/widgets/dev/scopes`);
    return res.data
  },
  async getWidgets() {
    let res = await Api.get(`/widgets`);
    return res.data
  },
  async createWidget(obj) {
    let res = await Api.post(`/widgets`, obj);
    return res.data
  },
  async updateWidget(obj) {
    let res = await Api.put(`/widgets/update`, obj);
    return res.data
  },
  async saveLayout(obj) {
    let res = await Api.post(`/widgets/saveLayout`, obj);
    return res.data
  },
  async getDashboardLayout() {
    let res = await Api.get(`/widgets/dashboardLayout`);
    return res.data
  },
  async updateLayoutToUser(obj) {
    let res = await Api.post(`/widgets/updateLayoutToUser`, obj);
    return res.data
  },


  //Trading
  async getTradingContainers() {
    let res = await Api.get(`/trading/containers`);
    return res.data
  },
  async groupContainersSS(obj) {
    let res = await Api.post(`/trading/groupContainersSS`, obj);
    return res.data
  },
  async getContentSummary(query) {
    let res = await Api.get(`/trading/contents`, query);
    return res.data
  },
  async saveTraderTemplate(obj) {
    let res = await Api.post(`/trading/saveTraderTemplate`, obj);
    return res.data
  },
  async getTradingTemplates() {
    let res = await Api.get(`/trading/templates`);
    return res.data
  },
  async updateTraderTemplate(obj) {
    let res = await Api.put(`/trading/updateTraderTemplate`, obj);
    return res.data
  },
  async fixContainerTotals(obj) {
    let res = await Api.put(`/trading/fixContainerTotals`, obj);
    return res.data
  },

  //Loglive Chat
  async getConversationsByOrder(query) {
    let res = await Api.get(`/chat/order`, query);
    return res.data
  },
  async LLChatsendMessage(message) {
    let res = await Api.post(`/chat/sendMessage`, message);
    return res.data
  },
  async messageRead(obj) {
    let res = await Api.post(`/chat/messageRead`, obj);
    return res.data
  },

  // Exchange Rates
  async getLatestExchangeRate(params) {
    let res = await Api.get(`/exchangeRate/getLatest`, {params})
    return res.data
  },
  // Tracking
  async getContainerTracking(containerId) {
    let res = await Api.get(`/tracking/container/${containerId}`);
    return res.data
  },
  async getAllContainerTracking(containerId) {
    let res = await Api.get(`/tracking/container/all/${containerId}`);
    return res.data
  },
  async listContainerTracking(containers) {
    let res = await Api.get(`/tracking/containers`,{
      params: {
        containers
      }
    });
    return res.data
  },

  async subscribeContainer(containerId) {
    let res = await Api.put(`/tracking/container/subscribe/${containerId}`)
    return res.data
  },

  async getContainerTrackingTEST(containerId) {
    let res = await Api.get(`/tracking/containerTEST/${containerId}`);
    return res
  },

  // Adding functions for UCR updates

  async saveFileUCR(obj){
    let res = await Api.put(`/ucr/shipmentFile/${obj.id}`, obj)
    return res
  },
  async saveShipmentUCR(obj){
    let res = await Api.put(`/ucr/shipment/${obj.id}`, obj)
    return res
  },
  async saveProfileUCR(obj){
    let res = await Api.put(`/ucr/consigneeProfile/${obj.id}`, obj)
    return res
  },

};




