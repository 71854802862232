<template>
  <div v-if="fileId">
    <v-card
      v-if="!loading && file && file.id"
      style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      "
    >
      <v-toolbar flat dense color="transparent">
        <v-toolbar-title>
          <v-chip
            class="mr-2 white--text"
            v-if="!file.status || file.status == 'Draft'"
            color="orange"
            small
          >
            <v-icon color="white" left>edit_note</v-icon>
            Draft
          </v-chip>
          <v-chip
            class="mr-2 white--text"
            v-else-if="file.status == 'Cancelled'"
            color="red"
            small
          >
            <v-icon color="white" left>cancel</v-icon>
            Cancelled
          </v-chip>
          <v-chip
            class="mr-2"
            v-else-if="file.status == 'Ready'"
            color="blue"
            small
          >
            <v-icon color="white" left>verified</v-icon>
            Ready
          </v-chip>
          <v-chip class="mr-2" v-else color="success" small>
            <v-icon color="white" left>task_alt</v-icon>
            Processed
          </v-chip>
          <v-icon></v-icon> {{ file.fileNumber }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <!-- <v-btn color="blue" outlined small rounded class="mr-2" v-if="!file.shipmentId" @click="confirmProcessShipment=true">
          Process File
        </v-btn> -->

        <v-btn icon color="redPop" @click="$emit('close'), $emit('refresh')">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col class="text-center mb-0 pb-0">
            <v-row class="text-center">
              <!-- <v-col cols="12" class="text-center">
                        <span style="font-size: 18px"
                          >Attached Parties</span
                        >
                      </v-col> -->
              <v-col v-if="file.shipper">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" large class="mb-1">outbound</v-icon>
                  </template>
                  <span>Shipper</span>
                </v-tooltip>
                <br />
                <span style="font-size: 14px; color: var(--v-primaryText-base)">
                  {{
                    file.shipper ? file.shipper.alias ?? file.shipper.name : "-"
                  }}</span
                >
                <br v-if="file.onBehalfShipper" />
                <span
                  v-if="file.onBehalfShipper"
                  style="font-size: 12px; color: var(--v-primaryText-base)"
                >
                  o.b.o.
                  {{
                    file.onBehalfShipper ? file.onBehalfShipper.name : "-"
                  }}</span
                >
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col v-if="file.forwarder">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" large class="mb-1">fast_forward</v-icon>
                  </template>
                  <span>Forwarder</span>
                </v-tooltip>
                <br />
                <span style="font-size: 14px; color: var(--v-primaryText-base)">
                  {{ file.forwarder ? file.forwarder.name : "-" }}</span
                >
                <br v-if="file.onBehalfForwarder" />
                <span
                  v-if="file.onBehalfForwarder"
                  style="font-size: 12px; color: var(--v-primaryText-base)"
                >
                  o.b.o.
                  {{
                    file.onBehalfForwarder ? file.onBehalfForwarder.name : "-"
                  }}</span
                >
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col v-if="file.buyer">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" large class="mb-1">shopping_cart</v-icon>
                  </template>
                  <span>Buyer</span>
                </v-tooltip>
                <br />
                <span style="font-size: 14px; color: var(--v-primaryText-base)">
                  {{ file.buyer ? file.buyer.name : "-" }}</span
                >
                <br v-if="file.onBehalfBuyer" />
                <span
                  v-if="file.onBehalfBuyer"
                  style="font-size: 12px; color: var(--v-primaryText-base)"
                >
                  o.b.o.
                  {{ file.onBehalfBuyer ? file.onBehalfBuyer.name : "-" }}</span
                >
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col v-if="file.consignee">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" large class="mb-1">call_received</v-icon>
                  </template>
                  <span>Consignee</span>
                </v-tooltip>
                <br />
                <span style="font-size: 14px; color: var(--v-primaryText-base)">
                  {{ file.consignee ? file.consignee.name : "-" }}</span
                >
                <br v-if="file.onBehalfConsignee" />
                <span
                  v-if="file.onBehalfConsignee"
                  style="font-size: 12px; color: var(--v-primaryText-base)"
                >
                  o.b.o.
                  {{
                    file.onBehalfConsignee ? file.onBehalfConsignee.name : "-"
                  }}</span
                >
              </v-col>
              <v-divider vertical inset></v-divider>
            </v-row>
          </v-col>
          <v-col class="text-center">
            <v-row class="text-center">
              <!-- <v-col cols="12" class="text-center">
                        <span style="font-size: 18px">Shipment Details</span>
                      </v-col> -->
              <v-col cols="12" sm="4">
                <v-row align="center" class="mt-0 pt-0">
                  <v-col cols="4" class="text-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-avatar size="32" right v-on="on">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${file.originCountry.iso2.toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </template>
                      <span>{{ file.originCountry.name }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-icon>arrow_forward</v-icon>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-avatar left size="32" v-on="on">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${file.destinationCountry.iso2.toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </template>
                      <span>{{ file.destinationCountry.name }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <!-- <br /> -->
                <span style="font-size: 12px; color: var(--v-primaryText-base)">
                  {{ file.portOfLoadValue }} -
                  {{ file.finalDestinationValue }}</span
                >
                <br
                  v-if="
                    file.portOfDischargeValue &&
                    file.portOfDischargeValue != file.finalDestinationValue
                  "
                />

                <!-- <span style="font-size: 12px; color: var(--v-primaryText-base)"
                  v-if="item.portOfDischargeValue && item.portOfDischargeValue != item.finalDestinationValue">
                  VIA
                  {{ item.portOfDischargeValue }}</span> -->
                <!-- <span>POL - POD</span> -->
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col cols="12" sm="4">
                <!-- -  -->
                <div v-if="file.shippingLine">
                  <v-avatar
                    class="mb-1"
                    v-if="file.shippingLine.logo"
                    color="white"
                  >
                    <v-img :src="file.shippingLine.logo" contain></v-img>
                  </v-avatar>
                  <br />
                  {{ file.shippingLine.name }}
                </div>
                <div v-else>
                  <v-icon large class="mb-1">directions_boat_filled</v-icon>
                </div>
                <!-- <br /> -->
                <span
                  v-if="file.vessel"
                  style="color: var(--v-primaryText-base)"
                >
                  {{ file.linkedVessel.name }}
                </span>
                <span v-else>{{ file.vesselName }}</span>
                <span> {{ file.voyage }}</span>
                <!-- <br /> -->
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col cols="12" sm="4">
                <v-icon large class="mb-1">schedule</v-icon> <br />
                <span
                  v-if="file.isATD"
                  style="font-size: 12px; color: var(--v-success-base)"
                  >ATD: {{ formatDate(file.etd) }}</span
                >
                <span
                  v-else
                  style="font-size: 12px; color: var(--v-primaryText-base)"
                  >ETD: {{ formatDate(file.etd) }}</span
                >
                <br />
                <span
                  v-if="file.isATA"
                  style="font-size: 12px; color: var(--v-success-base)"
                  >ATA: {{ formatDate(file.eta) }}</span
                >
                <span
                  v-else
                  style="font-size: 12px; color: var(--v-primaryText-base)"
                  >ETA: {{ formatDate(file.eta) }}</span
                >
                <br />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="9">
            <v-data-table
              :items="file.bookingContainers"
              :headers="containerHeaders"
              dense
              hide-default-footer
              disable-pagination
              style="cursor: pointer"
            >
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="3">
            <v-list v-if="file.status != 'Cancelled'">
              <v-subheader>
                <v-icon color="secondary" class="mr-2">insights</v-icon>
                Actions</v-subheader
              >
              <v-divider></v-divider>
              <v-autocomplete
                v-if="!allowShipmentProcess && !file.shipmentId"
                dense
                outlined
                label="Primary Booking"
                :items="linkedBookings"
                hide-details
                item-value="id"
                item-text="orderNo"
                v-model="file.bookingId"
                @change="updatePrimaryBooking()"
              >
                <template v-slot:label>
                  <span style="font-size: 13px"> Primary Booking </span>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-if="!allowShipmentProcess && !file.shipmentId"
                dense
                outlined
                label="Assign User to Shipment"
                :items="users"
                hide-details
                item-value="id"
                item-text="fullName"
                v-model="file.assignedDocumentUserId"
                :disabled="file.status == 'Ready' && !allowShipmentProcess"
                @change="updateAssignedUser(file.assignedDocumentUserId)"
              >
                <template v-slot:label>
                  <span style="font-size: 13px">
                    Assign User to Shipment*
                  </span>
                </template>
              </v-autocomplete>
              <!-- <v-autocomplete label="Document Processing Office" v-model="file.documentProcessingAddressId" @change="updateDocumentationAddress()"
                :items="addresses" outlined dense item-text="alias" item-value="id"></v-autocomplete> -->
              <!-- <v-list-item
                v-if="!file.assignedDocumentUserId"
                @click="assignUser()"
              >
                <v-list-item-action>
                  <v-icon color="tertiary">add_circle_outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Assign User to Document </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item
                style="height: 30px"
                v-if="!file.shipmentId && file.status != 'Ready'"
                @click="addContainers()"
              >
                <v-list-item-action>
                  <v-icon color="blue" small>add_circle_outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    Add Containers
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                style="height: 30px"
                v-if="!file.shipmentId && file.status != 'Ready'"
                @click="removeContainers()"
              >
                <v-list-item-action>
                  <v-icon color="red" small>remove_circle_outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    Remove Containers
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :key="coverKey" style="height: 30px">
                <v-list-item-action>
                  <v-icon small color="blue-grey">article</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    File Cover
                  </v-list-item-title>
                  <v-list-item-subtitle
                    style="font-size: 11px"
                    v-if="file.fileCover"
                  >
                    Version: {{ file.fileCover.version }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-container>
                    <v-row justify="start" class="ml-1 py-1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="generateCover()"
                            :loading="generatingFileCover"
                            v-on="on"
                          >
                            <v-icon small color="teal">post_add</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Generate new cover</span>
                      </v-tooltip>
                      <v-tooltip top v-if="file.fileCover">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="viewDocument(file.fileCover)"
                            v-on="on"
                          >
                            <v-icon small color="blue">launch</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">View Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="file.fileCover">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            @click="downloadDocument(file.fileCover)"
                            v-on="on"
                          >
                            <v-icon small>download</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Download</span>
                      </v-tooltip>
                    </v-row>
                  </v-container>
                </v-list-item-action>
              </v-list-item>

              <v-list-item
                style="height: 30px"
                :disabled="
                  file.shipmentId ||
                  (file.status == 'Ready' && !allowShipmentProcess) ||
                  disabledByContractOwner
                "
                @click="changeProfileModal = true"
              >
                <v-list-item-action>
                  <v-icon small color="orange">handshake</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    {{
                      file.consigneeProfile
                        ? file.consigneeProfile.systemReference
                        : ""
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    Shipment Profile
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                style="height: 30px"
                v-if="!file.shipmentId && allowShipmentProcess"
                @click="
                  confirmProcessShipment = true || disabledByContractOwner
                "
              >
                <v-list-item-action>
                  <v-icon small color="success">next_plan</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    Process File to Shipment
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                style="height: 30px"
                v-if="!file.shipmentId && file.status == 'Ready'"
                @click="confirmChangeToDraft = true"
              >
                <v-list-item-action>
                  <v-icon small color="orange">undo</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    Change status to Draft
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                style="height: 30px"
                v-if="!file.shipmentId && file.status != 'Ready'"
                :disabled="
                  !file.assignedDocumentUserId || disabledByContractOwner
                "
                @click="confirmMarkReady = true"
              >
                <v-list-item-action>
                  <v-icon small color="success">next_plan</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    Mark File as Ready
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                style="height: 30px"
                v-if="!file.shipmentId && file.status != 'Ready'"
                @click="confirmCancelFile = true"
              >
                <v-list-item-action>
                  <v-icon color="red" small>cancel</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    Cancel File
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="height: 30px">
                <v-list-item-action>
                  <v-icon small :color="file.ucrNo ? 'green' : 'grey'"
                    >tag</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    {{ file.ucrNo ?? "-" }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    UCR Number
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon @click="changeFileUCRModal = true" v-on="on">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span style="font-size: 12px">Edit UCR Number</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-if="file.shipmentId"
                @click="viewShipment()"
                style="height: 30px"
              >
                <v-list-item-action>
                  <v-icon color="primary" small>launch</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    View Shipment
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div v-else style="padding-top: 0; margin-top: 0" class="text-center">
      <v-card>
        <v-card-text>
          <valhalla-loading height="60vh" />
        </v-card-text>
      </v-card>
    </div>

    <v-dialog v-model="changeFileUCRModal" max-width="30vw" persistent>
      <v-card style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">Update UCR Number</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :loading="savingFileUCR"
            icon
            @click="saveFileUCR()"
            color="primary"
            ><v-icon small>save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="file.ucrNo"
            dense
            outlined
            small
            label="UCR Number"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changeProfileModal"
      :width="!settingShipmentProfile ? '90vw' : '400px'"
      :fullscreen="!settingShipmentProfile || $vuetify.breakpoint.mobile"
    >
      <SearchShipmentProfiles
        v-if="!settingShipmentProfile"
        :customerId="file.customerId"
        :contractOwner="
          linkedBookings.length > 0 ? linkedBookings[0].contractOwner : null
        "
        :height="!settingShipmentProfile ? '90vh' : '400px'"
        :filter="{
          status: 'Approved',
          originCountryId: file.originCountry?.id,
          destinationCountryId: file.destinationCountry?.id,
          finalDestinationValue: file.finalDestinationValue
        }"
        @close="changeProfileModal = false"
        @selected="incoTerms"
      />
      <v-container style="width: 100%; height: 200px" v-else>
        <v-row justify="center" align="center" style="height: 100%">
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <div class="mt-3">Setting Shipment Profile...</div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <!-- <v-dialog
      v-model="changeProfileModal"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-text>
          <v-row justify="end">
            <v-col cols="12" sm="4">
              <v-text-field
                placeholder="Search"
                autofocus
                prepend-inner-icon="search"
                v-model="searchProfiles"
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
              <v-btn color="primary" icon @click="changeProfileModal = false"
                ><v-icon>cancel</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-data-table
            id="profile-info"
            style="cursor: pointer; font-size: 12px"
            :items="filteredProfiles"
            dense
            :headers="shipmentProfileHeaders"
            fixed-header
            :search="searchProfiles"
            height="70vh"
            @click:row="incoTerms"
          >
            <template v-slot:[`item.systemReference`]="{ item }">
              <b>{{ item.systemReference }}</b>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-if="item.status == 'Draft'"
                color="blue"
                class="white--text"
                small
                >Draft</v-chip
              >
              <v-chip
                v-else-if="item.status == 'Approved'"
                color="success"
                class="white--text"
                small
                >Approved</v-chip
              >
            </template>
            <template v-slot:[`item.shipper.name`]="{ item }">
              <span v-if="item.shipper">{{ item.shipper.name }}</span>
              <span>
                <span v-if="item.onBehalfShipper">
                  <br />
                  On behalf of {{ item.onBehalfShipper.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.forwarder.name`]="{ item }">
              <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
              <span>
                <span v-if="item.onBehalfForwarder">
                  <br />
                  On behalf of {{ item.onBehalfForwarder.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.consignee.name`]="{ item }">
              <span v-if="item.consignee">{{ item.consignee.name }}</span>
              <span>
                <span v-if="item.onBehalfConsignee">
                  <br />
                  On behalf of {{ item.onBehalfConsignee.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.buyer.name`]="{ item }">
              <span v-if="item.buyer">{{ item.buyer.name }}</span>
              <span>
                <span v-if="item.onBehalfBuyer">
                  <br />
                  On behalf of {{ item.onBehalfBuyer.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.loadingPorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allOriginPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'loading'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.dischargePorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allDestinationPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'discharge'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.originCountry.name`]="{ item }">
              <v-tooltip top v-if="item.originCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="item.originCountry && item.originCountry.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.originCountry.name
                }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.documentationPOD`]="{ item }">
              <template v-if="item.consigneeProfilePorts.length > 0">
                {{ item.consigneeProfilePorts[0].name }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <template v-slot:[`item.destinationCountry.name`]="{ item }">
              <v-tooltip top v-if="booking.destinationCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="
                        item.destinationCountry && item.destinationCountry.iso2
                      "
                      :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.destinationCountry.name
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.incoTerm`]="{ item }">
              <v-chip
                small
                outlined
                class="mt-1"
                v-for="term in item.consigneeProfileIncoTerms"
                :key="term.id"
                >{{ term.incoTerm }}</v-chip
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-dialog
      v-model="setIncoTermModal"
      :fullscreen="$vuetify.breakpoint.mobile"
      width="500px"
    >
      <v-card style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-card-title class="text-center" style="font-size: 16px">Select IncoTerm</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="9">
              <v-list dense v-for="(item, index) in incoTermOptions" :key="index">
                <v-list-item
                  @click="
                    setFileProfile(selectedProfile, item),
                      (setIncoTermModal = false)
                  "
                  :disabled="item.contractOwner !== file.contractOwner"
                >
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px"
                      >Inco Term: {{ item.incoTerm }}</v-list-item-title
                    >
                    <v-list-item-subtitle style="font-size: 11px"
                      >Freight Charge:
                      {{
                        item.freightCharge
                          ? item.freightCharge.toUpperCase()
                          : ""
                      }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle style="font-size: 11px"
                      >Destination Charge:
                      {{
                        item.destinationCharge
                          ? item.destinationCharge.toUpperCase()
                          : ""
                      }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle style="font-size: 11px"
                      >Origin Charge:
                      {{
                        item.originCharge ? item.originCharge.toUpperCase() : ""
                      }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle style="font-size: 11px"
                      >Contract Owner:
                      {{
                        item.contractOwner
                          ? item.contractOwner.toUpperCase()
                          : ""
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addContainerModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loadingContainers" style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> Add Containers </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            icon
            v-if="selectedContainers.length > 0"
            :loading="savingContainers"
            @click="saveAddContainers()"
            ><v-icon small>save</v-icon></v-btn
          >
          <v-btn icon color="redPop" @click="addContainerModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-subtitle class="my-0 py-0">
          {{ selectedContainers.length }} Containers Selected
        </v-card-subtitle>
        <v-card-text class="mt-0 pt-0">
          <v-text-field
            hide-details
            placeholder="Search"
            prepend-inner-icon="search"
            v-model="searchContainer"
            outlined
            dense
            clearable
            rounded
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="!loadingContainers && containers.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No matching containers</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="container in filterContainers"
              :key="container.id"
              @click="selectContainer(container.id)"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">{{ container.ctoNo }}</v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px" v-if="container.containerNo">
                  {{ container.containerNo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 11px" v-if="container.booking">
                  Booking: {{ container.booking.orderNo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 11px" v-if="container.customerContainerRef">
                  Customer Ref: {{ container.customerContainerRef }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon small
                  v-if="selectedContainers.includes(container.id)"
                  color="success"
                  >check</v-icon
                >
              </v-list-item-action>
            </v-list-item></v-list
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="removeContainerModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loadingContainers" style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> Remove Containers </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            icon
            v-if="selectedContainers.length > 0"
            :loading="savingContainers"
            @click="saveRemoveContainers()"
            ><v-icon small>save</v-icon></v-btn
          >
          <v-btn icon @click="removeContainerModal = false" color="redPop">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-subtitle class="my-0 py-0">
          {{ selectedContainers.length }} Containers Selected
        </v-card-subtitle>
        <v-card-text class="mt-0 pt-0">
          <v-text-field
            hide-details
            placeholder="Search"
            prepend-inner-icon="search"
            v-model="searchContainer"
            outlined
            dense
            clearable
            rounded
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-if="!loadingContainers && filterCurrentContainers.length == 0"
            >
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No matching containers</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="container in filterCurrentContainers"
              :key="container.id"
              @click="selectContainer(container.id)"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">{{ container.ctoNo }}</v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px" v-if="container.containerNo">
                  {{ container.containerNo }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 11px" v-if="container.booking">
                  Booking: {{ container.booking.orderNo }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon small
                  v-if="selectedContainers.includes(container.id)"
                  color="success"
                  >check</v-icon
                >
              </v-list-item-action>
            </v-list-item></v-list
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmCancelFile"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn icon @click="confirmCancelFile = false" color="redPop">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would like to cancel this file?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmCancelFile = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="cancelFile()"
              :loading="cancellingFile"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmProcessShipment"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="confirmProcessShipment = false">
            <v-icon small>close</v-icon>
          </v-btn>

        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would like to convert this file to a shipment?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmProcessShipment = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="processFile()"
              :loading="processingFile"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmMarkReady"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="confirmProcessShipment = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would like to mark this file as ready for
            documentation?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmMarkReady = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="markAsReady()"
              :loading="processingFile"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmChangeToDraft"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="confirmChangeToDraft = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would like to mark this file as draft ?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmChangeToDraft = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="markAsDraft()"
              :loading="processingFile"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewModal"
      width="1200px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card v-if="file && file.fileCover && file.fileCover.name" style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            {{ file.fileCover.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(file.fileCover)" icon
            ><v-icon small>download</v-icon></v-btn
          >
          <v-btn @click="fullscreen = !fullscreen" icon
            ><v-icon small v-if="!fullscreen">fullscreen</v-icon
            ><v-icon small v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon color="redPop" @click="previewModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text
          :style="{
            'max-height': fullscreen ? '90vh' : '75vh',
            'overflow-y': 'auto',
          }"
        >
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div
                v-if="
                  file.fileCover.type && file.fileCover.type.includes('image/')
                "
              >
                <v-img
                  :src="file.fileCover.url"
                  contain
                  style="max-height: 70vh"
                  @load="imageLoading = false"
                >
                </v-img>
              </div>
              <div v-else-if="file.fileCover.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col
                    cols="12"
                    sm="9"
                    class="text-center"
                    v-if="file.fileCover.url"
                  >
                    <div ref="pdfBox">
                      <VuePdfEmbed
                        :width="fullscreen ? 1200 : 900"
                        :source="file.fileCover.url"
                        @loaded="imageLoading = false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span
                      >This file is in a format that cannot be previewed.</span
                    >
                    <br /><br />
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="downloadDocument(file.fileCover)"
                    >
                      <v-icon class="mr-1">download</v-icon> Download</v-btn
                    >
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="assignUserDialog" width="20vw">
      <v-card height="auto" style="
        background-color: rgba(0, 0, 0, 0.2) !important;
        background: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      ">
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">Assign User to Document</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="assignUserDialog = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-autocomplete
                v-model="selectedUser"
                :items="users"
                :loading="loadingUsers"
                return-object
                outlined
                clearable
                :search-input.sync="searchUsers"
                :key="userSearchKey"
                dense
                label="Search for a user"
              >
                <template v-slot:selection="{ item }">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img
                        v-if="item.avatar"
                        :src="`https://cdn.loglive.io/avatars/${item.avatar}`"
                      ></v-img>
                      <v-icon v-else>person</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ item.firstname }}
                        {{ item.surname }}</v-list-item-title
                      >
                      <v-list-item-subtitle>
                        {{ item.emailAddress }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-avatar>
                    <v-img
                      v-if="item.avatar"
                      :src="`https://cdn.loglive.io/avatars/${item.avatar}`"
                    ></v-img>
                    <v-icon v-else>person</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.firstname }}
                      {{ item.surname }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ item.emailAddress }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn color="primary" small outlined @click="assignDocumentUser(selectedUser)"
              >Assign</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import SearchShipmentProfiles from "../SystemComponents/SearchShipmentProfile.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  props: ["fileId", "allowShipmentProcess"],
  components: {
    SearchShipmentProfiles,
    VuePdfEmbed,
  },
  data: () => ({
    changeFileUCRModal: false,
    savingFileUCR: false,
    addContainerModal: false,
    addresses: [],
    cancellingFile: false,
    confirmCancelFile: false,
    selectedUser: null,
    users: [],
    confirmChangeToDraft: false,
    loadingUsers: false,
    userTimeout: null,
    userSearchKey: 0,
    confirmProcessShipment: false,
    assignUserDialog: false,
    confirmMarkReady: false,
    containers: [],
    changeProfileModal: false,
    searchUsers: null,
    disabledByContractOwner: true,
    shipmentProfileHeaders: [
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Buyer",
        value: "buyer.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Final Dest.",
        value: "finalDestinationCity",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
    ],
    containerHeaders: [
      // {
      //     value: 'actions',
      //     text: '',
      //     sortable: false,
      //     width: '100px',
      //     align: 'center',
      //     show: true,
      // },

      {
        value: "ctoNo",
        text: "CTO No.",
        align: "center",
        show: true,
      },
      {
        value: "booking.orderNo",
        text: "Booking No.",
        align: "center",
        show: true,
      },
      {
        value: "customerContainerRef",
        text: "Customer Ref.",
        align: "center",
        show: true,
      },
      {
        value: "containerNo",
        text: "Container No.",
        align: "center",
        show: true,
      },
      {
        value: "sealNo",
        text: "Seal No.",
        align: "center",
        show: true,
      },
      {
        value: "containerTypeCode",
        text: "Type",
        align: "center",
        show: true,
      },
      {
        value: "verificationMethod",
        text: "Method",
        align: "center",
        show: true,
      },
      // {
      //   value: 'features',
      //   text: 'Features',
      //   align: 'center',
      //   width: '150px',
      //   show: true,
      //   sortable: false
      // },
      // {
      //     value: 'regimeCode',
      //     text: 'Regime',
      //     align: 'center',
      //     show: true
      // },
      {
        value: "products",
        text: "Products",
        align: "center",
        show: true,
        sortable: false,
      },
      {
        value: "contractOwner",
        text: "Contract Owner",
        align: "center",
        show: true,
        sortable: false,
      },
    ],
    coverKey: 100,
    fullscreen: false,
    generatingFileCover: false,
    imageLoading: false,
    linkedBookings: [],
    loading: false,
    loadingContainers: false,
    previewModal: false,
    processingFile: false,
    file: {},
    removeContainerModal: false,
    savingContainers: false,
    searchContainer: null,
    selectedContainers: [],
    booking: {},
    searchProfiles: "",
    settingShipmentProfile: false,
    loadingShipmentProfiles: false,
    shipmentProfiles: [],
    setIncoTermModal: false,
    incoTermOptions: [],
    selectedProfile: {},
  }),
  watch: {
    fileId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.load();
          this.checkContractMatch();
        }
      },
    },
    async searchUsers(val) {
      if (this.loadingUsers) {
        clearTimeout(this.userTimeout);
      }
      if (val && val.length > 2) {
        this.userTimeout = setTimeout(async () => {
          this.loadingUsers = true;
          this.users = await this.$API.getUsers({
            params: { search: val, limit: 15 },
          });
          // console.log('users',this.users);
          this.loadingUsers = false;
          this.userSearchKey++;
        }, 300);
        this.loadingUsers = false;
      } else {
        clearTimeout(this.userTimeout);
        this.loadingUsers = false;
      }
    },
  },
  computed: {
    filteredProfiles() {
      if (this.shipmentProfiles.data == null) return [];
      let result = this.shipmentProfiles.data.filter(
        (x) =>
          x.status === "Approved" &&
          x.originCountryId === this.file.originCountry?.id &&
          x.destinationCountryId === this.file.destinationCountry?.id &&
          x.consigneeProfileIncoTerms?.some(
            (y) => y.contractOwner === this.linkedBookings?.[0]?.contractOwner
          ) &&
          (!x.finalDestinationValue ||
            x.finalDestinationValue === this.file.finalDestinationValue)
      );
      // console.log('filtered profiles',result)
      // console.log('booking',this.booking)
      // result = result
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.systemReference &&
              x.systemReference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.reference &&
              x.reference
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.buyer &&
              x.buyer.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.forwarder &&
              x.forwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.shipper &&
              x.shipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consignee &&
              x.consignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfForwarder &&
              x.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfShipper &&
              x.onBehalfShipper.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.onBehalfConsignee &&
              x.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.searchProfiles.toLowerCase())) ||
            (x.consigneeProfileIncoTerms &&
              x.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.searchProfiles.toLowerCase()))
        );
      }
      return result;
    },
    filterContainers() {
      let result = this.containers;
      if (this.searchContainer) {
        result = result.filter(
          (x) =>
            (x.ctoNo &&
              x.ctoNo
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.booking &&
              x.booking.orderNo
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase()))
        );
      }
      return result;
    },
    filterCurrentContainers() {
      let result = this.file.bookingContainers ?? [];
      if (this.searchContainer) {
        result = result.filter(
          (x) =>
            (x.ctoNo &&
              x.ctoNo
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase())) ||
            (x.booking &&
              x.booking.orderNo
                .toLowerCase()
                .includes(this.searchContainer.toLowerCase()))
        );
      }
      return result;
    },
  },
  mounted() {},
  unmounted() {
    this.fileId = null;
  },
  created() {
    // this.getOrganisationAddresses()
    this.getOrganisationUsers();
  },
  methods: {
    checkContractMatch() {
      if (
        !this.file.bookingContainers ||
        this.file.bookingContainers.length === 0
      )
        return;

      let match = this.file.bookingContainers?.every(
        (x) => x.booking.contractOwner === this.file.contractOwner
      );
      // console.log('match',match);
      if (match) {
        this.disabledByContractOwner = false;
      } else {
        this.disabledByContractOwner = true;
      }
    },
    updateAssignedUser(userId) {
      const userdata = this.users.find((user) => user.id === userId);
      this.file.documentProcessingAddressId = userdata.organisationAdressId;
    },
    async getOrganisationUsers() {
      this.users = await this.$API.getDocUsers();
      // console.log('return users',this.users);
    },
    assignUser() {
      this.assignUserDialog = true;
    },
    async assignDocumentUser(user) {
      this.file.assignedDocumentUserId = user.id;
      this.file.assignedDocumentUser = user;
      this.assignUserDialog = false;
      let assignment = await this.$API.assignDocumentUser({
        documentId: this.file.id,
        userId: user.id,
      });
    },
    // async getOrganisationAddresses() {
    //   this.addresses = await this.$API.getOrganisationAddress()
    //   // this.userAddress = await this.$API.getOrganisationUserAddress()
    // },

    async getShipmentProfiles() {
      this.loadingShipmentProfiles = true;
      this.shipmentProfiles = await this.$API.listCustomerConsigneeProfile(
        this.file.customerId,
        {}
      );
      // console.log('shipment profiles',this.shipmentProfiles)
      this.loadingShipmentProfiles = false;
    },
    async markAsDraft() {
      this.processingFile = true;
      this.file.status = "Draft";
      await this.$API.updateFileStatus(this.file.id, {
        status: "Draft",
      });
      this.confirmChangeToDraft = false;
      this.processingFile = false;
    },
    async markAsReady() {
      this.processingFile = true;
      this.file.status = "Ready";
      await this.$API.updateFileStatus(this.file.id, {
        status: "Ready",
        assignedDocumentUserId: this.file.assignedDocumentUserId,
        documentProcessingAddressId: this.file.documentProcessingAddressId,
      });
      this.confirmMarkReady = false;
      this.processingFile = false;
    },
    async incoTerms(profile) {
      this.incoTermOptions = profile.consigneeProfileIncoTerms;
      this.settingShipmentProfile = true;
      this.selectedProfile = profile;
      this.changeProfileModal = false;
      if (this.incoTermOptions.length == 1) {
        let item = this.incoTermOptions[0];
        await this.setFileProfile(profile, item);
      } else {
        this.setIncoTermModal = true;
      }
      this.settingShipmentProfile = false;
    },
    async setFileProfile(profile, item) {
      this.file.consigneeProfile = profile;
      this.file.consigneeProfileId = profile.id;
      this.file.consignee = profile.consignee;
      this.file.courierPartyId = profile.courierParty?.id;
      if (item) {
        this.setProfileIncoterm(item);
      } else {
        this.file.dealTerm = null;
        this.file.dealTermId = null;
      }
      await this.updateBookingFile();
      this.selectedProfile = {};
      this.changeProfileModal = false;
    },
    setProfileIncoterm(term) {
      this.file.incoTermId = term.id;
    },
    async updateBookingFile() {
      let obj = {
        fileId: this.file.id,
        consigneeProfileId: this.file.consigneeProfileId,
        incoTermId: this.file.incoTermId,
        shipmentFileId: this.file.id,
        customerId: this.file.customerId,
        containers: this.filterCurrentContainers.map((x) => x.id),
        courierPartyId: this.file.courierPartyId,
      };
      await this.$API.updateBookingFile(obj);
    },
    addContainers() {
      this.searchContainer = null;
      this.selectedContainers = [];
      this.addContainerModal = true;
      this.getContainers();
    },
    async cancelFile() {
      this.cancellingFile = true;
      await this.$API.cancelFile(this.file.id);
      this.file.status = "Cancelled";
      this.confirmCancelFile = false;
      this.$message.success("Successfully cancelled file!");
      this.$emit("Cancelled", this.file.id);
      this.cancellingFile = false;
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd mmm, yyyy");
      }
      return result;
    },
    async getContainers() {
      this.loadingContainers = true;
      this.containers = await this.$API.getMatchingBookingContainers({
        params: {
          portOfLoadValue: this.file.portOfLoadValue,
          finalDestinationValue: this.file.finalDestinationValue,
          vesselId: this.file.linkedVesselId,
          voyage: this.file.voyage,
          customerId: this.file.customerId,
          // consigneeId: this.file.consigneeId,
          shipperId: this.file.shipperId,
        },
      });

      this.loadingContainers = false;
    },
    async load() {
      this.loading = true;
      this.file = await this.$API.getShipmentFileDetail(this.fileId);
      this.linkedBookings = await this.$API.getLinkedFileBookings(this.fileId);
      // console.log('linkedBookings',this.linkedBookings);
      await this.getShipmentProfiles();
      // this.booking = await this.$API.getShipmentBookingDetail(this.fileId)
      this.checkContractMatch();
      this.loading = false;
    },
    async generateCover() {
      try {
        this.generatingFileCover = true;
        let result = await this.$API.generateFileCover(this.fileId);
        if (result && result.key) {
          this.file.fileCover = result;
          this.file.fileCoverId = result.id;
        }
        this.generatingFileCover = false;
        this.coverKey++;
        this.$message.success("File cover generated successfully!");
      } catch (e) {
        console.log(e);
        this.generatingFileCover = false;
        this.$message.error(
          "Oops! an error occurred while genrating the cover"
        );
      }
    },
    async processFile() {
      try {
        this.processingFile = true;
        let result = await this.$API.createShipmentFromFile({
          shipmentFileId: this.fileId,
        });
        this.file.status = "Processed";
        this.file.shipmentId = result.id;
        this.file.shipment = result;
        this.confirmProcessShipment = false;
        this.processingFile = false;
        this.$message.success("Successfully created shipment!");
        this.$emit("ProcessedFile", this.file);
      } catch (e) {
        this.processingFile = false;
        this.$message.error("An error occured while processing the file");
      }
    },
    removeContainers() {
      this.searchContainer = null;
      this.selectedContainers = [];
      this.removeContainerModal = true;
    },
    async saveRemoveContainers() {
      this.savingContainers = true;
      await this.$API.removeContainersFromFile({
        shipmentFileId: this.file.id,
        containerIds: this.selectedContainers,
      });
      this.removeContainerModal = false;
      this.savingContainers = false;
      this.$message.success("Successfully removed containers!");
      this.load();
    },
    async saveAddContainers() {
      this.savingContainers = true;
      await this.$API.addContainersToFile({
        shipmentFileId: this.file.id,
        containerIds: this.selectedContainers,
      });
      this.addContainerModal = false;
      this.savingContainers = false;
      this.$message.success("Successfully added containers!");
      this.load();
    },
    selectContainer(id) {
      let index = this.selectedContainers.indexOf(id);
      if (index == -1) {
        this.selectedContainers.push(id);
      } else {
        this.selectedContainers.splice(index, 1);
      }
    },
    async updatePrimaryBooking() {
      this.loadingPrimaryBooking = true;
      await this.$API.updatePrimaryFileBooking(this.fileId, {
        bookingId: this.file.bookingId,
      });
      this.file = await this.$API.getShipmentFileDetail(this.fileId);
      // console.log('this.file',this.file)
      this.$message.success("Successfully updated linked booking");
      this.loadingPrimaryBooking = false;
    },
    async updateDocumentationAddress() {
      let obj = {
        fileId: this.file.id,
        consigneeProfileId: this.file.consigneeProfileId,
        incoTermId: this.file.incoTermId,
        shipmentFileId: this.file.id,
        customerId: this.file.customerId,
        documentProcessingAddressId: this.file.documentProcessingAddressId,
      };
      await this.$API.updateBookingFile(obj);
    },
    viewDocument() {
      this.previewModal = true;
    },
    viewShipment() {
      this.$router.push({
        path: "/shipment/" + this.file.shipment.shipmentReference,
      });
    },
    async saveFileUCR() {
      this.savingFileUCR = true;
      let obj = {
        id: this.file.id,
        ucrNo: this.file.ucrNo,
      };
      await this.$API.saveFileUCR(obj);
      this.savingFileUCR = false;
      this.changeFileUCRModal = false;
      this.$message.success("Successfully updated UCR number!");
    },
  },
};
</script>