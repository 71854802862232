<template>
  <div>
    <v-card :loading="loading">
        <v-card-title>
            {{ container.containerNo }}
        </v-card-title>
      <v-card-text>
        <v-list dense>
            <v-list-item v-for="item in history" :key="item.id">
                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.booking.orderNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <i>{{ formatDate(item.createdAt) }}</i>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import dateFormat from 'dateformat'
export default {
  props: ["containerId", "container"],
  data: () => ({
    history: [],
    loading: false,
  }),
  watch: {
    containerId(val) {
      if (val) {
        this.getHistory();
      }
    },
  },
  methods: {
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getHistory() {
      this.loading = true;
      this.history = await this.$API.getContainerHistory(this.containerId);
      this.loading = false;
    },
  },
};
</script>