<template>
  <div>
    <div v-if="showFog" style="z-index: 1200">
      <div id="foglayer_01" class="fog">
        <div class="image01"></div>
        <div class="image02"></div>
      </div>
      <div id="foglayer_02" class="fog">
        <div class="image01"></div>
        <div class="image02"></div>
      </div>
      <div id="foglayer_03" class="fog">
        <div class="image01"></div>
        <div class="image02"></div>
      </div>
    </div>
    <div class="main-div" :style="{ height: height }">
      <transition name="fade" mode="out-in">
        <v-container class="fill-height" style="background: transparent">
          <v-row justify="center" align="center" class="text-center">
            <v-col cols="12" class="text-center">
              <img
                style="height: 180px; background: transparent"
                :src="tenantBrand.logoUrl.loaderLight"
                v-if="$vuetify.theme.dark"
              />
              <img
                style="height: 180px; background: transparent"
                :src="tenantBrand.logoUrl.loaderDark"
                v-else
              />
            </v-col>
            <v-col cols="12" class="text-center">
              <div class="loading-text" style="background: transparent">
                <span class="loading-text-words">-</span>
                <span class="loading-text-words">L</span>
                <span class="loading-text-words">Ø</span>
                <span class="loading-text-words">A</span>
                <span class="loading-text-words">D</span>
                <span class="loading-text-words">I</span>
                <span class="loading-text-words">N</span>
                <span class="loading-text-words">G</span>
                <span class="loading-text-words">-</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    height: {
      type: String,
      default: "98vh",
    },
    showFog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    quote: {},
    quotes: [
      {
        _id: "oPuec6Zts4",
        author: "Seneca the Younger",
        content:
          "If one does not know to which port one is sailing, no wind is favorable.",
        tags: ["wisdom"],
        authorSlug: "seneca-the-younger",
        length: 72,
        dateAdded: "2022-07-06",
        dateModified: "2022-07-08",
      },
      {
        _id: "n_jofZWaKsv",
        author: "Winston Churchill",
        content:
          "We are masters of the unsaid words, but slaves of those we let slip out.",
        tags: ["wisdom"],
        authorSlug: "winston-churchill",
        length: 72,
        dateAdded: "2022-03-12",
        dateModified: "2022-03-12",
      },

      {
        _id: "LQy9bhVL8Gy",
        author: "Abraham Lincoln",
        content:
          "When I do good I feel good, when I do bad I feel bad, and that's my religion.",
        tags: ["wisdom", "character"],
        authorSlug: "abraham-lincoln",
        length: 77,
        dateAdded: "2022-03-12",
        dateModified: "2022-03-12",
      },
      {
        _id: "NxfShZ40XpO",
        author: "Abraham Lincoln",
        content:
          "It has been my experience that folks who have no vices have very few virtues.",
        tags: ["wisdom"],
        authorSlug: "abraham-lincoln",
        length: 77,
        dateAdded: "2022-03-12",
        dateModified: "2022-03-12",
      },
      {
        _id: "fKsykJ99k",
        author: "Abraham Lincoln",
        content:
          "You can fool all the people some of the time, and some of the people all the time, but you cannot fool all the people all the time.",
        tags: ["wisdom"],
        authorSlug: "abraham-lincoln",
        length: 131,
        dateAdded: "2022-03-12",
        dateModified: "2022-03-12",
      },
      {
        _id: "ztXFU0-KGBx",
        author: "Abraham Lincoln",
        content:
          "I have always found that mercy bears richer fruits than strict justice.",
        tags: ["wisdom"],
        authorSlug: "abraham-lincoln",
        length: 71,
        dateAdded: "2022-03-12",
        dateModified: "2022-03-12",
      },
      {
        _id: "P1qpVayN1l",
        author: "Winston Churchill",
        content:
          "A lie gets halfway around the world before the truth has a chance to get its pants on.",
        tags: ["history", "politics", "wisdom"],
        authorSlug: "winston-churchill",
        length: 86,
        dateAdded: "2022-03-12",
        dateModified: "2022-03-12",
      },
      {
        _id: "6c2h-AtqMj6d",
        content:
          "You can stand tall without standing on someone. You can be a victor without having victims.",
        author: "Harriet Woods",
        tags: ["wisdom", "character"],
        authorSlug: "harriet-woods",
        length: 91,
        dateAdded: "2021-05-12",
        dateModified: "2022-07-04",
      },
      {
        _id: "2wYjTZiTUSgQ",
        content:
          "The things that one most wants to do are the things that are probably most worth doing.",
        author: "Winifred Holtby",
        tags: ["wisdom"],
        authorSlug: "winifred-holtby",
        length: 87,
        dateAdded: "2021-05-12",
        dateModified: "2022-07-04",
      },
      {
        _id: "l7UNABW6dA2",
        content: "Silence is the sleep that nourishes wisdom.",
        author: "Francis Bacon",
        tags: ["wisdom"],
        authorSlug: "francis-bacon",
        length: 43,
        dateAdded: "2021-05-12",
        dateModified: "2021-05-12",
      },
      {
        _id: "2xx4dfKrYVRf",
        content:
          "Some people thrive on huge, dramatic change. Some people prefer the slow and steady route. Do what's right for you.",
        author: "Julie Morgenstern",
        tags: ["wisdom", "success"],
        authorSlug: "julie-morgenstern",
        length: 115,
        dateAdded: "2021-05-12",
        dateModified: "2022-07-04",
      },
      {
        _id: "5syS8jEn8m",
        content:
          "When a thing is done, it's done. Don't look back. Look forward to your next objective.",
        author: "George Marshall",
        tags: ["wisdom"],
        authorSlug: "george-marshall",
        length: 86,
        dateAdded: "2021-05-12",
        dateModified: "2021-05-12",
      },
      {
        _id: "CdL1z3NLSDrN",
        content:
          "Don't talk about what you have done or what you are going to do.",
        author: "Thomas Jefferson",
        tags: ["wisdom"],
        authorSlug: "thomas-jefferson",
        length: 64,
        dateAdded: "2021-05-12",
        dateModified: "2022-07-04",
      },
      {
        _id: "96eV21NlZKJ",
        content: "Make it your habit not to be critical about small things.",
        author: "Edward Everett Hale",
        tags: ["wisdom"],
        authorSlug: "edward-everett-hale",
        length: 57,
        dateAdded: "2021-05-12",
        dateModified: "2021-05-12",
      },
      {
        _id: "Oh-e1-oygRPX",
        content: "To be wronged is nothing unless you continue to remember it.",
        author: "Confucius",
        tags: ["wisdom"],
        authorSlug: "confucius",
        length: 60,
        dateAdded: "2021-05-12",
        dateModified: "2022-07-04",
      },
      {
        _id: "bfpxfIQlqZ_3",
        content:
          "He who controls others may be powerful, but he who has mastered himself is mightier still.",
        author: "Laozi",
        tags: ["wisdom"],
        authorSlug: "laozi",
        length: 90,
        dateAdded: "2021-05-12",
        dateModified: "2022-07-04",
      },
      {
        _id: "6Kl3UT6ULk",
        content:
          "Wisdom, compassion, and courage are the three universally recognized moral qualities of men.",
        author: "Confucius",
        tags: ["wisdom"],
        authorSlug: "confucius",
        length: 92,
        dateAdded: "2021-05-12",
        dateModified: "2021-05-12",
      },
      {
        _id: "OrbTAJYtKCXr",
        content:
          "Until you value yourself, you won't value your time. Until you value your time, you won't do anything with it.",
        author: "M. Scott Peck",
        tags: ["success", "wisdom"],
        authorSlug: "m-scott-peck",
        length: 110,
        dateAdded: "2021-05-07",
        dateModified: "2022-07-04",
      },
      {
        _id: "mZAy5rJ2tW",
        content:
          "The greater our knowledge increases the more our ignorance unfolds.",
        author: "John F. Kennedy",
        tags: ["wisdom"],
        authorSlug: "john-f-kennedy",
        length: 67,
        dateAdded: "2021-05-07",
        dateModified: "2021-05-07",
      },
    ],
  }),
  computed: {
    ...mapGetters(["tenantBrand"]),
  },
  mounted() {
    this.quote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
  },
};
</script>

<style lang="scss" scoped>
@mixin position-center($text-align: center) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: $text-align;
}

.main-div {
  flex: 1;
  height: 85vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  @include position-center;
  width: 100%;
  height: 100px;
}

.content {
  position: relative;
  left: 50vw;
  top: 10vh;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
  // padding-bottom: 25px;
  background: var(--v-background-base);
  background-color: var(--v-background-base);
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: var(--v-background-base);
}

.loading-text {
  // @include position-center;
  top: 0;
  width: 100%;
  height: 100px;
  line-height: 50px;
  font-size: 32px;
  background: var(--v-background-base);

  span {
    display: inline-block;
    margin: 0 5px;
    color: var(--v-primaryText-base);
    font-family: Cinzel, serif;

    @for $i from 0 through 9 {
      &:nth-child(#{$i + 1}) {
        filter: blur(0px);
        animation: blur-text 1s (#{$i/5}) + s infinite linear alternate;
      }
    }
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }

  100% {
    filter: blur(4px);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

html,
body {
  margin: 0;
  padding: 0;
}
body {
  background: #000;
  background: rgba(0, 0, 0, 1);
  overflow-x: hidden;
}

/* ---------- Fog ---------- */
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
}
#foglayer_01,
#foglayer_02,
#foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}
#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}
#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite,
    foglayer_moveme 15s linear infinite;
}
#foglayer_02,
#foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite,
    foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
  'size: cover' || 'size: 100%'; results remain the same
  'attachment: scroll' can be added or removed; results remain the same
  'attachment: fixed' causing unexpected results in Chrome
  'repeat-x' || 'no-repeat'; results remain the same
*/
#foglayer_01 .image01,
#foglayer_01 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog1.png")
    center center/cover no-repeat transparent;
}
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog2.png")
    center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-moz-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@-o-keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes foglayer_01_opacity {
  0% {
    opacity: 0.1;
  }
  22% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.28;
  }
  58% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.16;
  }
  100% {
    opacity: 0.1;
  }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@-moz-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@-o-keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes foglayer_02_opacity {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.1;
  }
  80% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.5;
  }
}
/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-moz-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@-o-keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
@keyframes foglayer_03_opacity {
  0% {
    opacity: 0.8;
  }
  27% {
    opacity: 0.2;
  }
  52% {
    opacity: 0.6;
  }
  68% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
}
/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-moz-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@-o-keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes foglayer_moveme {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
  #foglayer_01 .image01,
  #foglayer_01 .image02,
  #foglayer_02 .image01,
  #foglayer_02 .image02,
  #foglayer_03 .image01,
  #foglayer_03 .image02 {
    width: 100%;
  }
}
</style>
